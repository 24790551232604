import React, { memo, useCallback, useContext, CSSProperties, FC } from 'react'
import {
  BookIcon,
  CogIcon,
  CubeIcon,
  // HelpIcon,
  InboxIcon,
  LogOutIcon,
  Pane,
  PaneProps,
  PeopleIcon,
  SearchAroundIcon,
  TabNavigation,
  WrenchIcon,
  majorScale,
  ControlIcon
} from 'evergreen-ui'
import { Link } from 'react-router-dom'
import NavMenuItem from './nav-menu-item'
import Logo from './components/logo'
import { CredentialsContext } from './login-wrapper'
import { PlatformSetupStatus, statusProgress } from '../api/platform-status'
import ProgressRing from './progress-ring'
import ShapesIcon from './components/shapes-icon'

interface NavMenuProps {
  setupStatus?: PlatformSetupStatus
  onLogOut: () => Promise<void>
  small: boolean
}

type SetupIconProps = PaneProps & {
  progress: number
  style: CSSProperties
}

const SetupIcon: FC<SetupIconProps> = memo(({ progress, style, ...props }) => {
  return (
    <Pane {...props} position='relative' display='inline-block'>
      <WrenchIcon style={style} />
      <Pane position='absolute' top={-2} left={-8} opacity={0.6}>
        <ProgressRing
          progress={progress}
          stroke={2}
          radius={16}
          color='currentcolor'
        />
      </Pane>
    </Pane>
  )
})

SetupIcon.displayName = 'SetupIcon'

const NavMenu: FC<NavMenuProps> = memo(({ onLogOut, small, setupStatus }) => {
  const { loggingOut } = useContext(CredentialsContext)

  const handleLogOut = useCallback(async () => {
    await onLogOut()
  }, [onLogOut])

  const progress = statusProgress(setupStatus)
  const useCredentialConnectors =
    setupStatus == null || setupStatus.use_credential_connectors
  const crmOnlyConnectors =
    !setupStatus?.use_credential_connectors && !setupStatus?.use_sync_connectors

  return (
    <Pane
      elevation={2}
      position='fixed'
      zIndex={1}
      height='100%'
      width={majorScale(small ? 10 : 25)}
      // TODO: add to theme?
      backgroundColor='#1A1A1A'
      display='flex'
      flexDirection='column'
    >
      <Pane padding={majorScale(3)} is={Link} to='/'>
        {small ? <Logo markOnly width={32} /> : <Logo width={88} />}
      </Pane>
      <TabNavigation
        paddingTop={majorScale(3)}
        paddingBottom={majorScale(3)}
        flexGrow={1}
        display='flex'
        flexDirection='column'
      >
        {/* For now, we only show setup steps for users with credential connectors.
        Eventually we may update the setup guide for sources. */}
        {useCredentialConnectors && progress != null && progress < 1 ? (
          <NavMenuItem
            iconOnly={small}
            linkTo='/setup'
            // TODO: please fix following typescript error
            // @ts-expect-error
            Icon={(props) => (
              // TODO: please fix following typescript error
              // @ts-expect-error
              <SetupIcon
                progress={progress == null ? 1 : progress}
                {...props}
              />
            )}
          >
            Set up
          </NavMenuItem>
        ) : null}
        {crmOnlyConnectors ? null : (
          <NavMenuItem
            iconOnly={small}
            linkTo='/dashboard'
            Icon={SearchAroundIcon}
          >
            Dashboard
          </NavMenuItem>
        )}
        {useCredentialConnectors ? (
          <>
            <NavMenuItem
              iconOnly={small}
              linkTo='/connections'
              Icon={PeopleIcon}
            >
              Connections
            </NavMenuItem>
          </>
        ) : null}
        <NavMenuItem
          iconOnly={small}
          linkTo='/providers'
          exclude='/providers/new'
          Icon={CubeIcon}
        >
          {crmOnlyConnectors ? 'CRMs' : 'Providers'}
        </NavMenuItem>
        {crmOnlyConnectors ? null : (
          <NavMenuItem iconOnly={small} linkTo='/destinations' Icon={InboxIcon}>
            Destinations
          </NavMenuItem>
        )}
        <NavMenuItem
          iconOnly={small}
          linkTo='/crm-objects'
          // TODO: please fix following typescript error
          // @ts-expect-error
          Icon={(props) => <ShapesIcon {...props} />}
        >
          CRM Objects
        </NavMenuItem>
        <NavMenuItem iconOnly={small} linkTo='/app-objects' Icon={ControlIcon}>
          App Objects
        </NavMenuItem>
        <NavMenuItem iconOnly={small} linkTo='/settings' Icon={CogIcon}>
          Settings
        </NavMenuItem>
        <NavMenuItem
          iconOnly={small}
          linkExternal={
            crmOnlyConnectors ? 'https://xkit.co/docs' : 'https://docs.xkit.co'
          }
          Icon={BookIcon}
        >
          Documentation
        </NavMenuItem>
        {/* <NavMenuItem iconOnly={small} linkTo='/support' Icon={HelpIcon}>
          Support
        </NavMenuItem> */}
        <Pane flexGrow={1} />
        <NavMenuItem
          iconOnly={small}
          loading={loggingOut}
          onSelect={handleLogOut}
          Icon={LogOutIcon}
        >
          Log Out
        </NavMenuItem>
      </TabNavigation>
    </Pane>
  )
})

NavMenu.displayName = 'NavMenu'

export default NavMenu
