import * as React from 'react'
import { Alert, Pane, majorScale } from 'evergreen-ui'
import { Link } from 'react-router-dom'
import { PlatformSetupStatus, statusProgress } from '../../api/platform-status'
import PlatformSettings from './platform-settings'
import PlatformOriginsSettings from './platform-origins-settings'
import APIKeySettings from './api-key-settings'
import SubscriptionSettings from './subscription-settings'
import TeamSettings from './team-settings'
import TestContextSettings from './test-context-settings'

interface SettingsProps {
  setupStatus?: PlatformSetupStatus
}

class Settings extends React.Component<SettingsProps> {
  componentDidMount(): void {
    document.title = 'Settings | Xkit'
  }

  renderSetupAlert(): React.ReactElement | null {
    const progress = statusProgress(this.props.setupStatus)

    const alertProps = {
      appearance: 'card',
      marginBottom: majorScale(3),
      is: Link,
      to: '/setup',
      textDecoration: 'none'
    }

    // The setup guide currently only applies to credential connectors, not sources.
    if (
      this.props.setupStatus == null ||
      !this.props.setupStatus.use_credential_connectors
    ) {
      return null
    }

    if (progress === 1) {
      return (
        // TODO: please fix following typescript error
        // @ts-expect-error
        <Alert {...alertProps} intent='success' title='Xkit setup completed'>
          If you'd like to revisit the completed setup guide, click here.
        </Alert>
      )
    }

    return (
      // TODO: please fix following typescript error
      // @ts-expect-error
      <Alert {...alertProps} title='Setup Xkit with an interactive guide'>
        Looking for the Xkit setup guide? Click here to walk through the set up
        process.
      </Alert>
    )
  }

  render(): React.ReactElement {
    const { setupStatus } = this.props
    const crmOnlyConnectors =
      !setupStatus?.use_credential_connectors &&
      !setupStatus?.use_sync_connectors
    return (
      <Pane>
        {this.renderSetupAlert()}
        <PlatformSettings crmOnlyConnectors={crmOnlyConnectors} />
        <PlatformOriginsSettings crmOnlyConnectors={crmOnlyConnectors} />
        <APIKeySettings crmOnlyConnectors={crmOnlyConnectors} />
        {crmOnlyConnectors ? null : <TestContextSettings />}
        <SubscriptionSettings crmOnlyConnectors={crmOnlyConnectors} />
        <TeamSettings />
      </Pane>
    )
  }
}

export default Settings
