import { useContext, useEffect, useState } from 'react'
import { getPlatform, Platform } from '../../api/platform'
import { CredentialsContext } from '../login-wrapper'

interface UsePlatformState {
  platform: Platform | undefined
  platformLoading: boolean
  platformError: string | undefined
}

export const usePlatform = (): UsePlatformState => {
  const [loading, setLoading] = useState(true)
  const [error] = useState<string | undefined>(undefined)
  const [platform, setPlatform] = useState<Platform | undefined>(undefined)
  const { callWithCredentials } = useContext(CredentialsContext)

  useEffect(() => {
    const loadPlatform = async (): Promise<void> => {
      setLoading(true)
      try {
        const platform = await callWithCredentials(getPlatform)
        setPlatform(platform)
      } catch (e) {
        // TODO: handle this error
        // setError(e)
        console.debug(e)
      } finally {
        setLoading(false)
      }
    }
    void loadPlatform()
  }, [callWithCredentials])

  return {
    platform,
    platformLoading: loading,
    platformError: error
  }
}
