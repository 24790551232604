import * as React from 'react'
import {
  SideSheet,
  Pane,
  Heading,
  majorScale,
  SideSheetProps
} from 'evergreen-ui'
import { ContentCard } from './components/content-card'

interface CustomSideSheetProps extends SideSheetProps {
  isShown: boolean
  onClose: Function
  title: string
}

const CustomSideSheet: React.FunctionComponent<CustomSideSheetProps> = ({
  isShown,
  onClose,
  title,
  children,
  ...props
}) => {
  return (
    <SideSheet
      {...props}
      isShown={isShown}
      // TODO: please fix following typescript error
      // @ts-expect-error
      onCloseComplete={onClose}
      containerProps={{
        display: 'flex',
        flex: '1',
        flexDirection: 'column'
      }}
    >
      <Pane
        flex={1}
        overflowY='scroll'
        background='tint1'
        padding={majorScale(2)}
      >
        <Heading size={600} marginBottom={majorScale(2)}>
          {title}
        </Heading>
        <ContentCard padding={majorScale(2)} elevation={1}>
          {children}
        </ContentCard>
      </Pane>
    </SideSheet>
  )
}

export default CustomSideSheet
