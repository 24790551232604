import React, { FC, memo, useCallback } from 'react'
import { TextInput } from 'evergreen-ui'
import { FilterFieldProps, getFilter, updateFilter } from './filter-field'
import { FilterOverrideField } from './filter-override'

const HISTORY_SINCE_PROPERTY = 'history_since'

export const ZendeskTicketsFilterField: FC<FilterFieldProps> = memo(
  ({ sourceFilters, syncFilters, filterErrors, onChange }) => {
    const handleChange = useCallback(
      (e, propertyName) => {
        const updatedFilters = updateFilter(
          syncFilters ?? sourceFilters,
          propertyName,
          'eq',
          e.target.value
        )
        onChange(updatedFilters)
      },
      [sourceFilters, syncFilters, onChange]
    )

    const historySince = getFilter(
      sourceFilters,
      syncFilters,
      filterErrors,
      HISTORY_SINCE_PROPERTY
    )

    return (
      <>
        <FilterOverrideField
          propertyName={HISTORY_SINCE_PROPERTY}
          syncFilters={syncFilters}
          sourceFilters={sourceFilters}
          onChange={onChange}
          label='Tickets age limit in days'
          description='Syncing only those tickets that have been updated in the given number of days'
          hint='How far to look into the past when syncing tickets.'
          placeholder='90'
          validationMessage={historySince.validationMessage}
        >
          <TextInput
            name={HISTORY_SINCE_PROPERTY}
            isInvalid={historySince.isInvalid}
            disabled={historySince.disabled}
            value={historySince.isEmpty ? '' : historySince.value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(event, HISTORY_SINCE_PROPERTY)
            }
          />
        </FilterOverrideField>
      </>
    )
  }
)

ZendeskTicketsFilterField.displayName = 'ZendeskTicketsFilterField'
