import * as React from 'react'
import {
  Heading,
  Pane,
  Text,
  majorScale,
  // TODO: please fix following typescript error
  // @ts-expect-error
  withTheme
} from 'evergreen-ui'
import slugify from '../util/slugify'
import { ContentCard } from './components/content-card'

interface ContentSectionProps {
  title: string
  description?: React.ReactNode
  height: string | number
}

const ContentSection: React.FunctionComponent<ContentSectionProps> = ({
  title,
  description,
  children,
  height
}) => {
  return (
    <Pane marginBottom={majorScale(3)} height={height}>
      {(title || description) && (
        <Heading marginBottom={majorScale(2)} size={600}>
          {title && (
            <a id={slugify(title)} style={{ marginRight: majorScale(2) }}>
              {title}
            </a>
          )}
          {description && (
            <Text size={300} color='muted'>
              {description}
            </Text>
          )}
        </Heading>
      )}
      <ContentCard
        padding={majorScale(2)}
        elevation={1}
        height={`calc(${height}${
          typeof height === 'number' ? 'px' : ''
        } - ${majorScale(5)}px)`}
      >
        {children}
      </ContentCard>
    </Pane>
  )
}

export default withTheme(ContentSection)
