import * as React from 'react'
import { getDisplayName } from './react-util'
import { TextInputField, TextareaField } from 'evergreen-ui'

const BLOCK_CLASSNAMES = 'cohere-block'
const BLOCK_ATTRIBUTES = { 'data-clarity-mask': 'True' }

export function withNoRecording<Props extends {}>(
  WrappedComponent: React.ComponentType<Props>
): React.ComponentType<Props> {
  class WithNoRecording extends React.Component {
    propsClassName(): string {
      // TODO: please fix following typescript error
      // @ts-expect-error
      return this.props.className || ''
    }

    combinedClassName(): string {
      if (this.propsClassName()) {
        return `${BLOCK_CLASSNAMES} ${this.propsClassName()}`
      }

      return BLOCK_CLASSNAMES
    }

    render(): React.ReactNode {
      return (
        <WrappedComponent
          {...(this.props as Props)}
          className={this.combinedClassName()}
          {...BLOCK_ATTRIBUTES}
        />
      )
    }
  }

  // TODO: please fix following typescript error
  // @ts-expect-error
  WithNoRecording.displayName = `WithNoRecording(${getDisplayName(
    // TODO: please fix following typescript error
    // @ts-expect-error
    WrappedComponent
  )})`

  // TODO: please fix following typescript error
  // @ts-expect-error
  return WithNoRecording
}

export const BlockedTextInputField = withNoRecording(TextInputField)
export const BlockedTextareaField = withNoRecording(TextareaField)
