import React from 'react'
import {
  CardProps,
  FunctionIcon,
  Heading,
  Pane,
  majorScale
} from 'evergreen-ui'
import { ContentCard } from '../components/content-card'
import { Destination, DestinationType } from '../../api/destination'
import { providerCard } from './provider-card'

type DestinationCardProps = {
  destination: Destination
} & CardProps

function functionCard({
  destination,
  ...cardProps
}: DestinationCardProps): React.ReactElement {
  return (
    <ContentCard
      padding={majorScale(2)}
      display='flex'
      flexDirection='column'
      width='278px'
      height='108px'
      cursor='pointer'
      {...cardProps}
    >
      <Pane display='flex'>
        <FunctionIcon size={40} color='black' />
      </Pane>
      <Heading
        size={600}
        marginTop={majorScale(1)}
        textOverflow='ellipsis'
        overflow='hidden'
      >
        {destination.name}
      </Heading>
    </ContentCard>
  )
}

export const destinationCard = ({
  destination,
  ...cardProps
}: DestinationCardProps): React.ReactElement => {
  if (destination.type === DestinationType.function) {
    return functionCard({ destination, ...cardProps })
  } else if (
    destination.type === DestinationType.native &&
    destination.provider != null
  ) {
    return providerCard({
      // Replace the provider name with the destination's name.
      provider: { ...destination.provider, name: destination.name },
      ...cardProps
    })
  } else {
    return <></>
  }
}

export const DestinationCard: React.FC<DestinationCardProps> = (props) => {
  return destinationCard(props)
}

DestinationCard.displayName = 'DestinationCard'
