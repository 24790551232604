import * as React from 'react'
import { Table } from 'evergreen-ui'
import { debounce } from '../util/throttle'

interface TableHeadPageableProps {
  fixedWidth: number
  widthPerElement: number
  onChange: (maxEls: number) => void
}

interface TableHeadPageableState {
  maxEls?: number
}

class TableHeadPageable extends React.Component<
  TableHeadPageableProps,
  TableHeadPageableState
> {
  constructor(props: TableHeadPageableProps) {
    super(props)
    // ref forwarding isn't working.
    // TODO: please fix following typescript error
    // @ts-expect-error
    this.htmlId = `TableHeadPageable-${Math.round(Math.random() * 10000)}`
    this.state = {}
  }

  componentDidMount(): void {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = debounce((): void => {
    const { fixedWidth, widthPerElement, onChange } = this.props
    // TODO: please fix following typescript error
    // @ts-expect-error
    const el = document.getElementById(this.htmlId)
    if (el) {
      const headWidth = el.clientWidth
      const elsWidth = headWidth - fixedWidth
      const maxEls = Math.floor(elsWidth / widthPerElement)

      if (maxEls !== this.state.maxEls) {
        this.setState({ maxEls })
        onChange(maxEls)
      }
    }
  }, 200)

  render(): React.ReactElement {
    return (
      // TODO: please fix following typescript error
      // @ts-expect-error
      <Table.Head overflowY='hidden' paddingRight={0} id={this.htmlId}>
        {this.props.children}
      </Table.Head>
    )
  }
}

export default TableHeadPageable
