import React, { FC, ChangeEvent, memo } from 'react'
import { TextInput, Table } from 'evergreen-ui'

interface TableCellTextInputProps {
  value: string
  disabled?: boolean
  isInvalid?: boolean
  onChange: (value: string) => void
}

export const TableCellTextInput: FC<TableCellTextInputProps> = memo(
  ({ value, disabled, isInvalid, onChange }) => {
    return (
      <Table.Cell>
        <TextInput
          maxWidth='100%'
          value={value}
          disabled={disabled}
          isInvalid={isInvalid}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.value)
          }}
        />
      </Table.Cell>
    )
  }
)

TableCellTextInput.displayName = 'TableCellTextInput'
