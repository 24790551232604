import React, { FC, Fragment, memo, SVGProps } from 'react'
import { Pane, useTheme } from 'evergreen-ui'
import { v4 as uuid } from 'uuid'

export interface LinesOneToManyProps {
  width: number
  offsetTop: number
  to: HTMLElement[]
  active: HTMLElement[]
  dashed: HTMLElement[]
  direction: 'ltr' | 'rtl'
}

const CURVE_LENGTH = 28

function getYCoords(
  elem: HTMLElement,
  offsetTop: number,
  direction: 'ltr' | 'rtl'
): { from: number; to: number } {
  const start = offsetTop
  const end = elem.offsetTop + elem.offsetHeight / 2

  if (direction === 'rtl') {
    return {
      from: end,
      to: start
    }
  }

  return {
    from: start,
    to: end
  }
}

export const LinesOneToMany: FC<LinesOneToManyProps> = memo(
  ({ width, offsetTop, to, active, dashed, direction }) => {
    const theme = useTheme()
    const fromX = 0
    const toX = width
    const curveX = CURVE_LENGTH
    const curveY = direction === 'rtl' ? -1 * CURVE_LENGTH : CURVE_LENGTH

    return (
      <Pane pointerEvents='none' width={width}>
        <svg width='100%' height='100%'>
          {to
            .slice()
            .sort((elem1, elem2) => {
              if (active.includes(elem1) && active.includes(elem2)) {
                return 0
              }
              if (active.includes(elem1)) {
                return 1
              }
              if (active.includes(elem2)) {
                return -1
              }
              return 0
            })
            .map((elem) => {
              const key = elem.getAttribute('data-uuid') ?? uuid()

              const { from: fromY, to: toY } = getYCoords(
                elem,
                offsetTop,
                direction
              )

              const distanceX = toX - fromX
              const distanceY = toY - fromY

              const straightDistanceX = distanceX - curveX * 2
              const straightDistanceY = distanceY - curveY * 2

              const stroke = active?.includes(elem)
                ? theme.scales.gray[800]
                : theme.scales.gray[400]
              const isDashed = dashed?.includes(elem)

              const style: SVGProps<SVGLineElement>['style'] = {
                stroke,
                strokeWidth: isDashed ? '1px' : '2px',
                strokeDasharray: isDashed ? '4' : undefined,
                fill: 'transparent'
              }

              // Approximately straight, so just do a straight line
              if (Math.abs(distanceY) < CURVE_LENGTH) {
                return (
                  <Fragment key={key}>
                    <line
                      x1={fromX}
                      y1={fromY}
                      x2={toX}
                      y2={fromY}
                      style={style}
                    />
                  </Fragment>
                )
              }

              return (
                <Fragment key={key}>
                  <path
                    d={`
                    M${fromX},${fromY}
                    h${straightDistanceX / 2}
                    q${curveX},0 ${curveX},${curveY}
                    v${straightDistanceY}
                    q0,${curveY} ${curveX},${curveY}
                    h${straightDistanceX / 2}
                    `}
                    style={style}
                  />
                </Fragment>
              )
            })}
        </svg>
      </Pane>
    )
  }
)
LinesOneToMany.displayName = 'LinesOneToMany'
