import React, {
  FC,
  memo,
  ReactNode,
  useCallback,
  useRef,
  useState
} from 'react'
import {
  CaretRightIcon,
  majorScale,
  Pane,
  PaneProps,
  Text,
  useTheme
} from 'evergreen-ui'
import { HeadingSubsection } from './heading-subsection'

export interface AccordionProps extends PaneProps {
  title: ReactNode
  disabled?: boolean
}

const headerPadding = majorScale(2)
const contentPadding = headerPadding

function calcMaxHeight(offsetHeight?: number): string {
  if (offsetHeight != null) {
    return `${offsetHeight + contentPadding}px`
  }

  // We don't know the height, so we're just guessing
  return '100vh'
}

export const Accordion: FC<AccordionProps> = memo(
  ({ title, disabled, children, ...paneProps }) => {
    const [toggled, setToggled] = useState(false)
    const expanded = !disabled && toggled
    const contentRef = useRef<HTMLDivElement>(null)
    const theme = useTheme()
    const [maxHeight, setMaxHeight] = useState<string>('0px')

    // Handle state updates to collapse the accordion
    const collapse = (): void => {
      setToggled(false)
      // We need to first set the height of the element to its actual pixel
      // height. Transitioning from `max-height: none` to `max-height: 0px` is
      // not a transition at all.
      // Since this is a ref-based value, this callback must be dynamic.
      setMaxHeight(calcMaxHeight(contentRef?.current?.offsetHeight))
      // We set the max-height to zero in the next event loop to start
      // the transition
      setTimeout(() => {
        setMaxHeight('0px')
      }, 0)
    }

    // Handle state updates to expand the accordion
    const expand = (): void => {
      setToggled(true)
      // This needs to be a dynamic callback since it relies on a ref
      setMaxHeight(calcMaxHeight(contentRef?.current?.offsetHeight))
    }

    const handleClick = (): void => {
      if (disabled) return
      if (toggled) {
        collapse()
      } else {
        expand()
      }
    }

    const handleTransitionEnd = useCallback(() => {
      if (expanded) {
        // After the transition, we set to `max-height: none` in
        // order to handle changes in the height of the content.
        setMaxHeight('none')
      } else {
        setMaxHeight('0px')
      }
    }, [setMaxHeight, expanded])

    return (
      <Pane {...paneProps}>
        <Pane
          is='button'
          appearance='minimal'
          background='inherit'
          cursor={disabled ? 'auto' : 'pointer'}
          padding={headerPadding}
          width='100%'
          borderStyle='none'
          onClick={handleClick}
        >
          <HeadingSubsection
            display='flex'
            alignItems='center'
            color={disabled ? theme.colors.text.muted : theme.colors.text.dark}
          >
            <Text
              display='flex'
              alignItems='center'
              marginRight={headerPadding}
              color={disabled ? 'muted' : 'default'}
            >
              <CaretRightIcon
                transition='all 0.5s'
                transform={expanded ? 'rotate(90deg)' : ''}
              />
            </Text>
            {title}
          </HeadingSubsection>
        </Pane>
        <Pane
          overflow='hidden'
          transition='all 0.5s'
          paddingLeft={contentPadding}
          paddingRight={contentPadding}
          paddingBottom={expanded ? contentPadding : 0}
          height='auto'
          maxHeight={maxHeight}
          onTransitionEnd={handleTransitionEnd}
        >
          <div ref={contentRef}>{disabled ? null : children}</div>
        </Pane>
      </Pane>
    )
  }
)
Accordion.displayName = 'Accordion'
