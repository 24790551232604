import { request, TokenCredentials } from './request'

export interface SupportToken {
  widgetId: string
  userJWT: string
  customerId: string
  customerName: string
  userId: string
  userEmail: string
  userName: string
}

export async function getSupportToken(
  credentials: TokenCredentials
): Promise<SupportToken> {
  const { token } = await request({
    path: '/support/token',
    method: 'POST',
    credentials
  })

  return token as SupportToken
}
