import * as React from 'react'

// Thank you: https://css-tricks.com/building-progress-ring-quickly/
interface ProgressRingProps {
  radius: number
  stroke: number
  // 0 to 1
  progress: number
  color: string
}

const ProgressRing: React.FC<ProgressRingProps> = ({
  radius,
  stroke,
  progress,
  color
}) => {
  const normalizedRadius = radius - stroke
  const circumference = normalizedRadius * 2 * Math.PI
  const strokeDashoffset = circumference * (1 - progress)

  return (
    <svg height={radius * 2} width={radius * 2}>
      <circle
        stroke={color}
        fill='transparent'
        strokeWidth={stroke}
        strokeDasharray={`${circumference} ${circumference}`}
        style={{
          strokeDashoffset,
          transition: 'stroke-dashoffset 0.35s',
          transform: 'rotate(-90deg)',
          transformOrigin: '50% 50%'
        }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </svg>
  )
}

export default ProgressRing
