import React, { FC, memo, useCallback } from 'react'
import { Input, isTypeEqual } from '../../../api/destination-input'
import { Selector, getPathToSelector } from '../../../api/mapping'
import { NodeButton } from './node-button'

interface SelectorNodeButtonProps {
  input: Input
  root: Selector
  selector: Selector
  onSelect: (selector: Selector) => void
}

function isInSelectableTree(root: Selector, selector: Selector): boolean {
  return getPathToSelector(root, selector) != null
}

function isSelectable(
  input: Input,
  root: Selector,
  selector: Selector
): boolean {
  if (!isInSelectableTree(root, selector)) {
    return false
  }

  if (selector.input_types == null) {
    return false
  }

  return (
    selector.input_types.filter(({ input_type: inputType }) =>
      isTypeEqual(input.simple_type, inputType)
    ).length > 0
  )
}

function displayNodeType(selector: Selector): string {
  if (Array.isArray(selector.type_label)) {
    return selector.type_label.join(' or ')
  }

  return selector.type_label
}

export const SelectorNodeButton: FC<SelectorNodeButtonProps> = memo(
  ({ input, root, selector, onSelect }) => {
    const canSelect = isSelectable(input, root, selector)
    const handleSelect = useCallback(
      () => onSelect(selector),
      [selector, onSelect]
    )

    return (
      <NodeButton
        description={displayNodeType(selector)}
        isSelectable={canSelect}
        onClick={handleSelect}
      >
        {selector.label}
      </NodeButton>
    )
  }
)

SelectorNodeButton.displayName = 'SelectorNodeButton'
