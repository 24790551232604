import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import {
  ArrowRightIcon,
  BackButton,
  FunctionIcon,
  Pane,
  majorScale
} from 'evergreen-ui'
import { MappingTab } from './mapping-tab'
import { ConnectionsTab } from './connections-tab'
import { ConnectorHeading } from '../connector/connector-heading'
import TestConnectorButton from '../test-connector-button'
import { XText } from '../components/x-text'
import { TabRoutes, TabRoute } from '../components/tab-routes'
import { HeadingTitle } from '../components/heading-title'
import { useErrorToaster, useTitle } from '../hooks/common'
import { usePipeline } from '../hooks/pipeline.hook'
import { SourceObjectsTab } from './source-objects-tab'
import { ConnectorHeadingSkeleton } from '../connector/connector-heading-skeleton'
import { getProviderFromPipeline } from '../../api/pipeline'

interface EditPipelineProps {
  uuid: string
}

const EditPipeline: React.FC<EditPipelineProps> = ({ uuid }) => {
  const { pipeline, loading, error, fetch: pipelineFetch } = usePipeline(uuid)

  useTitle('Edit Pipeline')
  useErrorToaster(error)

  if (pipeline == null) {
    return <ConnectorHeadingSkeleton />
  }

  if (error != null) {
    return <Redirect to={`/providers`} />
  }

  const mappingTab = {
    title: 'Data and mapping',
    slug: 'mapping',
    loading: false,
    content: (
      <MappingTab
        pipeline={pipeline}
        loading={loading}
        pipelineFetch={pipelineFetch}
      />
    )
  }

  const sourceObjectsTab = {
    title: 'Source Objects',
    slug: 'source-objects',
    loading: false,
    content: (
      <SourceObjectsTab
        pipeline={pipeline}
        loading={loading}
        pipelineFetch={pipelineFetch}
      />
    )
  }

  const connectionsTab = {
    title: 'Connections',
    slug: 'connections',
    loading: false,
    content: (
      <ConnectionsTab
        pipeline={pipeline}
        loading={loading}
        pipelineFetch={pipelineFetch}
      />
    )
  }

  const provider = getProviderFromPipeline(pipeline)

  const tabRoutes: TabRoute[] = [mappingTab]

  if (pipeline.source.provider.template.allow_custom_objects) {
    tabRoutes.push(sourceObjectsTab)
  }

  tabRoutes.push(connectionsTab)

  return (
    <>
      <HeadingTitle
        marginRight={4}
        marginLeft={4}
        display='flex'
        alignItems='center'
      >
        <ConnectorHeading
          connector={pipeline.source.provider}
          marginRight={majorScale(2)}
        />
        <ArrowRightIcon
          marginLeft={majorScale(2)}
          marginRight={majorScale(2)}
        />
        <FunctionIcon
          size={24}
          marginLeft={majorScale(2)}
          marginRight={majorScale(1)}
        />
        <XText>{pipeline.destination.name}</XText>
        <Pane flexGrow={1} />
        <TestConnectorButton
          allowRemoveConnection
          connector={provider}
          appearance='primary'
          marginLeft={majorScale(1)}
        >
          Test
        </TestConnectorButton>
      </HeadingTitle>
      <TabRoutes tabRoutes={tabRoutes} />
      <BackButton is={Link} to={`/providers/${provider.slug}/pipelines`}>
        Back to pipelines
      </BackButton>
    </>
  )
}

EditPipeline.displayName = 'EditPipeline'

export default EditPipeline
