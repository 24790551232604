import { ChangeError, request, TokenCredentials } from './request'
import { ApiObject } from './api-object'
import { Filter } from './filter'

export interface SourceRelation {
  id: number
  slug: string
  cardinality: string
  label_one?: string
  label_many?: string
  schema?: string
  to_source_object_id: number
}

export interface SourceObject {
  id: number
  api_object: ApiObject
  slug: string
  label_one: string
  label_many: string
  is_root: boolean
  is_selected_root: boolean
  schema: string
  filters: Filter[]
  from_relations: SourceRelation[]
}

export interface BasicSourceObject {
  id: number
  api_object: ApiObject
  slug: string
  label_one: string
  label_many: string
  is_root: boolean
  is_selected_root: boolean
}

export interface SourceRootObject extends SourceObject {
  is_root: true
}

export interface SelectedRootObject extends SourceObject {
  is_root: true
  is_selected_root: true
}

export interface SourceRelationParams {
  id?: number
  slug: string
  cardinality: string
  to_source_object_id?: number
}

export interface SourceObjectParams {
  id?: number
  slug: string
  label_one: string
  label_many: string
  is_root: boolean
  schema: string
  from_relations: SourceRelationParams[]
}

export type SourceObjectResult =
  | { source_object: SourceObject }
  | { errors: ChangeError<SourceObjectParams> }

export type SourceObjectWireResult =
  | { source_object: SourceObject }
  | { errors: ChangeError<SourceObjectParams> }

export interface RootObjectParams {
  source_object_id: number
  filters: Filter[]
}

// Needs to be a type to be compatible with UnknownJSON
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type RootObjectErrors = {
  errors: ChangeError<Omit<RootObjectParams, 'filters'>> & {
    filters: Array<{ message: string }> | Array<ChangeError<Filter>>
  }
}

export type RootObjectResult =
  | { root_object: SelectedRootObject }
  | RootObjectErrors

export async function getSourceObject(
  credentials: TokenCredentials,
  pipelineUUID: string,
  sourceObjectSlug: string
): Promise<SourceObjectResult> {
  return await request<SourceObjectWireResult>({
    path: `/pipelines/${pipelineUUID}/source/objects/${sourceObjectSlug}`,
    credentials
  })
}

export async function upsertSourceObject(
  credentials: TokenCredentials,
  pipelineUUID: string,
  sourceObjectParams: SourceObjectParams
): Promise<SourceObjectResult> {
  return await request<SourceObjectWireResult>({
    path: `/pipelines/${pipelineUUID}/source/objects`,
    method: 'POST',
    credentials,
    body: {
      source_object: sourceObjectParams
    }
  })
}
