import { Card, ControlIcon, Heading, majorScale } from 'evergreen-ui'
import React, { FC, memo } from 'react'
import { Link } from 'react-router-dom'
import { ContentCard } from '../components/content-card'
import { HeadingTitle } from '../components/heading-title'
import { NewItemCard } from '../components/new-item-card'
import { SpinnerSuspend } from '../components/spinner'
import { useAppObjects } from '../hooks/app-objects'
import { useErrorToaster, useTitle } from '../hooks/common'

const AppObjectsList: FC = memo(() => {
  const { appObjects, loading, error } = useAppObjects()
  useErrorToaster(error)
  useTitle('App Objects')

  return (
    <>
      <HeadingTitle display='flex'>App Objects</HeadingTitle>
      <SpinnerSuspend spinning={loading || error != null}>
        {appObjects.length
          ? appObjects.map((object) => (
              <Card
                is={Link}
                to={`/app-objects/${object.id}`}
                display='block'
                textDecoration='none'
                maxWidth={278}
                marginTop={majorScale(2)}
                marginBottom={majorScale(4)}
                key={object.id}
              >
                <ContentCard
                  paddingLeft={majorScale(2)}
                  paddingRight={majorScale(2)}
                  display='flex'
                  alignItems='center'
                  width={278}
                  height={80}
                  elevation={1}
                  hoverElevation={2}
                >
                  <ControlIcon size={24} color='black' />
                  <Heading
                    size={600}
                    paddingLeft={10}
                    textOverflow='ellipsis'
                    overflow='hidden'
                    whiteSpace='nowrap'
                  >
                    {object.label_one}
                  </Heading>
                </ContentCard>
              </Card>
            ))
          : null}
        <NewItemCard
          to={`/app-objects/new`}
          width={278}
          height={80}
          marginTop={majorScale(2)}
          title='Add App Object'
        />
      </SpinnerSuspend>
    </>
  )
})

AppObjectsList.displayName = 'AppObjectsList'

export default AppObjectsList
