import * as React from 'react'
import { Paragraph, Link, TextInputField, toaster } from 'evergreen-ui'
import { Link as RouterLink } from 'react-router-dom'
import { BlockedTextInputField } from './with-no-recording'
import LoginForm from './login-form'
import { TokenCredentials } from '../api/request'
import { login } from '../api/session'
import errorMessage from '../util/error-message'

interface LoginProps {
  onLogin: (credentials: TokenCredentials) => void
}

interface LoginState {
  username: string
  password: string
  loading: boolean
}

class Login extends React.Component<LoginProps, LoginState> {
  // TODO: please fix following typescript error
  // @ts-expect-error
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      loading: false
    }
  }

  handleLogin = async (): Promise<void> => {
    this.setState({ loading: true })
    try {
      const { username, password } = this.state

      const credentials = await login({ username, password })
      this.props.onLogin(credentials)
    } catch (e) {
      if (e.statusCode === 401) {
        toaster.danger('Invalid email or password')
      } else {
        toaster.danger(`Error logging in: ${errorMessage(e)}`)
      }
      this.setState({ loading: false })
    }
  }

  render(): React.ReactNode {
    const { loading, username, password } = this.state

    return (
      <LoginForm
        isLoading={loading}
        submitLabel='Log in'
        onSubmit={this.handleLogin}
        footer={
          <>
            <Paragraph color='muted'>
              Forgot your password?{' '}
              <Link is={RouterLink} to='/account/password-reset'>
                Reset your password
              </Link>
            </Paragraph>
            {/*            <Paragraph color='muted' marginTop={majorScale(2)}>
              Don't have an account?{' '}
              <Link
                is={RouterLink}
                to={{ pathname: '/sign-up', search: window.location.search }}
              >
                Create an account
              </Link>
            </Paragraph> */}
          </>
        }
      >
        {({ ...inputProps }) => (
          <>
            <TextInputField
              {...inputProps}
              type='email'
              label='Email'
              autoComplete='work email'
              value={username}
              // TODO: please fix following typescript error
              // @ts-expect-error
              onChange={(e) => this.setState({ username: e.target.value })}
            />
            <BlockedTextInputField
              {...inputProps}
              type='password'
              label='Password'
              autoComplete='current-password'
              value={password}
              // TODO: please fix following typescript error
              // @ts-expect-error
              onChange={(e) => this.setState({ password: e.target.value })}
            />
          </>
        )}
      </LoginForm>
    )
  }
}

export default Login
