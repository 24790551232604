import React, { FC, memo } from 'react'
import { BackButton, SegmentedControl, majorScale } from 'evergreen-ui'
import { useRouteMatch, Switch, Route, Link } from 'react-router-dom'
import { useTemplates } from '../hooks/templates.hook'
import { useErrorToaster, useTitle } from '../hooks/common'
import { useProviderMode, Mode } from '../hooks/provider-mode.hook'
import { HeadingTitle } from '../components/heading-title'
import ConnectorsList from '../connectors/connectors-list'
import NewConnector from '../connector/connector-new'

interface AvailableProvidersProps {
  crmOnlyConnectors?: boolean
}

const AvailableProviders: FC<AvailableProvidersProps> = memo(
  ({ crmOnlyConnectors }) => {
    const providerNoun = crmOnlyConnectors ? 'CRM' : 'Provider'
    const providersNoun = crmOnlyConnectors ? 'CRMs' : 'Providers'
    const { loading, templates, error } = useTemplates()
    useTitle(`Add new ${providerNoun}`)
    useErrorToaster(error)
    const match = useRouteMatch()
    const [mode, setMode] = useProviderMode('crm')

    const filteredTemplates = templates
      .filter((template) => template.public)
      .filter((template) => {
        switch (mode) {
          case 'auth':
            return !template.sync && !template.crm
          case 'data':
            return template.sync
          case 'crm':
          default:
            return template.crm
        }
      })

    return (
      <Switch>
        <Route path={match.path} exact>
          <HeadingTitle display='flex'>
            Add new {providerNoun}
            {crmOnlyConnectors ? null : (
              <SegmentedControl
                marginLeft={majorScale(2)}
                width={372}
                height={32}
                options={[
                  {
                    label: 'CRM',
                    value: 'crm'
                  },
                  {
                    label: 'Data Sync',
                    value: 'data'
                  },
                  {
                    label: 'Authorization Only',
                    value: 'auth'
                  }
                ]}
                value={mode}
                onChange={(newMode: Mode) => setMode(newMode)}
              />
            )}
          </HeadingTitle>
          <ConnectorsList
            searchPlaceholder={`Search ${providersNoun}...`}
            connectors={filteredTemplates}
            loading={loading}
            slugLinkPrefix={`${match.path}/`}
          />
          <BackButton
            is={Link}
            to={`/providers?type=${mode}`}
            marginTop={majorScale(3)}
          >
            Back to {providersNoun}
          </BackButton>
        </Route>
        <Route
          path={`${match.path}/:slug`}
          render={({ match }) => {
            return (
              <NewConnector
                template={templates.find((c) => c.slug === match.params.slug)}
                loading={loading}
                crmOnlyConnectors={crmOnlyConnectors}
              />
            )
          }}
        />
      </Switch>
    )
  }
)

AvailableProviders.displayName = 'AvailableProviders'

export default AvailableProviders
