import { request, TokenCredentials } from './request'
import { Scope } from './scope'
import { ApiObject } from './api-object'

export interface Template {
  name: string
  slug: string
  admin_label?: string
  about: string
  mark_url: string
  short_description: string
  scopes: Scope[]
  authorizer_prototype_slug: string
  dev_template_slug?: string
  supports_multiple_connections: boolean
  sync: boolean
  crm: boolean
  public: boolean
  allow_custom_objects: boolean
  api_objects: ApiObject[]
}

export async function listTemplates(
  credentials: TokenCredentials
): Promise<Template[]> {
  const { connector_templates: connectorTemplates } = await request({
    path: '/connector_templates',
    credentials
  })

  return connectorTemplates as Template[]
}
