import { Connector, ConnectorResultValues } from '../../api/connector'
import React, { ComponentProps } from 'react'
import Form, { FormField } from '../form'
import {
  majorScale,
  Pane,
  ShareIcon,
  Text,
  TextareaField,
  TextInputField
} from 'evergreen-ui'
import SwitchField from '../switch-field'
import { CatalogPreviewButton } from './catalog-preview-button'
import { Platform } from '../../api/platform'

export interface CatalogFormProps<T extends Connector> {
  connector?: T
  loading: boolean
  update: (
    slug: string,
    connector: Partial<T>
  ) => Promise<ConnectorResultValues>
  platform: Platform
}

export class CatalogForm<T extends Connector> extends React.Component<
  CatalogFormProps<T>
> {
  get connectorName(): string {
    const { connector } = this.props
    return connector?.name ?? connector?.slug ?? ''
  }

  getCatalogFields(): FormField[] {
    const { connector } = this.props

    const basicFields: FormField[] = [
      {
        name: 'name',
        Field: TextInputField,
        label: 'Display name',
        description: 'The name presented to your users',
        placeholder: this.connectorName
      },
      {
        name: 'slug',
        Field: TextInputField,
        label: 'URL slug',
        description:
          'Unique name which helps identifying, for use in URLs (like this one)',
        placeholder: connector ? connector.slug : ''
      },
      {
        name: 'short_description',
        Field: TextInputField,
        label: 'Short description',
        description: 'The one line description',
        placeholder: `Use MyApp better when connected to ${this.connectorName}`
      }
    ]

    const catalogFields: FormField[] = [
      {
        name: 'description',
        Field: (props: ComponentProps<typeof TextareaField>) => (
          <TextareaField {...props} inputHeight={184} />
        ),
        label: 'Detailed description',
        description: null,
        placeholder: `This connection allows you to get more work done with My App.

### With ${this.connectorName}, you can:

- Get more stuff done
- Keep all of your work in sync
- Collaborate more effectively with your team`,
        hint: (
          <Text size={300} color='muted'>
            Supports{' '}
            <a
              href='https://github.github.com/gfm/'
              target='_blank'
              rel='noreferrer'
            >
              Github Flavored Markdown
              <ShareIcon size={12} marginLeft={4} />
            </a>
          </Text>
        )
      },
      {
        name: 'supports_multiple_connections',
        Field: (props: ComponentProps<typeof SwitchField>) => (
          <SwitchField
            {...props}
            value='supports_multiple_connections'
            checked={Boolean(props.value)}
          />
        ),
        label: 'Allow multiple connections',
        description: 'Allow users to create multiple connections.'
      }
    ]

    return basicFields.concat(connector?.crm ? [] : catalogFields)
  }

  updateConnector = async (
    connectorParams: Partial<Connector>
  ): Promise<ConnectorResultValues> => {
    const { connector, update } = this.props
    // Merge them so we update the whole connector
    const updatedConnector = { ...connector, ...connectorParams }
    // TODO: please fix following typescript error
    // @ts-expect-error
    const { values, errors } = await update(connector.slug, updatedConnector)
    return { values, errors }
  }

  render(): React.ReactNode {
    const { loading, connector, platform } = this.props

    return (
      <Pane maxWidth={400}>
        <Form
          fields={this.getCatalogFields()}
          loading={loading}
          // TODO: please fix following typescript error
          // @ts-expect-error
          values={connector}
          // TODO: please fix following typescript error
          // @ts-expect-error
          update={this.updateConnector}
          saveMessage={`Updated Settings for ${this.connectorName}`}
          controls={
            connector?.crm ? undefined : (
              <CatalogPreviewButton
                platform={platform}
                connector={connector}
                height={majorScale(4)}
              />
            )
          }
        />
      </Pane>
    )
  }
}
