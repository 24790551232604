import React, { FC, useState, memo, useCallback } from 'react'
import { Pipeline, updatePipeline } from '../../api/pipeline'
import { Pane, Button, toaster, majorScale } from 'evergreen-ui'
import SwitchField from '../switch-field'
import { hasOwnProperty } from '../../util/has-own-property'
import { useCredentials } from '../login-wrapper'

interface ConfigFormProps {
  pipeline: Pipeline
  loading: boolean
}

export const ConfigForm: FC<ConfigFormProps> = memo(({ pipeline, loading }) => {
  const credentials = useCredentials()

  const [autostartSyncs, setAutostartSyncs] = useState(pipeline.autostart_syncs)
  const [saving, setSaving] = useState(false)

  const onChangeHandler = useCallback(
    (event) => {
      setAutostartSyncs(event.target.value)
    },
    [setAutostartSyncs]
  )

  const handleSave = useCallback(async (): Promise<void> => {
    setSaving(true)

    if (credentials == null) {
      throw new Error('Invalid credentials')
    }

    try {
      const params = {
        autostart_syncs: autostartSyncs
      }

      const result = await updatePipeline(credentials, pipeline.uuid, params)

      if (!hasOwnProperty(result, 'errors')) {
        toaster.success('Saved pipeline config')
        setAutostartSyncs(result.pipeline.autostart_syncs)
      } else {
        console.error(result)
        toaster.danger('Failed to create the pipeline!')
      }
    } finally {
      setSaving(false)
    }
  }, [autostartSyncs, setAutostartSyncs, setSaving, credentials, pipeline])

  return (
    <Pane maxWidth={600}>
      <SwitchField
        label='Automatically start syncs'
        description='If turned on then syncs will start immediately after being created. Otherwise you will need to manually start syncs.'
        checked={autostartSyncs}
        onChange={onChangeHandler}
        hasCheckIcon={false}
      />

      <Button
        marginTop={majorScale(1)}
        appearance='primary'
        isLoading={saving || loading}
        onClick={handleSave}
      >
        {loading ? 'Loading' : saving ? 'Saving' : 'Save'}
      </Button>
    </Pane>
  )
})

ConfigForm.displayName = 'ConfigForm'
