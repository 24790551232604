import { BackButton, majorScale, toaster } from 'evergreen-ui'
import React, { FC, memo, useEffect, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { CRMObject } from '../../api/crm'
import { HeadingTitle } from '../components/heading-title'
import { SpinnerSuspend } from '../components/spinner'
import { useErrorToaster, useTitle } from '../hooks/common'
import { useCRMObjects } from '../hooks/crm-objects'
import { useCredentials } from '../login-wrapper'
import EditCRMObject from './edit-crm-object'

const UpdateCRMObject: FC = memo(() => {
  const { CRMObjects, loading, error } = useCRMObjects()
  const match = useRouteMatch<{ id: string }>()
  const credentials = useCredentials()
  useErrorToaster(error)

  const [loadingCurrentObject, setLoadingCurrentObject] =
    useState<boolean>(true)
  const [currentObject, setCurrentObject] = useState<CRMObject | undefined>(
    undefined
  )
  useTitle(`${currentObject?.label ?? 'Edit'} CRM Object`)
  useEffect(() => {
    if (!loading && CRMObjects) {
      const object = CRMObjects.find(
        (object) => `${object.id}` === match.params.id
      )
      if (object) {
        setCurrentObject(object)
      } else {
        toaster.danger('Could not find CRM Object with given ID')
      }
      setLoadingCurrentObject(false)
    }
  }, [loading, CRMObjects, match.params.id])

  return (
    <>
      <HeadingTitle display='flex'>Edit CRM Object</HeadingTitle>
      <SpinnerSuspend
        spinning={loadingCurrentObject || loading || error != null}
      >
        {currentObject ? (
          <EditCRMObject
            object={currentObject}
            objects={CRMObjects}
            credentials={credentials}
          />
        ) : null}
      </SpinnerSuspend>
      <BackButton is={Link} to={`/crm-objects`} marginTop={majorScale(3)}>
        Back to CRM Objects
      </BackButton>
    </>
  )
})

UpdateCRMObject.displayName = 'UpdateCRMObject'

export default UpdateCRMObject
