type SimpleType = string | number | boolean | undefined | null
type SimpleArray = string[] | number[]

interface ComplexObject {
  [index: string]: SimpleType | ComplexObject | SimpleArray | ComplexObject[]
}

export type ValType = SimpleType | ComplexObject | SimpleArray | ComplexObject[]

function isSimpleType(val?: ValType): val is SimpleType {
  if (val == null) {
    return true
  }
  if (
    typeof val === 'string' ||
    typeof val === 'number' ||
    typeof val === 'boolean'
  ) {
    return true
  }
  return false
}

function valsAreEqual(valA: ValType, valB: ValType): boolean {
  if (isSimpleType(valA) || isSimpleType(valB)) {
    return valA === valB
  }

  if (Array.isArray(valA)) {
    if (!Array.isArray(valB)) {
      return false
    }

    if (valA.length !== valB.length) {
      return false
    }

    // TODO: please fix following typescript error
    // @ts-expect-error
    return valA.every((a, i) => valsAreEqual(a, valB[i]))
  }

  if (Array.isArray(valB)) {
    return false
  }

  const entriesA = Object.entries(valA)
  const entriesB = Object.entries(valB)

  if (entriesA.length !== entriesB.length) {
    return false
  }

  for (const [key, a] of entriesA) {
    if (!valsAreEqual(a, valB[key])) {
      return false
    }
  }

  return true
}

export default valsAreEqual
