import React, { FC, memo } from 'react'
import { Rectangle, RectangleProps } from './rectangle'

export type CircleProps = RectangleProps

export const Circle: FC<CircleProps> = memo((props) => {
  return (
    <Rectangle {...props} borderRadius='100%'>
      {props.children}
    </Rectangle>
  )
})
Circle.displayName = 'Circle'
