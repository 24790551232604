import React, { FC } from 'react'
import { Tooltip, InfoSignIcon, minorScale } from 'evergreen-ui'
import { PlatformContext, isTestContext } from '../../api/platform-user'

export const TestContextTooltip: FC<{ context?: PlatformContext }> = ({
  context
}) => {
  if (context == null || !isTestContext(context)) {
    return null
  }

  return (
    <Tooltip content='This is a test context created for you by Xkit'>
      <InfoSignIcon
        size={12}
        style={{ verticalAlign: 'middle' }}
        marginLeft={minorScale(1)}
      />
    </Tooltip>
  )
}

TestContextTooltip.displayName = 'TestContextTooltip'
