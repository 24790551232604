import * as React from 'react'
import {
  Heading,
  Link,
  Text,
  Table,
  Menu,
  Button,
  Pane,
  SelectField,
  TextInputField,
  Code,
  Popover,
  Position,
  IconButton,
  toaster,
  majorScale
} from 'evergreen-ui'
import Form from '../form'
import ContentSection from '../content-section'
import DeleteDialog from '../delete-dialog'
import SideSheet from '../side-sheet'
import {
  deleteSubscription,
  listSubscriptions,
  createSubscription,
  Subscription
} from '../../api/subscription'
import { LegacyChangeResultValues } from '../../api/request'
import { withCredentials, CredentialConsumer } from '../login-wrapper'
import TableSpinner from '../components/table-spinner'
import errorMessage from '../../util/error-message'

interface SubscriptionRowProps {
  onDelete: Function
  subscription: Subscription
}

interface SubscriptionSettingsState {
  loading: boolean
  subscriptions: Subscription[]
  showingNew: boolean
}

const SubscriptionMenu: React.FC<SubscriptionRowProps> = ({
  onDelete,
  subscription
}) => {
  const { url, event_type: eventType } = subscription

  return (
    <Menu>
      <Menu.Group>
        <DeleteDialog
          noun='Webhook Subscription'
          deleteName='Delete'
          deletingName='Deleting'
          // TODO: please fix following typescript error
          // @ts-expect-error
          onDelete={onDelete}
          dialogText={
            <>
              This will permanently delete your webhook subscription for{' '}
              <Code size={300}>{eventType}</Code> events which call your URL at{' '}
              <Code size={300}>{url}</Code>.
            </>
          }
        >
          {(showDeleteDialog) => {
            return (
              <Menu.Item
                intent='danger'
                icon='ban-circle'
                onSelect={showDeleteDialog}
              >
                Delete...
              </Menu.Item>
            )
          }}
        </DeleteDialog>
      </Menu.Group>
    </Menu>
  )
}

const SubscriptionRow: React.FC<SubscriptionRowProps> = ({
  onDelete,
  subscription
}) => {
  const { event_type: eventType, url } = subscription

  return (
    <Table.Row>
      <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0}>
        <Code size={300} title={eventType}>
          {eventType}
        </Code>
      </Table.TextCell>
      <Table.TextCell>
        <Code size={300} title={url}>
          {url}
        </Code>
      </Table.TextCell>
      <Table.Cell width={48} flex='none'>
        <Popover
          content={
            <SubscriptionMenu subscription={subscription} onDelete={onDelete} />
          }
          position={Position.BOTTOM_RIGHT}
        >
          <IconButton icon='more' height={24} appearance='minimal' />
        </Popover>
      </Table.Cell>
    </Table.Row>
  )
}

type SubscriptionSettingsProps = {
  crmOnlyConnectors: boolean
} & CredentialConsumer

class SubscriptionSettings extends React.Component<
  SubscriptionSettingsProps,
  SubscriptionSettingsState
> {
  constructor(props: SubscriptionSettingsProps) {
    super(props)
    this.state = {
      showingNew: false,
      loading: false,
      subscriptions: []
    }
  }

  componentDidMount(): void {
    void this.loadSubscriptions()
  }

  async loadSubscriptions(): Promise<void> {
    this.setState({ loading: true })
    try {
      const subscriptions = await this.props.callWithCredentials(
        listSubscriptions
      )
      this.setState({ subscriptions })
    } catch (e) {
      toaster.danger(`Error while loading webhooks: ${errorMessage(e)}`)
    } finally {
      this.setState({ loading: false })
    }
  }

  handleDelete = async (subscription: Subscription): Promise<void> => {
    const { subscriptions } = this.state
    try {
      await this.props.callWithCredentials(
        async (creds) => await deleteSubscription(creds, subscription)
      )
      const updatedSubscriptions = subscriptions.filter(
        ({ id }) => id !== subscription.id
      )
      this.setState({ subscriptions: updatedSubscriptions })
      toaster.success('Webhook deleted')
    } catch (e) {
      toaster.danger(`Error while deleting webhook: ${errorMessage(e)}`)
    }
  }

  handleCreate = async (
    // TODO: please fix following typescript error
    // @ts-expect-error
    params: SubscriptionParams
    // TODO: please fix following typescript error
    // @ts-expect-error
  ): Promise<LegacyChangeResultValues<Subscription>> => {
    const { subscriptions } = this.state
    try {
      // TODO: please fix following typescript error
      // @ts-expect-error
      const { errors, subscription } = await this.props.callWithCredentials(
        async (creds) => await createSubscription(creds, params)
      )
      if (errors != null && errors.length > 0) {
        return { errors }
      }
      if (subscription == null) {
        throw new Error('Unknown Error')
      }
      const updatedSubscriptions = subscriptions.concat(subscription)
      this.setState({
        subscriptions: updatedSubscriptions,
        showingNew: false
      })
      return { values: subscription }
    } catch (e) {
      toaster.danger(`Error while creating webhook: ${errorMessage(e)}`)
    }
  }

  renderTableBody(): React.ReactNode {
    const { loading, subscriptions, showingNew } = this.state

    if (loading) {
      return <TableSpinner />
    }

    return (
      <>
        {subscriptions.map((subscription) => {
          return (
            <SubscriptionRow
              key={subscription.id}
              subscription={subscription}
              onDelete={async () => await this.handleDelete(subscription)}
            />
          )
        })}
        <Table.Row display='flex' alignItems='center' justifyContent='center'>
          <SideSheet
            isShown={showingNew}
            onClose={() => this.setState({ showingNew: false })}
            title='Create Webhook'
          >
            <Pane maxWidth='400px'>
              <Form
                fields={[
                  {
                    name: 'event_type',
                    label: 'Event Type',
                    Field: (props) => {
                      return (
                        <SelectField {...props}>
                          {/* TODO: please fix following typescript error */}
                          {/* @ts-expect-error */}
                          <option value='' disabled select>
                            Select an event type
                          </option>
                          <option value='connection.enabled'>
                            connection.enabled
                          </option>
                          <option value='connection.disabled'>
                            connection.disabled
                          </option>
                          <option value='connection.errored'>
                            connection.errored
                          </option>
                          <option value='destination.errored'>
                            destination.errored
                          </option>
                        </SelectField>
                      )
                    },
                    description: 'The type of event you wish to subscribe to.'
                  },
                  {
                    name: 'url',
                    label: 'Your URL',
                    Field: TextInputField,
                    description: (
                      <Text color='muted'>
                        URL where we will <Code size={300}>POST</Code> a payload
                        when an event occurs.
                      </Text>
                    ),
                    placeholder: 'https://your-website.com/webhooks/xkit'
                  }
                ]}
                // TODO: inline after update is renamed to onUpdate,
                // otherwise the linter will complain about the naming.
                // TODO: please fix following typescript error
                // @ts-expect-error
                update={async (params) => await this.handleCreate(params)}
              />
            </Pane>
          </SideSheet>
          <Button
            iconBefore='add'
            appearance='minimal'
            onClick={() => this.setState({ showingNew: true })}
          >
            Add Webhook
          </Button>
        </Table.Row>
      </>
    )
  }

  render(): React.ReactElement {
    const { crmOnlyConnectors } = this.props
    return (
      <ContentSection
        title='Connection Webhooks'
        description={
          <>
            Connection Webhooks allow your application to be notified of Xkit
            events for connections.{' '}
            <Link
              size={300}
              href={
                crmOnlyConnectors
                  ? 'https://xkit.co/docs/advanced/subscribing-to-connection-events'
                  : 'https://docs.xkit.co/reference#webhooks-overview'
              }
            >
              View documentation
            </Link>
          </>
        }
      >
        <Heading>Active Webhooks</Heading>
        <Table marginTop={majorScale(2)} maxWidth='680px'>
          <Table.Head>
            <Table.TextHeaderCell flexBasis={180} flexShrink={0} flexGrow={0}>
              Event Type
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>URL</Table.TextHeaderCell>
            <Table.HeaderCell width={48} flex='none' />
          </Table.Head>
          <Table.Body>{this.renderTableBody()}</Table.Body>
        </Table>
      </ContentSection>
    )
  }
}

export default withCredentials(SubscriptionSettings)
