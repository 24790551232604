import React, { FC, memo } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { AppObject } from '../../api/crm'
import { TokenCredentials } from '../../api/request'
import AppObjectActions from './app-object-actions'
import NewAppObjectAction from './new-app-object-action'

interface ActionsAppObjectTabProps {
  object: AppObject | undefined
  loading: boolean
  credentials: TokenCredentials | null
  refresh: () => Promise<void>
}

const ActionsAppObjectTab: FC<ActionsAppObjectTabProps> = memo(
  ({ object, loading, credentials, refresh }) => {
    const { path } = useRouteMatch()

    return (
      <Switch>
        <Route path={`${path}/`} exact>
          <AppObjectActions
            object={object}
            loading={loading}
            credentials={credentials}
            refresh={refresh}
          />
        </Route>
        <Route path={`${path}/new`} exact>
          <NewAppObjectAction
            object={object}
            loading={loading}
            credentials={credentials}
            refresh={refresh}
          />
        </Route>
      </Switch>
    )
  }
)

ActionsAppObjectTab.displayName = 'ActionsAppObjectTab'

export default ActionsAppObjectTab
