import React, { createContext, ReactElement, ReactNode } from 'react'
import { usePlatform } from './hooks/platform.hook'
import { useTestContext, UseTestUserEffect } from './xkit-js/test-context.hook'

interface XkitContextProps {
  xkit: UseTestUserEffect['xkit']
  context: UseTestUserEffect['context']
}

export const XkitContext = createContext<XkitContextProps>({
  xkit: null,
  context: null
})

interface XkitWrapperProps {
  children: (callback: () => Promise<void>) => ReactNode
}

export function XkitWrapper({ children }: XkitWrapperProps): ReactElement {
  const { platform } = usePlatform()
  const { xkit, context, logout } = useTestContext({ platform })

  return (
    <XkitContext.Provider value={{ xkit, context }}>
      {children(logout)}
    </XkitContext.Provider>
  )
}
