import React, { FC, memo } from 'react'
import {
  minorScale,
  Icon,
  IconName,
  Position,
  Tooltip,
  Pane
} from 'evergreen-ui'
import {
  ConnectionStatus,
  getConnectionStatus,
  PlatformConnection
} from '../../api/connection'

interface ContentProps {
  icon: IconName
  color: string
  tooltip: string
}

function getContent(connectionStatus: ConnectionStatus | null): ContentProps {
  switch (connectionStatus) {
    case ConnectionStatus.active:
      return {
        icon: 'tick-circle',
        color: 'green',
        tooltip: 'Connection is up and running'
      }
    case ConnectionStatus.error:
      return {
        icon: 'warning-sign',
        color: 'yellow',
        tooltip: 'Your user will need to re-authorize this connection'
      }
    case ConnectionStatus.disabled:
      return {
        icon: 'warning-sign',
        color: 'yellow',
        tooltip: 'Connection was removed'
      }
    default:
      return {
        icon: 'dot',
        color: 'disabled',
        tooltip: ''
      }
  }
}

interface ConnectionIconProps {
  connection?: PlatformConnection
}

export const ConnectionIcon: FC<ConnectionIconProps> = memo(
  ({ connection }) => {
    const connectionStatus =
      connection != null ? getConnectionStatus(connection) : null
    const { icon, color, tooltip } = getContent(connectionStatus)

    return (
      <Tooltip content={tooltip} position={Position.TOP}>
        <Pane>
          <Icon
            icon={icon}
            color={color}
            size={minorScale(3)}
            marginRight={3}
          />
        </Pane>
      </Tooltip>
    )
  }
)

ConnectionIcon.displayName = 'ConnectionIcon'
