import React, { FC, memo, useCallback } from 'react'
import { TextInput } from 'evergreen-ui'
import { FilterFieldProps, getFilter, updateFilter } from './filter-field'
import { FilterOverrideField } from './filter-override'

const HISTORY_COUNT_PROPERTY = 'history_count'

export type HistoryCountFilterOverrideFieldProps = FilterFieldProps & {
  label: string
  description: string
}

export const HistoryCountFilterOverrideField: FC<HistoryCountFilterOverrideFieldProps> =
  memo(
    ({
      syncFilters,
      sourceFilters,
      filterErrors,
      onChange,
      label,
      description
    }) => {
      const handleChange = useCallback(
        (e, propertyName) => {
          const updatedFilters = updateFilter(
            syncFilters ?? sourceFilters,
            propertyName,
            'eq',
            e.target.value
          )
          onChange(updatedFilters)
        },
        [sourceFilters, syncFilters, onChange]
      )

      const historyCount = getFilter(
        sourceFilters,
        syncFilters,
        filterErrors,
        HISTORY_COUNT_PROPERTY
      )

      return (
        <FilterOverrideField
          propertyName={HISTORY_COUNT_PROPERTY}
          syncFilters={syncFilters}
          sourceFilters={sourceFilters}
          onChange={onChange}
          label={label}
          description={description}
          placeholder='1000'
          hint='New data is not affected by the limit. Leave blank to sync all the data.'
          validationMessage={historyCount.validationMessage}
        >
          <TextInput
            name={HISTORY_COUNT_PROPERTY}
            isInvalid={historyCount.isInvalid}
            disabled={historyCount.disabled}
            value={historyCount.isEmpty ? '' : historyCount.value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(event, HISTORY_COUNT_PROPERTY)
            }
          />
        </FilterOverrideField>
      )
    }
  )

HistoryCountFilterOverrideField.displayName = 'HistoryCountFilterOverrideField'
