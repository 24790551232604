import { TokenCredentials } from './request'
import { createSocket } from './socket'
import { Channel } from 'phoenix'
import mitt, { Emitter as mittEmitter } from 'mitt'

interface AttemptedOriginWire {
  id: string | number
  origin: string
  last_seen_at: string
}

export interface AttemptedOrigin {
  id: string | number
  origin: string
  last_seen_at: Date
}

export type Emitter = mittEmitter<{ update: AttemptedOrigin[] }>

function deserializeAttemptedOrigin(
  wire: AttemptedOriginWire
): AttemptedOrigin {
  return Object.assign(wire, { last_seen_at: new Date(wire.last_seen_at) })
}

let channel: Channel | undefined
let invalidOrigins: AttemptedOrigin[] = []
const originEmitter: Emitter = mitt()

export function listenToRejectedOrigins(
  credentials: TokenCredentials
): Emitter {
  updateOrigins(credentials)
  return originEmitter
}

function emitOrigins(): void {
  originEmitter.emit('update', invalidOrigins)
}

function addOrigins(origins: AttemptedOriginWire[]): AttemptedOrigin[] {
  invalidOrigins = origins.map(deserializeAttemptedOrigin)
  emitOrigins()
  return invalidOrigins
}

function updateOrigins(credentials: TokenCredentials): void {
  if (channel != null) {
    // Use a setTimeout to allow the caller to set up an event
    // listener before it fires
    setTimeout(() => emitOrigins(), 0)
    return
  }

  const socket = createSocket(credentials)
  channel = socket.channel('invalid_attempted_origins')
  channel.on('update', ({ origins }) => {
    addOrigins(origins)
  })

  channel
    .join()
    .receive('ok', ({ origins }) => {
      addOrigins(origins)
    })
    .receive('error', ({ reason }) => {
      channel = undefined
      console.error(`Failed to join channel: ${String(reason)}`)
    })
    .receive('timeout', () => {
      channel = undefined
      console.error('Timeout while joining channel')
    })
}
