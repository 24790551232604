import * as React from 'react'
import {
  majorScale,
  minorScale,
  Table,
  Tooltip,
  InfoSignIcon,
  Spinner
} from 'evergreen-ui'
import {
  PlatformUser,
  PlatformGroup,
  PlatformContext
} from '../../api/platform-user'
import {
  PlatformConnection,
  ConnectionBy,
  getConnectionStatus
} from '../../api/connection'
import { Connector } from '../../api/connector'
import { ConnectionIcon } from './connection-icon'

export type PlatformTargets =
  | PlatformGroup[]
  | PlatformUser[]
  | PlatformContext[]
export type PlatformTarget = PlatformGroup | PlatformUser | PlatformContext

export function targetKey(
  target: PlatformTarget,
  connectionType: ConnectionBy
): string {
  return `${ConnectionBy[connectionType]}:${target.external_id}`
}

interface ConnectionCellProps {
  minWidth: number
  connection: PlatformConnection
}

interface ConnectionsRowProps {
  style: React.CSSProperties
  connectionType: ConnectionBy
  target: PlatformTarget
  connectors: Connector[]
  connectionColumnMinWidth: number
  idColumnMinWidth: number
  connections: PlatformConnection[]
  loading: boolean
}

function connectorsAreEqual(
  connectorsA?: Connector[],
  connectorsB?: Connector[]
): boolean {
  if (connectorsA == null || connectorsB == null) {
    return connectorsA === connectorsB
  }

  if (connectorsA.length !== connectorsB.length) {
    return false
  }

  return connectorsA.every((a, i) => {
    return a.slug === connectorsB[i].slug
  })
}

function connectionsAreEqual(
  connectionsA?: PlatformConnection[],
  connectionsB?: PlatformConnection[]
): boolean {
  if (connectionsA == null || connectionsB == null) {
    return connectionsA === connectionsB
  }

  if (connectionsA.length !== connectionsB.length) {
    return false
  }

  return connectionsA.every((a, i) => {
    const b = connectionsB[i]
    return (
      a.connector.slug === b.connector.slug &&
      a.authorization?.status === b.authorization?.status
    )
  })
}

const ConnectionCell: React.FC<ConnectionCellProps> = ({
  connection,
  minWidth
}) => {
  return (
    <Table.Cell
      flexBasis={minWidth}
      flexGrow={1}
      flexShrink={0}
      display='flex'
      justifyContent='center'
    >
      <ConnectionIcon connection={connection} />
    </Table.Cell>
  )
}

const MemoizedConnectionCell = React.memo(
  ConnectionCell,
  (prevProps, nextProps) => {
    const isMinWidthEq = prevProps.minWidth === nextProps.minWidth
    const isConnectionNullish =
      prevProps.connection == null && nextProps.connection == null
    const isConnectionStatusEq =
      prevProps.connection != null &&
      nextProps.connection != null &&
      getConnectionStatus(prevProps.connection) ===
        getConnectionStatus(nextProps.connection)
    const isConnectionEq = isConnectionNullish || isConnectionStatusEq

    return isMinWidthEq && isConnectionEq
  }
)

class ConnectionsRow extends React.Component<ConnectionsRowProps> {
  shouldComponentUpdate(nextProps: ConnectionsRowProps): boolean {
    if (nextProps.loading !== this.props.loading) {
      return true
    }

    if (nextProps.connectionType !== this.props.connectionType) {
      return true
    }

    if (nextProps.target.external_id !== this.props.target.external_id) {
      return true
    }

    if (
      nextProps.connectionColumnMinWidth !== this.props.connectionColumnMinWidth
    ) {
      return true
    }

    if (nextProps.idColumnMinWidth !== this.props.idColumnMinWidth) {
      return true
    }

    if (nextProps.style !== this.props.style) {
      return true
    }

    return (
      !connectorsAreEqual(nextProps.connectors, this.props.connectors) ||
      !connectionsAreEqual(nextProps.connections, this.props.connections)
    )
  }

  renderConnections(): React.ReactNode {
    const { connectors, connectionColumnMinWidth, loading, connections } =
      this.props

    if (loading) {
      return (
        <Table.Cell
          flexBasis={connectionColumnMinWidth * connectors.length}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <Spinner size={12} />
        </Table.Cell>
      )
    }

    return connectors.map((connector) => {
      const foundConnection = connections.find(
        (connection) => connection.connector.slug === connector.slug
      )
      return (
        <MemoizedConnectionCell
          key={connector.slug}
          // TODO: please fix following typescript error
          // @ts-expect-error
          connection={foundConnection}
          minWidth={connectionColumnMinWidth}
        />
      )
    })
  }

  // TODO: replace this with TestContextTooltip when non-contexts are removed
  maybeRenderTestUserTooltip(): React.ReactNode {
    if (!this.props.target.external_id.startsWith('xkit_test_user')) {
      return null
    }

    return (
      <Tooltip content='This is a test user created for you by Xkit'>
        <InfoSignIcon
          size={12}
          style={{ verticalAlign: 'middle' }}
          marginLeft={minorScale(1)}
        />
      </Tooltip>
    )
  }

  render(): React.ReactElement {
    const {
      target: { external_id: externalId, external_name: externalName },
      idColumnMinWidth,
      style
    } = this.props

    const name = externalName ?? externalId

    return (
      <Table.Row style={style}>
        <Table.TextCell
          flexBasis={majorScale(25) + 24}
          flexShrink={1}
          minWidth={idColumnMinWidth}
        >
          <span title={externalId}>{name}</span>
          {this.maybeRenderTestUserTooltip()}
        </Table.TextCell>
        {this.renderConnections()}
      </Table.Row>
    )
  }
}

interface ConnectionsRowItemProps {
  targets: PlatformTargets
  idColumnMinWidth: number
  connectionColumnMinWidth: number
  connectionType: ConnectionBy
  connectors: Connector[]
  connections: Record<string, PlatformConnection[]>
}

interface ConnectionsRowListItemProps {
  index: number
  style: React.CSSProperties
  data: ConnectionsRowItemProps
}

export const ConnectionsRowListItem: React.FC<ConnectionsRowListItemProps> = ({
  index,
  style,
  data
}) => {
  const {
    targets,
    idColumnMinWidth,
    connectionColumnMinWidth,
    connectionType,
    connectors,
    connections
  } = data
  const target = targets[index]
  const targetConnections = connections[targetKey(target, connectionType)]
  return (
    <ConnectionsRow
      style={style}
      idColumnMinWidth={idColumnMinWidth}
      connectionColumnMinWidth={connectionColumnMinWidth}
      connectionType={connectionType}
      target={target}
      connectors={connectors}
      connections={targetConnections}
      loading={!targetConnections}
    />
  )
}

export default ConnectionsRow
