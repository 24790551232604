import decode, { JwtPayload } from 'jwt-decode'

// Should match the issuer on the backend
const SUB_PART = '/auth/jwt/sub/users/'

export function identifyFromToken(
  accessToken: string,
  payload: unknown = {}
): void {
  try {
    const { sub } = decode<JwtPayload>(accessToken)
    // TODO: please fix following typescript error
    // @ts-expect-error
    const id = sub.split(SUB_PART)[1]
    identify(id, payload)
  } catch (e) {
    console.error('Failed to identify from access token', e)
  }
}

export function track(event: string, payload?: unknown): void {
  try {
    // TODO: please fix following typescript error
    // @ts-expect-error
    window.analytics.track(event, payload)
  } catch (e) {
    console.error(`Failed to record Segment event: ${event}`, e)
  }
}

// name is just a human-readable category/tag like `Sign Up Page`, it's not required
export function page(name?: string): void {
  try {
    window.analytics.page(name)
  } catch (e) {
    console.error('Failed to record Segment pageview: ', e)
  }
}

export function identify(id: string, payload: unknown = {}): void {
  try {
    // TODO: please fix following typescript error
    // @ts-expect-error
    window.analytics.identify(id, payload)
  } catch (e) {
    console.error(`Failed to identify Segment user: ${id}`, e)
  }
}
