import { useEffect, useState } from 'react'
import { toaster } from 'evergreen-ui'

export const useErrorToaster = (error?: string): void => {
  useEffect(() => {
    if (error) {
      toaster.danger(error)
    }
  }, [error])
}

export const useTitle = (title?: string): void => {
  useEffect(() => {
    if (title != null) {
      document.title = `${title} | Xkit`
    }
  }, [title])
}

export interface FormField<S> {
  value: S
  setValue: React.Dispatch<React.SetStateAction<S>>
  isInvalid: boolean
  setIsInvalid: React.Dispatch<React.SetStateAction<boolean>>
  validationMessage: string | null
  setValidationMessage: React.Dispatch<React.SetStateAction<string | null>>
}

export function useFormField<S>(initialValue: S | (() => S)): FormField<S> {
  const [value, setValue] = useState(initialValue)
  const [isInvalid, setIsInvalid] = useState(false)
  const [validationMessage, setValidationMessage] = useState<string | null>(
    null
  )

  return {
    value,
    setValue,
    isInvalid,
    setIsInvalid,
    validationMessage,
    setValidationMessage
  }
}
