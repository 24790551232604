import {
  BackButton,
  Button,
  ControlIcon,
  Heading,
  IconButton,
  majorScale,
  Pane,
  SelectMenu,
  toaster,
  TrashIcon,
  useTheme
} from 'evergreen-ui'
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { Link } from 'react-router-dom'
import { AppObject, CRMObject, updateAppObject } from '../../api/crm'
import { TokenCredentials } from '../../api/request'
import { ContentCard } from '../components/content-card'
import { LinesOneToMany } from '../components/lines-one-to-many'
import ShapesIcon from '../components/shapes-icon'
import { SpinnerSuspend } from '../components/spinner'
import { useErrorToaster, useTitle } from '../hooks/common'
import { useCRMObjects } from '../hooks/crm-objects'
import { useForceUpdate } from '../hooks/force-update.hook'

interface RelationsAppObjectTabProps {
  object: AppObject | undefined
  loading: boolean
  credentials: TokenCredentials | null
  refresh: () => Promise<void>
}

const RelationsAppObjectTab: FC<RelationsAppObjectTabProps> = memo(
  ({ object, loading, credentials, refresh }) => {
    const theme = useTheme()
    const { CRMObjects, loading: CRMObjectsLoading, error } = useCRMObjects()
    useErrorToaster(error)
    useTitle(`${object?.label_one ?? ''} App Object Relations`)

    const forceUpdate = useForceUpdate()
    const [saving, setSaving] = useState<boolean>(false)
    const CRMObjectRefs = useRef<{ [id: string]: HTMLElement }>({})
    const CRMObjectRefHandler = useCallback(
      (CRMObject: CRMObject, element: HTMLElement): void => {
        if (
          element &&
          !Object.values(CRMObjectRefs.current).includes(element)
        ) {
          CRMObjectRefs.current[`${CRMObject.id}`] = element
          forceUpdate()
        }
      },
      [forceUpdate]
    )
    useEffect(() => {
      if (loading) {
        CRMObjectRefs.current = {}
      }
    }, [loading])
    const fromCRMObjects = Object.values(CRMObjectRefs.current)

    const deleteRelation: (
      object: AppObject,
      CRMObjectID: number
    ) => Promise<void> = async (object, CRMObjectID) => {
      if (credentials == null) {
        toaster.danger('Invalid credentials')
        return
      }
      const index = object.relations.findIndex(
        (individualRelation) => individualRelation.crm_object_id === CRMObjectID
      )
      if (!(index > -1)) {
        return
      }
      object.relations.splice(index, 1)
      const response = await updateAppObject(credentials, object)
      if (response.errors) {
        console.error('Could not remove relation', response.errors)
        toaster.danger('Could not remove relation')
      } else {
        toaster.success('Relation removed')
        await refresh()
      }
    }

    const addRelation: (
      object: AppObject,
      CRMObjectID: number
    ) => Promise<void> = async (object, CRMObjectID) => {
      if (credentials == null) {
        toaster.danger('Invalid credentials')
        return
      }
      const response = await updateAppObject(credentials, {
        ...object,
        relations: [
          ...object.relations,
          {
            crm_object_id: CRMObjectID
          }
        ]
      })
      if (response.errors) {
        console.error('Could not add relation', response.errors)
        toaster.danger('Could not add relation')
      } else {
        toaster.success('Relation added')
        await refresh()
      }
    }

    return (
      <>
        <SpinnerSuspend spinning={loading || CRMObjectsLoading}>
          {object && CRMObjects ? (
            <Pane
              maxWidth={900}
              display='flex'
              justifyContent='start'
              position='relative'
            >
              <Pane>
                <Heading size={500}>App Object</Heading>
                <Pane marginTop={majorScale(2)}>
                  <ContentCard
                    paddingLeft={majorScale(2)}
                    paddingRight={majorScale(2)}
                    display='flex'
                    alignItems='center'
                    width='300px'
                    height='56px'
                    elevation={1}
                  >
                    <ControlIcon size={24} />
                    <Heading
                      size={600}
                      paddingLeft={10}
                      textOverflow='ellipsis'
                      overflow='hidden'
                      whiteSpace='nowrap'
                    >
                      {object.label_one}
                    </Heading>
                  </ContentCard>
                </Pane>
              </Pane>
              <LinesOneToMany
                offsetTop={64}
                width={150}
                direction='ltr'
                dashed={[]}
                to={fromCRMObjects}
                active={fromCRMObjects}
              />
              <Pane>
                <Heading size={500}>CRM Objects</Heading>
                <Pane marginTop={majorScale(2)}>
                  {object.relations.map((relation) => {
                    const CRMObject = CRMObjects.find(
                      (CRMObject) => CRMObject.id === relation.crm_object_id
                    )
                    if (CRMObject) {
                      return (
                        <div
                          ref={(element: HTMLElement | null) => {
                            if (element) {
                              CRMObjectRefHandler(CRMObject, element)
                            }
                          }}
                          key={CRMObject.id}
                          data-uuid={CRMObject.id}
                        >
                          <Pane
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            marginBottom={majorScale(2)}
                          >
                            <ContentCard
                              paddingLeft={majorScale(2)}
                              paddingRight={majorScale(2)}
                              display='flex'
                              alignItems='center'
                              width='300px'
                              height='56px'
                              elevation={1}
                            >
                              <ShapesIcon size={24} />
                              <Heading
                                size={600}
                                paddingLeft={10}
                                textOverflow='ellipsis'
                                overflow='hidden'
                                whiteSpace='nowrap'
                              >
                                {CRMObject.label}
                              </Heading>
                            </ContentCard>
                            <IconButton
                              icon={TrashIcon}
                              intent='danger'
                              isLoading={saving}
                              width={saving ? 60 : 32} // Change width to accommodate spinner
                              marginLeft={majorScale(2)}
                              onClick={async () => {
                                setSaving(true)
                                try {
                                  await deleteRelation(object, CRMObject.id)
                                } catch (error) {
                                  console.error(error)
                                  toaster.danger('Could not remove relation')
                                }
                                setSaving(false)
                              }}
                            />
                          </Pane>
                        </div>
                      )
                    } else {
                      return null
                    }
                  })}
                  <SelectMenu
                    title='Select CRM Object'
                    options={CRMObjects.filter(
                      (CRMObject) =>
                        !object.relations
                          .map((relation) => relation.crm_object_id)
                          .includes(CRMObject.id)
                    ).map((CRMObject) => ({
                      label: CRMObject.label,
                      value: CRMObject.id
                    }))}
                    onSelect={async (item) => {
                      if (!saving) {
                        setSaving(true)
                        try {
                          await addRelation(object, +item.value)
                        } catch (error) {
                          console.error(error)
                          toaster.danger('Could not add relation')
                        }
                        setSaving(false)
                      }
                    }}
                  >
                    <Pane
                      border
                      borderColor={theme.scales.neutral.N5}
                      borderStyle='dashed'
                      borderWidth={3}
                      borderRadius={5}
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      width='300px'
                      height='56px'
                    >
                      <Button
                        iconBefore='add'
                        appearance='minimal'
                        isLoading={saving}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        width='100%'
                        paddingTop={10}
                        paddingBottom={10}
                        boxSizing='content-box'
                      >
                        Add Relation
                      </Button>
                    </Pane>
                  </SelectMenu>
                </Pane>
              </Pane>
            </Pane>
          ) : null}
        </SpinnerSuspend>
        <BackButton is={Link} to={`/app-objects`} marginTop={majorScale(3)}>
          Back to App Objects
        </BackButton>
      </>
    )
  }
)

RelationsAppObjectTab.displayName = 'RelationsAppObjectTab'

export default RelationsAppObjectTab
