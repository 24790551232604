import React, { useMemo, memo } from 'react'
import { Link } from 'react-router-dom'
import { Pane, BackButton, FunctionIcon, majorScale } from 'evergreen-ui'
import { SpinnerSuspend } from '../components/spinner'
import { TabRoute, TabRoutes } from '../components/tab-routes'
import { HeadingTitle } from '../components/heading-title'
import ConnectorMark from '../components/connector-mark'
import { useTitle, useErrorToaster } from '../hooks/common'
import { useDestination } from '../hooks/destination.hook'
import { EditFunction } from './functions/edit-function'
import { EditProvider } from './providers/edit-provider'
import { DestinationLogs } from './destination-logs'
import { DestinationRole } from '../../api/destination'

interface ViewDestinationProps {
  uuid: string
}

export const ViewDestination: React.FC<ViewDestinationProps> = memo(
  ({ uuid }) => {
    const {
      destination,
      loading,
      saving,
      error,
      validationErrors,
      updateDestinationFx
    } = useDestination(uuid)

    useTitle(destination?.name)
    useErrorToaster(error)

    const setupTab: TabRoute = useMemo(() => {
      return {
        title: 'Setup',
        slug: 'setup',
        loading: false,
        content:
          destination?.role === DestinationRole.platform ? (
            <EditFunction
              destination={destination}
              loading={loading}
              saving={saving}
              error={error}
              validationErrors={validationErrors}
              updateDestinationFx={updateDestinationFx}
            />
          ) : (
            <EditProvider
              destination={destination}
              loading={loading}
              saving={saving}
              error={error}
              validationErrors={validationErrors}
              updateDestinationFx={updateDestinationFx}
            />
          )
      }
    }, [
      destination,
      loading,
      saving,
      error,
      validationErrors,
      updateDestinationFx
    ])

    const logsTab: TabRoute = useMemo(() => {
      return {
        title: 'Logs',
        slug: 'logs',
        loading: false,
        content: <DestinationLogs uuid={uuid} />
      }
    }, [uuid])

    const tabs = [setupTab, logsTab]

    return (
      <Pane>
        <SpinnerSuspend spinning={loading || error != null}>
          {destination != null && (
            <>
              <HeadingTitle display='flex' alignItems='center'>
                <>
                  {destination.role === DestinationRole.platform && (
                    <FunctionIcon
                      size={24}
                      marginRight={majorScale(2)}
                      style={{ verticalAlign: 'bottom' }}
                    />
                  )}
                  {destination.role === DestinationRole.provider &&
                    destination.provider != null && (
                      <ConnectorMark
                        markUrl={destination.provider.mark_url}
                        marginRight={majorScale(2)}
                      />
                    )}

                  {destination.name}
                </>
              </HeadingTitle>
              <TabRoutes tabRoutes={tabs} />
              <BackButton is={Link} to='/destinations'>
                Back to Destinations
              </BackButton>
            </>
          )}
        </SpinnerSuspend>
      </Pane>
    )
  }
)

ViewDestination.displayName = 'ViewDestination'

export default ViewDestination
