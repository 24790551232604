import { useContext, useEffect, useState } from 'react'
import { CredentialsContext } from '../login-wrapper'
import {
  Destination,
  listDestinations,
  subscribeToDestinations
} from '../../api/destination'

interface UseDestinationsState {
  loading: boolean
  error: string | undefined
  destinations: Destination[]
}

function sortDestinationsDesc(destinations: Destination[]): Destination[] {
  return destinations.sort(
    (a, b) => b.updated_at.getTime() - a.updated_at.getTime()
  )
}

function destinationsToArray(
  destinations: Map<string, Destination>
): Destination[] {
  return sortDestinationsDesc(Array.from(destinations.values()))
}

export const useDestinations = (): UseDestinationsState => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const [destinations, setDestinations] = useState<Map<string, Destination>>(
    new Map()
  )
  const [destinationArr, setDestinationArr] = useState<Destination[]>([])
  const { callWithCredentials } = useContext(CredentialsContext)

  useEffect(() => {
    // In order to make this setup synchronous, we do a lot of
    // indirection to make sure the subscriber is always torn down
    // if the component is unmounted.
    // TODO: turn this into a repeatable pattern for other hooks
    let tornDown = false
    let tearDown = (): void => {
      tornDown = true
    }

    async function setupListener(): Promise<void> {
      const [onDestination, unsubscribe] = await callWithCredentials(
        async (creds) => subscribeToDestinations(creds)
      )

      if (tornDown) {
        unsubscribe()
        return
      }

      tearDown = unsubscribe

      onDestination((destination) => {
        setDestinations((oldDestinations) => {
          const destinations = new Map(oldDestinations)
          destinations.set(destination.uuid, destination)
          return destinations
        })
      })
    }

    async function loadDestinations(): Promise<void> {
      setLoading(true)
      try {
        const destinationsList = await callWithCredentials(listDestinations)
        setDestinations(
          new Map(
            destinationsList.map((destination) => [
              destination.uuid,
              destination
            ])
          )
        )
      } catch (error) {
        setError(`Error while loading destinations: ${String(error.message)}`)
      } finally {
        setLoading(false)
      }
    }

    void setupListener()
    void loadDestinations()

    return () => {
      tearDown()
    }
  }, [callWithCredentials])

  useEffect(() => {
    setDestinationArr(destinationsToArray(destinations))
  }, [destinations])

  return {
    loading,
    error,
    destinations: destinationArr
  }
}
