import React, { memo, FC, ReactNode } from 'react'
import { Table, majorScale, TableProps } from 'evergreen-ui'
import { ContentCard } from './components/content-card'
import TableSpinner from './components/table-spinner'

interface ContentTableProps extends TableProps {
  heading?: ReactNode
  loading?: boolean
}

const ContentTable: FC<ContentTableProps> = memo(
  ({ loading, heading, footer, children, ...props }) => {
    return (
      <ContentCard
        elevation={1}
        marginBottom={majorScale(4)}
        display='flex'
        flexDirection='column'
        flex={1}
      >
        <Table {...props} display='flex' flexDirection='column' flex={1}>
          {heading ?? null}
          {loading ? <TableSpinner /> : children}
        </Table>
      </ContentCard>
    )
  }
)

ContentTable.displayName = 'ContentTable'

export default ContentTable
