import React, {
  ChangeEvent,
  KeyboardEvent,
  FC,
  memo,
  useEffect,
  useState
} from 'react'
import { TextInput, IconButton, EditIcon } from 'evergreen-ui'
import { Input } from '../../../api/destination-input'
import { Selector } from '../../../api/mapping'
import { BUTTON_SIZE } from './constants'
import { SelectorNodeButton } from './selector-node-button'

interface EditableNodeProps {
  input: Input
  root: Selector
  selector: Selector
  onNameChange: (selector: Selector, newName: string | undefined) => void
  onSelect: (selector: Selector) => void
}

export const EditableNode: FC<EditableNodeProps> = memo(
  ({ input, root, selector, onNameChange, onSelect }) => {
    const [isEditing, setIsEditing] = useState(true)
    useEffect(() => {
      setIsEditing(selector.label == null)
    }, [setIsEditing, selector.label])

    const [label, setLabel] = useState('')
    useEffect(() => {
      setLabel(selector.label ?? '')
    }, [setLabel, selector.label])

    // TODO: verify that the input matches the pattern
    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>): void => {
      if (e.key === 'Enter') {
        e.preventDefault()
        e.stopPropagation()
        onNameChange(selector, label === '' ? undefined : label)
      }
    }

    if (isEditing) {
      return (
        <TextInput
          height={BUTTON_SIZE}
          value={label}
          placeholder={selector.label_pattern}
          maxWidth={400}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setLabel(e.target.value)
          }
          onKeyDown={handleKeyPress}
        />
      )
    }

    return (
      <>
        <SelectorNodeButton
          input={input}
          root={root}
          selector={selector}
          onSelect={onSelect}
        />
        <IconButton
          appearance='minimal'
          icon={EditIcon}
          height={BUTTON_SIZE}
          onClick={() => setIsEditing(true)}
        />
      </>
    )
  }
)

EditableNode.displayName = 'EditableNode'
