import React, { useState, memo } from 'react'
import { Link } from 'react-router-dom'
import { Pane, SearchInput, majorScale } from 'evergreen-ui'
import { HeadingTitle } from '../components/heading-title'
import { SpinnerSuspend } from '../components/spinner'
import { DestinationCard } from '../components/destination-card'
import { NewItemCard } from '../components/new-item-card'
import { useErrorToaster, useTitle } from '../hooks/common'
import { useDestinations } from '../hooks/destinations.hook'
import { Destination } from '../../api/destination'
import { applySearchFilter } from '../../util/apply-search-filter'

export const DestinationsList: React.FC = memo(() => {
  const { loading, error, destinations } = useDestinations()
  const [search, setSearch] = useState('')

  useTitle('Destinations')
  useErrorToaster(error)

  const filteredDestinations = destinations.filter(
    (destination: Destination) => {
      return applySearchFilter(destination.name, search)
    }
  )

  return (
    <Pane>
      <HeadingTitle>Added Destinations</HeadingTitle>
      <SpinnerSuspend spinning={loading || error != null}>
        <SearchInput
          marginBottom={majorScale(1)}
          placeholder='Search for destinations...'
          height={majorScale(6)}
          maxWidth='100%'
          width='100%'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearch(e.target.value)
          }
          value={search}
        />
        <Pane
          clearfix
          marginTop={majorScale(3)}
          display='flex'
          flexWrap='wrap'
          marginRight={majorScale(-3)}
          marginBottom={majorScale(-3)}
        >
          {filteredDestinations.map((destination) => (
            <DestinationCard
              key={destination.uuid}
              destination={destination}
              padding={majorScale(2)}
              marginRight={majorScale(3)}
              marginBottom={majorScale(3)}
              elevation={1}
              hoverElevation={2}
              is={Link}
              textDecoration='none'
              to={`/destinations/${destination.uuid}`}
            />
          ))}
          <NewItemCard
            to={'/destinations/new'}
            width={278}
            height={108}
            title='Add Destination'
            marginRight={majorScale(3)}
            marginBottom={majorScale(3)}
          />
        </Pane>
      </SpinnerSuspend>
    </Pane>
  )
})

DestinationsList.displayName = 'DestinationsList'

export default DestinationsList
