import * as React from 'react'
import Form, { FormField } from './form'
import CopyableTextInput from './copyable-text-input'
import {
  TextInputField,
  ShareIcon,
  Paragraph,
  Text,
  UnorderedList,
  ListItem,
  FilePicker,
  FormField as EvergreenFormField,
  Link as EvergreenLink,
  toaster,
  majorScale,
  minorScale
} from 'evergreen-ui'
import { Authorizer, AuthorizerResultValues } from '../api/authorizer'
import { Platform } from '../api/platform'
import {
  BlockedTextInputField,
  BlockedTextareaField
} from './with-no-recording'
import { readJSONFile } from '../util/read-json-file'
import { hasOwnProperty } from '../util/has-own-property'

function authorizerName(authorizer?: Authorizer): string {
  return authorizer ? authorizer.prototype.name : 'the Service Provider'
}

function getDomainCustomizationHint(text: string): React.ReactElement {
  return (
    <Paragraph size={300} marginTop={minorScale(1)} color='muted'>
      {text} in your{' '}
      <EvergreenLink
        size={300}
        href='/settings#application-settings'
        target='_blank'
      >
        Application Settings
      </EvergreenLink>
      .
    </Paragraph>
  )
}

export function getAuthorizerFields(authorizer?: Authorizer): FormField[] {
  const fields: FormField[] = []

  if (!authorizer) {
    return fields
  }

  if (hasOwnProperty(authorizer, 'segment_destination_slug')) {
    fields.push({
      name: 'segment_destination_slug',
      Field: TextInputField,
      label: 'Destination Slug',
      description: 'The destination that you want to manage for the user.',
      placeholder: 'destination-slug',
      hint: (
        <Paragraph size={300} marginTop={minorScale(1)} color='muted'>
          To see the exact slug we are expecting for your destination:
          <UnorderedList size={300} marginLeft={minorScale(4)}>
            <ListItem color='muted'>
              If you just submitted a destination in the Developer Center for
              approval, you can see the slug on the submission form, or in the
              URL once submitted.
            </ListItem>
            <ListItem color='muted'>
              If your destination is already public, look at the Segment Catalog
              and find your destination. That exact slug should appear in the
              URL.
            </ListItem>
          </UnorderedList>
        </Paragraph>
      )
    })
  }

  if (hasOwnProperty(authorizer, 'callback_url')) {
    fields.push({
      name: 'callback_url',
      Field: CopyableTextInput,
      label: 'Callback URL',
      description: `Callback URL to provide to ${authorizerName(authorizer)}.`,
      hint:
        hasOwnProperty(authorizer, 'deauthorize_url') ||
        hasOwnProperty(authorizer, 'webhooks_url')
          ? null
          : getDomainCustomizationHint(
              'You can customize the domain of this URL'
            )
    })
  }

  if (hasOwnProperty(authorizer, 'deauthorize_url')) {
    fields.push({
      name: 'deauthorize_url',
      Field: CopyableTextInput,
      label: 'Deauthorize URL',
      description: `Deauthorize URL to provide to ${authorizerName(
        authorizer
      )}.`,
      hint: hasOwnProperty(authorizer, 'webhooks_url')
        ? null
        : getDomainCustomizationHint(
            'You can customize the domain of these URLs'
          )
    })
  }

  if (hasOwnProperty(authorizer, 'webhooks_url')) {
    fields.push({
      name: 'webhooks_url',
      Field: CopyableTextInput,
      label: 'Webhooks URL',
      description: `Webhook endpoint to provide to ${authorizerName(
        authorizer
      )}.`,
      hint: getDomainCustomizationHint(
        'You can customize the domain of these URLs'
      )
    })
  }

  if (hasOwnProperty(authorizer, 'trello_display_name')) {
    fields.push({
      name: 'trello_display_name',
      Field: TextInputField,
      label: 'Application Name',
      description:
        'The name of your application to present to users during Trello Authorization.',
      placeholder: 'MyApp'
    })
  }

  if (hasOwnProperty(authorizer, 'client_id')) {
    fields.push({
      name: 'client_id',
      Field: BlockedTextInputField,
      label: 'Client ID',
      description: `The Client ID for your application, as provided by ${authorizerName(
        authorizer
      )}.`,
      placeholder: '12345'
    })
  }

  if (hasOwnProperty(authorizer, 'client_secret')) {
    fields.push({
      name: 'client_secret',
      Field: BlockedTextInputField,
      label: 'Client Secret',
      description: `The Client Secret for your application, as provided by ${authorizerName(
        authorizer
      )}.`,
      placeholder: 'unguessable_string'
    })
  }

  if (hasOwnProperty(authorizer, 'private_key_id')) {
    fields.push({
      name: 'private_key_id',
      Field: BlockedTextInputField,
      label: 'Private Key ID',
      description: `The ID for the Private Key you created for use with ${authorizerName(
        authorizer
      )}.`,
      placeholder: '19f4329bf81027ef37dd17f6dd5f7a6e08756600'
    })
  }

  if (hasOwnProperty(authorizer, 'private_key_pem')) {
    fields.push({
      name: 'private_key_pem',
      Field: (props) => <BlockedTextareaField fontFamily='mono' {...props} />, // eslint-disable-line react/display-name
      label: 'Private Key',
      description: `The PEM-encoded Private Key you created for use with ${authorizerName(
        authorizer
      )}.`,
      placeholder: `-----BEGIN RSA PRIVATE KEY-----
MIICWgIBAAKBgFLvjtQtIoNJettNl1kD6+4YGXTyg985y2FoyrzKS22rxcSh3BBX
er2UpaGgteISz7aMIGGs2g4rdEnGgTpBPgKcHj1/Jufw5M9cW4+BGmmy9CBEgYuE
cUphBQ0FKB8y36v9UBHu8EyRQCcIVWT69kUIlwlNFxZ+4NawLBQdoIgtAgMBAAEC
gYABPNXy0KGdN/F/aE8D4w0YRn/KwPu6zw/76iZsXtB1WIThBFFWZRfN0HQQT2en
cgRrgvfQvVWbtIZNu4qeJe8Y8nzxq6itxCp/PpZU2VeM9BMJlK9xWrPwzSDL/pQV
GTVTCchOkSIYAI6mfdNlR+nQriEMDgt+a4UYI81DuatE+QJBAKWyPS8GZxMjww6p
ptP47BRdsyDlnfPNNT9ggecwtUZZt/o5HIQJFiEc2+aGvvpJ7nDtCe1SVBv6Xrv9
nVdCaUMCQQCAIqrSZDAF/vo4V3zqzSCIFCknh4Acmsn9Ggp+zAvgXyX64oP1/GEy
MSdogvjmNd4yslNf4wBrZ1fI4q1/9LnPAkA4/XYXz37yUVGMoINV0v73/kZXIULT
kRgoZTwo1VL0EHW75ZrOOwXWe8OSpXPYSEdvpcfNTYzKVrHwvMk6MwhbAkBt81F5
b5b1zjGQHyKFqLb9ja8yyEbaRMiGcs4Gt7iJM5w3iB+L+KC+nQcf2yMRB7A++F4q
rXMytl7RdhFC9xBpAkArDGYvdo8Y2kSRNdBMku6Apz9n6aY8lmmHsooWQRfcJ+bH
49T86zQCT02r0ejvjg3vnuL3mOh1rfZmKmb4CMkn
-----END RSA PRIVATE KEY-----`
    })
  }

  if (hasOwnProperty(authorizer, 'google_service_account_email')) {
    fields.push({
      name: 'google_service_account_email',
      Field: TextInputField,
      label: 'Service Account Email',
      description:
        'The email address of your service account, as assigned by Google.',
      placeholder:
        'test-service-account@possible-sun-278123.iam.gserviceaccount.com'
    })
  }

  if (hasOwnProperty(authorizer, 'google_verification_filename')) {
    fields.push({
      name: 'google_verification_filename',
      Field: TextInputField,
      label: (
        <>
          Verification File Name <Text color='muted'>(optional)</Text>
        </>
      ),
      description: `The name of the verification file provided by ${authorizerName(
        authorizer
      )} for domain verification.`,
      placeholder: 'google1234abcdef1234.html',
      hint: (
        <Paragraph size={300} marginTop={minorScale(1)} color='muted'>
          See the Search Console{' '}
          <a
            href='https://support.google.com/webmasters/answer/9008080?hl=en#html_verification'
            target='_blank'
            // TODO: please fix following typescript error
            // @ts-expect-error
            noreferrer='true'
            external='true'
            rel='noreferrer'
          >
            HTML File upload documentation
            <ShareIcon size={12} marginLeft={4} />
          </a>{' '}
          for more information
        </Paragraph>
      )
    })
  }

  if (hasOwnProperty(authorizer, 'hubspot_app_id')) {
    fields.push({
      name: 'hubspot_app_id',
      Field: TextInputField,
      label: 'App ID',
      description: "Your app's unique ID, as provided by HubSpot.",
      placeholder: '123456'
    })
  }

  if (hasOwnProperty(authorizer, 'hubspot_api_key')) {
    fields.push({
      name: 'hubspot_api_key',
      Field: TextInputField,
      label: 'API Key',
      description: 'Your developer account API Key, as provided by HubSpot.',
      placeholder: 'abcd1234-0000-1111-2222-abcdef123456',
      hint: (
        <Paragraph size={300} marginTop={minorScale(1)} color='muted'>
          We use this API key to setup webhook subscriptions automatically. See{' '}
          <a
            href='https://legacydocs.hubspot.com/docs/faq/developer-api-keys'
            target='_blank'
            // TODO: please fix following typescript error
            // @ts-expect-error
            noreferrer='true'
            external='true'
            rel='noreferrer'
          >
            the docs
            <ShareIcon size={12} marginLeft={4} />
          </a>{' '}
          on how to generate it.
        </Paragraph>
      )
    })
  }

  if (hasOwnProperty(authorizer, 'bamboohr_application_key')) {
    fields.push({
      name: 'bamboohr_application_key',
      Field: BlockedTextInputField,
      label: 'Application Key',
      description: 'Your secret application key, as provided by BambooHR.',
      placeholder: '20fb4d1b'
    })
  }

  if (hasOwnProperty(authorizer, 'api_domain')) {
    fields.push({
      name: 'api_domain',
      Field: TextInputField,
      label: 'API domain',
      description: 'Base URL for API requests.',
      placeholder: 'https://api.example.com'
    })
  }

  if (hasOwnProperty(authorizer, 'events_url')) {
    fields.push({
      name: 'events_url',
      Field: CopyableTextInput,
      label: 'Events Webhook URL',
      description: `Webhook endpoint to collect events from ${authorizerName(
        authorizer
      )}.`,
      // TODO: make this less Segment-specific
      hint: 'This is your Segment Subscription API Endpoint.'
    })
  }

  if (hasOwnProperty(authorizer, 'salesforce_package_namespace')) {
    fields.push({
      name: 'salesforce_package_namespace',
      Field: TextInputField,
      label: (
        <>
          Packge Namespace <Text color='muted'>(optional)</Text>
        </>
      ),
      description: "The namespace of your app in Salesforce's AppExchange.",
      placeholder: 'myapp',
      hint: (
        <Paragraph size={300} marginTop={minorScale(1)} color='muted'>
          Leave this blank if you haven't published your package yet. See{' '}
          <a
            href='https://support.google.com/webmasters/answer/9008080?hl=en#html_verification'
            target='_blank'
            // TODO: please fix following typescript error
            // @ts-expect-error
            noreferrer='true'
            external='true'
            rel='noreferrer'
          >
            the docs
            <ShareIcon size={12} marginLeft={4} />
          </a>{' '}
          for more information.
        </Paragraph>
      )
    })
  }

  return fields
}

interface AuthorizerFormProps {
  isCRMConnector?: boolean
  loading: boolean
  updateAuthorizer: (authorizer: Authorizer) => Promise<AuthorizerResultValues>
  authorizer?: Authorizer
  platform?: Platform
}

interface AuthorizerFormState {
  client_id?: string
  google_service_account_email?: string
  private_key_id?: string
  private_key_pem?: string
}

class AuthorizerForm extends React.Component<
  AuthorizerFormProps,
  AuthorizerFormState
> {
  constructor(props: AuthorizerFormProps) {
    super(props)
    this.state = {}
    if (this.props.authorizer) {
      this.state = {
        client_id: this.props.authorizer.client_id,
        google_service_account_email:
          this.props.authorizer.google_service_account_email,
        // TODO: please fix following typescript error
        // @ts-expect-error
        private_key_id: this.props.private_key_id,
        // TODO: please fix following typescript error
        // @ts-expect-error
        private_key_pem: this.props.private_key_pem
      }
    }
  }

  componentDidUpdate(prevProps: AuthorizerFormProps): void {
    if (
      prevProps.authorizer !== this.props.authorizer &&
      this.props.authorizer
    ) {
      this.setState({
        client_id: this.props.authorizer.client_id,
        google_service_account_email:
          this.props.authorizer.google_service_account_email,
        // TODO: please fix following typescript error
        // @ts-expect-error
        private_key_id: this.props.private_key_id,
        // TODO: please fix following typescript error
        // @ts-expect-error
        private_key_pem: this.props.private_key_pem
      })
    }
  }

  getValues(): Authorizer | undefined {
    const { authorizer, platform } = this.props

    if (
      !authorizer ||
      !hasOwnProperty(authorizer, 'trello_display_name') ||
      // TODO: please fix following eslint error
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      authorizer.trello_display_name ||
      !platform ||
      !platform.name
    ) {
      // TODO: please fix following typescript error
      // @ts-expect-error
      return {
        ...authorizer,
        ...this.state
      }
    }

    return {
      ...authorizer,
      ...this.state,
      trello_display_name: platform.name
    }
  }

  handleGoogleServiceAccountJSONUpload = async (
    files: FileList
  ): Promise<void> => {
    if (!files || !files[0]) return

    try {
      const config = await readJSONFile(files[0])
      if (config.type !== 'service_account') {
        throw new Error('Unidentified JSON configuration')
      }
      this.setState({
        // TODO: please fix following typescript error
        // @ts-expect-error
        client_id: config.client_id,
        // TODO: please fix following typescript error
        // @ts-expect-error
        private_key_id: config.private_key_id,
        // TODO: please fix following typescript error
        // @ts-expect-error
        private_key_pem: config.private_key,
        // TODO: please fix following typescript error
        // @ts-expect-error
        google_service_account_email: config.client_email
      })
      toaster.success('Processed JSON upload!')
    } catch (e) {
      toaster.danger(e)
    }
  }

  renderGoogleServiceAccountJSONUpload(): React.ReactNode {
    const { authorizer } = this.props
    if (
      !authorizer ||
      !hasOwnProperty(authorizer, 'google_service_account_email')
    ) {
      return
    }

    return (
      <EvergreenFormField
        label='Upload JSON Key'
        description='Skip the manual configuration by uploading the key file generated by Google'
        marginBottom={majorScale(3)}
      >
        <FilePicker
          accept={['.json', 'application/json']}
          placeholder='possible-sun-278123-19f4329bf810.json'
          onChange={this.handleGoogleServiceAccountJSONUpload}
        />
      </EvergreenFormField>
    )
  }

  render(): React.ReactNode {
    const { loading, authorizer, updateAuthorizer, isCRMConnector } = this.props

    return (
      <Form
        loading={loading}
        // TODO: please fix following typescript error
        // @ts-expect-error
        values={this.getValues()}
        fields={getAuthorizerFields(authorizer)}
        // TODO: please fix following typescript error
        // @ts-expect-error
        update={updateAuthorizer}
        saveMessage={`Updated ${
          isCRMConnector ? 'Credentials' : 'Service Provider Settings'
        } for ${authorizerName(authorizer)}`}
      >
        {this.renderGoogleServiceAccountJSONUpload()}
      </Form>
    )
  }
}

export default AuthorizerForm
