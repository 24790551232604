import React, { ReactNode } from 'react'
import { Button, SelectMenu, Text, majorScale } from 'evergreen-ui'
import ConnectorMark from './components/connector-mark'
import { Connector } from '../api/connector'

interface SelectConnectorProps {
  connectors: Connector[]
  children: (connector: Connector) => ReactNode
}

const SelectConnector: React.FC<SelectConnectorProps> = ({
  connectors,
  children
}) => {
  const [selectedConnector, setSelectedConnector] = React.useState(
    connectors[0]
  )
  if (!selectedConnector && connectors.length > 0 && connectors[0]) {
    setSelectedConnector(connectors[0])
  }

  return (
    <>
      <SelectMenu
        title='Installed Connectors'
        options={connectors.map((c) => {
          return {
            label: c.name,
            value: c.slug,
            icon: c.mark_url
          }
        })}
        selected={selectedConnector?.slug}
        onSelect={(item) =>
          // TODO: please fix following typescript error
          // @ts-expect-error
          setSelectedConnector(connectors.find((c) => c.slug === item.value))
        }
        closeOnSelect
      >
        {selectedConnector ? (
          <Button height={40}>
            <ConnectorMark markUrl={selectedConnector.mark_url} size={20} />
            <Text marginLeft={majorScale(1)} fontWeight={500}>
              {selectedConnector.name}
            </Text>
          </Button>
        ) : (
          <Button height={40}>Select a connector</Button>
        )}
      </SelectMenu>
      {children(selectedConnector)}
    </>
  )
}

export default SelectConnector
