import { Connection } from '@xkit-co/xkit.js'
import {
  Button,
  ListItem,
  majorScale,
  Pane,
  Paragraph,
  toaster,
  UnorderedList
} from 'evergreen-ui'
import React, {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { Connector } from '../../api/connector'
import { SpinnerSuspend } from '../components/spinner'
import ConnectDeveloperAccountButton from '../connect-developer-account-button'
import ContentSection from '../content-section'
import { useTemplates } from '../hooks/templates.hook'
import { XkitForConnectContext } from '../xkit-for-connect-wrapper'

interface DeveloperAccountConnectProps {
  loading: boolean
  platformSlug?: string
  connector?: Connector
  onComplete?: () => void
}

const DeveloperAccountConnectTab: FC<DeveloperAccountConnectProps> = memo(
  ({ loading, platformSlug, connector, onComplete }) => {
    const { xkitForConnect } = useContext(XkitForConnectContext)
    const { loading: templatesLoading, templates } = useTemplates()
    const [devConnection, setDevConnection] = useState<Connection | null>(null)
    const [devConnectionLoading, setDevConnectionLoading] =
      useState<boolean>(true)

    const devConnectorTemplate = templates.find(
      (template) => template.slug === connector?.template.dev_template_slug
    )
    const devConnectionID = devConnectorTemplate
      ? `${platformSlug ?? ''}:${devConnectorTemplate.slug}`
      : null

    const isLinked =
      devConnection?.authorization?.status === 'active' && devConnection.enabled

    const getDevConnection = useCallback(async () => {
      if (xkitForConnect && devConnectionID && platformSlug) {
        setDevConnectionLoading(true)
        try {
          const connection = await xkitForConnect.getConnection({
            id: devConnectionID
          })
          if (connection) {
            setDevConnection(connection)
          }
        } catch (e) {
          setDevConnection(null)
        }
        setDevConnectionLoading(false)
      }
    }, [xkitForConnect, devConnectionID, platformSlug])

    useEffect(() => {
      void getDevConnection()
    }, [getDevConnection])

    const onCompleteHandler = useCallback(
      (added: boolean) => {
        toaster.success(
          added
            ? `Successfully connected ${devConnectorTemplate?.name ?? ''}.`
            : `Successfully disconnected ${devConnectorTemplate?.name ?? ''}.`
        )
        void getDevConnection()
      },
      [devConnectorTemplate?.name, getDevConnection]
    )

    return (
      <ContentSection title='Connect Developer Account'>
        <SpinnerSuspend
          spinning={loading || templatesLoading || devConnectionLoading}
        >
          {connector && templates && devConnectorTemplate ? (
            <>
              <Pane marginBottom={majorScale(3)}>
                <Paragraph>
                  Connecting your {devConnectorTemplate.name} will allow Xkit
                  to:
                </Paragraph>
                <UnorderedList
                  marginTop={majorScale(1)}
                  marginBottom={majorScale(2)}
                >
                  <ListItem>
                    Setup the CRM Embed application for your users
                  </ListItem>
                  <ListItem>
                    Setup webhook subscriptions for your users
                  </ListItem>
                </UnorderedList>
                <ConnectDeveloperAccountButton
                  icon={isLinked ? 'tick' : 'clean'}
                  appearance={isLinked ? 'default' : 'primary'}
                  intent={isLinked ? 'success' : 'none'}
                  height={40}
                  devConnectorTemplate={devConnectorTemplate}
                  connection={devConnection}
                  devConnectionID={devConnectionID}
                  xkit={xkitForConnect}
                  isLinked={isLinked}
                  onComplete={onCompleteHandler}
                >
                  Connect {devConnectorTemplate.name}
                </ConnectDeveloperAccountButton>
              </Pane>
              {onComplete ? (
                <Pane>
                  <Button
                    onClick={onComplete}
                    appearance={isLinked ? 'primary' : 'default'}
                  >
                    {isLinked ? 'Finish' : 'Skip'}
                  </Button>
                </Pane>
              ) : null}
            </>
          ) : null}
        </SpinnerSuspend>
      </ContentSection>
    )
  }
)
DeveloperAccountConnectTab.displayName = 'DeveloperAccountConnectTab'

export default DeveloperAccountConnectTab
