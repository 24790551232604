import React, { FC, memo } from 'react'
import { majorScale, Pane, PaneProps } from 'evergreen-ui'
import { Connector, ConnectorSlim } from '../../api/connector'
import ConnectorMark from '../components/connector-mark'
import { XText } from '../components/x-text'
import { Template } from '../../api/template'

export interface ConnectorHeadingProps extends PaneProps {
  spaceBetween?: number | string
  connector?: Connector | ConnectorSlim | Template
}

export const ConnectorHeading: FC<ConnectorHeadingProps> = memo(
  ({ connector, spaceBetween = majorScale(2), ...paneProps }) => {
    const color = paneProps.color || 'inherit'

    if (connector == null) {
      return null
    }

    return (
      <Pane display='flex' alignItems='center' {...paneProps}>
        <ConnectorMark
          markUrl={connector.mark_url}
          marginRight={spaceBetween}
        />
        <XText color={color}>{connector.name}</XText>
      </Pane>
    )
  }
)
ConnectorHeading.displayName = 'ConnectorHeading'
