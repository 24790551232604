import React, { FC, memo } from 'react'
import { majorScale, Pane, PaneProps, Spinner, useTheme } from 'evergreen-ui'
import { XText } from '../components/x-text'

export interface ConnectorHeadingSkeletonProps extends PaneProps {
  muted?: boolean
  spinnerSize?: number
}

export const ConnectorHeadingSkeleton: FC<ConnectorHeadingSkeletonProps> = memo(
  ({ muted, spinnerSize = 24, ...paneProps }) => {
    const theme = useTheme()

    const color = muted
      ? theme.colors.text.muted
      : paneProps.color ?? theme.colors.text.dark

    return (
      <Pane display='flex' alignItems='center' {...paneProps}>
        <Spinner marginRight={majorScale(2)} size={spinnerSize} />
        <XText color={color}>Loading...</XText>
      </Pane>
    )
  }
)
ConnectorHeadingSkeleton.displayName = 'ConnectorHeadingSkeleton'
