import * as React from 'react'
import ContentSection from '../content-section'
import { Link, Paragraph } from 'evergreen-ui'
import PlatformOrigins from './platform-origins'

interface PlatformOriginsSettingsProps {
  crmOnlyConnectors: boolean
}

const PlatformOriginsSettings: React.FC<PlatformOriginsSettingsProps> = ({
  crmOnlyConnectors
}) => {
  return (
    <ContentSection
      title='Web Origins'
      description={
        <>
          A security measure to control where you can use our JavaScript
          libraries.{' '}
          <Link
            size={300}
            target='_blank'
            href={
              crmOnlyConnectors
                ? 'https://xkit.co/docs/crm-link#allow-web-origins'
                : 'https://docs.xkit.co/docs/configure-xkit#website-origin'
            }
            rel='noreferrer'
          >
            View documentation
          </Link>
        </>
      }
    >
      <Paragraph>
        In order to protect your users from malicious actors, Xkit makes use of{' '}
        <Link
          href='https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS'
          target='_blank'
          rel='noreferrer'
        >
          CORS
        </Link>{' '}
        to prevent usage of the Xkit APIs from websites that you have not
        explicitly approved.
      </Paragraph>
      <Paragraph marginTop='default'>
        The entries in the table below that are marked "allowed" are places that
        you expect to use the crm-link.js or xkit.js library. The other entries
        are attempted usages that we have detected. To add an origin to the
        allowed list, you can enable the "allowed" switch, or type it in the
        last row of the table.{' '}
        <Link
          target='_blank'
          href={
            crmOnlyConnectors
              ? 'https://xkit.co/docs/crm-link#allow-web-origins'
              : 'https://docs.xkit.co/docs/configure-xkit#website-origin'
          }
          rel='noreferrer'
        >
          View the web origins documentation
        </Link>{' '}
        to learn more.
      </Paragraph>
      <PlatformOrigins />
    </ContentSection>
  )
}

export default PlatformOriginsSettings
