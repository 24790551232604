import React, { FC, memo, useCallback } from 'react'
import {
  majorScale,
  Table,
  Heading,
  Pane,
  IconButton,
  RefreshIcon,
  PeopleIcon
} from 'evergreen-ui'
import { Link } from 'react-router-dom'
import { Pipeline } from '../../api/pipeline'
import { useConnectionsByPipeline } from '../hooks/connections-by-pipeline.hook'
import { useSyncsByPipeline } from '../hooks/syncs.hook'
import { getConnectionReadableTitle } from '../../api/connection'
import ContentTable from '../content-table'
import { getPlatformContextReadableTitle } from '../../api/platform-user'
import { useErrorToaster } from '../hooks/common'
import { TableRowEmpty } from '../components/table-row-empty'
import { ConnectionStatusBadge } from '../providers/connection-status-badge'

interface ConnectionsTableProps {
  loading: boolean
  pipeline: Pipeline
}

const tableHead = (
  <Table.Head display='flex'>
    <Table.TextHeaderCell flexBasis={1} flexGrow={2}>
      Context
    </Table.TextHeaderCell>
    <Table.TextHeaderCell flexBasis={1} flexGrow={2}>
      Connection
    </Table.TextHeaderCell>
    <Table.TextHeaderCell flexBasis={1} flexGrow={1}>
      Status
    </Table.TextHeaderCell>
  </Table.Head>
)

export const ConnectionsTable: FC<ConnectionsTableProps> = memo(
  ({ loading, pipeline }) => {
    const {
      connections,
      loading: connectionsLoading,
      error: connectionsError,
      refresh: refreshConnections
    } = useConnectionsByPipeline(pipeline.uuid)
    useErrorToaster(connectionsError)
    const {
      syncs,
      loading: syncsLoading,
      error: syncsError,
      refresh: refreshSyncs
    } = useSyncsByPipeline(pipeline.uuid)
    useErrorToaster(syncsError)

    const refresh = useCallback(() => {
      void refreshConnections()
      void refreshSyncs()
    }, [refreshSyncs, refreshConnections])

    return (
      <>
        <Pane marginBottom={majorScale(2)} display='flex' alignItems='center'>
          <Heading size={600} marginRight={majorScale(1)}>
            Connections
          </Heading>
          <IconButton icon={RefreshIcon} onClick={refresh} title='Refresh' />
        </Pane>
        <ContentTable
          heading={tableHead}
          loading={loading || connectionsLoading}
        >
          <Table.Body>
            {connections.length === 0 && (
              <Table.Body>
                <TableRowEmpty message='No connections' icon={PeopleIcon} />
              </Table.Body>
            )}
            {connections.map((connection) => (
              <Table.Row
                key={connection.id}
                isSelectable
                is={Link}
                to={`connections/${connection.id}`}
                display='flex'
              >
                <Table.TextCell flexBasis={1} flexGrow={2}>
                  {getPlatformContextReadableTitle(connection.context)}
                </Table.TextCell>
                <Table.TextCell flexBasis={1} flexGrow={2}>
                  {getConnectionReadableTitle(connection)}
                </Table.TextCell>
                <Table.TextCell flexBasis={1} flexGrow={1}>
                  <ConnectionStatusBadge
                    sync={syncs.find(
                      (sync) => sync.connectionId === connection.id
                    )}
                    connection={connection}
                    loading={syncsLoading}
                  />
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </ContentTable>
      </>
    )
  }
)

ConnectionsTable.displayName = 'ConnectionsTable'
