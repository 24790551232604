import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  FormField,
  Heading,
  majorScale,
  Pane,
  RadioGroup,
  Select,
  Table,
  TextInputField,
  toaster
} from 'evergreen-ui'
import { hasOwnProperty } from '../../util/has-own-property'
import { Pipeline } from '../../api/pipeline'
import {
  SourceObject,
  SourceObjectParams,
  SourceRelation,
  SourceRelationParams
} from '../../api/source-object'
import { useSourceObject } from '../hooks/source-object.hooks'
import SideSheet from '../side-sheet'
import { Controlled as CodeMirror } from 'react-codemirror2'
import { InputsTable, InputsTableProps } from '../components/inputs-table'
import { TableCellTextInput } from '../components/table-cell-text-input'
import { ContentCard } from '../components/content-card'
import { ChangeError } from '../../api/request'
import { useErrorToaster } from '../hooks/common'
import TableSpinner from '../components/table-spinner'

interface SourceObjectsTabProps {
  loading: boolean
  pipeline: Pipeline
  pipelineFetch: () => void
}

interface SourceObjectRowProps {
  pipeline: Pipeline
  sourceObject: SourceObject
  onClose: () => void
}

interface SourceObjectFormProps {
  pipeline: Pipeline
  sourceObjectSlug: string
}

interface RelationshipsTableProps {
  loading: boolean
  pipeline: Pipeline
  relations: SourceRelation[]
  setRelations: React.Dispatch<React.SetStateAction<SourceRelation[]>>
}

function mapRelationsToInputs(
  relations: SourceRelation[]
): InputsTableProps<SourceRelationParams>['inputs'] {
  return relations.map((sourceRelation: SourceRelation) => {
    return {
      value: {
        id: sourceRelation.id,
        slug: sourceRelation.slug,
        to_source_object_id: sourceRelation.to_source_object_id,
        cardinality: sourceRelation.cardinality
      }
    }
  })
}

function mapRelationsFromInputs(
  newRelations: InputsTableProps<SourceRelationParams>['inputs']
): SourceRelation[] {
  return newRelations.map((params) => {
    return {
      id: params.value.id as number,
      slug: params.value.slug,
      cardinality: params.value.cardinality,
      to_source_object_id: params.value.to_source_object_id as number
    }
  })
}

function validationMessage(
  errors: ChangeError<SourceObjectParams> | undefined,
  key: string
): string | undefined {
  if (!errors) return
  if (!hasOwnProperty(errors, key)) return

  // TODO: handle casting the `unknown` error value to the array/message format
  // @ts-expect-error
  const error: Array<{ message: string }> = errors[key]

  return error.map((error) => error.message).join(', ')
}

const RelationshipsTable: React.FC<RelationshipsTableProps> = ({
  loading,
  pipeline,
  relations,
  setRelations
}) => {
  const handleSetInputs = useCallback(
    (newRelations: InputsTableProps<SourceRelationParams>['inputs']) => {
      setRelations(mapRelationsFromInputs(newRelations))
    },
    [setRelations]
  )

  const cardinalityOptions = [
    { label: 'One', value: 'one' },
    { label: 'Many', value: 'many' }
  ]

  if (!pipeline) return null
  if (!relations) return null

  return (
    <FormField label='Relationships' marginTop={majorScale(2)}>
      <InputsTable<SourceRelationParams>
        disabled={loading}
        inputLabel='Relationship'
        headers={['Slug', 'Object', 'Cardinality']}
        render={({ disabled, isInvalid, value }, onChange) => {
          return (
            <>
              <TableCellTextInput
                value={value.slug}
                onChange={(val) => {
                  onChange({ ...value, slug: val })
                }}
              />
              <Table.Cell>
                <Select
                  value={value.to_source_object_id}
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                    onChange({
                      ...value,
                      to_source_object_id: parseInt(event.target.value, 10)
                    })
                  }}
                >
                  <option>Select an Object</option>
                  {pipeline.source.source_objects.map((sourceObject) => {
                    return (
                      <option key={sourceObject.id} value={sourceObject.id}>
                        {sourceObject.label_one}
                      </option>
                    )
                  })}
                </Select>
              </Table.Cell>
              <Table.Cell>
                <RadioGroup
                  value={value.cardinality}
                  options={cardinalityOptions}
                  onChange={(val) => onChange({ ...value, cardinality: val })}
                />
              </Table.Cell>
            </>
          )
        }}
        inputs={mapRelationsToInputs(relations)}
        setInputs={handleSetInputs}
        newValue={() => {
          return { slug: '', cardinality: 'one' }
        }}
      />
    </FormField>
  )
}

const SourceObjectForm: React.FC<SourceObjectFormProps> = ({
  pipeline,
  sourceObjectSlug
}) => {
  const [saving, setSaving] = useState<boolean>(false)
  const [id, setId] = useState<number | undefined>(undefined)
  const [slug, setSlug] = useState<string>('')
  const [labelOne, setLabelOne] = useState<string>('')
  const [labelMany, setLabelMany] = useState<string>('')
  const [isRoot, setIsRoot] = useState<boolean>(false)
  const [schemaBody, setSchemaBody] = useState<string>('')
  const [relations, setRelations] = useState<SourceRelation[]>([])
  const {
    loading,
    error: sourceObjectError,
    validationErrors,
    sourceObject,
    updateFx
  } = useSourceObject(pipeline.uuid, sourceObjectSlug)

  useErrorToaster(sourceObjectError)

  const editorOptions = {
    mode: 'javascript',
    theme: 'xq-light',
    lineNumbers: true,
    readOnly: false
  }

  const handleSave = useCallback(async (): Promise<void> => {
    try {
      setSaving(true)
      const result = await updateFx({
        id: id,
        slug: slug,
        label_one: labelOne,
        label_many: labelMany,
        is_root: isRoot,
        schema: JSON.parse(schemaBody === '' ? '{}' : schemaBody),
        from_relations: relations.map((rel) => {
          return {
            id: rel.id,
            slug: rel.slug,
            cardinality: rel.cardinality,
            to_source_object_id: rel.to_source_object_id
          }
        })
      })

      // TODO: handle errors
      if (result && hasOwnProperty(result, 'source_object')) {
        toaster.success(`Saved Source Object!`)
      } else {
        toaster.danger(`Failed to save Source Object!`)
      }
    } catch (e) {
      toaster.danger(`Error while saving source object: ${String(e.message)}`)
    } finally {
      setSaving(false)
    }
  }, [id, slug, labelOne, labelMany, isRoot, schemaBody, relations, updateFx])

  useEffect(() => {
    if (sourceObject) {
      setId(sourceObject.id)
      setSlug(sourceObject.slug)
      setLabelOne(sourceObject.label_one)
      setLabelMany(sourceObject.label_many)
      setIsRoot(sourceObject.is_root)
      setSchemaBody(JSON.stringify(sourceObject.schema, null, 2))
      setRelations(sourceObject.from_relations)
    }
  }, [
    sourceObject,
    sourceObject?.id,
    sourceObject?.slug,
    sourceObject?.label_one,
    sourceObject?.label_many,
    sourceObject?.is_root,
    sourceObject?.schema,
    sourceObject?.from_relations
  ])

  return (
    <FormField>
      <TextInputField
        disabled={loading || saving}
        required={true}
        isInvalid={!!validationErrors?.slug}
        validationMessage={validationMessage(validationErrors, 'slug')}
        value={slug}
        label='Object Slug'
        placeholder='contacts, deals, leads'
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSlug(e.target.value)
        }
      />
      <TextInputField
        disabled={loading || saving}
        isInvalid={!!validationErrors?.label_one}
        validationMessage={validationMessage(validationErrors, 'label_one')}
        value={labelOne}
        required={true}
        label='Label (singular)'
        placeholder='Contact, Deal, Lead'
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setLabelOne(e.target.value)
        }
      />
      <TextInputField
        disabled={loading || saving}
        isInvalid={!!validationErrors?.label_many}
        validationMessage={validationMessage(validationErrors, 'label_many')}
        value={labelMany}
        required={true}
        label='Label (plural)'
        placeholder='Contacts, Deals, Leads'
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setLabelMany(e.target.value)
        }
      />
      <FormField
        label='Root Object'
        description='Makes the object selectable as a root in the sync.'
      >
        <Checkbox
          disabled={loading || saving}
          label='Root?'
          checked={isRoot}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setIsRoot(e.target.checked)
          }
        >
          Root?
        </Checkbox>
      </FormField>
      <FormField
        label='JSON Schema'
        description='The JSON schema that describes the object properties.'
      >
        <Pane border='default'>
          <CodeMirror
            value={schemaBody}
            options={editorOptions}
            onBeforeChange={(editor, data, value) => {
              setSchemaBody(value)
            }}
          />
        </Pane>
      </FormField>
      <RelationshipsTable
        pipeline={pipeline}
        relations={relations}
        loading={loading || saving}
        setRelations={setRelations}
      />
      <Button
        appearance='primary'
        isLoading={loading || saving}
        onClick={handleSave}
        marginTop={majorScale(2)}
      >
        Save
      </Button>
    </FormField>
  )
}

const SourceObjectRow: React.FC<SourceObjectRowProps> = ({
  pipeline,
  sourceObject,
  onClose
}) => {
  const [editMode, setEditMode] = useState<boolean>(false)

  const handleOnClose = useCallback(() => {
    setEditMode(false)
    onClose()
  }, [onClose])

  return (
    <>
      <Table.Row key={sourceObject.id}>
        <Table.TextCell>{sourceObject.slug}</Table.TextCell>
        <Table.TextCell>
          {sourceObject.label_one} / {sourceObject.label_many}
        </Table.TextCell>
        <Table.TextCell>{sourceObject.is_root ? 'Yes' : 'No'}</Table.TextCell>
        <Table.Cell width={120} flex='none'>
          <Button
            iconBefore='edit'
            onClick={() => {
              setEditMode(true)
            }}
          >
            Edit
          </Button>
        </Table.Cell>
      </Table.Row>
      <SideSheet
        title={`Edit ${sourceObject.label_one}`}
        isShown={editMode}
        onClose={handleOnClose}
      >
        <Pane maxWidth='600px'>
          {editMode && (
            <SourceObjectForm
              pipeline={pipeline}
              sourceObjectSlug={sourceObject.slug}
            />
          )}
        </Pane>
      </SideSheet>
    </>
  )
}

export const SourceObjectsTab: React.FC<SourceObjectsTabProps> = ({
  loading,
  pipeline,
  pipelineFetch
}) => {
  const [newFormVisible, setNewFormVisible] = useState<boolean>(false)

  const handleSideSheetClose = useCallback(() => {
    setNewFormVisible(false)
    pipelineFetch()
  }, [pipelineFetch])

  const header = (
    <Table.Head>
      <Table.TextHeaderCell key='slug'>Slug</Table.TextHeaderCell>
      <Table.TextHeaderCell key='labels'>Labels</Table.TextHeaderCell>
      <Table.TextHeaderCell key='is_root'>Root</Table.TextHeaderCell>
      <Table.HeaderCell width={120} flex='none' />
    </Table.Head>
  )

  if (loading) {
    return (
      <>
        <Pane marginBottom={majorScale(2)} display='flex' alignItems='center'>
          <Heading size={600}>Source Objects</Heading>
        </Pane>
        <ContentCard elevation={1} padding={majorScale(3)}>
          <Table maxWidth={700}>
            {header}
            <TableSpinner />
          </Table>
        </ContentCard>
      </>
    )
  }

  return (
    <>
      <Pane marginBottom={majorScale(2)} display='flex' alignItems='center'>
        <Heading size={600}>Source Objects</Heading>
      </Pane>
      <ContentCard elevation={1} padding={majorScale(3)}>
        <Table maxWidth={700}>
          {header}
          <Table.Body>
            {pipeline.source.source_objects.map((sourceObject) => {
              return (
                <SourceObjectRow
                  key={sourceObject.id}
                  pipeline={pipeline}
                  sourceObject={sourceObject}
                  onClose={handleSideSheetClose}
                />
              )
            })}
            <Table.Row
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <SideSheet
                title='New Source Object'
                isShown={newFormVisible}
                onClose={handleSideSheetClose}
              >
                <Pane maxWidth='600px'>
                  {newFormVisible && (
                    <SourceObjectForm pipeline={pipeline} sourceObjectSlug='' />
                  )}
                </Pane>
              </SideSheet>
              <Button
                appearance='minimal'
                iconBefore='add'
                disabled={false}
                onClick={() => setNewFormVisible(true)}
              >
                Add New Object
              </Button>
            </Table.Row>
          </Table.Body>
        </Table>
      </ContentCard>
    </>
  )
}

SourceObjectsTab.displayName = 'SourceObjectsTab'
