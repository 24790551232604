export function throttle<T extends (...args: any) => any>(
  func: T,
  delay: number
): (...args: Parameters<T>) => void {
  let wait = false

  return function (...args: Parameters<T>) {
    if (!wait) {
      func.apply(this, args)
      wait = true
      setTimeout(() => {
        wait = false
      }, delay)
    }
  }
}

export function debounce<T extends (...args: any) => any>(
  func: T,
  delay: number
): (...args: Parameters<T>) => void {
  let timer: NodeJS.Timeout | undefined

  return function (...args: Parameters<T>) {
    if (timer) {
      clearTimeout(timer)
    }

    timer = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}
