import { Optional } from '../util/helper-types'
import { request, TokenCredentials } from './request'

export interface CRMAPIErrors {
  [field: string]: Array<{
    message: string
  }>
}

export interface CRMObject {
  id: number
  label: string
  slug: string
  description: string
}

type NewCRMObject = Omit<CRMObject, 'id'>

export async function listCRMObjects(
  credentials: TokenCredentials
): Promise<CRMObject[]> {
  const { objects } = await request({
    path: '/crm/objects',
    method: 'GET',
    credentials
  })

  return objects as CRMObject[]
}

interface SaveCRMObjectsResponse {
  errors?: {
    crm_objects?: CRMAPIErrors[]
  }
}

export async function saveCRMObjects(
  credentials: TokenCredentials,
  objects: Array<CRMObject | NewCRMObject>
): Promise<SaveCRMObjectsResponse> {
  const response = await request({
    path: '/crm/objects',
    method: 'POST',
    credentials,
    body: {
      objects
    }
  })

  return response as SaveCRMObjectsResponse
}

export interface AppObject {
  id: number
  slug: string
  label_one: string
  label_many: string
  description: string
  primary_action_id: number | null
  main_link_action_id: number | null
  empty_state_action_id: number | null
  properties: AppObjectProperty[]
  actions: AppObjectAction[]
  relations: AppObjectRelation[]
}

type NewAppObject = Omit<AppObject, 'id'>

interface UpdatedAppObject
  extends Omit<AppObject, 'properties' | 'actions' | 'relations'> {
  properties: Array<Optional<AppObjectProperty, 'id'>>
  actions: Array<Optional<AppObjectAction, 'id'>>
  relations: Array<Optional<AppObjectRelation, 'id'>>
}

export interface AppObjectProperty {
  id: number
  slug: string
  label: string
  description: string
  type: string
}

export interface AppObjectAction {
  id: number
  slug: string
  label: string
  description: string
  type: string
  scope: string
  confirmation_required: boolean
  confirmation_message?: string
  confirmation_yes_label?: string
  confirmation_no_label?: string
  modal_use_custom_size: boolean
  modal_width?: number
  modal_height?: number
}

export interface AppObjectRelation {
  id: number
  crm_object_id: number
}

export async function listAppObjects(
  credentials: TokenCredentials
): Promise<AppObject[]> {
  const { app_objects: objects } = await request({
    path: '/app_objects',
    method: 'GET',
    credentials
  })

  return objects as AppObject[]
}

type CreateAppObjectResponse = { errors?: CRMAPIErrors } & {
  app_object?: AppObject
}

export async function createAppObject(
  credentials: TokenCredentials,
  object: NewAppObject
): Promise<CreateAppObjectResponse> {
  const response = await request({
    path: `/app_objects`,
    method: 'POST',
    credentials,
    body: {
      app_object: object
    }
  })

  return response as CreateAppObjectResponse
}

interface UpdateAppObjectResponse {
  errors?: {
    properties?: CRMAPIErrors[]
    actions?: CRMAPIErrors[]
    relations?: CRMAPIErrors[]
  } & CRMAPIErrors
}

export async function updateAppObject(
  credentials: TokenCredentials,
  object: UpdatedAppObject
): Promise<UpdateAppObjectResponse> {
  const response = await request({
    path: `/app_objects/${object.id}`,
    method: 'PUT',
    credentials,
    body: {
      app_object: object
    }
  })

  return response as UpdateAppObjectResponse
}

interface RemoveAppObjectResponse {
  errors?: any
}

export async function removeAppObject(
  credentials: TokenCredentials,
  object: AppObject
): Promise<RemoveAppObjectResponse> {
  const response = await request({
    path: `/app_objects/${object.id}`,
    method: 'DELETE',
    credentials
  })

  return response as RemoveAppObjectResponse
}
