import React, { memo, FunctionComponent } from 'react'
import { Spinner as EvergreenSpinner, Pane } from 'evergreen-ui'

interface SpinnerProps {
  spinning: boolean
  minHeight?: number
}

const SpinnerSuspend: FunctionComponent<SpinnerProps> = memo(
  ({ spinning, children, minHeight = 300 }) => {
    return (
      <>
        {spinning ? (
          <Pane
            display='flex'
            alignItems='center'
            justifyContent='center'
            minHeight={minHeight}
            width='100%'
          >
            <EvergreenSpinner />
          </Pane>
        ) : (
          children
        )}
      </>
    )
  }
)
SpinnerSuspend.displayName = 'SpinnerSuspend'

export { SpinnerSuspend }
