import React, { useEffect, useState } from 'react'
import {
  Button,
  Pane,
  Table,
  TextInputField,
  majorScale,
  toaster,
  minorScale
} from 'evergreen-ui'
import ContentSection from '../content-section'
import { useCredentials } from '../login-wrapper'
import TableSpinner from '../components/table-spinner'
import {
  listPlatformAdmins,
  createPlatformAdmin
} from '../../api/platform-admin'
import errorMessage from '../../util/error-message'

const TeamSettings: React.FC = () => {
  const credentials = useCredentials()
  const [initialLoading, setInitialLoading] = useState(true)
  const [teamMembers, setTeamMembers] = useState([])
  const [emailAddress, setEmailAddress] = useState('')
  const [inviting, setInviting] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)
  const [validationMessage, setValidationMessage] = useState(null)

  // TODO: please fix following typescript error
  // @ts-expect-error
  function loadTeamMembers(credentials: TokenCredentials): void {
    listPlatformAdmins(credentials)
      // TODO: please fix following typescript error
      // @ts-expect-error
      .then(setTeamMembers)
      .catch((e) =>
        toaster.danger(`Failed to load team members: ${errorMessage(e)}`)
      )
      .finally(() => setInitialLoading(false))
  }

  // TODO: please fix following typescript error
  // @ts-expect-error
  function changeEmailAddress(event): void {
    setEmailAddress(event.target.value)
    setIsInvalid(false)
    setValidationMessage(null)
  }

  async function handleInvite(): Promise<void> {
    try {
      setInviting(true)
      const params = { email: emailAddress }
      // TODO: please fix following typescript error
      // @ts-expect-error
      const { platformAdmin, errors } = await createPlatformAdmin(
        // TODO: please fix following typescript error
        // @ts-expect-error
        credentials,
        params
      )
      if (errors != null) {
        setIsInvalid(true)
        const error = errors[0]
        // TODO: please fix following typescript error
        // @ts-expect-error
        setValidationMessage(`Email ${error.error}`)
      } else {
        // TODO: please fix following typescript error
        // @ts-expect-error
        setTeamMembers([...teamMembers, platformAdmin])
        setIsInvalid(false)
        setValidationMessage(null)
        setEmailAddress('')
      }
    } catch (e) {
      toaster.danger(`Failed to invite a team member: ${errorMessage(e)}`)
    } finally {
      setInviting(false)
    }
  }

  useEffect(() => {
    loadTeamMembers(credentials)
  }, [credentials])

  return (
    <ContentSection
      title='Team Management'
      description='Add more people to your team.'
    >
      <Table maxWidth='680px'>
        <Table.Head>
          <Table.TextHeaderCell>Email Address</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {initialLoading && <TableSpinner />}
          {!initialLoading &&
            teamMembers.map(({ email }) => (
              <Table.Row key={email}>
                <Table.TextCell>{email}</Table.TextCell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      <Pane
        marginTop={majorScale(2)}
        marginBottom={majorScale(2)}
        maxWidth='680px'
        display='flex'
      >
        <TextInputField
          flexGrow={1}
          placeholder='invite@example.com'
          disabled={initialLoading}
          value={emailAddress}
          onChange={changeEmailAddress}
          isInvalid={isInvalid}
          validationMessage={validationMessage}
        />
        <Button
          isLoading={inviting}
          disabled={initialLoading}
          marginLeft={majorScale(2)}
          marginTop={minorScale(1)}
          iconBefore={inviting ? undefined : 'add'}
          onClick={handleInvite}
        >
          Invite
        </Button>
      </Pane>
    </ContentSection>
  )
}

export default TeamSettings
