import * as React from 'react'
import { Pane, Text, majorScale } from 'evergreen-ui'
import { HeadingTitle } from '../components/heading-title'
import { ContentCard } from '../components/content-card'

const SetupSection: React.FC<{ title: string; description: string }> = ({
  title,
  description,
  children
}) => {
  return (
    <Pane marginBottom={majorScale(4)}>
      <HeadingTitle>
        {title}
        <Text size={300} color='muted' marginLeft={majorScale(2)}>
          {description}
        </Text>
      </HeadingTitle>
      <ContentCard elevation={1}>{children}</ContentCard>
    </Pane>
  )
}

export default SetupSection
