import React, { FC, memo, ReactNode } from 'react'
import {
  Badge,
  BadgeProps,
  minorScale,
  Position,
  Tooltip,
  WarningSignIcon,
  TickCircleIcon,
  StopIcon,
  PauseIcon,
  Spinner,
  majorScale,
  Pane
} from 'evergreen-ui'
import {
  ConnectionStatus,
  getConnectionStatus,
  PlatformConnection
} from '../../api/connection'
import { BasicSync } from '../../api/sync'

type SyncForStatus = Omit<BasicSync, 'pipeline' | 'connectionId'>

export const enum Status {
  active,
  connectionError,
  connectionDisabled,
  syncError,
  syncPaused,
  syncNotStarted
}

export const getSyncStatus = (
  connection: PlatformConnection,
  syncs?: SyncForStatus[]
): Status | null => {
  if (syncs == null) {
    syncs = []
  }

  const connectionStatus = getConnectionStatus(connection)

  if (connectionStatus === ConnectionStatus.disabled) {
    return Status.connectionDisabled
  }

  if (connectionStatus === ConnectionStatus.error) {
    return Status.connectionError
  }

  // Connection is fine, let's check sync
  if (syncs.some((sync) => sync.state === 'broken')) {
    return Status.syncError
  }

  if (syncs.some((sync) => sync.state === 'paused')) {
    return Status.syncPaused
  }

  if (syncs.some((sync) => sync.state === 'not_started')) {
    return Status.syncNotStarted
  }

  if (syncs.every((sync) => sync.state === 'running')) {
    return Status.active
  }

  // Fallback
  return null
}

interface BaseConnectionBadgeProps {
  icon?: ReactNode
  color: BadgeProps['color']
  tooltip?: string
}

// TODO: Icon property seems to not be used. Check if it needs to be used
//       else it can be safely removed along with icons imported and passed
const BaseConnectionBadge: FC<BaseConnectionBadgeProps> = memo(
  ({ icon, color, tooltip, children }) => {
    return (
      <Tooltip content={tooltip} position={Position.TOP}>
        <Badge color={color}>
          <Pane display='flex' alignItems='center'>
            {children}
          </Pane>
        </Badge>
      </Tooltip>
    )
  }
)

BaseConnectionBadge.displayName = 'BaseConnectionBadge'

interface ConnectionStatusBadgeProps {
  connection?: PlatformConnection
  sync?: SyncForStatus
  syncs?: SyncForStatus[]
  loading?: boolean
}

export const ConnectionStatusBadge: FC<ConnectionStatusBadgeProps> = memo(
  ({ connection, syncs, sync, loading }) => {
    if (syncs == null && sync) {
      syncs = [sync]
    }

    if (connection == null) {
      return null
    }

    if (loading) {
      return <Spinner size={majorScale(3)} />
    }

    const status = getSyncStatus(connection, syncs)

    switch (status) {
      case Status.active:
        return (
          <BaseConnectionBadge
            icon={
              <TickCircleIcon
                color='success'
                size={minorScale(3)}
                marginRight={3}
              />
            }
            color='green'
            tooltip={`${sync ? 'Sync' : 'Connection'} is up and running`}
          >
            Active
          </BaseConnectionBadge>
        )
      case Status.connectionError:
        return (
          <BaseConnectionBadge
            icon={
              <WarningSignIcon
                color='warning'
                size={minorScale(3)}
                marginRight={3}
              />
            }
            color='yellow'
            tooltip='Your user will need to re-authorize this connection'
          >
            Error
          </BaseConnectionBadge>
        )
      case Status.connectionDisabled:
        return (
          <BaseConnectionBadge
            icon={<WarningSignIcon size={minorScale(3)} marginRight={3} />}
            color='neutral'
            tooltip='Connection was removed'
          >
            Removed
          </BaseConnectionBadge>
        )
      case Status.syncError:
        return (
          <BaseConnectionBadge
            icon={
              <WarningSignIcon
                color='warning'
                size={minorScale(3)}
                marginRight={3}
              />
            }
            color='yellow'
          >
            Error
          </BaseConnectionBadge>
        )
      case Status.syncPaused:
        return (
          <BaseConnectionBadge
            icon={<StopIcon size={minorScale(3)} marginRight={3} />}
            color='neutral'
            tooltip={`${sync ? 'Sync' : 'Connection'} is paused`}
          >
            Paused
          </BaseConnectionBadge>
        )

      case Status.syncNotStarted:
        return (
          <BaseConnectionBadge
            icon={<PauseIcon size={minorScale(3)} marginRight={3} />}
            color='blue'
            tooltip='Sync not started'
          >
            Not Started
          </BaseConnectionBadge>
        )

      default:
        return null
    }
  }
)

ConnectionStatusBadge.displayName = 'ConnectionStatusBadge'
