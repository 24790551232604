import React, { FC, memo, useState, useEffect, useCallback } from 'react'
import { JsonPointer } from 'json-ptr'
import { Input } from '../../../api/destination-input'
import { Selector, getPathToSelector } from '../../../api/mapping'
import { Tree } from '../../components/tree/tree'
import { SourcePointerValue } from './transformation-data-field'

export interface PropertyInputProps {
  input: Input
  selector: Selector
  expandAll: boolean
  search: string
  onChange: (sourcePointer: SourcePointerValue) => void
}

// We have to calculate this pointer dynamically since it may contain dynamic labels
function getPointerToNode(root: any, selector: Selector): string {
  const path = getPathToSelector(root, selector)

  if (path == null) {
    throw new Error('Cannot find path to selected node')
  }

  // We unfortunately use a kind of half Fragment Pointer, half regular pointer.
  // Basically we just use a regular pointer but with a `#` at the front.
  // We should fix this.
  return `#${JsonPointer.create(path).pointer}`
}

function updateSelectorPatterns(root: Selector, search: string): Selector {
  const selector = { ...root }

  if (selector.label_pattern != null) {
    if (search.length) {
      if (new RegExp(selector.label_pattern).test(search)) {
        selector.label = search
      }
    } else {
      selector.label = undefined
    }
  }

  if (selector.children?.length) {
    selector.children = selector.children.map((child) =>
      updateSelectorPatterns(child, search)
    )
  }

  return selector
}

export const PropertyInput: FC<PropertyInputProps> = memo(
  ({ input, selector, expandAll, search, onChange }) => {
    const [localSelector, setLocalSelector] = useState(selector)

    // Use the searched term in all matching selectors
    useEffect(() => {
      const newSelector = updateSelectorPatterns(localSelector, search)
      setLocalSelector(newSelector)
      // While we use `localSelector`, we don't need to update anything if it changes
      // and doing so would put us into an infinite loop
      /* eslint-disable react-hooks/exhaustive-deps */
    }, [search])
    /* eslint-enable react-hooks/exhaustive-deps */

    const handleSelect = useCallback(
      (selectorToSelect: Selector): void => {
        onChange({
          source_relation_id: selectorToSelect.source_relation_id,
          pointer: getPointerToNode(localSelector, selectorToSelect)
        })
      },
      [localSelector, onChange]
    )

    const handleNameChange = useCallback(
      (selectorToUpdate: Selector, newLabel: string): void => {
        selectorToUpdate.label = newLabel
        setLocalSelector({ ...localSelector })
      },
      [setLocalSelector, localSelector]
    )

    return (
      <Tree
        root={localSelector}
        input={input}
        selector={localSelector}
        expandAll={expandAll}
        search={search}
        onSelect={handleSelect}
        onNameChange={handleNameChange}
      />
    )
  }
)

PropertyInput.displayName = 'PropertyInput'
