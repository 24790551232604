import React, { FC, ChangeEvent, useCallback, memo } from 'react'
import {
  FormField,
  Checkbox,
  Table,
  majorScale,
  SelectFieldProps,
  SelectField
} from 'evergreen-ui'
import {
  InputParameters,
  SimpleType,
  isTypeEqual
} from '../../../api/destination-input'
import slugify from '../../../util/slugify'
import { InputsTable, InputsTableProps } from '../../components/inputs-table'
import { TableCellTextInput } from '../../components/table-cell-text-input'

type InputTableType = Omit<InputParameters, 'simple_type'> & {
  simple_type?: SimpleType
}

export interface InputsFieldProps {
  disabled: boolean
  inputs: InputParameters[]
  setInputs: (inputs: InputParameters[]) => void
  inputsError?: string
}

interface SelectInputTypeProps extends Omit<SelectFieldProps, 'value'> {
  value?: SimpleType
  onChange: (value?: SimpleType) => void
}

const inputTypes: Array<{ name: string; value: SimpleType }> = [
  { name: 'String', value: { type: 'string' } },
  { name: 'Number', value: { type: 'number' } },
  { name: 'Integer', value: { type: 'integer' } },
  { name: 'Boolean', value: { type: 'boolean' } },
  { name: 'Object', value: { type: 'object' } },
  {
    name: 'Date & Time (ISO 8601)',
    value: { type: 'string', format: 'datetime' }
  },
  { name: 'Array of Strings', value: { type: 'array', format: 'string' } },
  { name: 'Array of Numbers', value: { type: 'array', format: 'number' } },
  { name: 'Array of Integers', value: { type: 'array', format: 'integer' } },
  { name: 'Array of Booleans', value: { type: 'array', format: 'boolean' } },
  { name: 'Array of Objects', value: { type: 'array', format: 'object' } }
]

function matchesType(type?: SimpleType, expected?: SimpleType): boolean {
  if (type == null || expected == null) {
    return type === expected
  }
  return isTypeEqual(type, expected)
}

function serializeInputs(
  inputs: InputParameters[],
  inputsError?: string
): InputsTableProps<InputParameters>['inputs'] {
  return inputs.map(({ id, name, simple_type: simpleType, required }) => {
    return {
      id,
      value: {
        name,
        simple_type: simpleType,
        required
      },
      isInvalid: inputsError != null
    }
  })
}

function parseInputs(
  tableInputs: InputsTableProps<InputParameters>['inputs']
): InputParameters[] {
  return tableInputs.map(({ id, value }) => {
    return {
      id: id as number,
      name: value.name,
      simple_type: value.simple_type,
      required: value.required
    }
  })
}

export const SelectInputType: FC<SelectInputTypeProps> = memo(
  ({ disabled, value, onChange, ...props }) => {
    const handleSelect = (e: ChangeEvent<HTMLSelectElement>): void => {
      const newValue: SimpleType | undefined =
        e.target.value === ''
          ? undefined
          : (JSON.parse(e.target.value) as SimpleType)
      onChange(newValue)
    }

    return (
      <SelectField disabled={disabled} onChange={handleSelect} {...props}>
        <option value='' disabled selected={value == null}>
          Select a type
        </option>
        {inputTypes.map((type) => {
          return (
            <option
              key={slugify(type.name)}
              value={JSON.stringify(type.value)}
              selected={matchesType(value, type.value)}
            >
              {type.name}
            </option>
          )
        })}
      </SelectField>
    )
  }
)

SelectInputType.displayName = 'SelectInputType'

export const InputsField: FC<InputsFieldProps> = memo(
  ({ disabled, inputs, setInputs, inputsError }) => {
    const handleSetInputs = useCallback(
      (inputs: InputsTableProps<InputParameters>['inputs']) =>
        setInputs(parseInputs(inputs)),
      [setInputs]
    )

    return (
      <FormField
        label='Inputs'
        description='What the destination expects to receive'
        hint='Only letters and underscores allowed for names.'
        validationMessage={inputsError != null ? `Input ${inputsError}` : null}
        marginBottom={majorScale(2)}
      >
        <InputsTable<InputTableType>
          disabled={disabled}
          inputLabel='Input'
          headers={['Name', 'Type', 'Optional']}
          render={({ disabled, isInvalid, value }, onChange) => {
            return (
              <>
                <TableCellTextInput
                  disabled={disabled}
                  isInvalid={isInvalid}
                  value={value.name}
                  onChange={(newName) => onChange({ ...value, name: newName })}
                />
                <Table.Cell>
                  <SelectInputType
                    disabled={disabled}
                    value={value.simple_type}
                    marginBottom={0}
                    onChange={(newType) =>
                      onChange({ ...value, simple_type: newType })
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  <Checkbox
                    disabled={disabled}
                    isInvalid={isInvalid}
                    checked={!value.required}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onChange({ ...value, required: !e.target.checked })
                    }
                  />
                </Table.Cell>
              </>
            )
          }}
          inputs={serializeInputs(inputs, inputsError)}
          setInputs={handleSetInputs}
          newValue={() => {
            return { name: '', simple_type: undefined, required: true }
          }}
        />
      </FormField>
    )
  }
)

InputsField.displayName = 'InputsField'
