import {
  Destination,
  DestinationParameters,
  DestinationResult,
  ErrorResult,
  getDestination,
  updateDestination
} from '../../api/destination'
import { useCallback, useContext, useEffect, useState } from 'react'
import { CredentialsContext } from '../login-wrapper'
import { hasOwnProperty } from '../../util/has-own-property'

function sortDestinationInputs(destination: Destination): Destination {
  return {
    ...destination,
    inputs: destination.inputs.sort((a, b) => {
      return a.id - b.id
    })
  }
}

export interface UseDestinationState {
  loading: boolean
  saving: boolean
  error?: string
  validationErrors?: ErrorResult['errors']
  destination?: Destination
  updateDestinationFx: (
    destination: DestinationParameters
  ) => Promise<DestinationResult>
}

export const useDestination = (uuid: string): UseDestinationState => {
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [validationErrors, setValidationErrors] =
    useState<UseDestinationState['validationErrors']>(undefined)
  const [destination, setDestination] = useState<Destination | undefined>(
    undefined
  )
  const { callWithCredentials } = useContext(CredentialsContext)

  useEffect(() => {
    const loadDestination: () => Promise<void> = async () => {
      setLoading(true)
      try {
        const destination = await callWithCredentials(
          async (creds) => await getDestination(creds, uuid)
        )
        setDestination(sortDestinationInputs(destination))
      } catch (error) {
        setError(`Error while loading destination: ${String(error.message)}`)
      } finally {
        setLoading(false)
      }
    }

    loadDestination().catch(console.error)
  }, [uuid, callWithCredentials])

  const updateDestinationFx = useCallback(
    async (params: Destination) => {
      try {
        setSaving(true)
        const res = await callWithCredentials(
          async (credentials) => await updateDestination(credentials, params)
        )
        if (hasOwnProperty(res, 'destination') && res.destination != null) {
          setDestination(sortDestinationInputs(res.destination))
          setValidationErrors(undefined)
        } else if (hasOwnProperty(res, 'errors')) {
          setValidationErrors(res.errors)
        }
        return res
      } catch (e) {
        setError(e.message)
        throw e
      } finally {
        setSaving(false)
      }
    },
    [callWithCredentials]
  )

  return {
    destination,
    loading,
    saving,
    error,
    validationErrors,
    updateDestinationFx
  }
}
