import React, {
  ComponentProps,
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Button } from 'evergreen-ui'

type ActionButtonProps = ComponentProps<typeof Button>

// Idea behind this component is simple.
// From time to time we have actions over elements.
// This customized button will show loading indicator on it's own.
export const ActionButton: FC<ActionButtonProps> = memo((props) => {
  const { isLoading, onClick } = props
  const [patchedIsLoading, setPatchedIsLoading] = useState(false)
  useEffect(() => {
    if (isLoading != null) {
      setPatchedIsLoading(isLoading)
    }
  }, [isLoading])

  const patchedIconBefore = useMemo(() => {
    return patchedIsLoading ? null : props.iconBefore
  }, [patchedIsLoading, props.iconBefore])

  const patchedOnClick = useCallback(async () => {
    setPatchedIsLoading(true)
    try {
      await onClick?.()
    } finally {
      setPatchedIsLoading(false)
    }
  }, [onClick])

  return (
    <Button
      {...props}
      isLoading={patchedIsLoading}
      iconBefore={patchedIconBefore}
      onClick={patchedOnClick}
    >
      {props.children}
    </Button>
  )
})
ActionButton.displayName = 'ActionButton'
