import React, { memo } from 'react'
import { useDestinationInvocations } from '../hooks/invocations.hook'
import { InvocationsTable } from '../invocations/invocations-table'

interface DestinationLogsProps {
  uuid: string
}

export const DestinationLogs: React.FC<DestinationLogsProps> = memo(
  ({ uuid }) => {
    const { invocations, loading, hasMore, loadNextInvocations } =
      useDestinationInvocations(uuid)

    return (
      <InvocationsTable
        loading={loading}
        invocations={invocations}
        showEntity={false}
        nextPage={loadNextInvocations}
        hasMore={hasMore}
      />
    )
  }
)

DestinationLogs.displayName = 'DestinationLogs'
