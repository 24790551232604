import React, { FC, memo } from 'react'
import {
  Pane,
  FormField,
  Text,
  Popover,
  Link,
  Pre,
  majorScale
} from 'evergreen-ui'
import { FunctionDependencies } from '../../../api/destination'
import { Controlled as CodeMirror } from 'react-codemirror2'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/xq-light.css'
require('codemirror/mode/javascript/javascript')

export interface BodyEditorProps {
  label: string
  description: string
  disabled: boolean
  body: string
  setBody: (body: string) => void
  bodyError?: string
  dependencies: FunctionDependencies
}

export const BodyEditor: FC<BodyEditorProps> = memo(
  ({
    label,
    description,
    disabled,
    dependencies,
    body,
    bodyError,
    setBody
  }) => {
    return (
      <FormField
        label={label}
        description={description}
        hint={
          <Text>
            Supports JavaScript (Node.js 14.x) and some{' '}
            <Popover
              content={
                <Pane padding={majorScale(2)}>
                  <Text is={Pre} fontFamily='mono'>
                    {Object.entries(dependencies)
                      .map(([name, version]) => `${name}: ${version}`)
                      .join('\n')}
                  </Text>
                </Pane>
              }
            >
              <Link cursor='pointer'>common packages</Link>
            </Popover>
            .
          </Text>
        }
        validationMessage={
          bodyError != null ? `Function body ${bodyError}` : null
        }
        marginBottom={majorScale(4)}
      >
        <Pane border='default' marginTop={majorScale(1)}>
          <CodeMirror
            value={body}
            options={{
              mode: 'javascript',
              theme: 'xq-light',
              lineNumbers: true,
              readOnly: disabled
            }}
            onBeforeChange={(editor, data, value) => {
              setBody(value)
            }}
          />
        </Pane>
      </FormField>
    )
  }
)
BodyEditor.displayName = 'BodyEditor'
