import React, { FC, memo, useCallback } from 'react'
import { TagInput } from 'evergreen-ui'
import { FilterFieldProps, getFilter, updateFilter } from './filter-field'
import { FilterOverrideField } from './filter-override'
import { TypeformResponsesFilterField } from './typeform-responses-filter-field'

const FIELD_TYPES_PROPERTY = 'field_types'

function getFieldTypesFilterValue(value?: string | string[]): string[] {
  if (Array.isArray(value)) {
    return value
  }

  return []
}

export const TypeformAnswersFilterField: FC<FilterFieldProps> = memo(
  ({ syncFilters, sourceFilters, filterErrors, onChange }) => {
    const updateProperty = useCallback(
      (propertyName, operator, value) => {
        const updatedFilters = updateFilter(
          syncFilters ?? sourceFilters,
          propertyName,
          operator,
          value
        )
        onChange(updatedFilters)
      },
      [sourceFilters, syncFilters, onChange]
    )

    const fieldTypesFilter = getFilter(
      sourceFilters,
      syncFilters,
      filterErrors,
      FIELD_TYPES_PROPERTY
    )

    return (
      <>
        <TypeformResponsesFilterField
          syncFilters={syncFilters}
          sourceFilters={sourceFilters}
          filterErrors={filterErrors}
          onChange={onChange}
        />
        <FilterOverrideField
          propertyName={FIELD_TYPES_PROPERTY}
          sourceFilters={sourceFilters}
          syncFilters={syncFilters}
          onChange={onChange}
          defaultOperator='in'
          defaultValue={[]}
          label='Field types'
          description='Answers to what field types do you want to sync'
          hint='Please insert field types. Leave blank to sync all field types.'
          validationMessage={fieldTypesFilter.validationMessage}
        >
          <TagInput
            width='100%'
            name={fieldTypesFilter}
            values={getFieldTypesFilterValue(fieldTypesFilter.value)}
            onChange={(fieldTypes: string[]) => {
              updateProperty(FIELD_TYPES_PROPERTY, 'in', fieldTypes)
            }}
            disabled={fieldTypesFilter.disabled}
          />
        </FilterOverrideField>
      </>
    )
  }
)

TypeformAnswersFilterField.displayName = 'TypeformAnswersFilterField'
