import * as React from 'react'
import {
  Pane,
  Text,
  FormField,
  FormFieldProps,
  TextInput,
  TextInputProps,
  majorScale
} from 'evergreen-ui'

type PrefixInputFieldProps = TextInputProps &
  FormFieldProps & {
    suffix: string
    inputHeight?: number
  }

class PrefixInputField extends React.Component<PrefixInputFieldProps> {
  private readonly suffixPane = React.createRef<HTMLDivElement>()

  render(): React.ReactElement {
    const { suffix, inputHeight, inputWidth, ...restProps } = this.props
    const {
      value,
      isInvalid,
      onChange,
      placeholder,
      disabled,
      onKeyDown,
      ...formFieldProps
    } = restProps
    const inputProps = {
      value,
      isInvalid,
      onChange,
      placeholder,
      disabled,
      onKeyDown
    }

    const width = this.suffixPane.current
      ? this.suffixPane.current.offsetWidth
      : 75

    return (
      <FormField {...formFieldProps} marginBottom={majorScale(3)}>
        <Pane position='relative'>
          <TextInput
            {...inputProps}
            height={inputHeight}
            width='100%'
            paddingRight={width}
          />
          <Pane
            position='absolute'
            height='100%'
            right={0}
            top={0}
            pointerEvents='none'
            display='flex'
            alignItems='center'
          >
            <div ref={this.suffixPane}>
              <Text
                display='block'
                padding={6}
                paddingRight={majorScale(1)}
                color='muted'
              >
                {suffix}
              </Text>
            </div>
          </Pane>
        </Pane>
      </FormField>
    )
  }
}

export default PrefixInputField
