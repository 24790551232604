import React, { FC, memo } from 'react'
import { Pane, PaneProps } from 'evergreen-ui'

export type RectangleProps = PaneProps

export const Rectangle: FC<RectangleProps> = memo(
  ({ width, children, ...paneProps }) => {
    return (
      <Pane width={width ?? '100%'}>
        <Pane height={0} paddingBottom='100%' position='relative'>
          <Pane
            position='absolute'
            top={0}
            right={0}
            bottom={0}
            left={0}
            {...paneProps}
          >
            {children}
          </Pane>
        </Pane>
      </Pane>
    )
  }
)
Rectangle.displayName = 'Rectangle'
