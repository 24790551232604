import React, { useState, memo } from 'react'
import { Link } from 'react-router-dom'
import { Pane, SearchInput, majorScale } from 'evergreen-ui'
import { SpinnerSuspend } from '../components/spinner'
import { FunctionCard } from '../components/function-card'
import { ProviderCard } from '../components/provider-card'
import { HeadingTitle } from '../components/heading-title'
import { Connector } from '../../api/connector'
import { useErrorToaster, useTitle } from '../hooks/common'
import { useConnectors } from '../hooks/connectors.hook'
import { applySearchFilter } from '../../util/apply-search-filter'

// TODO: pull this flag from backend.
function canBeDestination(provider: Connector): boolean {
  const slug = provider.template.slug
  return slug === 'zendesk-sync' || slug === 'salesforce-sales-cloud-sync'
}

export const NewDestination: React.FC = memo(() => {
  const { connectors: providers, loading, error } = useConnectors()
  const [search, setSearch] = useState('')

  useTitle('New Destination')
  useErrorToaster(error)

  const filteredProviders = providers.filter((provider: Connector) => {
    return (
      canBeDestination(provider) &&
      (applySearchFilter(provider.name, search) ||
        applySearchFilter(provider.slug, search))
    )
  })

  return (
    <Pane>
      <HeadingTitle>Select Destination Type</HeadingTitle>
      <SpinnerSuspend spinning={loading || error != null}>
        <SearchInput
          marginBottom={majorScale(1)}
          placeholder='Search for providers...'
          height={majorScale(6)}
          maxWidth='100%'
          width='100%'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearch(e.target.value)
          }
          value={search}
        />
        <Pane
          clearfix
          marginTop={majorScale(3)}
          display='flex'
          flexWrap='wrap'
          marginRight={majorScale(-3)}
          marginBottom={majorScale(-3)}
        >
          {search === '' && (
            <FunctionCard
              name='Function'
              padding={majorScale(2)}
              marginRight={majorScale(3)}
              marginBottom={majorScale(3)}
              elevation={1}
              hoverElevation={2}
              is={Link}
              textDecoration='none'
              to='/destinations/new/function'
            />
          )}
          {filteredProviders.map((provider) => (
            <ProviderCard
              key={provider.slug}
              provider={provider}
              padding={majorScale(2)}
              marginRight={majorScale(3)}
              marginBottom={majorScale(3)}
              elevation={1}
              hoverElevation={2}
              is={Link}
              textDecoration='none'
              to={`/destinations/new/${provider.slug}`}
            />
          ))}
        </Pane>
      </SpinnerSuspend>
    </Pane>
  )
})

NewDestination.displayName = 'NewDestination'

export default NewDestination
