import { Button, Pane, toaster } from 'evergreen-ui'
import React, { FC, memo, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  AppObject,
  AppObjectProperty,
  CRMAPIErrors,
  updateAppObject
} from '../../api/crm'
import { TokenCredentials } from '../../api/request'
import { WindowWithStructuredClone } from '../../util/helper-types'
import { AppObjectTabType } from './app-object'
import Fields from './fields'

declare const window: WindowWithStructuredClone

interface EditAppObjectPropertyProps {
  object: AppObject
  property?: AppObjectProperty
  credentials: TokenCredentials | null
  refresh: () => Promise<void>
}

const EditAppObjectProperty: FC<EditAppObjectPropertyProps> = memo(
  ({ object, property, credentials, refresh }) => {
    const [slug, setSlug] = useState<string>(property ? property.slug : '')
    const [label, setLabel] = useState<string>(property ? property.label : '')
    const [description, setDescription] = useState<string>(
      property ? property.description : ''
    )
    const [type, setType] = useState<string>(
      property ? property.type : 'string'
    )
    const [loading, setLoading] = useState<boolean>(false)
    const [errors, setErrors] = useState<CRMAPIErrors>({})

    const history = useHistory()
    const navigateToProperties = useCallback(() => {
      history.push(`/app-objects/${object.id}/${AppObjectTabType.Properties}`)
    }, [history, object.id])

    const fields = [
      {
        value: slug,
        label: 'Slug',
        description: 'Unique identifier for this property',
        placeholder: 'Slug',
        setField: setSlug,
        errorKey: 'slug'
      },
      {
        value: label,
        label: 'Name',
        description: 'Human friendly name that appears to users',
        placeholder: 'Name',
        setField: setLabel,
        errorKey: 'label'
      },
      {
        textarea: true,
        value: description,
        label: 'Description',
        description: 'A description of this property for your users',
        placeholder: 'Description',
        setField: setDescription,
        errorKey: 'description'
      },
      {
        select: (
          <>
            <option value='string'>String</option>
          </>
        ),
        value: type,
        label: 'Type',
        description: 'Data type of the property',
        placeholder: '',
        setField: setType,
        errorKey: 'type'
      }
    ]

    const deleteProperty: () => Promise<void> = async () => {
      if (!property) {
        return
      }
      if (credentials == null) {
        toaster.danger('Invalid credentials')
        return
      }
      const index = object.properties.findIndex(
        (individualProperty) => individualProperty.id === property.id
      )
      if (!(index > -1)) {
        return
      }
      object.properties.splice(index, 1)
      const response = await updateAppObject(credentials, object)
      if (response.errors) {
        console.error('Could not remove property', response.errors)
        toaster.danger('Could not remove property')
      } else {
        toaster.success('Property removed')
        await refresh()
      }
    }

    let saveProperty: () => Promise<void> = async () => {
      if (credentials == null) {
        toaster.danger('Invalid credentials')
        return
      }
      setLoading(true)
      const response = await updateAppObject(credentials, {
        ...object,
        properties: [
          ...object.properties,
          {
            slug,
            label,
            description,
            type
          }
        ]
      })
      if (
        response.errors?.properties &&
        response.errors.properties.length > object.properties.length
      ) {
        setErrors(response.errors.properties[object.properties.length])
        console.error('Could not create property', response.errors)
        toaster.danger('Could not create property')
        setLoading(false)
      } else {
        toaster.success('Property created')
        setLoading(false)
        navigateToProperties()
        await refresh()
      }
    }
    if (property) {
      saveProperty = async () => {
        if (credentials == null) {
          toaster.danger('Invalid credentials')
          return
        }
        const index = object.properties.findIndex(
          (individualProperty) => individualProperty.id === property.id
        )
        if (!(index > -1)) {
          return
        }
        const id = object.properties[index].id
        const clonedObject = window.structuredClone<AppObject>(object)
        clonedObject.properties[index] = {
          id,
          slug,
          label,
          description,
          type
        }
        setLoading(true)
        const response = await updateAppObject(credentials, clonedObject)
        if (response.errors?.properties) {
          setErrors(response.errors.properties[index])
          console.error('Could not update property', response.errors)
          toaster.danger('Could not update property')
        } else {
          toaster.success('Property updated')
        }
        setLoading(false)
      }
    }

    return (
      <>
        {property ? (
          <Pane display='flex' justifyContent='end'>
            <Button
              appearance='minimal'
              intent='danger'
              iconBefore='trash'
              isLoading={loading}
              onClick={async () => {
                setLoading(true)
                try {
                  await deleteProperty()
                } catch (error) {
                  console.error(error)
                  toaster.danger('Could not remove property')
                }
                setLoading(false)
              }}
            >
              Remove property
            </Button>
          </Pane>
        ) : null}
        <Fields fields={fields} errors={errors} setErrors={setErrors} />
        <Button
          appearance='primary'
          isLoading={loading}
          onClick={async () => {
            try {
              await saveProperty()
            } catch (error) {
              console.error(error)
              toaster.danger('Could not save property')
            }
          }}
        >
          {object ? 'Save' : 'Add Property'}
        </Button>
      </>
    )
  }
)

EditAppObjectProperty.displayName = 'EditAppObjectProperty'

export default EditAppObjectProperty
