import * as React from 'react'
import ContentSection from '../content-section'
import {
  Link,
  Pane,
  TextInputField,
  Paragraph,
  ShareIcon,
  Code,
  minorScale,
  toaster
} from 'evergreen-ui'
import { getPlatform, updatePlatformValues, Platform } from '../../api/platform'
import Form, { FormField } from '../form'
import PrefixInputField from '../prefix-input-field'
import { withCredentials, CredentialConsumer } from '../login-wrapper'
import errorMessage from '../../util/error-message'

interface PlatformSettingsState {
  platform?: Platform
  loading: boolean
}

type PlatformSettingsProps = {
  crmOnlyConnectors: boolean
} & CredentialConsumer

class PlatformSettings extends React.Component<
  PlatformSettingsProps,
  PlatformSettingsState
> {
  constructor(props: PlatformSettingsProps) {
    super(props)
    this.state = {
      loading: false
    }
  }

  componentDidMount(): void {
    void this.loadPlatform()
  }

  async loadPlatform(): Promise<void> {
    this.setState({ loading: true })
    try {
      const platform = await this.props.callWithCredentials(getPlatform)
      this.setState({ platform })
    } catch (e) {
      toaster.danger(`Error while loading platform: ${errorMessage(e)}`)
    } finally {
      this.setState({ loading: false })
    }
  }

  render(): React.ReactElement {
    const { platform, loading } = this.state
    const { crmOnlyConnectors } = this.props

    const fields: FormField[] = [
      {
        name: 'name',
        Field: TextInputField,
        label: 'Display Name',
        description:
          'The name of your application, as presented to your users.',
        placeholder: 'My App'
      },
      {
        name: 'slug',
        Field: (props) => <PrefixInputField {...props} suffix='.xkit.co' />, // eslint-disable-line react/display-name
        label: 'Project Subdomain',
        description:
          'The subdomain of xkit.co that serves as your primary identifier.',
        placeholder: 'my-app'
      },
      {
        name: 'custom_domain',
        Field: TextInputField,
        label: 'Custom Domain',
        description:
          'The custom domain that your Xkit installation is served from.',
        placeholder: 'integrations.myapp.com',
        hint: (
          <Paragraph size={300} marginTop={minorScale(1)} color='muted'>
            This field will override your Xkit Project Subdomain. See the{' '}
            <Link
              size={300}
              href={
                crmOnlyConnectors
                  ? 'https://xkit.co/docs/advanced/custom-domains'
                  : 'https://docs.xkit.co/docs/configure-xkit#custom-domain'
              }
              target='_blank'
              rel='noopener noreferrer'
            >
              custom domain documentation
              <ShareIcon size={12} marginLeft={4} />
            </Link>{' '}
            for a setup guide
          </Paragraph>
        )
      },
      {
        name: 'website',
        Field: TextInputField,
        label: 'App Homepage',
        description: crmOnlyConnectors
          ? 'The URL of your app that users will navigate to.'
          : 'The URL of your app that users will navigate to from the Xkit Catalog.',
        placeholder: 'https://myapp.com',
        hint: 'This should be the app itself, not a marketing page.'
      },
      {
        name: 'login_redirect_url',
        Field: TextInputField,
        label: 'Login Redirect URL',
        description:
          'The URL we should send users to when they are not logged in.',
        placeholder: 'https://myapp.com/login'
      },
      {
        name: 'webhooks_api_token',
        Field: TextInputField,
        label: 'Webhooks API Token',
        description: 'The token for webhooks sent by Xkit.',
        hint: (
          <Paragraph size={300} marginTop={minorScale(1)} color='muted'>
            We'll send this as the{' '}
            <Code fontSize='10px'>Authorization: Bearer token</Code> header.
          </Paragraph>
        ),
        placeholder: 'your-secret-token'
      },
      {
        name: 'crm_webhooks_url',
        Field: TextInputField,
        label: 'CRM Event Webhooks URL',
        description: 'The URL where you will receive webhooks with CRM events.',
        placeholder: 'https://myapp.com/crm-webhooks',
        hint: (
          <Paragraph size={300} marginTop={minorScale(1)} color='muted'>
            Configure this to be notified when relevant data in the CRM has
            changed.{' '}
            <Link
              size={300}
              href='https://xkit.co/docs/crm-api#configure-webhook-url'
              target='_blank'
              rel='noopener noreferrer'
            >
              View documentation
              <ShareIcon size={12} marginLeft={4} />
            </Link>
          </Paragraph>
        )
      },
      {
        name: 'crm_embed_webhooks_url',
        Field: TextInputField,
        label: 'CRM Embed Webhooks URL',
        description: 'The URL where you will receive CRM Embed webhooks.',
        placeholder: 'https://myapp.com/crm-embed',
        hint: (
          <Paragraph size={300} marginTop={minorScale(1)} color='muted'>
            See{' '}
            <Link
              size={300}
              href='https://xkit.co/docs/crm-embed#request-and-response'
              target='_blank'
              rel='noopener noreferrer'
            >
              the documentation
              <ShareIcon size={12} marginLeft={4} />
            </Link>{' '}
            for the schema of the requests and responses.
          </Paragraph>
        )
      }
    ]

    return (
      <ContentSection
        title='Application Settings'
        description='Basic settings for how Xkit works with your app.'
      >
        <Pane maxWidth='400px'>
          <Form
            fields={fields}
            loading={loading}
            // TODO: please fix following typescript error
            // @ts-expect-error
            values={platform}
            // TODO: please fix following typescript error
            // @ts-expect-error
            update={async (...args) => {
              return await this.props.callWithCredentials(
                async (credentials) =>
                  // TODO: please fix following typescript error
                  // @ts-expect-error
                  await updatePlatformValues(credentials, ...args)
              )
            }}
          />
        </Pane>
      </ContentSection>
    )
  }
}

export default withCredentials(PlatformSettings)
