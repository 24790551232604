import { useCallback, useContext, useEffect, useState } from 'react'
import { SupportToken, getSupportToken } from '../../api/support'
import { CredentialsContext } from '../login-wrapper'
import errorMessage from '../../util/error-message'

interface UseSupportTokenState {
  loading: boolean
  error: string | undefined
  supportToken: SupportToken | undefined
  fetch: () => void
}

export const useSupportToken = (): UseSupportTokenState => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const [supportToken, setSupportToken] = useState<SupportToken | undefined>(
    undefined
  )
  const { callWithCredentials } = useContext(CredentialsContext)

  const fetch = useCallback(async () => {
    try {
      setLoading(true)
      const supportToken = await callWithCredentials(
        async (creds) => await getSupportToken(creds)
      )
      setSupportToken(supportToken)
      setError(undefined)
    } catch (e) {
      setError(`Error while loading support token: ${errorMessage(e)}`)
    } finally {
      setLoading(false)
    }
  }, [callWithCredentials])

  useEffect(() => {
    void fetch()
  }, [fetch])

  return {
    loading,
    error,
    supportToken,
    fetch
  }
}
