import React, { ChangeEvent } from 'react'
import { Popover, TextInput, TextInputFieldProps } from 'evergreen-ui'

interface TextInputPopoverProps extends TextInputFieldProps {
  content:
    | React.ReactNode
    | ((object: { close: () => void }) => React.ReactNode)
  onClearValue: () => void
}

export const TextInputPopover: React.FC<TextInputPopoverProps> = ({
  content,
  onClearValue,
  ...inputProps
}) => {
  return (
    <Popover content={content}>
      <TextInput
        type='search'
        autoComplete='off'
        spellCheck={false}
        style={{ caretColor: 'transparent' }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          // Clear the value
          if (e.target.value === '') {
            onClearValue()
          }
          e.preventDefault()
        }}
        {...inputProps}
      />
    </Popover>
  )
}

TextInputPopover.displayName = 'TextInputPopover'
