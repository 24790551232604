import React, { FC, memo } from 'react'
import { Filter, FilterErrors } from '../../../api/filter'
import { hasOwnProperty } from '../../../util/has-own-property'
import { SpinnerSuspend } from '../../components/spinner'
import { AppleAppStoreReviewsFilterField } from './apple-appstore-reviews-filter-field'
import { DelightedSurveyResponsesFilterField } from './delighted-survey-responses-filter-field'
import { FrontMessagesFilterField } from './front-messages-filter-field'
import { GongCallsFilterField } from './gong-calls-filter-field'
import { GooglePlayReviewsFilterField } from './google-play-reviews-filter-field'
import { HubSpotContactsFilterField } from './hubspot-contacts-filter-field'
import { IntercomConversationsFilterField } from './intercom-conversations-filter-field'
import { IntercomConversationPartsFilterField } from './intercom-conversation-parts-filter-field'
import { ZendeskTicketCommentsFilterField } from './zendesk-ticket-comments-filter-field'
import { ZendeskTicketsFilterField } from './zendesk-tickets-filter-field'
import { TypeformAnswersFilterField } from './typeform-answers-filter-field'
import { TypeformResponsesFilterField } from './typeform-responses-filter-field'

const FILTER_FIELDS: Record<string, Record<string, FC<FilterFieldProps>>> = {
  'apple-appstore-sync': {
    reviews: AppleAppStoreReviewsFilterField
  },
  'delighted-sync': {
    'survey-responses': DelightedSurveyResponsesFilterField
  },
  'frontapp-sync': {
    messages: FrontMessagesFilterField
  },
  'gong-sync': {
    calls: GongCallsFilterField
  },
  'google-play-sync': {
    reviews: GooglePlayReviewsFilterField
  },
  'hubspot-sync': {
    contacts: HubSpotContactsFilterField
  },
  'intercom-sync': {
    conversations: IntercomConversationsFilterField,
    conversation_parts: IntercomConversationPartsFilterField
  },
  'typeform-sync': {
    answers: TypeformAnswersFilterField,
    responses: TypeformResponsesFilterField
  },
  'zendesk-sync': {
    ticket_comments: ZendeskTicketCommentsFilterField,
    tickets: ZendeskTicketsFilterField
  }
}

export function hasFilters(sourceTemplateSlug: string): boolean {
  return FILTER_FIELDS[sourceTemplateSlug] != null
}

export interface FilterFieldProps {
  sourceFilters: Filter[]
  syncFilters?: Filter[]
  filterErrors?: FilterErrors
  onChange: (updated: Filter[]) => void
}

interface FilterFieldContainerProps extends FilterFieldProps {
  sourceTemplateSlug?: string
  rootObjectName?: string
}

export const FilterField: FC<FilterFieldContainerProps> = memo(
  ({ sourceTemplateSlug, rootObjectName, ...filterFieldProps }) => {
    const Component =
      FILTER_FIELDS[sourceTemplateSlug ?? '']?.[rootObjectName ?? '']

    return (
      <SpinnerSuspend
        minHeight={100}
        spinning={sourceTemplateSlug == null || rootObjectName == null}
      >
        {Component == null ? null : <Component {...filterFieldProps} />}
      </SpinnerSuspend>
    )
  }
)
FilterField.displayName = 'FilterField'

function getValidationMessage(
  filterError?: string,
  propertyError?: string,
  valueError?: string
): string | undefined {
  if (filterError != null) {
    return `Filter ${filterError}`
  }

  if (propertyError != null) {
    return `Property ${propertyError}`
  }

  if (valueError != null) {
    return `Value ${valueError}`
  }
}

interface FilterData {
  value?: Filter['value']
  operator?: Filter['operator']
  property?: Filter['property']
  disabled: boolean
  isOverride: boolean
  isEmpty: boolean
  isInvalid: boolean
  validationMessage?: string
}

export function getFilter(
  sourceFilters: Filter[],
  syncFilters: Filter[] | undefined,
  filterErrors: FilterErrors | undefined,
  propertyName: string
): FilterData {
  const syncIndex = (syncFilters ?? []).findIndex(
    ({ property }) => property === propertyName
  )
  const sourceIndex = sourceFilters.findIndex(
    ({ property }) => property === propertyName
  )

  const filter = syncFilters?.[syncIndex] ?? sourceFilters[sourceIndex]
  const errors = filterErrors?.[syncFilters == null ? sourceIndex : syncIndex]

  const filterError =
    errors != null && hasOwnProperty(errors, 'message')
      ? errors.message
      : undefined
  const propertyError =
    errors != null && hasOwnProperty(errors, 'property')
      ? errors.property?.[0].message
      : undefined
  const valueError =
    errors != null && hasOwnProperty(errors, 'value')
      ? errors.value?.[0].message
      : undefined

  return {
    value: filter?.value,
    operator: filter?.operator,
    property: filter?.property,
    isEmpty: filter == null || (filter.is_empty ?? false),
    isOverride: syncIndex !== -1,
    disabled: syncFilters != null && syncIndex === -1,
    isInvalid:
      filterError != null || propertyError != null || valueError != null,
    validationMessage: getValidationMessage(
      filterError,
      propertyError,
      valueError
    )
  }
}

export function updateFilter(
  filters: Filter[],
  property: string,
  operator: string,
  value: string | string[],
  remove?: boolean
): Filter[] {
  const updatedFilters = [...filters]

  let index = updatedFilters.findIndex((filter) => filter.property === property)
  if (index === -1) {
    // One past the array end - splicing and inserting there is safe.
    index = updatedFilters.length
  }

  if (remove) {
    updatedFilters.splice(index, 1)
  } else {
    updatedFilters[index] = {
      property,
      operator,
      value,
      is_empty: value === '' || (Array.isArray(value) && value.length === 0)
    }
  }

  return updatedFilters
}
