import React, { FC, memo } from 'react'
import { Route } from 'react-router'
import { Redirect } from 'react-router-dom'
import { useErrorToaster } from '../hooks/common'

interface NotFoundRouteProps {
  to: string
  message?: string
}

const NotFoundRoute: FC<NotFoundRouteProps> = memo(
  ({ to = '/', message = 'Not Found' }) => {
    useErrorToaster(message)
    return (
      <Route path='*'>
        <Redirect to={to} />
      </Route>
    )
  }
)
NotFoundRoute.displayName = 'NotFoundRoute'

export { NotFoundRoute }
