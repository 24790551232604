import {
  BackButton,
  Button,
  Heading,
  Link as EvergreenLink,
  majorScale,
  ShareIcon,
  Text,
  toaster
} from 'evergreen-ui'
import React, { FC, memo, useCallback, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { createAppObject, CRMAPIErrors } from '../../api/crm'
import { ContentCard } from '../components/content-card'
import { useTitle } from '../hooks/common'
import { useCredentials } from '../login-wrapper'
import Fields from './fields'

const NewAppObject: FC = memo(() => {
  const credentials = useCredentials()
  useTitle('New App Object')

  const [slug, setSlug] = useState<string>('')
  const [labelOne, setLabelOne] = useState<string>('')
  const [labelMany, setLabelMany] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<CRMAPIErrors>({})

  const history = useHistory()
  const navigateToNewAppObject = useCallback(
    (id: number) => {
      history.push(`/app-objects/${id}`)
    },
    [history]
  )

  const fields = [
    {
      value: slug,
      label: 'Slug',
      description: 'Unique identifier that appears in the Xkit API',
      placeholder: 'Slug',
      setField: setSlug,
      errorKey: 'slug'
    },
    {
      value: labelOne,
      label: 'Singular Name',
      description:
        'Human friendly name of your App Object as it appears to your users',
      placeholder: 'Singular Name',
      setField: setLabelOne,
      errorKey: 'label_one'
    },
    {
      value: labelMany,
      label: 'Plural Name',
      description: 'The pluralized name of your App Object',
      placeholder: 'Plural Name',
      setField: setLabelMany,
      errorKey: 'label_many'
    },
    {
      textarea: true,
      value: description,
      label: 'Description',
      description: 'A description of your App Object for your users',
      placeholder: 'Description',
      setField: setDescription,
      errorKey: 'description'
    }
  ]

  const createObject: () => Promise<void> = async () => {
    if (credentials == null) {
      toaster.danger('Invalid credentials')
      return
    }
    const response = await createAppObject(credentials, {
      slug,
      label_one: labelOne,
      label_many: labelMany,
      description,
      primary_action_id: null,
      main_link_action_id: null,
      empty_state_action_id: null,
      properties: [],
      actions: [],
      relations: []
    })
    if (response.errors) {
      setErrors(response.errors)
      console.error('Could not create App Object', response.errors)
      toaster.danger('Could not create App Object')
    } else if (response.app_object) {
      toaster.success('App Object created')
      navigateToNewAppObject(response.app_object.id)
    }
  }

  return (
    <>
      <Heading marginBottom={majorScale(2)} size={600}>
        <span style={{ marginRight: majorScale(2) }}>New App Object</span>
        <Text size={300} color='muted'>
          <EvergreenLink
            size={300}
            href='https://xkit.co/docs/crm-embed#app-objects'
            target='_blank'
            rel='noopener noreferrer'
          >
            View documentation
            <ShareIcon size={12} marginLeft={4} />
          </EvergreenLink>
        </Text>
      </Heading>
      <ContentCard
        elevation={1}
        marginBottom={majorScale(2)}
        width={570}
        padding={majorScale(2)}
      >
        <Fields fields={fields} errors={errors} setErrors={setErrors} />
        <Button
          appearance='primary'
          isLoading={loading}
          onClick={async () => {
            setLoading(true)
            try {
              await createObject()
            } catch (error) {
              console.error(error)
              toaster.danger('Could not create App Object')
            }
            setLoading(false)
          }}
        >
          Add App Object
        </Button>
      </ContentCard>
      <BackButton is={Link} to={`/app-objects`} marginTop={majorScale(3)}>
        Back to App Objects
      </BackButton>
    </>
  )
})

NewAppObject.displayName = 'NewAppObject'

export default NewAppObject
