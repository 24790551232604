import { BackButton, Heading, majorScale } from 'evergreen-ui'
import React, { FC, memo } from 'react'
import { Link } from 'react-router-dom'
import { AppObject } from '../../api/crm'
import { TokenCredentials } from '../../api/request'
import { ContentCard } from '../components/content-card'
import { SpinnerSuspend } from '../components/spinner'
import { useTitle } from '../hooks/common'
import EditAppObjectProperty from './edit-app-object-property'

interface NewAppObjectPropertyProps {
  object: AppObject | undefined
  loading: boolean
  credentials: TokenCredentials | null
  refresh: () => Promise<void>
}

const NewAppObjectProperty: FC<NewAppObjectPropertyProps> = memo(
  ({ object, loading, credentials, refresh }) => {
    useTitle(`${object?.label_one ?? ''} App Object New Property`)

    return (
      <>
        <SpinnerSuspend spinning={loading}>
          {object ? (
            <ContentCard
              elevation={1}
              marginBottom={majorScale(2)}
              width={570}
              padding={majorScale(2)}
            >
              <Heading
                size={600}
                paddingBottom={10}
                textOverflow='ellipsis'
                overflow='hidden'
                whiteSpace='nowrap'
              >
                Create New Property
              </Heading>
              <EditAppObjectProperty
                object={object}
                credentials={credentials}
                refresh={refresh}
              />
            </ContentCard>
          ) : null}
        </SpinnerSuspend>
        <BackButton is={Link} to={`/app-objects`} marginTop={majorScale(3)}>
          Back to App Objects
        </BackButton>
      </>
    )
  }
)

NewAppObjectProperty.displayName = 'NewAppObjectProperty'

export default NewAppObjectProperty
