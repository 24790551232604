import React, { FC, memo } from 'react'
import { Heading, HeadingProps, majorScale } from 'evergreen-ui'

export type HeadingTitleProps = HeadingProps

export const HeadingTitle: FC<HeadingTitleProps> = memo(
  ({ children, ...headingProps }) => {
    return (
      <Heading
        marginBottom={majorScale(3)}
        size={700}
        flexGrow={1}
        {...headingProps}
      >
        {children}
      </Heading>
    )
  }
)
HeadingTitle.displayName = 'HeadingTitle'
