import React, { memo, FC, ComponentProps } from 'react'
import { Icon, Table } from 'evergreen-ui'
import EmptyState from './empty-state'

interface TableRowEmptyProps {
  message?: string
  icon?: ComponentProps<typeof Icon>['icon']
}

export const TableRowEmpty: FC<TableRowEmptyProps> = memo(
  ({ message = 'No data found', icon = 'search' }: TableRowEmptyProps) => {
    return (
      <Table.Cell
        height={240}
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <EmptyState message={message} icon={icon} />
      </Table.Cell>
    )
  }
)

TableRowEmpty.displayName = 'TableRowEmpty'
