import {
  request,
  LegacyChangeResult,
  TokenCredentials,
  UnknownJSON
} from './request'

export interface PlatformAdminParams {
  email: string
}

export interface PlatformAdmin {
  email: string
}

export interface PlatformAdminResult extends LegacyChangeResult<PlatformAdmin> {
  platformAdmin: PlatformAdmin
}

function parsePlatformAdmin(json: UnknownJSON): PlatformAdmin {
  return {
    // TODO: please fix following typescript error
    // @ts-expect-error
    email: json.email
  }
}

export async function listPlatformAdmins(
  credentials: TokenCredentials
): Promise<PlatformAdmin[]> {
  const { platform_admins: platformAdmins } = await request({
    path: '/users',
    credentials
  })

  // TODO: please fix following typescript error
  // @ts-expect-error
  return platformAdmins.map(parsePlatformAdmin)
}

export async function createPlatformAdmin(
  credentials: TokenCredentials,
  params: PlatformAdminParams
): Promise<LegacyChangeResult<PlatformAdmin> | PlatformAdminResult> {
  const { user_errors: userErrors, platform_admin: platformAdmin } =
    await request({
      path: '/users/invite',
      method: 'POST',
      credentials,
      body: {
        platform_admin: params
      }
    })

  if (userErrors != null) {
    // TODO: please fix following typescript error
    // @ts-expect-error
    return { errors: userErrors }
  }

  return {
    // TODO: please fix following typescript error
    // @ts-expect-error
    platformAdmin: parsePlatformAdmin(platformAdmin)
  }
}
