import * as React from 'react'
import {
  Switch,
  FormField,
  majorScale,
  SwitchProps,
  FormFieldProps
} from 'evergreen-ui'

type SwitchFieldProps = SwitchProps & FormFieldProps

const SwitchField: React.FC<SwitchFieldProps> = ({ ...props }) => {
  const {
    id,
    name,
    value,
    height,
    checked,
    onChange,
    disabled,
    isInvalid,
    appearance,
    hasCheckIcon,
    defaultChecked,

    inputHeight,
    ...formFieldProps
  } = props
  const switchProps = {
    id,
    name,
    value,
    height,
    checked,
    disabled,
    // In current evergreen-ui version (4.29.1) isInvalid property is ignored
    // Reference -> https://github.com/segmentio/evergreen/blob/4d66076dcef153b528a4a7f95d2519e62b59b44c/src/switch/src/Switch.js
    // TODO: uncomment following line after evergree-ui upgrade (5^)
    // isInvalid,
    appearance,
    hasCheckIcon,
    defaultChecked
  }

  return (
    <FormField marginBottom={majorScale(3)} {...formFieldProps}>
      <Switch
        {...switchProps}
        height={inputHeight}
        onChange={(e) => {
          // TODO: please fix following typescript error
          // @ts-expect-error
          return onChange({ target: { value: e.target.checked } })
        }}
      />
    </FormField>
  )
}

export default SwitchField
