import React, { FC, useState, useEffect, useCallback, memo } from 'react'
import { majorScale, Pane, IconButton, MinusIcon, PlusIcon } from 'evergreen-ui'
import { Input } from '../../../api/destination-input'
import {
  Selector,
  selectorMatchesSearch,
  childrenMatchSearch
} from '../../../api/mapping'
import { NodeEntry } from './node-entry'
import { BUTTON_SIZE } from './constants'

export const NodePane: FC = memo(({ children }) => (
  <Pane marginTop={majorScale(1)}>{children}</Pane>
))
NodePane.displayName = 'NodePane'

interface TreeProps {
  root: Selector
  input: Input
  selector: Selector
  expandAll: boolean
  search: string
  parentMatchesSearch?: boolean
  onSelect: (selector: Selector) => void
  onNameChange: (selector: Selector, newLabel: string | undefined) => void
}

export const Tree: FC<TreeProps> = memo(
  ({
    root,
    selector,
    expandAll,
    search,
    parentMatchesSearch,
    ...nodeProps
  }) => {
    const [expanded, setExpanded] = useState(false)

    useEffect(() => {
      setExpanded(expandAll)
    }, [expandAll])

    const handleExpand = useCallback(
      () => setExpanded(!expanded),
      [expanded, setExpanded]
    )

    if (
      parentMatchesSearch !== true &&
      !selectorMatchesSearch(selector, search) &&
      !childrenMatchSearch(selector, search)
    ) {
      return null
    }

    if (selector.children?.length) {
      return (
        <NodePane>
          <NodeEntry root={root} selector={selector} {...nodeProps}>
            <IconButton
              appearance='minimal'
              icon={expanded ? MinusIcon : PlusIcon}
              height={BUTTON_SIZE}
              onClick={handleExpand}
            />
          </NodeEntry>
          {expanded ? (
            <Pane marginLeft={BUTTON_SIZE}>
              {selector.children.map((child) => (
                <Tree
                  key={selector.label}
                  root={root}
                  selector={child}
                  expandAll={expandAll}
                  search={search}
                  parentMatchesSearch={
                    parentMatchesSearch === true ||
                    selectorMatchesSearch(selector, search)
                  }
                  {...nodeProps}
                />
              ))}
            </Pane>
          ) : undefined}
        </NodePane>
      )
    }

    return (
      <NodePane>
        <NodeEntry root={root} selector={selector} {...nodeProps} />
      </NodePane>
    )
  }
)

Tree.displayName = 'Tree'
