import React, { FC, memo, useCallback } from 'react'
import { Select } from 'evergreen-ui'
import { FilterFieldProps, getFilter, updateFilter } from './filter-field'
import { FilterOverrideField } from './filter-override'
import { HistoryCountFilterOverrideField } from './history-count-filter-override-field'

const CALL_TYPE_PROPERTY = 'call_type'

export const GongCallsFilterField: FC<FilterFieldProps> = memo(
  ({ sourceFilters, syncFilters, filterErrors, onChange }) => {
    const updateProperty = useCallback(
      (propertyName, operator, value) => {
        const updatedFilters = updateFilter(
          syncFilters ?? sourceFilters,
          propertyName,
          operator,
          value
        )
        onChange(updatedFilters)
      },
      [sourceFilters, syncFilters, onChange]
    )

    const callTypeFilter = getFilter(
      sourceFilters,
      syncFilters,
      filterErrors,
      CALL_TYPE_PROPERTY
    )

    return (
      <>
        <FilterOverrideField
          propertyName={CALL_TYPE_PROPERTY}
          sourceFilters={sourceFilters}
          syncFilters={syncFilters}
          onChange={onChange}
          label='Call Type'
          description='What kind of Gong calls to sync'
          hint='External calls have at least 1 external attendee, internal calls have only internal attendees'
          validationMessage={callTypeFilter.validationMessage}
        >
          <Select
            name={CALL_TYPE_PROPERTY}
            isInvalid={callTypeFilter.isInvalid}
            value={callTypeFilter.isEmpty ? '' : callTypeFilter.value}
            disabled={callTypeFilter.disabled}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              updateProperty(CALL_TYPE_PROPERTY, 'eq', event.target.value)
            }
          >
            <option value=''>All</option>
            <option value='external'>External</option>
            <option value='internal'>Internal</option>
          </Select>
        </FilterOverrideField>
        <HistoryCountFilterOverrideField
          syncFilters={syncFilters}
          sourceFilters={sourceFilters}
          filterErrors={filterErrors}
          onChange={onChange}
          label='Historical calls limit'
          description='The number of historical calls to sync'
        />
      </>
    )
  }
)

GongCallsFilterField.displayName = 'GongCallsFilterField'
