import { useContext, useEffect, useState } from 'react'
import { Template, listTemplates } from '../../api/template'
import { CredentialsContext } from '../login-wrapper'
import errorMessage from '../../util/error-message'

interface UseTemplatesState {
  loading: boolean
  error: string | undefined
  templates: Template[]
}

export const useTemplates = (): UseTemplatesState => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const [templates, setTemplates] = useState<Template[]>([])
  const { callWithCredentials } = useContext(CredentialsContext)

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      setLoading(true)
      try {
        const templates = await callWithCredentials(listTemplates)
        setTemplates(templates)
        setError(undefined)
      } catch (e) {
        setError(`Error while loading available providers: ${errorMessage(e)}`)
      } finally {
        setLoading(false)
      }
    }

    void fetch()
  }, [callWithCredentials])

  return {
    loading,
    error,
    templates
  }
}
