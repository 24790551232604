import React, { FC, memo } from 'react'
import { Pane, PaneProps, useTheme } from 'evergreen-ui'

export type DividerProps = PaneProps

export const Divider: FC<DividerProps> = memo(({ ...paneProps }) => {
  const theme = useTheme()

  return (
    <Pane
      borderTop
      borderWidth='1px'
      borderColor={theme.colors.text.default}
      opacity={0.2}
      width='100%'
      height='0'
      {...paneProps}
    />
  )
})
Divider.displayName = 'Divider'
