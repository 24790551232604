import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Button,
  Card,
  Pane,
  Paragraph,
  SelectMenu,
  SelectMenuItem,
  Text,
  majorScale,
  toaster
} from 'evergreen-ui'
import { providerCard } from '../components/provider-card'
import { destinationCard } from '../components/destination-card'
import { Arrow } from './arrow'
import { ConnectorHeadingSkeleton } from '../connector/connector-heading-skeleton'
import { ContentCard } from '../components/content-card'
import { HeadingTitle } from '../components/heading-title'
import { useErrorToaster, useTitle } from '../hooks/common'
import { useConnectors } from '../hooks/connectors.hook'
import { useDestinations } from '../hooks/destinations.hook'
import { useCredentials } from '../login-wrapper'
import { Pipeline, createPipeline } from '../../api/pipeline'
import { hasOwnProperty } from '../../util/has-own-property'

interface NewPipelineProps {
  providerSlug: string
}

// First version: only support providers as sources and functions as destinations.
const NewPipeline: React.FC<NewPipelineProps> = ({
  providerSlug: pipelineProviderSlug
}) => {
  const credentials = useCredentials()
  const {
    connectors: providers,
    loading: providersLoading,
    error: providersError
  } = useConnectors()
  const {
    destinations,
    loading: destinationsLoading,
    error: destinationsError
  } = useDestinations()

  const loading = providersLoading || destinationsLoading
  const error = providersError ?? destinationsError

  const pipelineProvider = providers.find(
    (provider) => provider.slug === pipelineProviderSlug
  )

  useTitle(
    `New ${pipelineProvider == null ? '' : pipelineProvider.name + ' '}Pipeline`
  )
  useErrorToaster(error)

  const [providerSlug, setProviderSlug] = useState('')
  const [destinationUuid, setDestinationUuid] = useState('')
  const [saving, setSaving] = useState(false)
  const [pipeline, setPipeline] = useState<Pipeline | null>(null)

  if (loading) {
    return <ConnectorHeadingSkeleton />
  }

  const provider = providers.find((provider) => provider.slug === providerSlug)

  const destination = destinations.find(
    (destination) => destination.uuid === destinationUuid
  )

  if (error != null || pipelineProvider == null) {
    return <Redirect to={`/providers/${providerSlug}/pipelines`} />
  }

  if (pipeline != null) {
    toaster.success('Created Pipeline!')
    return <Redirect to={`/pipelines/${pipeline.uuid}`} />
  }

  async function handleSave(): Promise<void> {
    setSaving(true)

    if (credentials == null) {
      throw new Error('Invalid credentials')
    }

    try {
      const params = {
        provider_slug: providerSlug,
        destination_uuid: destinationUuid
      }

      const result = await createPipeline(credentials, params)

      if (!hasOwnProperty(result, 'errors')) {
        setPipeline(result.pipeline)
      } else {
        console.error(result)
        toaster.danger('Failed to create the pipeline!')
      }
    } finally {
      setSaving(false)
    }
  }

  return (
    <Pane>
      <HeadingTitle>New {pipelineProvider.name} Pipeline</HeadingTitle>
      <ContentCard
        elevation={1}
        padding={majorScale(2)}
        marginBottom={majorScale(2)}
      >
        <Paragraph is='div' marginBottom={majorScale(2)}>
          Pipelines are one-directional flows of data between your application
          and the provider.
        </Paragraph>

        <Pane display='flex' marginTop={majorScale(4)}>
          <SelectMenu
            title='Sources'
            options={providers.map((provider) => ({
              label: provider.name,
              value: provider.slug
            }))}
            selected={providerSlug}
            onSelect={(item: SelectMenuItem) =>
              setProviderSlug(item.value as string)
            }
            closeOnSelect={true}
          >
            {/* TODO: figure out how to make refs work and use real components. */}
            {provider == null
              ? placeholder({ title: 'Select the source...' })
              : providerCard({ provider, elevation: 1 })}
          </SelectMenu>
          <Arrow />
          <SelectMenu
            title='Destinations'
            options={destinations.map((destination) => ({
              label: destination.name,
              value: destination.uuid
            }))}
            emptyView={
              <Pane
                height='100%'
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                <Text size={300}>No destinations defined</Text>
              </Pane>
            }
            selected={destinationUuid}
            onSelect={(item) => setDestinationUuid(item.value as string)}
            closeOnSelect={true}
          >
            {/* TODO: figure out how to make refs work and use real components. */}
            {destinationUuid === '' || destination == null
              ? placeholder({ title: 'Select the destination...' })
              : destinationCard({ destination: destination, elevation: 1 })}
          </SelectMenu>
        </Pane>

        <Button
          appearance='primary'
          marginTop={majorScale(6)}
          isLoading={loading || saving}
          onClick={handleSave}
          disabled={providerSlug === '' || destinationUuid === ''}
        >
          Save
        </Button>
      </ContentCard>
    </Pane>
  )
}

const placeholder = ({ title }: { title: string }): React.ReactNode => {
  return (
    <Card
      padding={majorScale(2)}
      textDecoration='none'
      border
      borderStyle='dashed'
      borderWidth={3}
      width='278px'
      height='108px'
      display='flex'
      justifyContent='center'
      alignItems='center'
      cursor='pointer'
    >
      <Text size={500}>{title} </Text>
    </Card>
  )
}

NewPipeline.displayName = 'NewPipeline'

export default NewPipeline
