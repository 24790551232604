import React, { memo, ChangeEvent, FC, useCallback } from 'react'
import {
  FormField,
  majorScale,
  Pane,
  PaneProps,
  Select,
  TextInputField
} from 'evergreen-ui'
import { TransformationFind } from '../../../api/mapping'

export interface FindArgumentsProps extends PaneProps {
  value: TransformationFind
  onChange: (value: TransformationFind) => unknown
}

export const FindArguments: FC<FindArgumentsProps> = memo(
  ({ value, onChange, ...paneProps }) => {
    const handleExtractFieldsChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onChange({
          ...value,
          extract_pointer: e.target.value
        })
      },
      [value, onChange]
    )

    const handleConditionPointerChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onChange({
          ...value,
          condition: {
            ...value.condition,
            pointer: e.target.value
          }
        })
      },
      [value, onChange]
    )

    const handleConditionValueChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onChange({
          ...value,
          condition: {
            ...value.condition,
            value: e.target.value
          }
        })
      },
      [value, onChange]
    )

    return (
      <Pane {...paneProps}>
        <Pane display='flex' alignItems='center' marginBottom={majorScale(3)}>
          <TextInputField
            value={value.condition.pointer ?? ''}
            label='Find Pointer'
            description='JSON Pointer to the field used to find the right object'
            // TODO: better way to specify JSON pointer
            placeholder='/email'
            marginBottom={0}
            marginRight={majorScale(1)}
            onChange={handleConditionPointerChange}
          />
          <FormField label='Operation' marginRight={majorScale(1)}>
            <Select value='eq'>
              <option value='eq'>=</option>
            </Select>
          </FormField>
          <TextInputField
            value={value.condition.value ?? ''}
            placeholder='test@example.com'
            label='Value'
            marginBottom={0}
            onChange={handleConditionValueChange}
          />
        </Pane>
        <TextInputField
          label='Extract Pointer'
          description='JSON Pointer to the field on the found object to extract'
          width='100%'
          placeholder='/name'
          // TODO: better way to specify JSON pointer
          value={value.extract_pointer ?? ''}
          onChange={handleExtractFieldsChange}
          marginBottom={0}
        />
      </Pane>
    )
  }
)
FindArguments.displayName = 'FindArguments'
