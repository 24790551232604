import {
  request,
  TokenCredentials,
  LegacyChangeError,
  LegacyChangeResult
} from './request'
import * as analytics from '../util/analytics'
import { PlatformRequest } from './platform'
export { PlatformRequest } from './platform'

interface UserIdentifier {
  email: string
}

export interface User extends UserIdentifier {
  given_name?: string
  surname?: string
  password: string
}

interface CreateAccountResult {
  user_errors?: LegacyChangeError<User>
  platform_errors?: LegacyChangeError<PlatformRequest>
}

export async function createAccount(
  user: User,
  platform: PlatformRequest
): Promise<CreateAccountResult | TokenCredentials> {
  const {
    access_token: accessToken,
    user_errors: userErrors,
    platform_errors: platformErrors
  } = await request({
    path: '/users',
    method: 'POST',
    body: {
      user,
      platform
    }
  })

  if (userErrors != null || platformErrors != null) {
    return {
      // TODO: please fix following typescript error
      // @ts-expect-error
      user_errors: userErrors,
      // TODO: please fix following typescript error
      // @ts-expect-error
      platform_errors: platformErrors
    }
  }

  if (accessToken != null) {
    // TODO: please fix following typescript error
    // @ts-expect-error
    analytics.identifyFromToken(accessToken, {
      email: user.email,
      firstName: user.given_name,
      lastName: user.surname
    })
    analytics.track('create-account')
    return {
      // TODO: please fix following typescript error
      // @ts-expect-error
      token: accessToken
    }
  }

  throw new Error('Invalid return value from user creation')
}

interface UpdatedCredentials {
  password: string
}

export async function resetPassword(
  credentials: TokenCredentials,
  { password }: UpdatedCredentials
): Promise<LegacyChangeResult<UpdatedCredentials> | TokenCredentials> {
  const { access_token: accessToken, user_errors: userErrors } = await request({
    path: '/users/me',
    method: 'PUT',
    body: {
      user: {
        password
      }
    },
    credentials
  })

  if (userErrors != null) {
    return {
      // TODO: fix type
      // @ts-expect-error
      user_errors: userErrors
    }
  }

  if (accessToken != null) {
    return {
      // TODO: fix type
      // @ts-expect-error
      token: accessToken
    }
  }

  throw new Error('Invalid return value from password reset')
}

export async function requestPasswordReset({
  email
}: UserIdentifier): Promise<void> {
  await request({
    path: '/users/password-reset',
    method: 'POST',
    body: { email }
  })
}
