import React, { FC, memo, useCallback } from 'react'
import { Select, TextInput } from 'evergreen-ui'
import { FilterFieldProps, getFilter, updateFilter } from './filter-field'
import { FilterOverrideField } from './filter-override'

const AUTHOR_ROLE_PROPERTY = 'author_role'
const VISIBILITY_PROPERTY = 'visibility'
const HISTORY_SINCE_PROPERTY = 'history_since'

// UI for the supported Zendesk Ticket Comment filters
export const ZendeskTicketCommentsFilterField: FC<FilterFieldProps> = memo(
  ({ sourceFilters, syncFilters, filterErrors, onChange }) => {
    const handleChange = useCallback(
      (e, propertyName) => {
        const updatedFilters = updateFilter(
          syncFilters ?? sourceFilters,
          propertyName,
          'eq',
          e.target.value
        )
        onChange(updatedFilters)
      },
      [sourceFilters, syncFilters, onChange]
    )

    const authorRole = getFilter(
      sourceFilters,
      syncFilters,
      filterErrors,
      AUTHOR_ROLE_PROPERTY
    )

    const visibility = getFilter(
      sourceFilters,
      syncFilters,
      filterErrors,
      VISIBILITY_PROPERTY
    )

    const historySince = getFilter(
      sourceFilters,
      syncFilters,
      filterErrors,
      HISTORY_SINCE_PROPERTY
    )

    return (
      <>
        <FilterOverrideField
          propertyName={AUTHOR_ROLE_PROPERTY}
          sourceFilters={sourceFilters}
          syncFilters={syncFilters}
          onChange={onChange}
          label='Comment author'
          description='Filter the ticket comments by the role of the author'
          validationMessage={authorRole.validationMessage}
        >
          <Select
            name={AUTHOR_ROLE_PROPERTY}
            isInvalid={authorRole.isInvalid}
            disabled={authorRole.disabled}
            value={authorRole.isEmpty ? '' : authorRole.value}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              handleChange(event, AUTHOR_ROLE_PROPERTY)
            }
          >
            <option value=''>Any</option>
            <option value='agent'>Support Agent</option>
            <option value='end_user'>End-user (Customer)</option>
          </Select>
        </FilterOverrideField>
        <FilterOverrideField
          propertyName={VISIBILITY_PROPERTY}
          sourceFilters={sourceFilters}
          syncFilters={syncFilters}
          onChange={onChange}
          label='Comment visibility'
          description='Filter the ticket comments by visibility'
          hint='Internal notes are only visible to agents, whereas public comments are visible to end-users (customers) too.'
          validationMessage={visibility.validationMessage}
        >
          <Select
            name={VISIBILITY_PROPERTY}
            isInvalid={visibility.isInvalid}
            value={visibility.isEmpty ? '' : visibility.value}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              handleChange(event, VISIBILITY_PROPERTY)
            }
          >
            <option value=''>Any</option>
            <option value='public'>Public Comments Only</option>
            <option value='internal'>Internal Notes Only</option>
          </Select>
        </FilterOverrideField>
        <FilterOverrideField
          propertyName={HISTORY_SINCE_PROPERTY}
          syncFilters={syncFilters}
          sourceFilters={sourceFilters}
          onChange={onChange}
          label='Comment age limit in days'
          description='Filter ticket comments by age'
          hint='How far to look into the past when syncing ticket comments.'
          placeholder='90'
          validationMessage={historySince.validationMessage}
        >
          <TextInput
            name={HISTORY_SINCE_PROPERTY}
            isInvalid={historySince.isInvalid}
            disabled={historySince.disabled}
            value={historySince.isEmpty ? '' : historySince.value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(event, HISTORY_SINCE_PROPERTY)
            }
          />
        </FilterOverrideField>
      </>
    )
  }
)

ZendeskTicketCommentsFilterField.displayName =
  'ZendeskTicketCommentsFilterField'
