import * as React from 'react'
import * as ReactDOM from 'react-dom'
import '../css/app.css'
import App from './ui/app'
import Cohere from 'cohere-js'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

Cohere.init('-u8i68RjijHhCUjUEmNEbxRP')
Sentry.init({
  dsn: 'https://ee0c8ff1007a484e96729507dd8e6153@o521543.ingest.sentry.io/5780867',
  environment: process.env.APP_ENV ?? process.env.NODE_ENV,
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0
})

ReactDOM.render(<App />, document.getElementById('app'))
