import React, { FC, useState, useEffect, memo, useCallback } from 'react'
import { Pane, Button, toaster } from 'evergreen-ui'
import { Filter } from '../../api/filter'
import { updateSyncFilters } from '../../api/sync'
import { FilterField } from '../connector/filters/filter-field'
import { useCredentials } from '../login-wrapper'
export { hasFilters } from '../connector/filters/filter-field'

export interface SyncFilterFormProps {
  pipelineUuid: string
  connectionId: string
  syncFilters: Filter[]
  sourceFilters: Filter[]
  sourceTemplateSlug?: string
  rootObjectSlug?: string
  loading: boolean
}

export const SyncFilterForm: FC<SyncFilterFormProps> = memo(
  ({
    pipelineUuid,
    connectionId,
    loading,
    syncFilters,
    sourceFilters,
    sourceTemplateSlug,
    rootObjectSlug
  }) => {
    const credentials = useCredentials()
    const [saving, setSaving] = useState(false)
    const [filters, setFilters] = useState<Filter[]>([])
    useEffect(() => {
      setFilters(syncFilters ?? [])
    }, [syncFilters])

    const handleSave = useCallback(async (): Promise<void> => {
      setSaving(true)

      try {
        if (credentials == null) {
          throw new Error('Invalid credentials')
        }

        const filtersRes = await updateSyncFilters(
          credentials,
          pipelineUuid,
          connectionId,
          filters
        )
        if ('filters' in filtersRes) {
          setFilters(filtersRes.filters)
          toaster.success(`Saved sync filters!`)
        } else {
          // TODO: use a filter errors list and pass through
          throw new Error('Invalid format')
        }
      } catch (e) {
        console.error(e)
        toaster.danger(`Error while saving sync filters: ${String(e.message)}`)
      } finally {
        setSaving(false)
      }
    }, [credentials, pipelineUuid, connectionId, filters])

    return (
      <Pane maxWidth={400}>
        <FilterField
          sourceTemplateSlug={sourceTemplateSlug}
          rootObjectName={rootObjectSlug}
          filterErrors={[]}
          sourceFilters={sourceFilters}
          syncFilters={filters}
          onChange={setFilters}
        />
        <Button
          appearance='primary'
          isLoading={saving || loading}
          onClick={handleSave}
        >
          {loading ? 'Loading' : saving ? 'Saving' : 'Save'}
        </Button>
      </Pane>
    )
  }
)

SyncFilterForm.displayName = 'SyncFilterForm'
