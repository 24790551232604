import React, { FC, memo } from 'react'
import { Button, Text, majorScale } from 'evergreen-ui'
import { BUTTON_SIZE } from './constants'

interface NodeButtonProps {
  description: string
  onClick: () => unknown
  isSelectable: boolean
}

export const NodeButton: FC<NodeButtonProps> = memo(
  ({ children, description, onClick, isSelectable }) => (
    <Button
      appearance='minimal'
      paddingRight={majorScale(1)}
      paddingLeft={majorScale(1)}
      height={BUTTON_SIZE}
      onClick={onClick}
      disabled={!isSelectable}
      // The background color for disabled buttons looks
      // weird in a tree with a bunch of buttons together.
      // Also, if we use the regular `backgroundColor` property
      // it gets overridden by the disabled styles.
      style={!isSelectable ? { backgroundColor: 'transparent' } : {}}
    >
      {children != null ? (
        <Text size={400} marginRight={majorScale(1)}>
          {children}
        </Text>
      ) : null}
      <Text size={300}>{description}</Text>
    </Button>
  )
)

NodeButton.displayName = 'NodeButton'
