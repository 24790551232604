import {
  LegacyChangeResult,
  LegacyChangeResultValues,
  TokenCredentials,
  request
} from './request'
import * as analytics from '../util/analytics'

export interface PlatformRequest {
  slug: string
}

export interface Platform extends PlatformRequest {
  name: string
  website: string
  domain: string
  custom_domain?: string
  login_redirect_url: string
  remove_branding: boolean
  is_new: boolean
  webhooks_api_token?: string
  crm_webhooks_url?: string
}

export interface PlatformResult extends LegacyChangeResult<Platform> {
  platform: Platform
}

export type PlatformResultValues = LegacyChangeResultValues<Platform>

export async function getPlatform(
  credentials: TokenCredentials
): Promise<Platform> {
  const { platform } = await request({
    path: '/platform',
    credentials
  })

  return platform as Platform
}

export async function updatePlatform(
  credentials: TokenCredentials,
  platform: Platform
): Promise<PlatformResult> {
  const { errors, platform: updatedPlatform } = await request({
    path: '/platform',
    method: 'PUT',
    credentials,
    body: {
      platform
    }
  })

  if (platform.is_new) {
    analytics.track('setup-platform', {
      // TODO: please fix following typescript error
      // @ts-expect-error
      slug: updatedPlatform.slug,
      // TODO: please fix following typescript error
      // @ts-expect-error
      name: updatedPlatform.name
    })
  }

  return {
    // TODO: please fix following typescript error
    // @ts-expect-error
    platform: updatedPlatform,
    // TODO: please fix following typescript error
    // @ts-expect-error
    errors
  }
}

export async function updatePlatformValues(
  credentials: TokenCredentials,
  platform: Platform
): Promise<PlatformResultValues> {
  const { platform: updatedPlatform, errors } = await updatePlatform(
    credentials,
    platform
  )

  return {
    values: updatedPlatform,
    errors
  }
}
