import { useContext, useEffect, useState } from 'react'
import { Pipeline, listPipelines } from '../../api/pipeline'
import { CredentialsContext } from '../login-wrapper'
import errorMessage from '../../util/error-message'

interface UsePipelinesState {
  loading: boolean
  error: string | undefined
  pipelines: Pipeline[]
}

export const usePipelines = (): UsePipelinesState => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [pipelines, setPipelines] = useState<Pipeline[]>([])
  const { callWithCredentials } = useContext(CredentialsContext)

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      setLoading(true)
      try {
        const pipelines = await callWithCredentials(listPipelines)
        setPipelines(pipelines)
        setError(undefined)
      } catch (e) {
        setError(`Error while loading pipelines: ${errorMessage(e)}`)
      } finally {
        setLoading(false)
      }
    }

    fetch().catch(console.error)
  }, [callWithCredentials])

  return {
    loading,
    error,
    pipelines
  }
}
