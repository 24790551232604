import { useEffect, useState } from 'react'
import { CredentialConsumer } from '../login-wrapper'
import { PlatformConnection, getConnection } from '../../api/connection'

interface UseConnectionState {
  loading: boolean
  error?: string
  connection?: PlatformConnection
}

export const useConnection = (
  callWithCredentials: CredentialConsumer['callWithCredentials'],
  externalConnectionId: string
): UseConnectionState => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const [connection, setConnection] = useState<PlatformConnection | undefined>(
    undefined
  )

  useEffect(() => {
    const loadConnection: () => Promise<void> = async () => {
      setLoading(true)
      try {
        const connection = await callWithCredentials(
          async (creds) => await getConnection(creds, externalConnectionId)
        )
        setConnection(connection)
      } catch (error) {
        console.error(error)
        setError(`Error while loading connection: ${String(error.message)}`)
      } finally {
        setLoading(false)
      }
    }

    loadConnection().catch(console.error)
  }, [callWithCredentials, externalConnectionId])

  return {
    loading,
    error,
    connection
  }
}
