import React, { memo, FunctionComponent, useState } from 'react'
import {
  Button,
  Combobox,
  FormField,
  Pane,
  Paragraph,
  ShareIcon,
  majorScale,
  useTheme
} from 'evergreen-ui'
import { FormProps } from './form-props'
import { AppBuildStatus } from './app-build-status'
import { useAppBuild } from '../../hooks/app-build'
import { useTitle, useErrorToaster } from '../../hooks/common'
import { SpinnerSuspend } from '../../components/spinner'

export const SalesforceForm: FunctionComponent<FormProps> = memo(
  ({ provider }) => {
    const {
      loading,
      enqueueing,
      inProgress,
      error,
      appBuild,
      enqueueBuild,
      refresh
    } = useAppBuild(provider.slug)
    const [selectedPackage, setSelectedPackage] = useState('')
    useTitle('CRM Embed for Salesforce')
    useErrorToaster(error)
    const theme = useTheme()

    const packages = (appBuild?.params.packages ?? []) as string[]

    async function handleClickBuild(): Promise<void> {
      await enqueueBuild({ package: selectedPackage })
      setSelectedPackage('')
      await refresh()
    }

    return (
      <SpinnerSuspend spinning={appBuild == null}>
        <Paragraph color={theme.scales.gray[700]} maxWidth={400}>
          CRM Embed for Salesforce is distributed as a package that your users
          can install in their Salesforce account.
        </Paragraph>
        {appBuild != null && (
          <Pane marginTop={majorScale(3)} maxWidth={400}>
            <FormField
              label='Start a new app build'
              description='Select the package to use for the build.'
              marginTop={majorScale(4)}
            >
              <Combobox
                openOnFocus
                items={packages}
                selectedItem={selectedPackage}
                onChange={setSelectedPackage}
                placeholder='Select a package...'
                marginTop={majorScale(1)}
              />
            </FormField>
            <Button
              appearance='primary'
              marginTop={majorScale(2)}
              onClick={handleClickBuild}
              disabled={
                loading ||
                inProgress ||
                packages.length === 0 ||
                selectedPackage == null ||
                selectedPackage === ''
              }
              isLoading={enqueueing}
            >
              {enqueueing ? 'Enqueueing...' : 'Build'}
            </Button>
          </Pane>
        )}
        {appBuild != null && packages.length === 0 && (
          <Pane marginTop={majorScale(3)} maxWidth={400}>
            <Paragraph>
              You don't have any packages defined in your Salesforce Developer
              account. Start by{' '}
              <a
                href='https://developer.salesforce.com/docs/atlas.en-us.234.0.packagingGuide.meta/packagingGuide/creating_packages.htm'
                target='_blank'
                // TODO: please fix following typescript error
                // @ts-expect-error
                noreferrer='true'
                external='true'
                rel='noreferrer'
              >
                creating a package
                <ShareIcon size={12} marginLeft={4} />
              </a>
              .
            </Paragraph>
          </Pane>
        )}
        {appBuild != null && (
          <Pane marginTop={majorScale(3)}>
            <AppBuildStatus appBuild={appBuild} />
          </Pane>
        )}
      </SpinnerSuspend>
    )
  }
)

SalesforceForm.displayName = 'SalesforceForm'
