import React, { FC, memo, useCallback, useState, useEffect } from 'react'
import {
  FormField,
  FormFieldProps,
  majorScale,
  Pane,
  Switch
} from 'evergreen-ui'
import { Input, isTypeEqual } from '../../../api/destination-input'
import {
  TransformationName,
  TransformationType,
  TransformationParameters,
  Selector,
  isStaticTransformation,
  requiresArguments,
  getSelectorFromPointer
} from '../../../api/mapping'
import { Connector } from '../../../api/connector'
import {
  TransformationDataField,
  DataFieldValue
} from './transformation-data-field'
import { TransformationNameField } from './transformation-name-field'

export interface TransformationFieldProps extends FormFieldProps {
  transformation?: TransformationParameters
  overridden?: boolean
  allowOverrides: boolean
  forceDisabled?: boolean
  provider?: Connector
  selectors?: Selector[]
  input: Input
  onChange: (transformation: Partial<TransformationParameters>) => unknown
}

function availableTransformationsForPointer(
  input: Input,
  pointer: string,
  selectors: Selector[],
  sourceRelationId: number
): TransformationName[] {
  const selector = getSelectorFromPointer(pointer, selectors, sourceRelationId)
  const inputType = selector.input_types.find(({ input_type: inputType }) =>
    isTypeEqual(inputType, input.simple_type)
  )

  if (inputType == null || inputType.transformations == null) {
    return []
  }

  return inputType.transformations.map((name) => TransformationName[name])
}

function deriveDataValue(
  transformation?: TransformationParameters
): DataFieldValue | undefined {
  if (transformation == null) {
    return undefined
  }

  if (isStaticTransformation(transformation)) {
    return { value: transformation.static_value }
  }

  return {
    pointer: transformation.source_pointer,
    source_relation_id: transformation.source_relation_id
  }
}

export const TransformationField: FC<TransformationFieldProps> = memo(
  ({
    transformation,
    overridden,
    allowOverrides,
    forceDisabled,
    provider,
    selectors,
    input,
    onChange,
    children,
    ...formFieldProps
  }) => {
    const [transformationNames, setTransformationNames] = useState<
      TransformationName[]
    >([TransformationName.direct])
    useEffect(() => {
      const pointer = transformation?.source_pointer
      const sourceRelationId = transformation?.source_relation_id

      if (pointer != null && sourceRelationId != null && selectors != null) {
        setTransformationNames(
          availableTransformationsForPointer(
            input,
            pointer,
            selectors,
            sourceRelationId
          )
        )
      }
    }, [
      setTransformationNames,
      input,
      selectors,
      transformation?.source_pointer,
      transformation?.source_relation_id
    ])

    const handleDataChange = useCallback(
      (dataValue?: DataFieldValue) => {
        if (dataValue == null) {
          // Reset
          onChange({
            type: TransformationType.native,
            destination_input_id: input.id
          })
        } else if ('value' in dataValue) {
          onChange({
            type: TransformationType.native,
            name: TransformationName.static,
            destination_input_id: input.id,
            static_value: dataValue.value
          })
        } else {
          const validTransformations =
            selectors == null
              ? []
              : availableTransformationsForPointer(
                  input,
                  dataValue.pointer,
                  selectors,
                  dataValue.source_relation_id
                )

          // Default the transformation to one that doesn't require arguments
          const transformationName = validTransformations.find(
            (name) => !requiresArguments(name)
          )

          onChange({
            type: TransformationType.native,
            destination_input_id: input.id,
            source_relation_id: dataValue.source_relation_id,
            source_pointer: dataValue.pointer,
            name: transformationName
          })
        }
      },
      [onChange, input, selectors]
    )

    const handleNameChange = useCallback(
      (newTransformation: TransformationParameters) => {
        if (transformation == null) {
          return
        }

        onChange({
          ...transformation,
          ...newTransformation
        })
      },
      [onChange, transformation]
    )

    const handleOverrideChange = useCallback(
      (removeOverride) => {
        if (removeOverride) {
          onChange({ deleted: true })
        } else {
          onChange({ deleted: false })
        }
      },
      [onChange]
    )

    const isDisabled = (allowOverrides && !overridden) || forceDisabled

    return (
      <FormField label={input.name} {...formFieldProps}>
        <Pane display='flex' alignItems='center'>
          <TransformationDataField
            disabled={isDisabled}
            provider={provider}
            selectors={selectors ?? []}
            input={input}
            value={deriveDataValue(transformation)}
            onChange={handleDataChange}
          />
          <TransformationNameField
            disabled={isDisabled}
            value={transformation}
            names={transformationNames}
            onChange={handleNameChange}
          />
          {allowOverrides && (
            <Switch
              name={`override_${input.name}`}
              checked={overridden}
              marginLeft={majorScale(1)}
              onChange={(e) => {
                handleOverrideChange(!e.target.checked)
              }}
            />
          )}
        </Pane>
      </FormField>
    )
  }
)

TransformationField.displayName = 'TransformationField'
