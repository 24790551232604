import React, { FC, memo } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { AppObject } from '../../api/crm'
import { TokenCredentials } from '../../api/request'
import AppObjectProperties from './app-object-properties'
import NewAppObjectProperty from './new-app-object-property'

interface PropertiesAppObjectTabProps {
  object: AppObject | undefined
  loading: boolean
  credentials: TokenCredentials | null
  refresh: () => Promise<void>
}

const PropertiesAppObjectTab: FC<PropertiesAppObjectTabProps> = memo(
  ({ object, loading, credentials, refresh }) => {
    const { path } = useRouteMatch()

    return (
      <Switch>
        <Route path={`${path}/`} exact>
          <AppObjectProperties
            object={object}
            loading={loading}
            credentials={credentials}
            refresh={refresh}
          />
        </Route>
        <Route path={`${path}/new`} exact>
          <NewAppObjectProperty
            object={object}
            loading={loading}
            credentials={credentials}
            refresh={refresh}
          />
        </Route>
      </Switch>
    )
  }
)

PropertiesAppObjectTab.displayName = 'PropertiesAppObjectTab'

export default PropertiesAppObjectTab
