import * as React from 'react'
import {
  Pane,
  Button,
  Code,
  TextInput,
  Paragraph,
  Table,
  Switch,
  majorScale,
  toaster
} from 'evergreen-ui'
import { withCredentials, CredentialConsumer } from '../login-wrapper'
import {
  listenToRejectedOrigins,
  AttemptedOrigin,
  Emitter
} from '../../api/attempted-origin'
import {
  listOrigins,
  deleteOrigin,
  createOrigin,
  Origin
} from '../../api/origin'
import TableSpinner from '../components/table-spinner'
import errorMessage from '../../util/error-message'

interface DisplayOrigin {
  origin: AttemptedOrigin | Origin
  allowed: boolean
}

function zipOrigins(
  rejectedOrigins: AttemptedOrigin[],
  allowedOrigins: Origin[]
): DisplayOrigin[] {
  const normalizedRejected = rejectedOrigins.map((origin) => {
    return { allowed: false, origin }
  })

  const normalizedAllowed = allowedOrigins.map((origin) => {
    return { allowed: true, origin }
  })

  const all = [...normalizedAllowed, ...normalizedRejected]

  all.sort((a, b) => {
    return a.origin.origin.localeCompare(b.origin.origin)
  })

  return all
}

interface PlatformOriginsState {
  allowedOrigins: Map<string | number, Origin>
  rejectedOrigins: AttemptedOrigin[]
  proposedOrigin: string
  proposedOriginError?: string
  initialLoading: boolean
  saving: boolean
}

interface OriginRowProperties {
  origin: AttemptedOrigin | Origin
  allowed: boolean
  onChange: (value: boolean) => void
}

const OriginRow: React.FC<OriginRowProperties> = ({
  origin,
  allowed,
  onChange
}) => {
  return (
    <Table.Row key={origin.origin} intent={allowed ? 'none' : 'warning'}>
      <Table.TextCell>
        <Code size={300}>{origin.origin}</Code>
      </Table.TextCell>
      <Table.Cell flexBasis={56} flexGrow={0}>
        <Switch
          checked={allowed}
          onChange={(e) => onChange(e.target.checked)}
        />
      </Table.Cell>
    </Table.Row>
  )
}

class PlatformOrigins extends React.Component<
  CredentialConsumer,
  PlatformOriginsState
> {
  originEmitter: Emitter

  constructor(props: {}) {
    // TODO: please fix following typescript error
    // @ts-expect-error
    super(props)
    this.state = {
      proposedOrigin: '',
      initialLoading: true,
      saving: false,
      // TODO: please fix following typescript error
      // @ts-expect-error
      allowedOrigins: [],
      rejectedOrigins: []
    }
  }

  async loadAllowedOrigins(): Promise<void> {
    try {
      const allowedOrigins = await this.props.callWithCredentials(listOrigins)
      const allowedOriginsTuples = allowedOrigins.map((origin) => [
        origin.id,
        origin
      ])
      // TODO: please fix following typescript error
      // @ts-expect-error
      this.setState({ allowedOrigins: new Map(allowedOriginsTuples) })
    } catch (e) {
      toaster.danger(`Error while loading web origins: ${errorMessage(e)}`)
    } finally {
      this.setState({ initialLoading: false })
    }
  }

  componentDidMount(): void {
    void this.loadAllowedOrigins()
    this.originEmitter = listenToRejectedOrigins(this.props.credentials)
    this.originEmitter.on('update', this.updateOrigins)
  }

  componentWillUnmount(): void {
    this.originEmitter.off('update', this.updateOrigins)
  }

  updateOrigins = (rejectedOrigins: AttemptedOrigin[]): void => {
    this.setState({ rejectedOrigins })
  }

  async createOrigin(origin: string): Promise<void> {
    const createdOrigin = await this.props.callWithCredentials(
      async (creds) => await createOrigin(creds, origin)
    )
    const updatedOrigins = new Map(this.state.allowedOrigins).set(
      createdOrigin.id,
      createdOrigin
    )
    this.setState({ allowedOrigins: updatedOrigins })
  }

  async enableOrigin(origin: AttemptedOrigin): Promise<void> {
    try {
      await this.createOrigin(origin.origin)
    } catch (e) {
      toaster.danger(`Error while enabling web origin: ${errorMessage(e)}`)
    }
  }

  async disableOrigin(origin: Origin): Promise<void> {
    try {
      await this.props.callWithCredentials(
        async (creds) => await deleteOrigin(creds, origin.id)
      )
      const updatedOrigins = new Map(this.state.allowedOrigins)
      updatedOrigins.delete(origin.id)
      this.setState({ allowedOrigins: updatedOrigins })
    } catch (e) {
      toaster.danger(`Error while disabling web origin: ${errorMessage(e)}`)
    }
  }

  async saveOrigin(): Promise<void> {
    const { proposedOrigin } = this.state
    this.setState({
      saving: true
    })
    try {
      await this.createOrigin(proposedOrigin)
      this.setState({
        proposedOrigin: '',
        proposedOriginError: undefined
      })
    } catch (e) {
      toaster.danger(`Error while adding web origin: ${errorMessage(e)}`)
      this.setState({ proposedOriginError: e.message })
    } finally {
      this.setState({ saving: false })
    }
  }

  renderTableBody(): React.ReactNode {
    const { initialLoading, rejectedOrigins, allowedOrigins } = this.state

    if (initialLoading) {
      return (
        <Table.Body
          display='flex'
          alignItems='center'
          justifyContent='center'
          padding={majorScale(4)}
        >
          <TableSpinner />
        </Table.Body>
      )
    }

    const allOrigins = zipOrigins(
      rejectedOrigins,
      Array.from(allowedOrigins.values())
    )

    return (
      <Table.Body>
        {allOrigins.map(({ allowed, origin }) => {
          return (
            <OriginRow
              key={allowed ? `allowed:${origin.id}` : `rejected:${origin.id}`}
              origin={origin}
              allowed={allowed}
              onChange={(shouldAdd) => {
                // make sure we're not duplicating on a fast switch
                // by checking that shouldAdd is different from our current state
                if (shouldAdd && !allowed) {
                  // TODO: please fix following typescript error
                  // @ts-expect-error
                  void this.enableOrigin(origin)
                } else if (allowed) {
                  void this.disableOrigin(origin)
                }
              }}
            />
          )
        })}
      </Table.Body>
    )
  }

  render(): React.ReactNode {
    const { initialLoading, saving, proposedOrigin, proposedOriginError } =
      this.state

    return (
      <>
        <Table marginTop={majorScale(2)} maxWidth='680px'>
          <Table.Head>
            <Table.TextHeaderCell>Origin</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={56} flexGrow={0}>
              Allowed?
            </Table.TextHeaderCell>
          </Table.Head>
          {this.renderTableBody()}
        </Table>
        <Pane
          marginTop={majorScale(2)}
          marginBottom={majorScale(2)}
          maxWidth='680px'
        >
          <Pane display='flex'>
            <TextInput
              flexGrow={1}
              fontFamily='mono'
              placeholder='https://myapp.com'
              isInvalid={proposedOriginError != null}
              disabled={initialLoading}
              value={proposedOrigin}
              // TODO: please fix following typescript error
              // @ts-expect-error
              onChange={(e) =>
                this.setState({ proposedOrigin: e.target.value })
              }
            />
            <Button
              isLoading={saving}
              disabled={initialLoading}
              marginLeft={majorScale(2)}
              iconBefore={saving ? undefined : 'add'}
              onClick={async () => await this.saveOrigin()}
            >
              Add Origin
            </Button>
          </Pane>
          <Pane>
            <Paragraph size={300} marginTop={majorScale(2)} color='muted'>
              Supports wildcard patterns, e.g.{' '}
              <Code fontSize='10px'>https://app-*.example.com</Code>.
            </Paragraph>
          </Pane>
        </Pane>
      </>
    )
  }
}

export default withCredentials(PlatformOrigins)
