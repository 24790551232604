import { BackButton, Button, majorScale, toaster } from 'evergreen-ui'
import React, { FC, memo, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AppObject, CRMAPIErrors, updateAppObject } from '../../api/crm'
import { TokenCredentials } from '../../api/request'
import { ContentCard } from '../components/content-card'
import { SpinnerSuspend } from '../components/spinner'
import { useTitle } from '../hooks/common'
import Fields from './fields'

interface ObjectAppObjectTabProps {
  object: AppObject | undefined
  loading: boolean
  credentials: TokenCredentials | null
}

const ObjectAppObjectTab: FC<ObjectAppObjectTabProps> = memo(
  ({ object, loading, credentials }) => {
    const [slug, setSlug] = useState<string>('')
    const [labelOne, setLabelOne] = useState<string>('')
    const [labelMany, setLabelMany] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [saving, setSaving] = useState<boolean>(false)
    const [errors, setErrors] = useState<CRMAPIErrors>({})

    useTitle(`${object?.label_one ?? ''} App Object`)

    useEffect(() => {
      if (!loading && object) {
        setSlug(object.slug)
        setLabelOne(object.label_one)
        setLabelMany(object.label_many)
        setDescription(object.description)
      }
    }, [object, loading])

    const fields = [
      {
        value: slug,
        label: 'Slug',
        description: 'Unique identifier that appears in the Xkit API',
        placeholder: 'Slug',
        setField: setSlug,
        errorKey: 'slug'
      },
      {
        value: labelOne,
        label: 'Singular Name',
        description:
          'Human friendly name of your App Object as it appears to your users',
        placeholder: 'Singular Name',
        setField: setLabelOne,
        errorKey: 'label_one'
      },
      {
        value: labelMany,
        label: 'Plural Name',
        description: 'The pluralized name of your App Object',
        placeholder: 'Plural Name',
        setField: setLabelMany,
        errorKey: 'label_many'
      },
      {
        textarea: true,
        value: description,
        label: 'Description',
        description: 'A description of your App Object for your users',
        placeholder: 'Description',
        setField: setDescription,
        errorKey: 'description'
      }
    ]

    const saveObject: (object: AppObject) => Promise<void> = async (object) => {
      if (credentials == null) {
        toaster.danger('Invalid credentials')
        return
      }
      const response = await updateAppObject(credentials, {
        id: object.id,
        slug,
        label_one: labelOne,
        label_many: labelMany,
        description,
        primary_action_id: object.primary_action_id,
        main_link_action_id: object.main_link_action_id,
        empty_state_action_id: object.main_link_action_id,
        properties: object.properties,
        actions: object.actions,
        relations: object.relations
      })
      if (response.errors) {
        setErrors(response.errors)
        console.error('Could not update App Object', response.errors)
        toaster.danger('Could not update App Object')
      } else {
        toaster.success('App Object updated')
      }
    }

    return (
      <>
        <SpinnerSuspend spinning={loading}>
          {object ? (
            <ContentCard
              elevation={1}
              marginBottom={majorScale(2)}
              width={570}
              padding={majorScale(2)}
            >
              <Fields fields={fields} errors={errors} setErrors={setErrors} />
              <Button
                appearance='primary'
                isLoading={saving}
                onClick={async () => {
                  setSaving(true)
                  try {
                    await saveObject(object)
                  } catch (error) {
                    console.error(error)
                    toaster.danger('Could not update App Object')
                  }
                  setSaving(false)
                }}
              >
                Save
              </Button>
            </ContentCard>
          ) : null}
        </SpinnerSuspend>
        <BackButton is={Link} to={`/app-objects`} marginTop={majorScale(3)}>
          Back to App Objects
        </BackButton>
      </>
    )
  }
)

ObjectAppObjectTab.displayName = 'ObjectAppObjectTab'

export default ObjectAppObjectTab
