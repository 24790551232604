import React, { useState } from 'react'
import { Button, majorScale, Pane, TextInputField } from 'evergreen-ui'
import { Input } from '../../../api/destination-input'
import { SelectInputType } from '../../destinations/functions/inputs-field'

interface NestedInputFormProps {
  parentInput: Input
  onSave: (newInput: Partial<Input>) => void
}

export const NestedInputForm: React.FC<NestedInputFormProps> = ({
  parentInput,
  onSave
}) => {
  const [input, setInput] = useState<Partial<Input>>({})

  return (
    <Pane marginTop={majorScale(1)} display='flex' alignItems='center'>
      <TextInputField
        marginRight={majorScale(1)}
        marginBottom={0}
        label='Name'
        value={input.name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setInput({ ...input, name: e.target.value })
        }}
      />
      <SelectInputType
        marginRight={majorScale(1)}
        marginBottom={0}
        value={input.simple_type}
        label='Type'
        disabled={false}
        onChange={(value) => {
          setInput({ ...input, simple_type: value })
        }}
      />
      <Button
        iconBefore='add'
        marginTop={24}
        marginRight={0}
        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
          e.preventDefault()
          if (input.name && input.simple_type) {
            onSave({
              name: input.name,
              simple_type: input.simple_type,
              parent_id: parentInput.id,
              required: false
            })
          }
        }}
      >
        Add
      </Button>
    </Pane>
  )
}
