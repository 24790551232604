import React, {
  FC,
  useState,
  useEffect,
  memo,
  useCallback,
  ChangeEvent
} from 'react'
import { Pane, Button, majorScale, toaster, SelectField } from 'evergreen-ui'
import { SelectedRootObject } from '../../api/source-object'
import { UseRootObjectState } from '../hooks/root-object.hook'

export interface ObjectFormProps {
  selectedRootObject?: UseRootObjectState['selectedRootObject']
  sourceRootObjects: UseRootObjectState['sourceRootObjects']
  rootObjectErrors?: UseRootObjectState['errors']
  updateRootObject: UseRootObjectState['updateFx']
  onChange: () => void
  loading: boolean
}

export const ObjectForm: FC<ObjectFormProps> = memo(
  ({
    selectedRootObject,
    sourceRootObjects,
    updateRootObject,
    rootObjectErrors,
    onChange,
    loading
  }) => {
    const [selectedId, setSelectedId] = useState<
      SelectedRootObject['id'] | undefined
    >()
    const [saving, setSaving] = useState(false)

    useEffect(() => {
      setSelectedId(selectedRootObject?.id)
    }, [selectedRootObject?.id])

    const onChangeHandler = useCallback(
      (event: ChangeEvent<HTMLSelectElement>) => {
        const parsedValue = parseInt(event.target.value)
        setSelectedId(isNaN(parsedValue) ? undefined : parsedValue)
      },
      [setSelectedId]
    )

    const handleSave = useCallback(async (): Promise<void> => {
      setSaving(true)

      try {
        const success = await updateRootObject({
          // TODO: please fix following typescript error
          // @ts-expect-error
          filters: selectedRootObject?.filters,
          // TODO: please fix following typescript error
          // @ts-expect-error
          source_object_id: selectedId
        })

        if (success) {
          toaster.success(`Saved objects to sync`)
        } else {
          toaster.danger(`Failed to save objects to sync`)
        }

        onChange()
      } catch (e) {
        console.error(e)
        toaster.danger(
          `Error while saving objects to sync: ${String(e.message)}`
        )
      } finally {
        setSaving(false)
      }
    }, [selectedId, selectedRootObject, updateRootObject, onChange])

    const validationMessage = rootObjectErrors?.source_object_id?.length
      ? rootObjectErrors?.source_object_id?.[0].message
      : null

    return (
      <Pane maxWidth={600}>
        <SelectField
          label='Object to sync'
          description='Select the object from the external system that you want to sync.'
          value={selectedId}
          disabled={saving || loading}
          onChange={onChangeHandler}
          validationMessage={validationMessage}
          maxWidth={400}
        >
          <option value={undefined} hidden>
            None
          </option>
          {sourceRootObjects.map((object) => (
            <option key={object.slug} value={object.id}>
              {object.label_many}
            </option>
          ))}
        </SelectField>
        <Button
          marginTop={majorScale(1)}
          appearance='primary'
          isLoading={saving || loading}
          disabled={selectedId == null}
          onClick={handleSave}
        >
          {loading ? 'Loading' : saving ? 'Saving' : 'Save'}
        </Button>
      </Pane>
    )
  }
)
ObjectForm.displayName = 'ObjectForm'
