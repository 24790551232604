import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export type Mode = 'auth' | 'data' | 'crm'

type ProviderModeReturn = [Mode, (mode: Mode) => unknown]

export function useProviderMode(initialMode: Mode): ProviderModeReturn {
  const location = useLocation()
  const history = useHistory()
  const [mode, setMode] = useState<Mode>(initialMode)

  useEffect(() => {
    if (!location.search) {
      history.push({ search: `?type=${initialMode}` })
    } else {
      const type = location.search.slice('?type='.length)
      switch (type) {
        case 'auth':
          setMode('auth')
          break
        case 'data':
          setMode('data')
          break
        case 'crm':
          setMode('crm')
          break
        default:
          history.push({ search: '' })
      }
    }
  }, [location.search, history, initialMode])

  const setHistoryMode = (newMode: Mode): void => {
    history.push({ search: `?type=${newMode}` })
  }

  return [mode, setHistoryMode]
}
