import React, { memo } from 'react'
import ContentSection from '../content-section'
import { Connector } from '../../api/connector'
import { PermissionsForm, PermissionsFormProps } from './permissions-form'

export interface PermissionsTabProps<T extends Connector>
  extends PermissionsFormProps<T> {
  showTitle: boolean
}

export const PermissionsTab = memo(
  <T extends Connector>({
    showTitle,
    ...formProps
  }: PermissionsTabProps<T>) => {
    return (
      <ContentSection title={showTitle ? 'Permissions' : ''}>
        <PermissionsForm {...formProps} />
      </ContentSection>
    )
  }
)
PermissionsTab.displayName = 'PermissionsTab'
