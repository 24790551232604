import * as React from 'react'
import {
  Pane,
  Button,
  Table,
  Code,
  Checkbox,
  ErrorIcon,
  Paragraph,
  majorScale,
  toaster,
  useTheme
} from 'evergreen-ui'
import { Connector, ConnectorResultValues } from '../api/connector'
import { Scope } from '../api/scope'
import { applySearchFilter } from '../util/apply-search-filter'
import TableSpinner from './components/table-spinner'
import errorMessage from '../util/error-message'

interface ScopeRowProps {
  scope: Scope
  isSelected: boolean
  onChange: (isSelected: boolean) => void
}

const ScopeRow: React.FC<ScopeRowProps> = ({ scope, isSelected, onChange }) => {
  const theme = useTheme()

  return (
    <Table.Row height='auto' paddingY={12}>
      <Table.TextCell
        textProps={{ overflow: 'visible', style: { width: '100%' } }}
      >
        <Checkbox
          marginTop={0}
          marginBottom={0}
          label={
            <Code
              size={300}
              style={{
                overflowWrap: 'break-word',
                overflow: 'visible',
                color: scope.is_deprecated
                  ? theme.colors.text.muted
                  : theme.colors.text.dark,
                textDecoration: scope.is_deprecated ? 'line-through' : 'none'
              }}
            >
              {scope.content + (scope.is_deprecated ? ' (deprecated)' : '')}
            </Code>
          }
          checked={scope.required ? true : isSelected}
          disabled={scope.required}
          onChange={(e) => onChange(e.target.checked)}
        />
      </Table.TextCell>
      <Table.TextCell
        textProps={{
          whiteSpace: 'break-spaces',
          overflow: 'visible',
          textDecoration: scope.is_deprecated ? 'line-through' : 'none',
          color:
            scope.required === true || scope.is_deprecated ? 'muted' : 'dark'
        }}
      >
        {scope.required
          ? scope.description || 'Required for token retrieval'
          : scope.description}
      </Table.TextCell>
    </Table.Row>
  )
}

interface ScopesFormProps<T extends Connector> {
  connector: T
  loading: boolean
  update: (connector: Partial<T>) => Promise<ConnectorResultValues<T>>
}

interface ScopesFormState<T extends Connector> {
  connector: T
  saving: boolean
  scopeFilter: string
  error?: string
}

class ScopesForm<T extends Connector> extends React.Component<
  ScopesFormProps<T>,
  ScopesFormState<T>
> {
  constructor(props: ScopesFormProps<T>) {
    super(props)
    this.state = {
      connector: this.props.connector,
      saving: false,
      scopeFilter: ''
    }
  }

  componentDidUpdate(prevProps: ScopesFormProps<T>): void {
    if (prevProps.connector !== this.props.connector) {
      this.setState({ connector: this.props.connector })
    }
  }

  handleSave = async (): Promise<void> => {
    this.setState({ saving: true })
    try {
      const { errors, values } = await this.props.update(this.state.connector)

      if (!errors) {
        this.setState({
          // TODO: please fix following typescript error
          // @ts-expect-error
          connector: values,
          error: undefined
        })
        toaster.success(
          `Updated connector settings for ${this.props.connector.name}`
        )
      } else {
        const scopesError = errors.find(({ field }) => field === 'scopes')
        if (scopesError) {
          this.setState({ error: scopesError.error })
        }
      }
    } catch (e) {
      console.error(e)
      toaster.danger(`Error while saving scopes: ${errorMessage(e)}`)
    } finally {
      this.setState({ saving: false })
    }
  }

  changeScope(scope: Scope, shouldBeSelected: boolean): void {
    const { connector } = this.state
    const isSelected = connector.scopes.some(
      ({ content }) => content === scope.content
    )
    if (isSelected !== shouldBeSelected) {
      const scopes = connector.scopes.slice()
      if (shouldBeSelected) {
        scopes.push(scope)
      } else {
        const scopeIndex = scopes.findIndex(
          ({ content }) => content === scope.content
        )
        scopes.splice(scopeIndex, 1)
      }
      this.setState({ connector: { ...connector, scopes } })
    }
  }

  renderContent(): React.ReactNode {
    const { loading } = this.props
    const { connector, scopeFilter } = this.state

    if (loading || !connector) {
      return <TableSpinner />
    }

    const filteredScopes = connector.template.scopes
      .filter((scope: Scope) => {
        return (
          applySearchFilter(scope.content, scopeFilter) ||
          applySearchFilter(scope.description, scopeFilter)
        )
      })
      .sort((scopeA: Scope, scopeB: Scope) => {
        // TODO: please fix following eslint error
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        if (scopeA.required || scopeB.required) {
          const scopeARequired = scopeA.required ? 1 : 0
          const scopeBRequired = scopeB.required ? 1 : 0
          return scopeBRequired - scopeARequired
        }
        return scopeA.content.localeCompare(scopeB.content)
      })

    return (
      <>
        {filteredScopes.map((scope) => {
          const isSelected = connector.scopes.some(
            ({ content }) => content === scope.content
          )
          return (
            <ScopeRow
              key={scope.id}
              scope={scope}
              isSelected={isSelected}
              onChange={(shouldBeSelected) =>
                this.changeScope(scope, shouldBeSelected)
              }
            />
          )
        })}
      </>
    )
  }

  // See: https://github.com/segmentio/evergreen/blob/master/src/form-field/src/FormFieldValidationMessage.js
  renderError(): React.ReactNode {
    const { error } = this.state
    if (!error) {
      return
    }

    return (
      <Pane display='flex' marginBottom={majorScale(2)}>
        <ErrorIcon color='danger' marginTop={1} size={14} marginRight={8} />
        <Paragraph marginTop={0} size={300} color='danger' role='alert'>
          {error}
        </Paragraph>
      </Pane>
    )
  }

  render(): React.ReactNode {
    const { loading } = this.props
    const { saving, scopeFilter } = this.state

    return (
      <Pane>
        <Table marginBottom={majorScale(2)}>
          <Table.Head>
            <Table.SearchHeaderCell
              placeholder='Filter by scope...'
              value={scopeFilter}
              onChange={(value) => this.setState({ scopeFilter: value })}
            />
            <Table.TextHeaderCell>Description</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>{this.renderContent()}</Table.Body>
        </Table>
        {this.renderError()}
        <Button
          marginTop={majorScale(1)}
          appearance='primary'
          isLoading={saving || loading}
          onClick={this.handleSave}
        >
          {loading ? 'Loading' : saving ? 'Saving' : 'Save'}
        </Button>
      </Pane>
    )
  }
}

export default ScopesForm
