import { useCallback, useContext, useEffect, useState } from 'react'
import {
  AppBuild,
  AppBuildParams,
  AppBuildStatus as Status,
  getAppBuild,
  enqueueAppBuild
} from '../../api/crm-embed'
import errorMessage from '../../util/error-message'
import { CredentialsContext } from '../login-wrapper'

interface UseAppBuildState {
  loading: boolean
  enqueueing: boolean
  inProgress: boolean
  error?: string
  appBuild?: AppBuild
  enqueueBuild: (params: AppBuildParams) => Promise<void>
  refresh: () => Promise<void>
}

export const useAppBuild = (providerSlug: string): UseAppBuildState => {
  const [loading, setLoading] = useState(true)
  const [enqueueing, setEnqueueing] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [appBuild, setAppBuild] = useState<AppBuild | undefined>(undefined)
  const { callWithCredentials } = useContext(CredentialsContext)

  const refresh = useCallback(async () => {
    const fetch = async (): Promise<void> => {
      setLoading(true)
      try {
        const appBuild = await callWithCredentials(
          async (credentials) => await getAppBuild(credentials, providerSlug)
        )
        setAppBuild(appBuild)
        setInProgress(appBuild.status === Status.inProgress)
        setError(undefined)
      } catch (e) {
        setError(`Error while loading app build: ${errorMessage(e)}`)
      } finally {
        setLoading(false)
      }
    }
    fetch().catch(console.error)
  }, [callWithCredentials, providerSlug])

  async function enqueueBuild(params: AppBuildParams): Promise<void> {
    setEnqueueing(true)
    try {
      await callWithCredentials(
        async (credentials) =>
          await enqueueAppBuild(credentials, providerSlug, params)
      )
      setError(undefined)
    } catch (e) {
      setError(`Error while enqueueing app build: ${errorMessage(e)}`)
    } finally {
      setEnqueueing(false)
    }
  }

  useEffect(() => {
    void refresh()
  }, [refresh])

  useEffect(() => {
    const ref = setInterval(() => {
      if (appBuild != null && appBuild.status === Status.inProgress) {
        void refresh()
      }
    }, 2000)
    return () => clearTimeout(ref)
  })

  return {
    loading,
    enqueueing,
    inProgress,
    error,
    appBuild,
    enqueueBuild,
    refresh
  }
}
