import { useCallback, useContext, useEffect, useState } from 'react'
import {
  SourceRootObject,
  SelectedRootObject,
  RootObjectErrors,
  RootObjectParams
} from '../../api/source-object'
import { Pipeline, createOrUpdateRootObject } from '../../api/pipeline'
import { CredentialsContext } from '../login-wrapper'
import { hasOwnProperty } from '../../util/has-own-property'

export interface UseRootObjectState {
  errors?: RootObjectErrors['errors']
  sourceRootObjects: SourceRootObject[]
  selectedRootObject?: SelectedRootObject
  updateFx: (params: RootObjectParams) => Promise<boolean>
}

export const useRootObject = (pipeline?: Pipeline): UseRootObjectState => {
  const [sourceRootObjects, setSourceRootObjects] = useState<
    SourceRootObject[]
  >([])
  const [selectedRootObject, setRootObject] = useState<
    SelectedRootObject | undefined
  >(undefined)
  const [errors, setErrors] = useState<RootObjectErrors['errors'] | undefined>(
    undefined
  )
  const { callWithCredentials } = useContext(CredentialsContext)

  useEffect(() => {
    if (pipeline == null) {
      return
    }
    const sourceRootObjects = pipeline.source.source_objects.filter(
      (object) => object.is_root
    )
    const selectedRootObject = pipeline.source.source_objects.find(
      (object) => object.is_selected_root
    )
    setSourceRootObjects(sourceRootObjects as SourceRootObject[])
    setRootObject(selectedRootObject as SelectedRootObject)
  }, [pipeline])

  const updateFx = useCallback(
    async (rootObjectParams: RootObjectParams) => {
      if (pipeline == null) {
        throw new Error('Cannot update pipeline before it is loaded')
      }

      const result = await callWithCredentials(async (creds) => {
        return await createOrUpdateRootObject(
          creds,
          pipeline.uuid,
          rootObjectParams
        )
      })

      if (hasOwnProperty(result, 'errors')) {
        setErrors(result.errors)
        return false
      }

      if (hasOwnProperty(result, 'root_object')) {
        setRootObject(result.root_object)
        setErrors(undefined)
        return true
      }

      throw new Error('Unknown error while updating root object')
    },
    [callWithCredentials, pipeline]
  )

  return {
    errors,
    sourceRootObjects,
    selectedRootObject,
    updateFx
  }
}
