import { TokenCredentials } from './request'
import { createSocket } from './socket'

export interface PlatformSetupStatus {
  added_connector?: boolean
  retrieved_admin_credentials?: boolean
  provisioned_non_test_user?: boolean
  enabled_test_connection?: boolean
  has_external_attempted_origins?: boolean
  has_allowed_origins?: boolean
  use_credential_connectors?: boolean
  use_sync_connectors?: boolean
}

export function onPlatformStatusUpdate(
  credentials: TokenCredentials,
  onUpdate: (status: PlatformSetupStatus) => void
): void {
  const socket = createSocket(credentials)
  const channel = socket.channel('platform_setup_status')
  channel.on('status_update', ({ status }) =>
    onUpdate(status as PlatformSetupStatus)
  )

  channel
    .join()
    .receive('ok', ({ status }) => onUpdate(status as PlatformSetupStatus))
    .receive('error', ({ reason }) =>
      console.error(`Failed to join channel: ${String(reason)}`)
    )
    .receive('timeout', () => console.error('Timeout while joining channel'))
}

export function statusProgress(status?: PlatformSetupStatus): number | null {
  if (status == null) {
    return null
  }

  // We use the setup status as a hack to get the legacy credential connector status, so remove it from
  // progress.
  const entries = Object.entries(status).filter(
    ([step, _isComplete]) => step !== 'use_credential_connectors'
  )

  if (entries.length === 0) {
    return null
  }

  const completedEntries = entries.filter(
    ([step, isComplete]) => isComplete === true
  )
  return completedEntries.length / entries.length
}
