import React, { memo, FC } from 'react'
import { Table, Spinner } from 'evergreen-ui'

const TableSpinner: FC = memo(() => {
  return (
    <Table.Row minHeight={240}>
      <Table.Cell display='flex' alignItems='center' justifyContent='center'>
        <Spinner />
      </Table.Cell>
    </Table.Row>
  )
})

TableSpinner.displayName = 'TableSpinner'

export default TableSpinner
