// thx: https://fettblog.eu/typescript-hasownproperty/
export function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> {
  if (obj == null) {
    return false
  }
  return Object.prototype.hasOwnProperty.call(obj, prop)
}
