import React, { FC, memo } from 'react'
import ConnectorMark from './connector-mark'
import ConnectorLabel from './connector-label'
import {
  Heading,
  Pane,
  Paragraph,
  majorScale,
  Theme,
  // TODO: please fix following typescript error
  // @ts-expect-error
  withTheme,
  CardProps
} from 'evergreen-ui'
// TODO: please fix following typescript error
// @ts-expect-error
import LinesEllipsis from 'react-lines-ellipsis'
import { Link } from 'react-router-dom'
import { Connector } from '../../api/connector'
import { Template } from '../../api/template'
import { ContentCard } from './content-card'

type ConnectorThumbProps = CardProps & {
  linkTo: string
  connector: Connector | Template
  theme: Theme
}

const ConnectorThumb: FC<ConnectorThumbProps> = memo(
  ({
    linkTo,
    connector,
    theme,
    connector: { name, short_description: shortDescription, mark_url: markUrl },
    ...cardProps
  }) => {
    return (
      <ContentCard
        elevation={1}
        hoverElevation={3}
        display='flex'
        flexDirection='column'
        padding={majorScale(2)}
        is={Link}
        textDecoration='none'
        to={linkTo}
        {...cardProps}
      >
        <Pane display='flex'>
          <Pane flexGrow={1}>
            <ConnectorMark markUrl={markUrl} size={majorScale(5)} />
          </Pane>
          <ConnectorLabel connector={connector} />
        </Pane>
        <Heading
          size={600}
          marginTop={majorScale(1)}
          textOverflow='ellipsis'
          overflow='hidden'
        >
          {name}
        </Heading>
        <Paragraph is='div' size={300} marginTop={majorScale(1)}>
          <LinesEllipsis
            text={shortDescription ?? ''}
            maxLine={2}
            ellipsis='...'
            trimRight
            basedOn='words'
          />
        </Paragraph>
      </ContentCard>
    )
  }
)
ConnectorThumb.displayName = 'ConnectorThumb'

export default withTheme(ConnectorThumb)
