import React, { FC, memo, useCallback } from 'react'
import { Select } from 'evergreen-ui'
import { FilterFieldProps, getFilter, updateFilter } from './filter-field'
import { FilterOverrideField } from './filter-override'
import { HistoryCountFilterOverrideField } from './history-count-filter-override-field'

const AUTHOR_TYPE_PROPERTY = 'author_type'
const CONVERSATION_PART_TYPE_PROPERTY = 'conversation_part_type'

// UI for the supported Intercom Conversation Part filters
export const IntercomConversationPartsFilterField: FC<FilterFieldProps> = memo(
  ({ sourceFilters, syncFilters, filterErrors, onChange }) => {
    const handleChange = useCallback(
      (e, propertyName) => {
        const updatedFilters = updateFilter(
          syncFilters ?? sourceFilters,
          propertyName,
          'eq',
          e.target.value
        )
        onChange(updatedFilters)
      },
      [sourceFilters, syncFilters, onChange]
    )

    const authorType = getFilter(
      sourceFilters,
      syncFilters,
      filterErrors,
      AUTHOR_TYPE_PROPERTY
    )

    const conversationPartType = getFilter(
      sourceFilters,
      syncFilters,
      filterErrors,
      CONVERSATION_PART_TYPE_PROPERTY
    )

    return (
      <>
        <FilterOverrideField
          propertyName={AUTHOR_TYPE_PROPERTY}
          sourceFilters={sourceFilters}
          syncFilters={syncFilters}
          onChange={onChange}
          label='Conversation part author'
          description="Filter the parts by the author's type"
          validationMessage={authorType.validationMessage}
        >
          <Select
            name={AUTHOR_TYPE_PROPERTY}
            isInvalid={authorType.isInvalid}
            disabled={authorType.disabled}
            value={authorType.isEmpty ? '' : authorType.value}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              handleChange(event, AUTHOR_TYPE_PROPERTY)
            }
          >
            <option value=''>Any</option>
            <option value='admin'>Admin (team member)</option>
            <option value='contact'>Lead or User (customers)</option>
          </Select>
        </FilterOverrideField>
        <FilterOverrideField
          propertyName={CONVERSATION_PART_TYPE_PROPERTY}
          sourceFilters={sourceFilters}
          syncFilters={syncFilters}
          onChange={onChange}
          label='Conversation part type'
          description='Filter the parts by type'
          validationMessage={conversationPartType.validationMessage}
        >
          <Select
            name={CONVERSATION_PART_TYPE_PROPERTY}
            isInvalid={conversationPartType.isInvalid}
            value={
              conversationPartType.isEmpty ? '' : conversationPartType.value
            }
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              handleChange(event, CONVERSATION_PART_TYPE_PROPERTY)
            }
          >
            <option value=''>Any</option>
            <option value='comment'>Comments Only</option>
          </Select>
        </FilterOverrideField>
        <HistoryCountFilterOverrideField
          syncFilters={syncFilters}
          sourceFilters={sourceFilters}
          filterErrors={filterErrors}
          onChange={onChange}
          label='Historical conversation parts limit'
          description='The number of historical conversation parts to sync'
        />
      </>
    )
  }
)

IntercomConversationPartsFilterField.displayName =
  'IntercomConversationPartsFilterField'
