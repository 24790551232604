import React, { FC, memo } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { Connector } from '../../api/connector'
import { ConnectionsTable } from './connections-table'
import Connection from './connection'

interface ConnectionsTabProps {
  loading: boolean
  connector?: Connector
}

export const ConnectionsTab: FC<ConnectionsTabProps> = memo(
  ({ loading, connector }) => {
    const { path } = useRouteMatch()

    return (
      <Switch>
        <Route path={`${path}/`} exact>
          <ConnectionsTable loading={loading} connector={connector} />
        </Route>
        <Route
          path={`${path}/:connectionId`}
          render={({ match }) => <Connection id={match.params.connectionId} />}
        />
      </Switch>
    )
  }
)

ConnectionsTab.displayName = 'ConnectionsTab'
