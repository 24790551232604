import * as React from 'react'
import { toaster } from 'evergreen-ui'
import { Redirect } from 'react-router-dom'
import { BlockedTextInputField } from './with-no-recording'
import LoginForm from './login-form'
import { resetPassword } from '../api/user'
import { CredentialConsumer } from './login-wrapper'
import errorMessage from '../util/error-message'

interface ResetPasswordProps {
  // TODO: please fix following typescript error
  // @ts-expect-error
  onReset: (credentials: TokenCredentials) => void
}

interface PasswordError {
  field: 'password'
  error: string
}

interface ResetPasswordState {
  password: string
  passwordError?: PasswordError
  confirm: string
  confirmError?: string
  loading: boolean
  hasReset: boolean
}

class ResetPassword extends React.Component<
  CredentialConsumer<ResetPasswordProps>,
  ResetPasswordState
> {
  // TODO: please fix following typescript error
  // @ts-expect-error
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      confirm: '',
      loading: false,
      hasReset: false
    }
  }

  componentDidMount(): void {
    this.setState({ hasReset: false })
  }

  handleReset = async (): Promise<void> => {
    const { password, confirm } = this.state

    if (password !== confirm) {
      this.setState({ confirmError: 'Passwords do not match' })
      return
    }

    this.setState({ loading: true, confirmError: undefined })

    try {
      // TODO: fix types
      // @ts-expect-error
      const { token, errors } = await this.props.callWithCredentials(
        async (creds) => await resetPassword(creds, { password })
      )
      if (errors || !token) {
        if (!errors || !errors.length) {
          throw new Error('Validation failed without a response')
        }
        // TODO: please fix following typescript error
        // @ts-expect-error
        const passwordError = errors.find(({ field }) => field === 'password')
        if (!passwordError) {
          throw new Error('Validation failed for an unknown field')
        }
        this.setState({
          passwordError,
          loading: false
        })
      } else {
        this.setState({
          passwordError: undefined,
          loading: false,
          hasReset: true
        })
        this.props.onReset({ token })
      }
    } catch (e) {
      if (e.statusCode === 401) {
        toaster.danger('Invalid credentials used in reset')
      } else {
        toaster.danger(`Error resetting password: ${errorMessage(e)}`)
      }
      this.setState({ loading: false })
    }
  }

  handleUpdate = (field: 'password' | 'confirm', value: string): void => {
    const otherField = field === 'password' ? 'confirm' : 'password'
    const otherValue = this.state[otherField]
    if (value === otherValue) {
      // remove the error as soon as they match
      this.setState({ confirmError: undefined })
    }
    // TODO: please fix following typescript error
    // @ts-expect-error
    this.setState({ [field]: value })
  }

  render(): React.ReactNode {
    const {
      loading,
      password,
      passwordError,
      confirm,
      confirmError,
      hasReset
    } = this.state

    if (hasReset) {
      return <Redirect to='/' />
    }

    return (
      <LoginForm
        isLoading={loading}
        submitLabel='Reset Password'
        onSubmit={this.handleReset}
      >
        {({ ...inputProps }) => (
          <>
            <BlockedTextInputField
              {...inputProps}
              type='password'
              label='New Password'
              autoComplet='new-password'
              value={password}
              isInvalid={Boolean(passwordError)}
              validationMessage={
                passwordError
                  ? `New password ${passwordError.error}`
                  : undefined
              }
              // TODO: please fix following typescript error
              // @ts-expect-error
              onChange={(e) => this.handleUpdate('password', e.target.value)}
            />
            <BlockedTextInputField
              {...inputProps}
              type='password'
              label='Confirm Password'
              autoComplet='new-password'
              value={confirm}
              isInvalid={Boolean(confirmError)}
              validationMessage={confirmError}
              // TODO: please fix following typescript error
              // @ts-expect-error
              onChange={(e) => this.handleUpdate('confirm', e.target.value)}
            />
          </>
        )}
      </LoginForm>
    )
  }
}

export default ResetPassword
