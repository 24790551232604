import React, { memo, FunctionComponent } from 'react'
import ContentSection from '../content-section'
import {
  ServiceProviderForm,
  ServiceProviderFormProps
} from './service-provider-form'

export interface ServiceProviderTabProps extends ServiceProviderFormProps {
  title?: string
}

export const ServiceProviderTab: FunctionComponent<ServiceProviderTabProps> =
  memo(({ title, ...formProps }) => {
    return (
      <ContentSection title={title ?? ''}>
        <ServiceProviderForm {...formProps} />
      </ContentSection>
    )
  })
ServiceProviderTab.displayName = 'ServiceProviderTab'
