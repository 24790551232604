import { useCallback, useContext, useEffect, useState } from 'react'
import {
  listSyncsByConnection,
  listSyncsByConnector,
  listSyncsByPipeline,
  BasicSync
} from '../../api/sync'
import { TokenCredentials } from '../../api/request'
import { CredentialsContext } from '../login-wrapper'

interface UseSyncsState {
  syncs: BasicSync[]
  loading: boolean
  error: string | undefined
  refresh: () => Promise<void>
}

type FetchSyncs = (
  creds: TokenCredentials,
  param: string
) => Promise<BasicSync[]>

const useSyncs = (fetch: FetchSyncs, param?: string): UseSyncsState => {
  const { callWithCredentials } = useContext(CredentialsContext)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [syncs, setSyncs] = useState<BasicSync[]>([])

  const refresh = useCallback(async () => {
    try {
      setLoading(true)
      if (param == null) {
        return
      }
      const syncs = await callWithCredentials(
        async (creds) => await fetch(creds, param)
      )
      setSyncs(syncs)
    } catch (e) {
      console.error(e)
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }, [callWithCredentials, param, fetch])

  useEffect(() => {
    void refresh()
  }, [refresh])

  return {
    syncs,
    loading,
    error,
    refresh
  }
}

export const useSyncsByConnection = (connectionId?: string): UseSyncsState => {
  return useSyncs(listSyncsByConnection, connectionId)
}

export const useSyncsByConnector = (connectorSlug?: string): UseSyncsState => {
  return useSyncs(listSyncsByConnector, connectorSlug)
}

export const useSyncsByPipeline = (pipelineUuid?: string): UseSyncsState => {
  return useSyncs(listSyncsByPipeline, pipelineUuid)
}
