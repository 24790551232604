import { BackButton, majorScale, Menu, Pane, toaster } from 'evergreen-ui'
import React, { FC, memo, useCallback, useMemo, useState } from 'react'
import { Link, Redirect, useRouteMatch } from 'react-router-dom'
import { getAuthorizerFields } from '../authorizer-form'
import { HeadingTitle } from '../components/heading-title'
import { MoreMenu } from '../components/more-menu'
import { TabRoute, TabRoutes } from '../components/tab-routes'
import CatalogTab from '../connector/catalog-tab'
import { ConnectorHeading } from '../connector/connector-heading'
import { ConnectorHeadingSkeleton } from '../connector/connector-heading-skeleton'
import { CrmEmbedTab } from '../connector/crm-embed-tab'
import DeveloperAccountConnectTab from '../connector/developer-account-connect'
import { PermissionsTab } from '../connector/permissions-tab'
import { ServiceProviderTab } from '../connector/service-provider-tab'
import DeleteDialog from '../delete-dialog'
import { useAuthorizer } from '../hooks/authorizer.hook'
import { useErrorToaster, useTitle } from '../hooks/common'
import { useConnector } from '../hooks/connector.hook'
import { usePlatform } from '../hooks/platform.hook'
import { ConnectionsTab } from './connections-tab'
import { PipelinesList } from './pipelines-list'

const enum ProviderTabType {
  Catalog = 'catalog',
  ServiceProvider = 'service-provider',
  Permissions = 'permissions',
  Connections = 'connections',
  Pipelines = 'pipelines',
  DeveloperAccount = 'developer-account',
  CrmEmbed = 'crm-embed'
}

interface ProviderTab extends TabRoute {
  visible: boolean
}

interface ProviderProps {
  crmOnlyConnectors?: boolean
}

export const Provider: FC<ProviderProps> = memo(({ crmOnlyConnectors }) => {
  const providerNoun = crmOnlyConnectors ? 'CRM' : 'Provider'
  const providersNoun = crmOnlyConnectors ? 'CRMs' : 'Providers'
  const routeMatch = useRouteMatch<{ slug: string }>()
  const { slug } = routeMatch.params
  const {
    connector,
    loading: connectorLoading,
    error: connectorError,
    updateFx: updateConnectorFx,
    deleteFx: deleteConnectorFx
  } = useConnector(slug)
  useErrorToaster(connectorError)
  useTitle(
    connector?.name != null
      ? `Edit ${connector.name} ${providerNoun}`
      : undefined
  )

  const { platform, platformLoading, platformError } = usePlatform()
  useErrorToaster(platformError)

  const { authorizer, updateAuthorizerFx, authorizerLoading, authorizerError } =
    useAuthorizer({
      slug: connector?.authorizer_prototype_slug
    })
  useErrorToaster(authorizerError)

  const [deleted, setDeleted] = useState(false)

  const handleDelete = useCallback(async () => {
    await deleteConnectorFx()
    toaster.success(`Deleted ${providerNoun}`)
    setDeleted(true)
  }, [deleteConnectorFx, providerNoun])

  const connectionsProviderTab = useMemo<ProviderTab>(
    () => ({
      slug: ProviderTabType.Connections,
      title: 'Connections',
      loading: false,
      visible: true,
      content: (
        <ConnectionsTab connector={connector} loading={connectorLoading} />
      )
    }),
    [connector, connectorLoading]
  )

  const pipelinesProviderTab = useMemo<ProviderTab>(
    () => ({
      slug: ProviderTabType.Pipelines,
      title: 'Pipelines',
      loading: connectorLoading,
      // Don't show this tab for authorization only providers
      visible: connector ? connector.sync : false,
      content: (
        <PipelinesList connector={connector} loading={connectorLoading} />
      )
    }),
    [connector, connectorLoading]
  )

  const catalogTab = useMemo<ProviderTab>(
    () => ({
      slug: ProviderTabType.Catalog,
      title: crmOnlyConnectors ? 'Setup' : 'Catalog',
      visible: true,
      loading: false,
      content: (
        <CatalogTab
          loading={connectorLoading}
          connector={connector}
          update={updateConnectorFx}
          // TODO: please fix following typescript error
          // @ts-expect-error
          platform={platform}
        />
      )
    }),
    [
      platform,
      connector,
      updateConnectorFx,
      connectorLoading,
      crmOnlyConnectors
    ]
  )

  const serviceProviderTab = useMemo<ProviderTab>(
    () => ({
      slug: ProviderTabType.ServiceProvider,
      title: crmOnlyConnectors ? 'Credentials' : 'Service Provider',
      loading: authorizerLoading || platformLoading,
      visible: authorizer != null && getAuthorizerFields(authorizer).length > 0,
      content: (
        <ServiceProviderTab
          isCRMConnector={connector?.crm}
          platform={platform}
          authorizer={authorizer}
          updateAuthorizer={updateAuthorizerFx}
        />
      )
    }),
    [
      connector?.crm,
      platform,
      platformLoading,
      authorizer,
      authorizerLoading,
      updateAuthorizerFx,
      crmOnlyConnectors
    ]
  )

  const permissionsProviderTab = useMemo<ProviderTab>(
    () => ({
      slug: ProviderTabType.Permissions,
      title: 'Permissions',
      loading: connectorLoading,
      visible: connector != null && connector.template.scopes.length > 0,
      content: (
        <PermissionsTab
          showTitle={false}
          connector={connector}
          updateConnector={updateConnectorFx}
          loading={connectorLoading}
          crmOnlyConnectors={crmOnlyConnectors}
        />
      )
    }),
    [connector, updateConnectorFx, connectorLoading, crmOnlyConnectors]
  )

  const developerAccountTab = useMemo<ProviderTab>(
    () => ({
      slug: ProviderTabType.DeveloperAccount,
      title: 'Developer Account',
      loading: connectorLoading,
      visible: connector?.template.dev_template_slug != null,
      content: (
        <DeveloperAccountConnectTab
          platformSlug={platform?.slug}
          connector={connector}
          loading={connectorLoading || platformLoading}
        />
      )
    }),
    [connector, connectorLoading, platformLoading, platform?.slug]
  )

  const crmEmbedTab = useMemo<ProviderTab>(
    () => ({
      slug: ProviderTabType.CrmEmbed,
      title: 'CRM Embed',
      visible: true,
      loading: connectorLoading,
      content: <CrmEmbedTab loading={connectorLoading} connector={connector} />
    }),
    [connector, connectorLoading]
  )

  const tabs = [
    connectionsProviderTab,
    pipelinesProviderTab,
    catalogTab,
    serviceProviderTab,
    permissionsProviderTab,
    developerAccountTab,
    crmEmbedTab
  ]

  const visibleTabs = tabs.filter((tab) => tab.loading || tab.visible)

  // the slug of the connector changed (was edited by the user)
  // so we should change where we are
  if (connector != null && connector.slug !== slug) {
    return <Redirect to={`/providers/${connector.slug}`} />
  }

  // Failed to load the connector or it is deleted
  if (deleted || (!connectorLoading && connector == null)) {
    return <Redirect to='/providers' />
  }

  let connectorMode = 'auth'
  if (connector?.sync) {
    connectorMode = 'data'
  }
  if (connector?.crm) {
    connectorMode = 'crm'
  }

  const name = connector?.name ?? slug

  return (
    <>
      <Pane marginRight={4} marginLeft={4} display='flex' alignItems='center'>
        <HeadingTitle display='flex' alignItems='center'>
          {connectorLoading ? (
            <ConnectorHeadingSkeleton />
          ) : (
            <>
              <ConnectorHeading connector={connector} />
              <MoreMenu marginLeft={majorScale(1)}>
                <DeleteDialog
                  noun={`${name} ${providerNoun}`}
                  onDelete={handleDelete}
                  dialogText={`
              This will permanently delete the ${name} ${providerNoun}.
              All related users will have their credentials removed from Xkit entirely and irrevocably.
              Use with caution!
            `}
                >
                  {(showDeleteDialog) => {
                    return (
                      <Menu.Item
                        intent='danger'
                        icon='trash'
                        onSelect={showDeleteDialog}
                      >
                        Delete...
                      </Menu.Item>
                    )
                  }}
                </DeleteDialog>
              </MoreMenu>
            </>
          )}
        </HeadingTitle>
      </Pane>
      <TabRoutes tabRoutes={visibleTabs} />
      <BackButton is={Link} to={`/providers?type=${connectorMode}`}>
        Back to {providersNoun}
      </BackButton>
    </>
  )
})

Provider.displayName = 'Provider'
