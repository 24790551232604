import { useCallback, useContext, useEffect, useState } from 'react'
import {
  listConnectionsByConnector,
  PlatformConnection
} from '../../api/connection'
import { Connector } from '../../api/connector'
import { CredentialsContext } from '../login-wrapper'

interface UseConnectionsByConnectorState {
  connections: PlatformConnection[]
  loading: boolean
  error: string | undefined
  refresh: () => Promise<void>
}

export const useConnectionsByConnector = (
  connector?: Connector
): UseConnectionsByConnectorState => {
  const { callWithCredentials } = useContext(CredentialsContext)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [connections, setConnections] = useState<PlatformConnection[]>([])

  const refresh = useCallback(async () => {
    try {
      setLoading(true)
      if (connector?.slug == null) {
        return
      }
      const connections = await callWithCredentials(
        async (credentials) =>
          await listConnectionsByConnector(credentials, connector.slug)
      )
      setConnections(connections)
    } catch (e) {
      console.error(e)
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }, [callWithCredentials, connector?.slug])

  useEffect(() => {
    void refresh()
  }, [refresh])

  return {
    connections,
    loading,
    error,
    refresh
  }
}
