import React, {
  ChangeEvent,
  ElementType,
  FC,
  FormEvent,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react'
import { Button, toaster, majorScale } from 'evergreen-ui'
import errorMessage from '../util/error-message'
import { LegacyChangeError } from '../api/request'

interface RawValues {
  [index: string]: unknown
}

export interface UpdateResult {
  errors?: Array<LegacyChangeError<unknown>>
  values?: RawValues
}

type ErrorMessages = Record<string, string>

export interface FormField {
  name: string
  Field: ElementType
  label: ReactNode
  placeholder?: string
  description?: ReactNode
  hint?: ReactNode
}

export interface FormProps {
  controls?: ReactNode
  fields: FormField[]
  values: RawValues
  loading?: boolean
  saveMessage?: string
  saveLabel?: string
  savingLabel?: string
  update?: (values: RawValues) => Promise<UpdateResult>
}

export const Form: FC<FormProps> = memo(
  ({
    controls,
    fields,
    values: propValues,
    loading,
    saveMessage = 'Saved!',
    saveLabel = 'Save',
    savingLabel = 'Saving',
    update,
    children
  }) => {
    const [values, setValues] = useState<RawValues>({})
    useEffect(() => {
      if (propValues != null) {
        setValues(propValues)
      }
    }, [propValues, setValues])

    const [errorMessages, setErrorMessages] = useState<ErrorMessages>({})
    const [saving, setSaving] = useState(false)

    const handleSave = useCallback(
      async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault()
        event.stopPropagation()
        setSaving(true)

        try {
          // TODO: please fix following typescript error
          // @ts-expect-error
          const { errors, values: updatedValues } = await update?.(values)

          if (errors != null && errors.length > 0) {
            const errorMessages = errors.reduce<ErrorMessages>(
              // TODO: please fix following typescript error
              // @ts-expect-error
              (messages, { field, error }) => {
                messages[field] = error
                return messages
              },
              {}
            )
            setErrorMessages(errorMessages)
          } else {
            setErrorMessages({})
            setValues(updatedValues)
            toaster.success(saveMessage)
          }
        } catch (e) {
          toaster.danger(`Error while saving: ${errorMessage(e)}`)
        } finally {
          setSaving(false)
        }
      },
      [saveMessage, update, values]
    )

    return (
      <form onSubmit={handleSave}>
        {children}
        {fields.map(
          ({ name, Field, label, description, placeholder, hint }) => {
            const value = values[name] == null ? '' : values[name]
            const message = errorMessages[name]
            return (
              <Field
                key={name}
                label={label}
                description={description}
                placeholder={loading ? 'Loading...' : placeholder}
                hint={hint}
                value={value}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setValues({ ...values, [name]: e.target.value })
                }}
                isInvalid={Boolean(message)}
                validationMessage={
                  message != null && (
                    <>
                      {label} {message}
                    </>
                  )
                }
                disabled={loading}
              />
            )
          }
        )}
        <Button
          type='submit'
          appearance='primary'
          isLoading={saving || loading}
          marginRight={controls != null ? majorScale(1) : 0}
        >
          {loading ? 'Loading' : saving ? savingLabel : saveLabel}
        </Button>
        {controls && <>{controls}</>}
      </form>
    )
  }
)
Form.displayName = 'Form'

export default Form
