import {
  BackButton,
  Heading,
  Link as EvergreenLink,
  majorScale,
  ShareIcon,
  Text
} from 'evergreen-ui'
import React, { FC, memo } from 'react'
import { Link } from 'react-router-dom'
import { SpinnerSuspend } from '../components/spinner'
import { useErrorToaster, useTitle } from '../hooks/common'
import { useCRMObjects } from '../hooks/crm-objects'
import { useCredentials } from '../login-wrapper'
import EditCRMObject from './edit-crm-object'

const NewCRMObject: FC = memo(() => {
  const { CRMObjects, loading, error } = useCRMObjects()
  const credentials = useCredentials()
  useErrorToaster(error)
  useTitle('New CRM Object')

  return (
    <>
      <Heading marginBottom={majorScale(2)} size={600}>
        <span style={{ marginRight: majorScale(2) }}>New CRM Object</span>
        <Text size={300} color='muted'>
          <EvergreenLink
            size={300}
            href='https://xkit.co/docs/crm-api#crm-objects'
            target='_blank'
            rel='noopener noreferrer'
          >
            View documentation
            <ShareIcon size={12} marginLeft={4} />
          </EvergreenLink>
        </Text>
      </Heading>
      <SpinnerSuspend spinning={loading || error != null}>
        <EditCRMObject objects={CRMObjects} credentials={credentials} />
      </SpinnerSuspend>
      <BackButton is={Link} to={`/crm-objects`} marginTop={majorScale(3)}>
        Back to CRM Objects
      </BackButton>
    </>
  )
})

NewCRMObject.displayName = 'NewCRMObject'

export default NewCRMObject
