import { compareTwoStrings } from 'string-similarity'

const SIMILARITY_MIN = 0.8

export function applySearchFilter(
  rawContent: string | null | undefined,
  rawQuery: string | null | undefined
): boolean {
  if (!rawQuery || !rawQuery.length) {
    return true
  }

  if (!rawContent || !rawContent.length) {
    return false
  }

  const content = rawContent.toLowerCase()
  const query = rawQuery.toLowerCase()

  return (
    content.includes(query) ||
    compareTwoStrings(content, query) > SIMILARITY_MIN
  )
}
