import React, { FC, memo, useCallback } from 'react'
import { Heading, majorScale, Pane, PaneProps } from 'evergreen-ui'
import { Destination } from '../../api/destination'
import { DestinationCard, DestinationCardProps } from './dashboard-destination'
import { NewItemCard } from '../components/new-item-card'

type Appearance = 'default' | 'primary'

interface DestinationsProps extends PaneProps {
  selected: Destination[]
  destinations: Destination[]
  appearance?: Appearance
  onHover?: (target?: Destination) => void
  refCallback?: DestinationCardProps['refCallback']
}

export const Destinations: FC<DestinationsProps> = memo(
  ({
    appearance = 'default',
    selected,
    destinations,
    onHover,
    refCallback,
    ...paneProps
  }) => {
    const isSelected = useCallback(
      (destination: Destination, selected: Destination[]): boolean => {
        return selected.find((item) => item.uuid === destination.uuid) != null
      },
      []
    )

    return (
      <Pane {...paneProps}>
        <Heading size={500}>Destinations</Heading>
        <Pane marginTop={majorScale(2)}>
          {destinations.map((destination) => (
            <DestinationCard
              key={destination.id}
              destination={destination}
              selected={isSelected(destination, selected)}
              onHover={onHover}
              refCallback={refCallback}
            />
          ))}
          {destinations.length === 0 ? (
            <NewItemCard
              appearance='primary'
              to='/destinations/new'
              title='Add destination'
              body="Where you'll import your customers' data — like an API endpoint."
              minHeight={150}
            />
          ) : (
            <NewItemCard to='/destinations/new' title='Add destination' />
          )}
        </Pane>
      </Pane>
    )
  }
)
Destinations.displayName = 'Destinations'
