import React, { ChangeEvent, FC, memo, useCallback, useMemo } from 'react'
import { majorScale, TextInput, SearchInput } from 'evergreen-ui'
import { JsonPointer } from 'json-ptr'
import { Input } from '../../../api/destination-input'
import {
  Selector,
  findSelector,
  countMatchingDescendants
} from '../../../api/mapping'
import { Connector } from '../../../api/connector'
import { useDebouncedState } from '../../hooks/debounced-state.hook'
import { PopoverContent } from '../../components/popover-content'
import { HeadingSubsection } from '../../components/heading-subsection'
import { ConnectorHeading } from '../../connector/connector-heading'
import { StaticInput } from './static-input'
import { PropertyInput } from './property-input'
import { TextInputPopover } from '../../components/text-input-popover'

export interface SourcePointerValue {
  source_relation_id: number
  pointer: string
}

interface StaticValue {
  value: string | boolean
}

export type DataFieldValue = SourcePointerValue | StaticValue

// Small enough that we can render it quickly
// and it doesn't take up too much screen space.
const AUTO_EXPAND_NODE_COUNT = 20
// Long enough to catch successive key strokes, short
// enough to be imperceptible.
const SEARCH_INPUT_DELAY_MS = 20

interface ContentProps {
  disabled?: boolean
  provider?: Connector
  selectors: Selector[]
  input: Input
  value?: DataFieldValue
  onChange: (args?: DataFieldValue) => void
  close?: () => void
}

const Content: FC<ContentProps> = memo(
  ({ provider, selectors, input, value, close, onChange }) => {
    const [search, setSearch] = useDebouncedState('', SEARCH_INPUT_DELAY_MS)
    const handleSearchChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
      [setSearch]
    )
    const handlePointerChange = useCallback(
      (pointerValue: SourcePointerValue) => {
        onChange(pointerValue)
        close?.()
      },
      [onChange, close]
    )

    const handleStaticChange = useCallback(
      (value: string | boolean) => {
        onChange({ value })
        close?.()
      },
      [onChange, close]
    )

    // Expand the full tree if there aren't many nodes to select
    const expandAll = useMemo(() => {
      const totalMatching = selectors.reduce(
        (count, selector) => count + countMatchingDescendants(selector, search),
        0
      )
      return totalMatching < AUTO_EXPAND_NODE_COUNT
    }, [selectors, search])

    return (
      <PopoverContent title='Select data'>
        <SearchInput
          marginBottom={majorScale(2)}
          placeholder='Search for or enter data'
          onChange={handleSearchChange}
        />
        <StaticInput
          simpleType={input.simple_type}
          value={value && 'value' in value ? value.value : undefined}
          search={search}
          marginBottom={majorScale(2)}
          onChange={handleStaticChange}
        />
        <HeadingSubsection
          marginBottom={majorScale(1)}
          display='flex'
          alignItems='center'
        >
          <ConnectorHeading connector={provider} spaceBetween={majorScale(1)} />
        </HeadingSubsection>
        {selectors.map((selector) => (
          <PropertyInput
            key={selector.label}
            input={input}
            selector={selector}
            expandAll={expandAll}
            search={search}
            onChange={handlePointerChange}
          />
        ))}
      </PopoverContent>
    )
  }
)
Content.displayName = 'Content'

function displayValue(roots: Selector[], dataValue?: DataFieldValue): string {
  if (dataValue == null) {
    return ''
  }

  if ('pointer' in dataValue) {
    if (dataValue.pointer == null || dataValue.source_relation_id == null) {
      return ''
    }

    const rootSelector = findSelector(roots, dataValue.source_relation_id)

    if (rootSelector == null) {
      return ''
    }

    const path = JsonPointer.decode(dataValue.pointer).join('.')

    // Technically, this could be undefined if it was a dynamic node.
    // In practice, all of our root nodes have labels.
    if (rootSelector.label == null) {
      throw new Error('Root selector label is not defined')
    }

    if (path.length) {
      return `${rootSelector.label}: ${path}`
    }

    return rootSelector.label
  }

  if ('value' in dataValue) {
    return dataValue.value.toString()
  }

  return ''
}

export type TransformationFieldDataProps = Omit<ContentProps, 'close'>

export const TransformationDataField: FC<TransformationFieldDataProps> = memo(
  ({ value, selectors, onChange, disabled, ...props }) => {
    if (selectors == null) {
      return (
        <TextInput
          value={displayValue([], value)}
          placeholder='Choose data...'
          disabled
        />
      )
    }

    return (
      <TextInputPopover
        content={({ close }) => (
          <Content
            value={value}
            close={close}
            selectors={selectors}
            onChange={onChange}
            {...props}
          />
        )}
        disabled={disabled}
        value={displayValue(selectors, value)}
        placeholder='Choose data...'
        onClearValue={() => {
          onChange(undefined)
        }}
      />
    )
  }
)
TransformationDataField.displayName = 'TransformationDataField'
