import React, { FC, memo } from 'react'
import {
  Pane,
  Tooltip,
  Position,
  DotIcon,
  TickCircleIcon,
  WarningSignIcon,
  BanCircleIcon,
  IntentTypes,
  IconProps
} from 'evergreen-ui'

interface StatusIconProps {
  intent: IntentTypes
  tooltip?: string
  size?: number
}

type BareStatusIconProps = Omit<IconProps, 'icon' | 'color'> & {
  intent: IntentTypes
}

const BareStatusIcon: FC<BareStatusIconProps> = memo(
  ({ intent, ...iconProps }) => {
    switch (intent) {
      case 'success':
        return <TickCircleIcon color='success' {...iconProps} />
      case 'warning':
        return <WarningSignIcon color='warning' {...iconProps} />
      case 'danger':
        // TODO: better icon/color for danger?
        return <BanCircleIcon color='danger' {...iconProps} />
      default:
        return <DotIcon color='disabled' {...iconProps} />
    }
  }
)

BareStatusIcon.displayName = 'BareStatusIcon'

export const StatusIcon: FC<StatusIconProps> = memo(
  ({ intent, tooltip, size }) => {
    const content = (
      // Tooltip doesn't work without Pane
      <Pane display='flex'>
        <BareStatusIcon intent={intent} title={tooltip} size={size} />
      </Pane>
    )

    if (tooltip != null) {
      return (
        <Tooltip content={tooltip} position={Position.TOP}>
          {content}
        </Tooltip>
      )
    }

    return content
  }
)

StatusIcon.displayName = 'StatusIcon'
