import React, { useEffect, useState, memo, FC } from 'react'
import { toaster } from 'evergreen-ui'
import { hasOwnProperty } from '../../../util/has-own-property'
import { InputParameters } from '../../../api/destination-input'
import { UseDestinationState } from '../../hooks/destination.hook'
import FunctionForm from './function-form'

type EditFunctionProps = UseDestinationState

export const EditFunction: FC<EditFunctionProps> = memo(
  ({ destination, loading, saving, validationErrors, updateDestinationFx }) => {
    const [name, setName] = useState<string>('')
    const [variables, setVariables] = useState<Array<[string, string]>>([])
    const [inputs, setInputs] = useState<InputParameters[]>([])
    const [upsertBody, setUpsertBody] = useState<string | undefined>('')
    const [deleteBody, setDeleteBody] = useState<string | undefined>('')
    useEffect(() => {
      setName(destination?.name ?? '')
      setVariables(Object.entries(destination?.variables ?? {}))
      setInputs(destination?.inputs ?? [])
      setUpsertBody(destination?.upsert_function?.body ?? '')
      setDeleteBody(destination?.delete_function?.body ?? '')
    }, [
      destination?.name,
      destination?.variables,
      destination?.inputs,
      destination?.upsert_function?.body,
      destination?.delete_function?.body
    ])

    const erroredInput = validationErrors?.inputs?.find(
      (inputsError) => Object.keys(inputsError).length
    )
    const inputsError =
      erroredInput?.name?.[0]?.message ??
      erroredInput?.simple_type?.type?.[0]?.message ??
      erroredInput?.simple_type?.format?.[0]?.message

    return (
      <FunctionForm
        loading={loading}
        saving={saving}
        onSubmit={async () => {
          try {
            const result = await updateDestinationFx({
              ...destination,
              name,
              variables: Object.fromEntries(variables),
              inputs: inputs,
              upsert_function: {
                // TODO: please fix following typescript error
                // @ts-expect-error
                ...destination.upsert_function,
                // TODO: please fix following typescript error
                // @ts-expect-error
                body: upsertBody
              },
              delete_function: {
                // TODO: please fix following typescript error
                // @ts-expect-error
                ...destination.delete_function,
                // TODO: please fix following typescript error
                // @ts-expect-error
                body: deleteBody
              }
            })
            if (hasOwnProperty(result, 'errors')) {
              toaster.danger('Failed to save destination')
            } else {
              toaster.success('Saved destination!')
            }
          } catch (e) {}
        }}
        name={name}
        nameError={validationErrors?.name?.[0]?.message}
        setName={setName}
        variables={variables}
        variablesError={validationErrors?.variables?.[0]?.message}
        setVariables={setVariables}
        inputs={inputs}
        inputsError={inputsError}
        setInputs={setInputs}
        // TODO: please fix following typescript error
        // @ts-expect-error
        upsertBody={upsertBody}
        upsertBodyError={validationErrors?.upsert_function?.body?.[0]?.message}
        setUpsertBody={setUpsertBody}
        upsertDependencies={destination?.upsert_function?.dependencies ?? {}}
        // TODO: please fix following typescript error
        // @ts-expect-error
        deleteBody={deleteBody}
        deleteBodyError={validationErrors?.delete_function?.body?.[0]?.message}
        setDeleteBody={setDeleteBody}
        deleteDependencies={destination?.delete_function?.dependencies ?? {}}
      />
    )
  }
)
EditFunction.displayName = 'EditFunction'
