import { useCallback, useContext, useEffect, useState } from 'react'
import {
  Authorizer,
  AuthorizerResultValues,
  getAuthorizer,
  updateAuthorizerValues
} from '../../api/authorizer'
import { CredentialsContext } from '../login-wrapper'

interface UseAuthorizerProps {
  slug?: string
}

interface UseAuthorizerState {
  authorizer: Authorizer | undefined
  authorizerLoading: boolean
  authorizerError: string | undefined
  updateAuthorizerFx: (
    authorizer: Authorizer
  ) => Promise<AuthorizerResultValues>
}

export const useAuthorizer = ({
  slug
}: UseAuthorizerProps): UseAuthorizerState => {
  const [loading, setLoading] = useState(true)
  const [error] = useState<string | undefined>(undefined)
  const [authorizer, setAuthorizer] = useState<Authorizer | undefined>(
    undefined
  )
  const { callWithCredentials } = useContext(CredentialsContext)

  useEffect(() => {
    const loadAuthorizer = async (): Promise<void> => {
      setLoading(true)
      // if we don't have the slug yet, leave us in a loading
      // state until we get one
      if (!slug) {
        return
      }
      try {
        const authorizer = await callWithCredentials(
          async (credentials) => await getAuthorizer(credentials, slug)
        )
        setAuthorizer(authorizer)
      } catch (error) {
        // TODO: handle error
        // setError(error)
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    void loadAuthorizer()
  }, [slug, callWithCredentials])

  const updateAuthorizerFx = useCallback(
    async (params: Authorizer): Promise<AuthorizerResultValues> => {
      const { values, errors } = await callWithCredentials(
        async (credentials) => await updateAuthorizerValues(credentials, params)
      )
      if (!errors && values) {
        setAuthorizer(values)
      }
      return { values, errors }
    },
    [callWithCredentials]
  )

  return {
    authorizer,
    authorizerLoading: loading,
    authorizerError: error,
    updateAuthorizerFx
  }
}
