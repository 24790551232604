import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { Pane, majorScale, useTheme } from 'evergreen-ui'
import { Providers } from './dashboard-providers'
import { Destinations } from './dashboard-destinations'
import { SpinnerSuspend } from '../components/spinner'
import { Destination } from '../../api/destination'
import Logo from '../components/logo'
import { Circle } from '../components/circle'
import { LinesOneToMany } from '../components/lines-one-to-many'
import { HeadingTitle } from '../components/heading-title'
import { useErrorToaster, useTitle } from '../hooks/common'
import { usePipelines } from '../hooks/pipelines.hook'
import { useDestinations } from '../hooks/destinations.hook'
import { useConnectors } from '../hooks/connectors.hook'
import { useForceUpdate } from '../hooks/force-update.hook'
import { Connector } from '../../api/connector'

const CIRCLE_BORDER_WIDTH = 22
const LOGO_SIZE = 40
const TOTAL_CIRCLE_SIZE = LOGO_SIZE + CIRCLE_BORDER_WIDTH * 4

export const Dashboard: FC = memo(() => {
  useTitle('Dashboard')
  const theme = useTheme()

  const {
    connectors: providers,
    loading: providersLoading,
    error: providersError
  } = useConnectors()
  const {
    pipelines,
    loading: pipelinesLoading,
    error: pipelinesError
  } = usePipelines()
  const {
    destinations,
    loading: destinationsLoading,
    error: destinationsError
  } = useDestinations()

  const loading = pipelinesLoading || destinationsLoading || providersLoading
  const error = pipelinesError ?? destinationsError ?? providersError

  useErrorToaster(error)

  const [selectedProviders, setSelectedProviders] = useState<Connector[]>([])
  useEffect(() => {
    setSelectedProviders(providers)
  }, [providers])

  const [selectedDestinations, setSelectedDestinations] = useState<
    Destination[]
  >([])
  useEffect(() => setSelectedDestinations(destinations), [destinations])

  const isPartOfPipeline = useCallback(
    (provider: Connector, destination: Destination): boolean =>
      pipelines.some(
        (pipeline) =>
          pipeline.source.provider.slug === provider.slug &&
          pipeline.destination.id === destination.id
      ),
    [pipelines]
  )

  const providerHoverHandler = useCallback(
    (provider: Connector) => {
      if (provider == null) {
        setSelectedProviders(providers)
        setSelectedDestinations(destinations)
        return
      }
      setSelectedProviders([provider])
      setSelectedDestinations(
        destinations.filter((destination) =>
          isPartOfPipeline(provider, destination)
        )
      )
    },
    [providers, destinations, isPartOfPipeline]
  )

  const destinationHoverHandler = useCallback(
    (destination) => {
      if (destination == null) {
        setSelectedProviders(providers)
        setSelectedDestinations(destinations)
        return
      }
      setSelectedDestinations([destination])
      setSelectedProviders(
        providers.filter((provider) => isPartOfPipeline(provider, destination))
      )
    },
    [providers, destinations, isPartOfPipeline]
  )

  const forceUpdate = useForceUpdate()
  const providerRefs = useRef<{ [slug: string]: HTMLElement }>({})
  const destinationRefs = useRef<{ [id: string]: HTMLElement }>({})

  const providerRefHandler = useCallback(
    (provider: Connector, element: HTMLElement): void => {
      if (element && !Object.values(providerRefs.current).includes(element)) {
        providerRefs.current[provider.slug] = element
        forceUpdate()
      }
    },
    [forceUpdate]
  )

  const destinationRefHandler = useCallback(
    (destination: Destination, element: HTMLElement): void => {
      if (
        element &&
        !Object.values(destinationRefs.current).includes(element)
      ) {
        destinationRefs.current[destination.id] = element
        forceUpdate()
      }
    },
    [forceUpdate]
  )

  const fromProviders = Object.values(providerRefs.current)
  const fromProvidersActive = selectedProviders.map(
    (provider) => providerRefs.current[provider.slug]
  )
  const fromDashed = providers
    .filter(
      (provider) =>
        destinations.find((destination) =>
          isPartOfPipeline(provider, destination)
        ) == null
    )
    .map((provider) => providerRefs.current[provider.slug])

  const toDestinations = Object.values(destinationRefs.current)
  const toDestinationsActive = selectedDestinations.map(
    (destination) => destinationRefs.current[destination.id]
  )
  const toDashed = destinations
    .filter(
      (destination) =>
        providers.find((provider) => isPartOfPipeline(provider, destination)) ==
        null
    )
    .map((destination) => destinationRefs.current[destination.id])

  return (
    <>
      <HeadingTitle>Dashboard</HeadingTitle>
      <SpinnerSuspend spinning={loading}>
        <Pane
          maxWidth={majorScale(144)}
          display='flex'
          justifyContent='space-between'
          position='relative'
        >
          <Providers
            minWidth={200}
            flexGrow={1}
            flexBasis={0}
            providers={providers}
            selected={selectedProviders}
            onHover={providerHoverHandler}
            refCallback={providerRefHandler}
          />
          <Pane
            width={300 + TOTAL_CIRCLE_SIZE}
            display='flex'
            flexGrow={0}
            flexShrink={0}
          >
            <LinesOneToMany
              offsetTop={64}
              width={150}
              to={fromProviders}
              active={fromProvidersActive}
              dashed={fromDashed}
              direction='rtl'
            />
            <Pane
              width={TOTAL_CIRCLE_SIZE}
              display='flex'
              justifyContent='center'
              alignItems='start'
              // To line up the first row of connections to be a straight line
              marginTop={majorScale(8) - TOTAL_CIRCLE_SIZE / 2}
            >
              <Circle
                background={theme.scales.gray[900]}
                display='flex'
                justifyContent='center'
                alignItems='center'
                borderRadius='100%'
                borderWidth={CIRCLE_BORDER_WIDTH}
                borderColor={theme.scales.gray[700]}
                borderStyle='solid'
              >
                <Logo width={LOGO_SIZE} appearance='small' />
              </Circle>
            </Pane>
            <LinesOneToMany
              offsetTop={64}
              width={150}
              to={toDestinations}
              active={toDestinationsActive}
              dashed={toDashed}
              direction='ltr'
            />
          </Pane>
          <Destinations
            minWidth={200}
            flexGrow={1}
            flexBasis={0}
            destinations={destinations}
            selected={selectedDestinations}
            onHover={destinationHoverHandler}
            refCallback={destinationRefHandler}
          />
        </Pane>
      </SpinnerSuspend>
    </>
  )
})
Dashboard.displayName = 'Dashboard'
