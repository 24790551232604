import React, { useEffect, useState, memo } from 'react'
import { Button, Pane, majorScale, toaster, minorScale } from 'evergreen-ui'
import { InputsTable } from '../components/inputs-table'
import { TableCellTextInput } from '../components/table-cell-text-input'
import ContentSection from '../content-section'
import { useCredentials } from '../login-wrapper'
import {
  PlatformContext,
  getTestPlatformContext,
  updateTestPlatformContext
} from '../../api/platform-user'
import errorMessage from '../../util/error-message'

interface Attribute {
  value: {
    name: string
    value: string
  }
  disabled?: boolean
}

function sortAttributes(attributes: Attribute[]): Attribute[] {
  const baseAttributes = attributes.slice(0, 2)
  const customAttributes = attributes.slice(2)
  return baseAttributes.concat(
    customAttributes.sort((a, b) => a.value.name.localeCompare(b.value.name))
  )
}

function attributesFromContext(context: PlatformContext): Attribute[] {
  const baseAttributes: Attribute[] = [
    {
      value: {
        name: 'external_id',
        value: context.external_id
      },
      disabled: true
    },
    {
      value: {
        name: 'external_name',
        value: context.external_name ?? ''
      },
      disabled: true
    }
  ]

  return Object.getOwnPropertyNames(context).reduce((acc, name) => {
    const value = context[name]
    if (name !== 'external_id' && name !== 'external_name' && value != null) {
      const attribute: Attribute = {
        value: {
          name,
          value
        }
      }
      return [...acc, attribute]
    } else {
      return acc
    }
  }, baseAttributes)
}

function contextFromAttributes(
  context: PlatformContext,
  attributes: Attribute[]
): PlatformContext {
  const baseContext: PlatformContext = {
    external_id: context.external_id,
    external_name: context.external_name
  }

  return attributes
    .filter(
      (attribute) =>
        !['external_id', 'external_name'].includes(attribute.value.name)
    )
    .reduce((acc, attribute) => {
      return { ...acc, [attribute.value.name]: attribute.value.value }
    }, baseContext)
}

const TestContextSettings: React.FC = memo(() => {
  const credentials = useCredentials()
  const [initialLoading, setInitialLoading] = useState(true)
  const [updating, setUpdating] = useState(false)
  const [testContext, setTestContext] = useState<PlatformContext | null>(null)
  const [attributes, setAttributes] = useState<Attribute[]>([])

  async function handleSave(): Promise<void> {
    setUpdating(true)
    try {
      if (!credentials) {
        throw new Error('Invalid credentials')
      }

      if (testContext == null) {
        throw new Error(
          `Can't update test context until it has finished loading`
        )
      }

      const res = await updateTestPlatformContext(
        credentials,
        contextFromAttributes(testContext, attributes)
      )

      if ('errors' in res) {
        // TODO: add errors as inline validations
        throw new Error('Invalid attributes')
      }

      setTestContext(res.context)
      toaster.success('Saved test user attributes!')
    } catch (e) {
      toaster.danger(`Failed to update the test user: ${errorMessage(e)}`)
    } finally {
      setUpdating(false)
    }
  }

  useEffect(() => {
    if (!credentials) {
      return
    }

    const fetchTestContext = async (): Promise<void> => {
      setInitialLoading(true)

      try {
        const { context } = await getTestPlatformContext(credentials)
        setTestContext(context)
      } catch (e) {
        toaster.danger(`Failed to get the test user: ${errorMessage(e)}`)
      } finally {
        setInitialLoading(false)
      }
    }

    void fetchTestContext()
  }, [credentials, setTestContext])

  useEffect(() => {
    if (testContext != null) {
      setAttributes(sortAttributes(attributesFromContext(testContext)))
    }
  }, [testContext, setAttributes])

  return (
    <ContentSection
      title='Test Context'
      description='Customize the properties of the Context that is used for testing connectors.'
    >
      <InputsTable<{ name: string; value: string }>
        maxWidth={680}
        disabled={initialLoading || updating}
        inputLabel='Custom Attribute'
        headers={['Attribute name', 'Attribute value']}
        render={(input, onChange) => {
          return (
            <>
              <TableCellTextInput
                disabled={input.disabled}
                isInvalid={input.isInvalid}
                value={input.value.name}
                onChange={(newName) =>
                  onChange({ ...input.value, name: newName })
                }
              />
              <TableCellTextInput
                disabled={input.disabled}
                isInvalid={input.isInvalid}
                value={input.value.value}
                onChange={(newValue) =>
                  onChange({ ...input.value, value: newValue })
                }
              />
            </>
          )
        }}
        newValue={() => {
          return { name: '', value: '' }
        }}
        inputs={attributes}
        setInputs={setAttributes}
      />
      <Pane
        marginTop={majorScale(2)}
        marginBottom={majorScale(2)}
        maxWidth='680px'
        display='flex'
        alignItems='center'
      >
        <Button
          appearance='primary'
          isLoading={updating}
          disabled={initialLoading || updating}
          marginLeft={majorScale(2)}
          marginTop={minorScale(1)}
          onClick={handleSave}
        >
          Save
        </Button>
      </Pane>
    </ContentSection>
  )
})

TestContextSettings.displayName = 'TestContextSettings'

export default TestContextSettings
