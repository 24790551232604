import React, { FC, memo, ReactNode, useState } from 'react'
import {
  Text,
  Card,
  AddIcon,
  majorScale,
  CardProps,
  useTheme,
  Pane
} from 'evergreen-ui'
import { Link } from 'react-router-dom'

type Appearance = 'default' | 'primary'

export type NewItemLinkProps = CardProps & {
  to: string
  appearance?: Appearance
  title: ReactNode
  body?: ReactNode
  button?: ReactNode
}

export const NewItemCard: FC<NewItemLinkProps> = memo(
  ({ to, appearance = 'default', title, body, button, ...cardProps }) => {
    const theme = useTheme()
    const [hovered, setHovered] = useState(false)

    const color =
      appearance === 'primary'
        ? theme.scales.neutral.N9
        : theme.scales.neutral.N7
    const titleColor =
      appearance === 'primary' ? theme.scales.blue.B9 : 'inherit'

    return (
      <Card
        hoverElevation={1}
        padding={majorScale(2)}
        color={color}
        is={Link}
        textDecoration='none'
        to={to}
        border
        borderColor={
          hovered ? theme.scales.neutral.N6 : theme.scales.neutral.N5
        }
        borderStyle='dashed'
        borderWidth={3}
        backgroundColor={
          hovered ? theme.scales.neutral.N2 : theme.scales.neutral.N2
        }
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems={body != null ? 'flex-start' : 'center'}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        {...cardProps}
      >
        <Pane display='flex' alignItems='center' color={titleColor}>
          <AddIcon marginRight={majorScale(1)} size={16} />
          <Text size={500} color={titleColor}>
            {title}
          </Text>
        </Pane>
        {body != null && (
          <Pane marginTop={majorScale(2)}>
            <Text>{body}</Text>
          </Pane>
        )}
      </Card>
    )
  }
)
NewItemCard.displayName = 'NewItemCard'
