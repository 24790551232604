import { TokenCredentials, request } from './request'

export interface Origin {
  id: string | number
  origin: string
}

export async function createOrigin(
  credentials: TokenCredentials,
  origin: string
): Promise<Origin> {
  const { origin: createdOrigin } = await request({
    path: '/origins',
    method: 'POST',
    credentials,
    body: {
      origin: {
        origin
      }
    }
  })

  return createdOrigin as Origin
}

export async function deleteOrigin(
  credentials: TokenCredentials,
  id: string | number
): Promise<void> {
  await request({
    path: `/origins/${id}`,
    method: 'DELETE',
    credentials
  })
}

export async function listOrigins(
  credentials: TokenCredentials
): Promise<Origin[]> {
  const { origins } = await request({
    path: '/origins',
    credentials
  })

  return origins as Origin[]
}
