import { useCallback, useContext, useEffect, useState } from 'react'
import { ChangeError } from '../../api/request'
import {
  getSourceObject,
  SourceObject,
  SourceObjectParams,
  SourceObjectResult,
  upsertSourceObject
} from '../../api/source-object'
import { hasOwnProperty } from '../../util/has-own-property'
import { CredentialsContext } from '../login-wrapper'

export interface UseSourceObjectState {
  loading: boolean
  error?: string
  validationErrors?: ChangeError<SourceObjectParams>
  sourceObject?: SourceObject
  updateFx: (
    params: SourceObjectParams
  ) => Promise<SourceObjectResult | undefined>
}

export const useSourceObject = (
  pipelineUUID: string,
  sourceObjectSlug?: string
): UseSourceObjectState => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>()
  const [validationErrors, setValidationErrors] = useState<
    ChangeError<SourceObjectParams> | undefined
  >()
  const [sourceObject, setSourceObject] = useState<SourceObject | undefined>()
  const { credentials } = useContext(CredentialsContext)

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      if (!sourceObjectSlug) return

      try {
        setLoading(true)
        const result = await getSourceObject(
          credentials,
          pipelineUUID,
          sourceObjectSlug
        )
        if (hasOwnProperty(result, 'source_object')) {
          setSourceObject(result.source_object)
          setError(undefined)
        } else {
          setError(`Unable to fetch Source Object: ${sourceObjectSlug}.`)
        }
      } catch (error) {
        setError(`Error while loading source object: ${String(error.message)}`)
      } finally {
        setLoading(false)
      }
    }

    fetch().catch(console.error)
  }, [credentials, pipelineUUID, sourceObjectSlug])

  const updateFx = useCallback(
    async (params: SourceObjectParams) => {
      try {
        setLoading(true)
        const result = await upsertSourceObject(
          credentials,
          pipelineUUID,
          params
        )
        if (hasOwnProperty(result, 'source_object')) {
          setSourceObject(result.source_object)
          setError(undefined)
          setValidationErrors(undefined)
        } else if (hasOwnProperty(result, 'errors')) {
          setValidationErrors(result.errors)
        }
        return result
      } catch (error) {
        setError(
          `Error while upserting source object: ${String(error.message)}`
        )
      } finally {
        setLoading(false)
      }
    },
    [credentials, pipelineUUID]
  )

  return {
    loading,
    error,
    validationErrors,
    sourceObject,
    updateFx
  }
}
