import { request, UserPassCredentials, TokenCredentials } from './request'
import { identifyFromToken } from '../util/analytics'

export async function login(
  credentials: UserPassCredentials
): Promise<TokenCredentials> {
  const { access_token: accessToken } = await request({
    path: '/sessions',
    method: 'POST',
    credentials
  })

  // TODO: please fix following typescript error
  // @ts-expect-error
  identifyFromToken(accessToken, { email: credentials.username })

  return {
    // TODO: please fix following typescript error
    // @ts-expect-error
    token: accessToken
  }
}

export async function getToken(): Promise<TokenCredentials> {
  const { access_token: accessToken } = await request({
    path: '/sessions/token',
    method: 'POST'
  })

  // TODO: please fix following typescript error
  // @ts-expect-error
  identifyFromToken(accessToken)

  return {
    // TODO: please fix following typescript error
    // @ts-expect-error
    token: accessToken
  }
}

export async function logout(): Promise<void> {
  await request({
    path: '/sessions',
    method: 'DELETE'
  })
}
