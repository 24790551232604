import * as React from 'react'
import logoDark from '../../../static/images/xkit-logo-white.svg'
import logoLight from '../../../static/images/xkit-logo-black.svg'
import logoSmall from '../../../static/images/xkit-logo-small.svg'
import mark from '../../../static/images/xkit-mark.svg'

type Appearance = 'dark' | 'light' | 'small'

function src(appearance: Appearance, markOnly: boolean): string | undefined {
  if (markOnly) {
    return mark
  }

  switch (appearance) {
    case 'dark':
      return logoDark
    case 'light':
      return logoLight
    case 'small':
      return logoSmall
    default:
      return undefined
  }
}

interface LogoProps {
  width: number | string
  appearance?: Appearance
  markOnly?: boolean
}

const Logo: React.FunctionComponent<LogoProps> = ({
  width,
  appearance = 'dark',
  markOnly = false
}) => {
  const style =
    markOnly && typeof width === 'number' ? { marginTop: width * 0.25 } : {}
  return (
    <img
      src={src(appearance, markOnly)}
      width={width}
      style={style}
      alt='Xkit'
    />
  )
}

export default Logo
