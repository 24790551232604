import React, { useEffect } from 'react'
import {
  Button,
  Code,
  ErrorIcon,
  Pane,
  Paragraph,
  SelectField,
  Text,
  TextInputField,
  majorScale
} from 'evergreen-ui'
import SwitchField from '../../switch-field'
import { ContentCard } from '../../components/content-card'
import { BaseProviderForm } from './provider-form'
import { InputsField } from '../functions/inputs-field'

const SalesforceForm: BaseProviderForm = ({
  loading,
  saving,
  name,
  setName,
  nameError,
  inputs,
  setInputs,
  inputsError,
  config,
  setConfig,
  configError,
  onSubmit
}) => {
  const operation = config.operation ?? 'upsert'

  useEffect(() => {
    setConfig({ resource: 'sobject', operation: operation })
  }, [setConfig, operation])

  const isConfigInvalid = configError != null

  function isRelationshipEnabled(): boolean {
    return config.relationship_enabled === 'true'
  }

  return (
    <ContentCard
      elevation={1}
      padding={majorScale(2)}
      marginBottom={majorScale(2)}
    >
      <Pane maxWidth={640}>
        <TextInputField
          label='Name'
          description='The name of your destination'
          placeholder={loading ? 'Loading...' : 'My Destination'}
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setName(e.target.value)
          }
          onKeyDown={(e: React.KeyboardEvent) =>
            e.keyCode === 13 ? onSubmit() : null
          }
          isInvalid={nameError != null}
          validationMessage={nameError != null ? `Name ${nameError}` : null}
          disabled={saving}
        />
        <SelectField
          label='Operation'
          description='Whether to create new objects or only update existing ones'
          value={config.operation ?? 'upsert'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setConfig({ ...config, operation: e.target.value })
          }
          isInvalid={isConfigInvalid}
          disabled={saving}
        >
          <option value='upsert'>Create or update</option>
          <option value='update'>Update only</option>
        </SelectField>
        <TextInputField
          label='Object'
          description='The API name of the object'
          placeholder='My_Object__c'
          value={config.sobject_name ?? config.sobject_api_name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setConfig({ ...config, sobject_name: e.target.value })
          }
          isInvalid={isConfigInvalid}
          disabled={saving}
        />
        <TextInputField
          label='Identifying field'
          description='The API name of the field that uniquely identifies the object'
          placeholder='Id'
          hint={
            <Text size={300} color='muted'>
              You can use the built-in <Code fontSize='10px'>Id</Code> field or
              a custom{' '}
              <a
                href='https://help.salesforce.com/s/articleView?id=000325076&type=1'
                target='_blank'
                rel='noreferrer'
              >
                external ID
              </a>
              .
            </Text>
          }
          value={config.id_field_name ?? config.external_id_field_name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setConfig({ ...config, id_field_name: e.target.value })
          }
          isInvalid={isConfigInvalid}
          disabled={saving}
        />
        <SwitchField
          label='Use relationships'
          description='The object has a parent relationship to another object in Salesforce'
          checked={isRelationshipEnabled()}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setConfig({
              ...config,
              relationship_enabled: String(e.target.value)
            })
          }
        />
        <TextInputField
          label='Relationship field'
          description='The API name of the field on the child object that holds the relationship'
          placeholder='Other_Object__c'
          hint={
            <Text size={300} color='muted'>
              This is usually the same as the name of the parent object.
            </Text>
          }
          value={config.relationship_field_name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setConfig({ ...config, relationship_field_name: e.target.value })
          }
          isInvalid={isConfigInvalid}
          disabled={!isRelationshipEnabled() || saving}
        />
        <TextInputField
          label='Relationship external ID field'
          description='The API name of the field on the child object that holds the external ID of the parent'
          placeholder='Other_Object_ID__c'
          value={config.relationship_id_field_name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setConfig({ ...config, relationship_id_field_name: e.target.value })
          }
          isInvalid={isConfigInvalid}
          disabled={!isRelationshipEnabled() || saving}
        />
        <TextInputField
          label='Relationship parent object'
          description='The API name of the parent object'
          placeholder='Other_Object__c'
          value={config.relationship_sobject_name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setConfig({ ...config, relationship_sobject_name: e.target.value })
          }
          isInvalid={isConfigInvalid}
          disabled={!isRelationshipEnabled() || saving}
        />
        <TextInputField
          label='Relationship parent external ID'
          description='The API name of the field on the parent object that holds the external ID of the parent'
          placeholder='External_ID__c'
          value={config.relationship_sobject_id_field_name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setConfig({
              ...config,
              relationship_sobject_id_field_name: e.target.value
            })
          }
          isInvalid={isConfigInvalid}
          disabled={!isRelationshipEnabled() || saving}
        />
        {isConfigInvalid && (
          <Pane
            display='flex'
            marginTop={-majorScale(1)}
            marginBottom={majorScale(2)}
          >
            <ErrorIcon color='danger' marginTop={1} size={14} marginRight={8} />
            <Paragraph marginTop={0} size={300} color='danger' role='alert'>
              {configError}
            </Paragraph>
          </Pane>
        )}
        <InputsField
          disabled={saving || loading}
          inputs={inputs}
          setInputs={setInputs}
          inputsError={inputsError}
        />
        <Button
          appearance='primary'
          isLoading={saving || loading}
          onClick={onSubmit}
        >
          {loading ? 'Loading...' : saving ? 'Saving...' : 'Save'}
        </Button>
      </Pane>
    </ContentCard>
  )
}

SalesforceForm.displayName = 'SalesforceForm'

export default SalesforceForm
