export async function readJSONFile(
  file: File
): Promise<{ [index: string]: unknown }> {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      try {
        // TODO: please fix following typescript error
        // @ts-expect-error
        const data = JSON.parse(e.target.result)
        resolve(data)
      } catch (e) {
        reject(e)
      }
    }
    reader.onabort = () => {
      reject(new Error('File read aborted'))
    }
    reader.onerror = (err) => {
      reject(err)
    }
    reader.readAsText(file)
  })
}
