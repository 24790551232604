import React, { FC, useState, useEffect, memo, useCallback } from 'react'
import { Pane, Button, toaster } from 'evergreen-ui'
import { Filter } from '../../api/filter'
import { UseRootObjectState } from '../hooks/root-object.hook'
import { FilterField } from '../connector/filters/filter-field'
export { hasFilters } from '../connector/filters/filter-field'

export interface FilterFormProps {
  sourceTemplateSlug?: string
  selectedRootObject?: UseRootObjectState['selectedRootObject']
  rootObjectErrors?: UseRootObjectState['errors']
  updateRootObject: UseRootObjectState['updateFx']
  loading: boolean
}

export const FilterForm: FC<FilterFormProps> = memo(
  ({
    loading,
    sourceTemplateSlug,
    selectedRootObject,
    rootObjectErrors,
    updateRootObject
  }) => {
    const [saving, setSaving] = useState(false)
    const [filters, setFilters] = useState<Filter[]>([])
    useEffect(() => {
      setFilters(selectedRootObject?.filters ?? [])
    }, [selectedRootObject?.filters])

    const handleFiltersChange = useCallback(
      (filters: Filter[]) => {
        // Filters at the source level don't have an `is_empty` property,
        // those filters should not exist at all
        setFilters(filters.filter((filter) => filter.is_empty !== true))
      },
      [setFilters]
    )

    const handleSave = useCallback(async (): Promise<void> => {
      setSaving(true)

      try {
        const success = await updateRootObject({
          filters,
          // TODO: please fix following typescript error
          // @ts-expect-error
          source_object_id: selectedRootObject?.id
        })
        if (success) {
          toaster.success(`Saved object filters!`)
        } else {
          toaster.danger(`Failed to save object filters`)
        }
      } catch (e) {
        console.error(e)
        toaster.danger(
          `Error while saving object filters: ${String(e.message)}`
        )
      } finally {
        setSaving(false)
      }
    }, [selectedRootObject?.id, filters, updateRootObject])

    if (selectedRootObject == null) {
      return null
    }

    return (
      <Pane maxWidth={400}>
        <FilterField
          sourceTemplateSlug={sourceTemplateSlug}
          rootObjectName={selectedRootObject?.slug}
          filterErrors={rootObjectErrors?.filters}
          sourceFilters={filters}
          onChange={handleFiltersChange}
        />
        <Button
          appearance='primary'
          isLoading={saving || loading}
          onClick={handleSave}
        >
          {loading ? 'Loading' : saving ? 'Saving' : 'Save'}
        </Button>
      </Pane>
    )
  }
)

FilterForm.displayName = 'FilterForm'
