import { useCallback, useContext, useEffect, useState } from 'react'
import { AppObject, listAppObjects } from '../../api/crm'
import errorMessage from '../../util/error-message'
import { CredentialsContext } from '../login-wrapper'

interface UseAppObjectState {
  loading: boolean
  error?: string
  appObject: AppObject | undefined
  refresh: () => Promise<void>
}

export const useAppObject = (id: string): UseAppObjectState => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const [appObject, setAppObject] = useState<AppObject | undefined>(undefined)
  const { callWithCredentials } = useContext(CredentialsContext)

  const refresh = useCallback(async () => {
    const fetch = async (): Promise<void> => {
      setLoading(true)
      try {
        const objects = await callWithCredentials(listAppObjects)
        const object = objects.find((object) => `${object.id}` === id)
        if (object) {
          setAppObject(object)
          setError(undefined)
        } else {
          setError(`Could not find App Object with ID ${id}`)
        }
      } catch (e) {
        setError(`Error while loading App Objects: ${errorMessage(e)}`)
      } finally {
        setLoading(false)
      }
    }

    fetch().catch(console.error)
  }, [callWithCredentials, id])

  useEffect(() => {
    void refresh()
  }, [refresh])

  return {
    loading,
    error,
    appObject,
    refresh
  }
}
