import React, { FC, memo } from 'react'
import { Badge, BadgeProps } from 'evergreen-ui'
import { Connector, ConnectorSlim } from '../../api/connector'
import { Template } from '../../api/template'
import { hasOwnProperty } from '../../util/has-own-property'

// See: https://evergreen.segment.com/components/badge-and-pill
// We don't want to use colors that have real meaning, like danger, success, etc
type LabelColor = 'neutral' | 'blue' | 'orange' | 'purple' | 'teal'
const LABEL_COLORS: LabelColor[] = [
  'neutral',
  'blue',
  'orange',
  'purple',
  'teal'
]

// We want a deterministic way to pick a badge color so the same
// labels have the same colors (even across reloads) but don't
// want to waste time with storing the color
function textToColor(label: string): LabelColor {
  // this is a weird way to do this (summing the char codes of the letters)
  // but... best idea I had?
  const charCodeSum = label
    .toUpperCase()
    .split('')
    .reduce((sum, letter) => {
      return sum + letter.charCodeAt(0)
    }, 0)

  // TODO: please fix following typescript error
  // @ts-expect-error
  const lastDigit = parseInt(charCodeSum.toString().split('').pop(), 10)

  // 10 possible digits, 0-9
  const labelIndex = Math.floor(lastDigit / (10 / LABEL_COLORS.length))
  return LABEL_COLORS[labelIndex]
}

function getLabel(
  connOrTempl?: ConnectorSlim | Partial<Connector> | Connector | Template
): string | null {
  if (connOrTempl == null) {
    return null
  }

  if (
    hasOwnProperty(connOrTempl, 'admin_label') &&
    typeof connOrTempl.admin_label === 'string'
  ) {
    return connOrTempl.admin_label
  } else {
    return null
  }
}

interface ConnectorLabelProps {
  connector?: ConnectorSlim | Partial<Connector> | Connector | Template
}

const ConnectorLabel: FC<ConnectorLabelProps & BadgeProps> = memo(
  ({ connector, ...badgeProps }) => {
    const label = getLabel(connector)
    if (label == null) {
      return null
    }

    return (
      <Badge color={textToColor(label)} {...badgeProps}>
        {label}
      </Badge>
    )
  }
)
ConnectorLabel.displayName = 'ConnectorLabel'

export default ConnectorLabel
