import React, { ChangeEvent } from 'react'
import ConnectorThumb from '../components/connector-thumb'
import { Pane, Spinner, SearchInput, majorScale } from 'evergreen-ui'
import { Connector } from '../../api/connector'
import { Template } from '../../api/template'
import { applySearchFilter } from '../../util/apply-search-filter'
import { throttle } from '../../util/throttle'

interface ConnectorsListProps {
  searchPlaceholder: string
  connectors: Connector[] | Template[]
  loading: boolean
  slugLinkPrefix: string
}

interface ConnectorsListState {
  search: string
  searchWidth: string | number
}

class ConnectorsList extends React.Component<
  ConnectorsListProps,
  ConnectorsListState
> {
  gridRef: React.RefObject<HTMLDivElement>

  constructor(props: ConnectorsListProps) {
    super(props)

    this.state = {
      search: '',
      searchWidth: '100%'
    }

    this.gridRef = React.createRef()
  }

  componentDidMount(): void {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
    document.title = 'Installed Connectors | Xkit'
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = throttle((): void => {
    this.setState({ searchWidth: this.calcSearchWidth() })
  }, 200)

  calcSearchWidth(): number | string {
    // TODO: consolidate this with ConnectorThumb
    const gridWidth = majorScale(32)
    const gridMargin = majorScale(3)

    if (
      !this.gridRef ||
      !this.gridRef.current ||
      !this.gridRef.current.offsetWidth
    ) {
      return '100%'
    }

    const gridItems = Math.floor(
      (this.gridRef.current.offsetWidth + gridMargin) / (gridWidth + gridMargin)
    )

    return gridItems * (gridWidth + gridMargin) - gridMargin
  }

  renderContent(): React.ReactNode {
    const { connectors, loading, slugLinkPrefix, children } = this.props
    const { search, searchWidth } = this.state

    if (loading) {
      return (
        <Pane
          display='flex'
          alignItems='center'
          justifyContent='center'
          maxWidth='100%'
          width={searchWidth}
          height={150}
        >
          <Spinner />
        </Pane>
      )
    }

    // TODO: remove any once it is fixed on TS side
    // Reference -> https://github.com/microsoft/TypeScript/issues/44373
    const filteredConnectors: typeof connectors = (connectors as any).filter(
      (connector: Connector | Template) => {
        return (
          applySearchFilter(connector.name, search) ||
          applySearchFilter(connector.slug, search)
        )
      }
    )

    return (
      <>
        {(filteredConnectors as any).map((connector: Connector | Template) => {
          return (
            <ConnectorThumb
              marginRight={majorScale(3)}
              marginBottom={majorScale(3)}
              width={majorScale(32)}
              height={150}
              key={connector.slug}
              connector={connector}
              linkTo={slugLinkPrefix + String(connector.slug)}
            />
          )
        })}
        {children}
      </>
    )
  }

  render(): React.ReactNode {
    const { searchPlaceholder } = this.props
    const { search, searchWidth } = this.state

    return (
      <>
        <SearchInput
          marginBottom={majorScale(1)}
          placeholder={searchPlaceholder}
          height={majorScale(6)}
          maxWidth='100%'
          width={searchWidth}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            this.setState({ search: e.target.value })
          }
          value={search}
        />
        <div ref={this.gridRef}>
          <Pane
            clearfix
            marginTop={majorScale(3)}
            display='flex'
            flexWrap='wrap'
            marginRight={majorScale(-3)}
            marginBottom={majorScale(-3)}
          >
            {this.renderContent()}
          </Pane>
        </div>
      </>
    )
  }
}

export default ConnectorsList
