import React, { StrictMode } from 'react'
import {
  BrowserRouter as Router,
  BrowserRouterProps,
  Redirect,
  Route,
  Switch
} from 'react-router-dom'
import Media from 'react-media'
import theme from './theme'
import ScrollToTop from './scroll-to-top'
import NavMenu from './nav-menu'
import Settings from './settings/settings'
import Providers from './providers/providers'
import AvailableProviders from './providers/available-providers'
import { Provider } from './providers/provider'
import Connections from './connections/connections'
import Setup from './setup/setup'
import Destinations from './destinations/destinations'
import Pipelines from './pipelines/pipelines'
import {
  LoginWrapper,
  withCredentials,
  CredentialConsumer
} from './login-wrapper'
import PageViewTracker from './page-view-tracker'
import { XkitWrapper } from './xkit-wrapper'
import {
  Pane,
  // TODO: please fix following typescript error
  // @ts-expect-error
  ThemeProvider,
  majorScale,
  Spinner
} from 'evergreen-ui'
import {
  PlatformSetupStatus,
  onPlatformStatusUpdate,
  statusProgress
} from '../api/platform-status'
import { NotFoundRoute } from './components/not-found-route'
import { Dashboard } from './dashboard/dashboard'
import CRMObjectsList from './crm/crm-objects-list'
import NewCRMObject from './crm/new-crm-object'
import UpdateCRMObject from './crm/update-crm-object'
import AppObjectsList from './crm/app-objects-list'
import NewAppObject from './crm/new-app-object'
import AppObject from './crm/app-object'
import Support from './support'
import { XkitForConnectWrapper } from './xkit-for-connect-wrapper'

type DebugRouterProps = BrowserRouterProps

// Reference -> https://gist.github.com/moshemal/540ccb312b3d9941463789eff3c565a4
// TODO: There is some issue with typing when extending Router, need to fix types and eslint issues
// Using functional component may help -> https://gist.github.com/moshemal/540ccb312b3d9941463789eff3c565a4#gistcomment-3603355
class DebugRouter extends Router {
  constructor(props: DebugRouterProps) {
    super(props)
    if (process.env.NODE_ENV !== 'production') {
      console.debug(
        'initial history is: ',
        // @ts-expect-error
        JSON.stringify(this.history, null, 2)
      )
      // @ts-expect-error
      this.history.listen((location, action) => {
        console.debug(
          `The current URL is ${location.pathname}${location.search}${location.hash}` // eslint-disable-line @typescript-eslint/restrict-template-expressions
        )
        console.debug(
          `The last navigation action was ${action}`, // eslint-disable-line @typescript-eslint/restrict-template-expressions
          // @ts-expect-error
          JSON.stringify(this.history, null, 2)
        ) // eslint-disable-line @typescript-eslint/restrict-template-expressions
      })
    }
  }
}

interface BaseHomeProps {
  mediaMatches: { small: boolean; normal: boolean }
  onLogOut: () => Promise<void>
}

interface HomeState {
  setupStatus?: PlatformSetupStatus
}

type HomeWithoutCredsProps = CredentialConsumer<BaseHomeProps>

class HomeWithoutCreds extends React.Component<
  HomeWithoutCredsProps,
  HomeState
> {
  constructor(props: HomeWithoutCredsProps) {
    super(props)
    this.state = {}
  }

  componentDidMount(): void {
    this.listenToSetupStatus()
  }

  listenToSetupStatus(): void {
    onPlatformStatusUpdate(this.props.credentials, (setupStatus) =>
      this.setState({ setupStatus })
    )
  }

  renderRedirect(): React.ReactElement {
    const { setupStatus } = this.state
    const crmOnlyConnectors =
      !setupStatus?.use_credential_connectors &&
      !setupStatus?.use_sync_connectors

    if (setupStatus == null) {
      return (
        <Pane
          display='flex'
          alignItems='center'
          justifyContent='center'
          height='100vh'
        >
          <Spinner />
        </Pane>
      )
    }

    const progress = statusProgress(setupStatus)

    // Setup guide is currently on for the credential connectors.
    // When/if we update it for sources, we can resume redirecting to it.
    if (
      setupStatus?.use_credential_connectors &&
      progress != null &&
      progress < 1
    ) {
      return <Redirect to='/setup' />
    }

    return crmOnlyConnectors ? (
      <Redirect to='/providers' />
    ) : (
      <Redirect to='/dashboard' />
    )
  }

  render(): React.ReactElement {
    const { onLogOut, mediaMatches } = this.props
    const { setupStatus } = this.state
    const crmOnlyConnectors =
      !setupStatus?.use_credential_connectors &&
      !setupStatus?.use_sync_connectors

    return (
      <>
        <Route path='/'>
          <NavMenu
            setupStatus={setupStatus}
            onLogOut={onLogOut}
            small={mediaMatches.small}
          />
        </Route>
        <Pane
          marginLeft={majorScale(mediaMatches.small ? 10 : 25)}
          background='tint1'
          minHeight='100vh'
          padding={majorScale(3)}
        >
          <Switch>
            <Route path='/settings'>
              <Settings setupStatus={setupStatus} />
            </Route>
            <Route path='/providers' exact>
              <Providers crmOnlyConnectors={crmOnlyConnectors} />
            </Route>
            <Route path='/providers/new'>
              <AvailableProviders crmOnlyConnectors={crmOnlyConnectors} />
            </Route>
            <Route path='/providers/:slug'>
              <Provider crmOnlyConnectors={crmOnlyConnectors} />
            </Route>
            <Route path='/connectors' exact>
              <Redirect to='/providers' />
            </Route>
            <Route path='/connectors/new'>
              <Redirect to='/providers/new' />
            </Route>
            <Route
              path='/connectors/:slug'
              render={({ match }) => {
                return <Redirect to={`/providers/${match.params.slug}`} />
              }}
            />
            <Route path='/sources' exact>
              <Redirect to='/providers' />
            </Route>
            <Route path='/sources/new'>
              <Redirect to='/providers/new' />
            </Route>
            <Route
              path='/sources/:slug'
              render={({ match }) => {
                return <Redirect to={`/providers/${match.params.slug}`} />
              }}
            />
            <Route path='/connections' exact>
              <Connections />
            </Route>
            <Route path='/dashboard' exact>
              <Dashboard />
            </Route>
            <Route path='/setup'>
              {/* TODO: please fix following typescript error */}
              {/* @ts-expect-error */}
              <Setup setupStatus={setupStatus} />
            </Route>
            <Route path='/destinations'>
              <Destinations />
            </Route>
            <Route path='/pipelines'>
              <Pipelines />
            </Route>
            <Route path='/crm-objects' exact>
              <CRMObjectsList />
            </Route>
            <Route path='/crm-objects/new' exact>
              <NewCRMObject />
            </Route>
            <Route path='/crm-objects/:id' exact>
              <UpdateCRMObject />
            </Route>
            <Route path='/app-objects' exact>
              <AppObjectsList />
            </Route>
            <Route path='/app-objects/new' exact>
              <NewAppObject />
            </Route>
            <Route path='/app-objects/:id'>
              <AppObject />
            </Route>
            <Route path='/support' exact>
              <Pane margin={-majorScale(3)}>
                <Support />
              </Pane>
            </Route>
            <Route path='/' exact>
              {this.renderRedirect()}
            </Route>
            <Route path='*'>
              <NotFoundRoute to='/' />
            </Route>
          </Switch>
        </Pane>
      </>
    )
  }
}

const Home = withCredentials(HomeWithoutCreds)

class App extends React.Component {
  render(): React.ReactNode {
    return (
      <StrictMode>
        <DebugRouter>
          <PageViewTracker />
          <ScrollToTop />
          <ThemeProvider value={theme}>
            <LoginWrapper>
              {(onLogOut) => (
                <Media
                  queries={{
                    small: '(max-width: 784px)',
                    normal: '(min-width: 785px)'
                  }}
                >
                  {(matches) => (
                    <XkitWrapper>
                      {(xkitLogOut) => (
                        <XkitForConnectWrapper>
                          {(xkitForConnectLogOut) => {
                            const logout = async (): Promise<void> => {
                              await Promise.all([
                                xkitLogOut(),
                                xkitForConnectLogOut(),
                                onLogOut()
                              ])
                            }
                            return (
                              <Home mediaMatches={matches} onLogOut={logout} />
                            )
                          }}
                        </XkitForConnectWrapper>
                      )}
                    </XkitWrapper>
                  )}
                </Media>
              )}
            </LoginWrapper>
          </ThemeProvider>
        </DebugRouter>
      </StrictMode>
    )
  }
}

export default App
