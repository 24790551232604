import React, { FC, memo } from 'react'
import { Text, TextProps } from 'evergreen-ui'

export type XTextProps = TextProps

export const XText: FC<XTextProps> = memo(({ children, ...textProps }) => {
  return (
    <Text
      fontSize='inherit'
      fontWeight='inherit'
      color='inherit'
      lineHeight='inherit'
      {...textProps}
    >
      {children}
    </Text>
  )
})
XText.displayName = 'XText'
