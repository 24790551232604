import { useCallback, useContext, useState, useEffect } from 'react'
import { CredentialsContext } from '../login-wrapper'
import { TokenCredentials } from '../../api/request'
import {
  Invocation,
  listDestinationInvocations,
  listSyncInvocations,
  subscribeToDestinationInvocations,
  subscribeToSyncInvocations
} from '../../api/invocation'

interface UseInvocationsState {
  loading: boolean
  hasMore: boolean
  error: string | undefined
  loadNextInvocations: () => Promise<void>
  invocations: Invocation[]
}

type ListInvocations = (
  creds: TokenCredentials,
  nextTokenParam?: string
) => ReturnType<typeof listDestinationInvocations>
type SubscribeToInvocations = (
  creds: TokenCredentials
) => ReturnType<typeof subscribeToDestinationInvocations>

function sortInvocationsDesc(invocations: Invocation[]): Invocation[] {
  return invocations.sort(
    (a, b) => b.inserted_at.getTime() - a.inserted_at.getTime()
  )
}

function invocationsToArray(
  invocations: Map<string, Invocation>
): Invocation[] {
  return sortInvocationsDesc(Array.from(invocations.values()))
}

export const useInvocations = (
  listInvocations: ListInvocations,
  subscribeToInvocations: SubscribeToInvocations
): UseInvocationsState => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const [nextToken, setNextToken] = useState<string | undefined>(undefined)
  const [hasMore, setHasMore] = useState<boolean>(false)
  // Use a map so we can avoid duplicates
  const [invocations, setInvocations] = useState<Map<string, Invocation>>(
    new Map()
  )
  const [invocationArr, setInvocationArr] = useState<Invocation[]>([])
  const { callWithCredentials } = useContext(CredentialsContext)

  const loadFirstPage = useCallback(async () => {
    const { invocations, nextToken, hasMore } = await callWithCredentials(
      async (creds) => await listInvocations(creds)
    )
    setHasMore(hasMore)
    setNextToken(nextToken)
    setInvocations(
      new Map(invocations.map((invocation) => [invocation.uuid, invocation]))
    )
  }, [callWithCredentials, listInvocations])

  const loadNextPage = useCallback(async () => {
    const {
      invocations: newInvocations,
      nextToken: newNextToken,
      hasMore: newHasMore
    } = await callWithCredentials(
      async (creds) => await listInvocations(creds, nextToken)
    )
    setHasMore(newHasMore)
    setNextToken(newNextToken)
    setInvocations((oldInvocations) => {
      const invocations = new Map(oldInvocations)
      newInvocations.forEach((invocation) => {
        invocations.set(invocation.uuid, invocation)
      })
      return invocations
    })
  }, [callWithCredentials, listInvocations, nextToken])

  const loadInvocations = useCallback(async () => {
    try {
      setLoading(true)
      await loadFirstPage()
    } catch (error) {
      setError(`Error while loading invocations: ${String(error.message)}`)
    } finally {
      setLoading(false)
    }
  }, [loadFirstPage])

  const loadNextInvocations = useCallback(async () => {
    try {
      setLoading(true)
      await loadNextPage()
    } catch (error) {
      setError(`Error while loading invocations: ${String(error.message)}`)
    } finally {
      setLoading(false)
    }
  }, [loadNextPage])

  useEffect(() => {
    // In order to make this setup synchronous, we do a lot of
    // indirection to make sure the subscriber is always torn down
    // if the component is unmounted.
    let tornDown = false
    let tearDown = (): void => {
      tornDown = true
    }

    async function setupListener(): Promise<void> {
      const [onInvocation, unsubscribe] = await callWithCredentials(
        async (creds) => subscribeToInvocations(creds)
      )

      if (tornDown) {
        unsubscribe()
        return
      }

      tearDown = unsubscribe

      onInvocation((invocation) => {
        setInvocations((oldInvocations) => {
          const invocations = new Map(oldInvocations)
          invocations.set(invocation.uuid, invocation)
          return invocations
        })
      })
    }

    void setupListener()

    return () => {
      tearDown()
    }
  }, [callWithCredentials, subscribeToInvocations])

  useEffect(() => {
    void loadInvocations()
  }, [loadInvocations])

  useEffect(() => {
    setInvocationArr(invocationsToArray(invocations))
  }, [invocations])

  return {
    loading,
    hasMore,
    error,
    loadNextInvocations,
    invocations: invocationArr
  }
}

export const useDestinationInvocations = (
  destinationUUID: string
): UseInvocationsState => {
  const listInvocations: ListInvocations = useCallback(
    async (creds: TokenCredentials, nextTokenParam?: string) => {
      return await listDestinationInvocations(
        creds,
        destinationUUID,
        nextTokenParam
      )
    },
    [destinationUUID]
  )
  const subscribeToInvocations: SubscribeToInvocations = useCallback(
    (creds: TokenCredentials) =>
      subscribeToDestinationInvocations(creds, destinationUUID),
    [destinationUUID]
  )
  return useInvocations(listInvocations, subscribeToInvocations)
}

export const useSyncInvocations = (
  pipelineUUID: string,
  externalConnectionId?: string
): UseInvocationsState => {
  const listInvocations: ListInvocations = useCallback(
    async (creds: TokenCredentials, nextTokenParam?: string) => {
      if (externalConnectionId == null) {
        return {
          invocations: [],
          nextToken: '',
          hasMore: false
        }
      }
      return await listSyncInvocations(
        creds,
        pipelineUUID,
        externalConnectionId,
        nextTokenParam
      )
    },
    [pipelineUUID, externalConnectionId]
  )

  const subscribeToInvocations: SubscribeToInvocations = useCallback(
    (creds: TokenCredentials) => {
      if (externalConnectionId == null) {
        return [(fn: unknown) => {}, () => {}]
      }
      return subscribeToSyncInvocations(
        creds,
        pipelineUUID,
        externalConnectionId
      )
    },
    [pipelineUUID, externalConnectionId]
  )

  return useInvocations(listInvocations, subscribeToInvocations)
}
