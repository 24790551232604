import React from 'react'
import { InputParameters } from '../../../api/destination-input'

import GenericForm from './generic-form'
import SalesforceForm from './salesforce-form'

export interface BaseProviderFormProps {
  loading: boolean
  saving: boolean
  name: string
  setName: (name: string) => void
  nameError?: string
  inputs: InputParameters[]
  setInputs: (inputs: InputParameters[]) => void
  inputsError?: string
  config: Record<string, string>
  setConfig: (config: Record<string, string>) => void
  configError?: string
  onSubmit: () => void
}

export type BaseProviderForm = React.FC<BaseProviderFormProps>

function slugToForm(slug?: String): BaseProviderForm {
  switch (slug) {
    case 'salesforce-sales-cloud-sync':
      return SalesforceForm
    case 'zendesk-sync':
      return GenericForm
    default:
      return GenericForm
  }
}

type ProviderFormProps = {
  providerTemplateSlug: string
} & BaseProviderFormProps

export const ProviderForm: React.FC<ProviderFormProps> = ({
  providerTemplateSlug,
  ...props
}) => {
  const FormComponent = slugToForm(providerTemplateSlug)
  return <FormComponent {...props} />
}

ProviderForm.displayName = 'ProviderForm'

export default ProviderForm
