import React, { memo, FunctionComponent } from 'react'
import AuthorizerForm from '../authorizer-form'
import { Authorizer, AuthorizerResultValues } from '../../api/authorizer'
import { Platform } from '../../api/platform'
import { SpinnerSuspend } from '../components/spinner'
import { Pane } from 'evergreen-ui'

export interface ServiceProviderFormProps {
  isCRMConnector?: boolean
  platform?: Platform
  authorizer?: Authorizer
  updateAuthorizer: (authorizer: Authorizer) => Promise<AuthorizerResultValues>
  loading?: boolean
}

export const ServiceProviderForm: FunctionComponent<ServiceProviderFormProps> =
  memo(
    ({
      isCRMConnector,
      platform,
      authorizer,
      updateAuthorizer,
      loading = false
    }) => {
      if (!authorizer) {
        return null
      }

      return (
        <Pane maxWidth={400}>
          <SpinnerSuspend spinning={loading}>
            <AuthorizerForm
              isCRMConnector={isCRMConnector}
              loading={false}
              authorizer={authorizer}
              updateAuthorizer={async (authorizer) =>
                await updateAuthorizer(authorizer)
              }
              platform={platform}
            />
          </SpinnerSuspend>
        </Pane>
      )
    }
  )
ServiceProviderForm.displayName = 'ServiceProviderForm'
