import * as React from 'react'
import {
  Pane,
  UnorderedList,
  ListItem,
  Spinner,
  majorScale
} from 'evergreen-ui'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'

const SpinnerIcon: React.FC = () => {
  return (
    <Spinner
      size={16}
      display='inline-block'
      marginRight={12}
      marginLeft={-28}
    />
  )
}

interface SetupItemProps {
  title: string
  isComplete?: boolean
  parentUrl: string
  parentPath: string
  slug: string
  loading?: boolean
}

const SetupItem: React.FC<SetupItemProps> = ({
  title,
  children,
  isComplete,
  parentUrl,
  parentPath,
  slug,
  loading
}) => {
  const path = `${parentPath}/${slug}`
  const match = useRouteMatch(path)
  const history = useHistory()
  const staticIcon = isComplete ? 'tick-circle' : 'circle'

  return (
    <Pane
      paddingLeft={majorScale(3)}
      paddingRight={majorScale(3)}
      paddingTop={majorScale(2)}
      paddingBottom={majorScale(2)}
      borderBottom='muted'
      cursor='pointer'
      onClick={() => history.push(`${parentUrl}/${slug}`)}
    >
      <UnorderedList size={600} start='2'>
        <ListItem
          icon={loading ? 'blank' : staticIcon}
          iconColor={isComplete ? 'success' : undefined}
          color={match == null ? 'muted' : undefined}
        >
          {loading ? <SpinnerIcon /> : null}
          {title}
          <Route path={path}>
            <Pane
              marginTop={majorScale(1)}
              marginBottom={majorScale(1)}
              cursor='auto'
              // TODO: please fix following typescript error
              // @ts-expect-error
              onClick={(e) => e.stopPropagation()}
            >
              {children}
            </Pane>
          </Route>
        </ListItem>
      </UnorderedList>
    </Pane>
  )
}

export default SetupItem
