import React, { useState } from 'react'
import { Pane, BackButton, majorScale, toaster } from 'evergreen-ui'
import { Redirect, Link } from 'react-router-dom'
import { SpinnerSuspend } from '../../components/spinner'
import { HeadingTitle } from '../../components/heading-title'
import { XText } from '../../components/x-text'
import ConnectorMark from '../../components/connector-mark'
import { useTitle, useErrorToaster } from '../../hooks/common'
import { useConnector } from '../../hooks/connector.hook'
import {
  Destination,
  DestinationRole,
  DestinationType,
  createDestination
} from '../../../api/destination'
import { InputParameters } from '../../../api/destination-input'
import { hasOwnProperty } from '../../../util/has-own-property'
import { CredentialConsumer, withCredentials } from '../../login-wrapper'
import { ProviderForm } from './provider-form'

type NewProviderParams = {
  slug: string
} & CredentialConsumer

const NewProvider: React.FC<NewProviderParams> = ({
  slug,
  callWithCredentials
}) => {
  const { connector: provider, loading, error } = useConnector(slug)

  useTitle(`New ${provider == null ? '' : provider.name + ' '}Destination`)
  useErrorToaster(error)

  const [destination, setDestination] = useState<Destination | undefined>(
    undefined
  )
  const [name, setName] = useState<string>('')
  const [nameError, setNameError] = useState<string | undefined>(undefined)
  const [inputs, setInputs] = useState<InputParameters[]>([])
  const [inputsError, setInputsError] = useState<string | undefined>(undefined)
  const [config, setConfig] = useState<Record<string, string>>({})
  const [configError, setConfigError] = useState<string | undefined>(undefined)
  const [saving, setSaving] = useState<boolean>(false)

  async function handleCreate(): Promise<void> {
    setSaving(true)
    try {
      const result = await callWithCredentials(
        async (creds) =>
          await createDestination(creds, {
            role: DestinationRole.provider,
            provider_slug: slug,
            type: DestinationType.native,
            name,
            inputs,
            config
          })
      )

      if (hasOwnProperty(result, 'errors')) {
        const errors = result.errors
        let foundError = false

        setNameError(undefined)
        setConfigError(undefined)

        if (errors.name?.length) {
          setNameError(errors.name[0].message)
          foundError = true
        }

        if (errors.inputs?.length) {
          const inputsError = errors.inputs.find(
            (inputsError) => Object.keys(inputsError).length
          )
          if (inputsError != null) {
            setInputsError(
              inputsError.name?.[0].message ??
                inputsError.simple_type?.type?.[0].message ??
                inputsError.simple_type?.format?.[0].message
            )
            foundError = true
          }
        }

        if (errors.config?.length) {
          setConfigError(errors.config[0].message)
          foundError = true
        }

        if (!foundError) {
          throw new Error('Unknown error while saving destination.')
        }
      } else if (
        hasOwnProperty(result, 'destination') &&
        result.destination != null
      ) {
        setDestination(result.destination)
      } else {
        throw new Error('Unknown error while saving destination.')
      }
    } catch (e) {
      toaster.danger(e.message)
    } finally {
      setSaving(false)
    }
  }

  if (destination != null) {
    toaster.success('Created Destination!')
    return <Redirect to={`/destinations/${destination.uuid}`} />
  }

  const loaded = !loading && error == null

  return (
    <Pane>
      <SpinnerSuspend spinning={!loaded}>
        {loaded && provider != null && (
          <>
            <HeadingTitle display='flex' alignItems='center'>
              <ConnectorMark
                markUrl={provider.mark_url}
                marginRight={majorScale(2)}
              />
              <XText>New {provider.name} Destination</XText>
            </HeadingTitle>
            <ProviderForm
              providerTemplateSlug={provider.template.slug}
              loading={loading}
              saving={saving}
              name={name}
              setName={setName}
              nameError={nameError}
              // TODO: should we be defining the inputs here?
              inputs={inputs}
              setInputs={setInputs}
              inputsError={inputsError}
              config={config}
              setConfig={setConfig}
              configError={configError}
              onSubmit={handleCreate}
            />
            <BackButton is={Link} to='/destinations'>
              Back to Destinations
            </BackButton>
          </>
        )}
      </SpinnerSuspend>
    </Pane>
  )
}

export default withCredentials(NewProvider)
