import React, { ReactElement, useCallback, memo } from 'react'
import { Button, IconButton, Table, TableProps, majorScale } from 'evergreen-ui'

interface Input<ValueType> {
  id?: number | string
  value: ValueType
  disabled?: boolean
  isInvalid?: boolean
}

type InputRender<ValueType> = (
  input: Input<ValueType>,
  onSetValue: (newValue: ValueType) => void
) => ReactElement

interface InputsTableProps<ValueType> extends TableProps {
  headers: string[]
  inputLabel: string
  disabled: boolean
  inputs: Array<Input<ValueType>>
  newValue: () => ValueType
  render: InputRender<ValueType>
  setInputs: (inputs: Array<Input<ValueType>>) => void
}

interface InputsTableRowProps<ValueType> {
  render: InputRender<ValueType>
  input: Input<ValueType>
  inputIndex: number
  onSetValue: (inputIndex: number, newValue: ValueType) => void
  onRemove: (inputIndex: number) => void
}

function InputsTableRow<ValueType>({
  render,
  input,
  inputIndex,
  onSetValue,
  onRemove
}: InputsTableRowProps<ValueType>): ReactElement {
  const setValue = useCallback(
    (newValue: ValueType): void => {
      onSetValue(inputIndex, newValue)
    },
    [onSetValue, inputIndex]
  )

  const remove = useCallback((): void => {
    onRemove(inputIndex)
  }, [onRemove, inputIndex])

  return (
    <Table.Row>
      {render(input, setValue)}
      <Table.Cell width={48} flex='none'>
        {!input.disabled ? (
          <IconButton
            appearance='minimal'
            icon='small-cross'
            onClick={remove}
          />
        ) : null}
      </Table.Cell>
    </Table.Row>
  )
}

const MemoizedInputsTableRow = memo(InputsTableRow)

function InputsTable<ValueType>({
  disabled,
  headers,
  inputs,
  newValue,
  setInputs,
  inputLabel,
  render,
  ...tableProps
}: InputsTableProps<ValueType>): ReactElement {
  const setValue = useCallback(
    (inputIndex: number, newValue: ValueType): void => {
      const input = inputs[inputIndex]
      inputs[inputIndex] = { ...input, value: newValue }
      setInputs([...inputs])
    },
    [inputs, setInputs]
  )

  const removeInput = useCallback(
    (inputIndex: number) => {
      const newInputs = inputs.slice()
      newInputs.splice(inputIndex, 1)
      setInputs(newInputs)
    },
    [inputs, setInputs]
  )

  const addInput = useCallback(() => {
    const newInputs = inputs.slice()
    newInputs.push({
      value: newValue(),
      disabled: false,
      isInvalid: false
    })
    setInputs(newInputs)
  }, [newValue, inputs, setInputs])

  return (
    <Table marginTop={majorScale(1)} {...tableProps}>
      <Table.Head>
        {headers.map((header) => (
          <Table.TextHeaderCell key={header}>{header}</Table.TextHeaderCell>
        ))}
        <Table.HeaderCell width={48} flex='none' />
      </Table.Head>
      <Table.Body>
        {inputs.map(({ id, value, disabled: inputDisabled, isInvalid }, i) => {
          const input = {
            id,
            value,
            disabled: disabled || inputDisabled,
            isInvalid
          }

          return (
            <MemoizedInputsTableRow
              key={id ?? i}
              render={render}
              input={input}
              inputIndex={i}
              onSetValue={setValue}
              onRemove={removeInput}
            />
          )
        })}
        <Table.Row display='flex' alignItems='center' justifyContent='center'>
          <Button
            appearance='minimal'
            iconBefore='add'
            disabled={disabled}
            onClick={addInput}
          >
            Add New {inputLabel}
          </Button>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}

export { InputsTable, InputsTableProps }
