import React, { createContext, ReactElement, ReactNode } from 'react'
import { usePlatform } from './hooks/platform.hook'
import {
  useXkitForConnectContext,
  UseXkitForConnectContextEffect
} from './xkit-js/xkit-connect-context.hook'

interface XkitForConnectContextProps {
  xkitForConnect: UseXkitForConnectContextEffect['xkitForConnect']
}

export const XkitForConnectContext = createContext<XkitForConnectContextProps>({
  xkitForConnect: null
})

interface XkitForConnectWrapperProps {
  children: (callback: () => Promise<void>) => ReactNode
}

export function XkitForConnectWrapper({
  children
}: XkitForConnectWrapperProps): ReactElement {
  const { platform } = usePlatform()
  const { xkitForConnect, logout } = useXkitForConnectContext({ platform })

  return (
    <XkitForConnectContext.Provider value={{ xkitForConnect }}>
      {children(logout)}
    </XkitForConnectContext.Provider>
  )
}
