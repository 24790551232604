import { useCallback, useContext, useEffect, useState } from 'react'
import {
  Connector,
  ConnectorResultValues,
  deleteConnector,
  getConnector,
  updateConnectorValues
} from '../../api/connector'
import errorMessage from '../../util/error-message'
import { CredentialsContext } from '../login-wrapper'

interface UseConnectorState {
  connector: Connector | undefined
  loading: boolean
  error: string | undefined
  updateFx: (
    slug: string,
    connector: Partial<Connector>
  ) => Promise<ConnectorResultValues>
  deleteFx: () => Promise<void>
}

export const useConnector = (slug?: Connector['slug']): UseConnectorState => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const [connector, setConnector] = useState<Connector | undefined>(undefined)
  const { callWithCredentials } = useContext(CredentialsContext)

  useEffect(() => {
    const loadConnector = async (): Promise<void> => {
      if (slug == null) {
        return
      }
      setLoading(true)
      try {
        const connector = await callWithCredentials(
          async (credentials) => await getConnector(credentials, slug)
        )
        setConnector(connector)
      } catch (error) {
        if (error.statusCode === 404) {
          setError('Connector not found')
        } else {
          setError(`Error while loading connector: ${errorMessage(error)}`)
        }
      } finally {
        setLoading(false)
      }
    }
    void loadConnector()
  }, [slug, callWithCredentials])

  const updateFx = useCallback(
    async (slug: string, connectorParams: Connector) => {
      const { values, errors } = await callWithCredentials(
        async (credentials) =>
          await updateConnectorValues(credentials, slug, connectorParams)
      )
      if (errors) {
        // TODO: handle error
        // setError(error)
        console.error(errors)
        return
      }
      setConnector(values)
      return { errors, values }
    },
    [callWithCredentials]
  )

  const deleteFx = useCallback(async () => {
    if (slug == null) {
      return
    }
    try {
      await callWithCredentials(
        async (credentials) => await deleteConnector(credentials, slug)
      )
      setConnector(undefined)
    } catch (error) {
      setError(`Error while deleting connector: ${errorMessage(error)}`)
    }
  }, [callWithCredentials, slug])

  return {
    connector,
    loading,
    error,
    // TODO: please fix following typescript error
    // @ts-expect-error
    updateFx,
    deleteFx
  }
}
