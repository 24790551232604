import React from 'react'
import { Heading, majorScale, Text } from 'evergreen-ui'
import { PlatformConnection } from '../../api/connection'
import { Pipeline } from '../../api/pipeline'
import { SourceObject } from '../../api/source-object'
import { Sync } from '../../api/sync'
import { ContentCard } from '../components/content-card'
import { SpinnerSuspend } from '../components/spinner'
import { SyncFilterForm, hasFilters } from './sync-filter-form'

interface SyncFiltersProps {
  loading: boolean
  pipeline?: Pipeline
  connection?: PlatformConnection
  sync?: Sync
  selectedRootObject?: SourceObject
}

export const SyncFilters: React.FC<SyncFiltersProps> = ({
  loading,
  pipeline,
  connection,
  sync,
  selectedRootObject
}) => {
  if (connection == null || !hasFilters(connection.connector.template_slug)) {
    return null
  }

  return (
    <>
      <Heading
        marginTop={majorScale(2)}
        marginBottom={majorScale(2)}
        size={400}
      >
        Filter Overrides
        <Text size={300} color='muted' marginLeft={majorScale(2)}>
          If enabled, sync-specific filters will override those defined for{' '}
          {pipeline?.source.provider?.name ?? 'the Source'}.
        </Text>
      </Heading>
      <ContentCard
        padding={majorScale(2)}
        elevation={1}
        marginBottom={majorScale(3)}
      >
        <SpinnerSuspend spinning={loading || !pipeline}>
          <SyncFilterForm
            // Will never render if pipeline.uuid is undefined
            pipelineUuid={pipeline?.uuid ?? ''}
            connectionId={connection.id}
            sourceFilters={selectedRootObject?.filters ?? []}
            syncFilters={sync?.filters ?? []}
            loading={loading}
            sourceTemplateSlug={pipeline?.source.provider.template_slug}
            rootObjectSlug={selectedRootObject?.slug}
          />
        </SpinnerSuspend>
      </ContentCard>
    </>
  )
}

SyncFilters.displayName = 'SyncFilters'
