import * as React from 'react'
import { Card, majorScale } from 'evergreen-ui'
import Highlight, { defaultProps } from 'prism-react-renderer'
import GithubTheme from 'prism-react-renderer/themes/github'

interface CodeBlockProps {
  code: string
  language: 'bash' | 'javascript' | 'json' | 'markdown'
}

const CodeBlock: React.FC<CodeBlockProps> = ({ code, language }) => {
  const codeRef = React.useRef(null)

  return (
    <Card
      border='muted'
      padding={majorScale(2)}
      marginTop={majorScale(2)}
      background='tint2'
      // TODO: please fix following typescript error
      // @ts-expect-error
      onClick={() => window.getSelection().selectAllChildren(codeRef.current)}
      overflowY='scroll'
    >
      <Highlight
        {...defaultProps}
        theme={GithubTheme}
        code={code}
        language={language}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre
            className={className}
            style={{ margin: 0, fontSize: '14px' }}
            ref={codeRef}
          >
            {tokens.map((line, i) => (
              // eslint-disable-next-line react/jsx-key
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  // eslint-disable-next-line react/jsx-key
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    </Card>
  )
}

export default CodeBlock
