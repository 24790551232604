import React, { FC, memo, useCallback } from 'react'
import { Destination } from '../../api/destination'
import { Card, Heading, FunctionIcon, majorScale } from 'evergreen-ui'
import { Link } from 'react-router-dom'

export interface DestinationCardProps {
  destination: Destination
  selected: boolean
  onHover?: (target?: Destination) => void
  refCallback?: (destination: Destination, element: HTMLElement | null) => void
}

// TODO: unify with the destination card in the pipelines UI.
export const DestinationCard: FC<DestinationCardProps> = memo(
  ({ destination, selected, onHover, refCallback }) => {
    const onEnterHandler = useCallback(() => {
      onHover?.(destination)
    }, [onHover, destination])

    const onLeaveHandler = useCallback(() => {
      onHover?.(undefined)
    }, [onHover])

    const internalRefCallback = useCallback(
      (element: HTMLElement | null) => {
        refCallback?.(destination, element)
      },
      [refCallback, destination]
    )

    return (
      <div ref={internalRefCallback} data-uuid={destination.uuid}>
        <Card
          is={Link}
          to={`/destinations/${destination.uuid}`}
          elevation={1}
          hoverElevation={3}
          display='flex'
          padding={majorScale(2)}
          textDecoration='none'
          marginBottom={majorScale(2)}
          opacity={selected ? 1 : 0.5}
          background='white'
          onMouseEnter={onEnterHandler}
          onMouseLeave={onLeaveHandler}
        >
          <Heading
            size={600}
            textOverflow='ellipsis'
            overflow='hidden'
            title={destination.name}
          >
            <FunctionIcon
              size={20}
              marginRight={majorScale(1)}
              style={{ verticalAlign: 'text-bottom' }}
            />
            {destination.name}
          </Heading>
        </Card>
      </div>
    )
  }
)
DestinationCard.displayName = 'DestinationCard'
