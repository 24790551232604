import { request, ChangeError, TokenCredentials } from './request'

export interface PlatformUser {
  external_id: string
  external_name?: string
  external_group_id?: string
}

export interface PlatformGroup {
  external_id: string
  external_name?: string
}

interface PlatformContextBaseAttrs {
  external_id: string
  external_name?: string
}

export interface PlatformContext extends PlatformContextBaseAttrs {
  [key: string]: string | undefined
}

type PlatformContextResponse =
  | { context: PlatformContext }
  | { errors: ChangeError<PlatformContext> }

export const getPlatformContextReadableTitle = (
  context?: PlatformContext
): string => context?.external_name ?? context?.external_id ?? ''

export function isTestContext(context: PlatformContext): boolean {
  // TODO: return an explicit flag from the backend?
  return context.external_id.startsWith('xkit_test_user')
}

export async function listPlatformUsers(
  credentials: TokenCredentials
): Promise<PlatformUser[]> {
  const { users } = await request({
    path: '/platform_users',
    credentials
  })
  return users as PlatformUser[]
}

export async function listPlatformGroups(
  credentials: TokenCredentials
): Promise<PlatformGroup[]> {
  const { groups } = await request({
    path: '/platform_groups',
    credentials
  })
  return groups as PlatformGroup[]
}

export async function listPlatformContexts(
  credentials: TokenCredentials
): Promise<PlatformContext[]> {
  const { contexts } = await request({
    path: '/platform_contexts',
    credentials
  })

  return contexts as PlatformContext[]
}

export async function getTestPlatformContext(
  credentials: TokenCredentials
): Promise<{ accessToken: string; context: PlatformContext }> {
  const { access_token: accessToken, context } = await request<{
    context: PlatformContext
    access_token: string
  }>({
    path: '/platform_test_context',
    method: 'GET',
    credentials
  })

  return { context, accessToken }
}

export async function getConnectPlatformToken(
  credentials: TokenCredentials
): Promise<{ token: string; domain: string }> {
  const { token, domain } = await request<{
    token: string
    domain: string
  }>({
    path: '/dev_connections/xkit_token',
    method: 'POST',
    credentials
  })

  return { token, domain }
}

export async function updateTestPlatformContext(
  credentials: TokenCredentials,
  contextParams: PlatformContext
): Promise<PlatformContextResponse> {
  const res = await request<PlatformContextResponse>({
    path: '/platform_test_context',
    method: 'PUT',
    credentials,
    body: contextParams
  })

  if ('errors' in res) {
    return { errors: res.errors }
  }

  if ('context' in res) {
    return { context: res.context }
  }

  throw new Error('Unknown response while updating test context')
}
