import React, { FC, memo } from 'react'
import { majorScale, Pane, PaneProps } from 'evergreen-ui'
import { NodePane } from '../../components/tree/tree'
import { NodeButton } from '../../components/tree/node-button'
import { HeadingSubsection } from '../../components/heading-subsection'
import { SimpleType } from '../../../api/destination-input'

export interface StaticInputProps extends PaneProps {
  value?: string | boolean
  search: string
  onChange: (value: string | boolean) => unknown
  simpleType: SimpleType
}

interface StaticButtonProps {
  value: string | boolean
  onChange: (value: string | boolean) => unknown
}

const StaticButton: FC<StaticButtonProps> = memo(({ value, onChange }) => (
  <NodePane>
    <NodeButton
      isSelectable={true}
      onClick={() => onChange(value)}
      description={typeof value}
    >
      {typeof value === 'string' ? `"${value}"` : value.toString()}
    </NodeButton>
  </NodePane>
))

StaticButton.displayName = 'StaticButton'

export const StaticInput: FC<StaticInputProps> = memo((props) => {
  const { value, search, onChange, simpleType, ...paneProps } = props

  if (simpleType.type === 'string' && (search || value)) {
    return (
      <>
        <HeadingSubsection marginBottom={majorScale(1)}>
          Static
        </HeadingSubsection>
        <Pane {...paneProps}>
          {value ? <StaticButton value={value} onChange={onChange} /> : null}
          {search ? <StaticButton value={search} onChange={onChange} /> : null}
        </Pane>
      </>
    )
  }

  if (simpleType.type === 'boolean') {
    return (
      <>
        <HeadingSubsection marginBottom={majorScale(1)}>
          Static
        </HeadingSubsection>
        <Pane {...paneProps}>
          <StaticButton value={true} onChange={onChange} />
          <StaticButton value={false} onChange={onChange} />
        </Pane>
      </>
    )
  }

  return null
})
StaticInput.displayName = 'StaticInput'
