import * as React from 'react'
import { ThemeConsumer } from './theme-helpers'
import {
  Pane,
  Card,
  Spinner,
  Heading,
  majorScale,
  Portal,
  // TODO: please fix following typescript error
  // @ts-expect-error
  withTheme
} from 'evergreen-ui'
import Transition, { TransitionStatus } from 'react-transition-group/Transition'
import Logo from './components/logo'
import '../../css/animations.css'

const ANIMATION_DURATION = 1000

const backgroundStyles = Object.freeze({
  shown: {
    transition: `background-color ${ANIMATION_DURATION / 4}ms`,
    width: '100%',
    opacity: 1
  },
  hidden: {
    transition: `background-color ${ANIMATION_DURATION / 4}ms`,
    width: '200px',
    opacity: 0
  }
})

const backgroundTransitionStyles = Object.freeze({
  entering: {
    ...backgroundStyles.shown,
    animation: [
      `${ANIMATION_DURATION / 2}ms ${
        ANIMATION_DURATION / 4
      }ms growFrom200 both`,
      `${ANIMATION_DURATION / 4}ms fadeIn both`
    ].join(', ')
  },
  entered: { ...backgroundStyles.shown },
  exiting: {
    ...backgroundStyles.shown,
    animation: [
      `${ANIMATION_DURATION / 2}ms ${
        ANIMATION_DURATION / 4
      }ms shrinkTo200 both`,
      `${ANIMATION_DURATION / 4}ms ${
        (ANIMATION_DURATION * 3) / 4
      }ms fadeOut both`
    ].join(', ')
  },
  exited: { ...backgroundStyles.hidden }
})

const DEFAULT_CARD_HEIGHT = majorScale(55)

function logoStyles(
  cardHeight: number = DEFAULT_CARD_HEIGHT
): Record<'shown' | 'hidden', React.CSSProperties> {
  return {
    shown: {
      top: '50%',
      left: '50%',
      marginLeft: -1 * (88 / 2 + majorScale(3)),
      // account for the size of the card, its margins and the logo
      marginTop: -1 * ((cardHeight + 100 + 36 - 36) / 2)
    },
    hidden: {
      top: 0,
      marginTop: 0,
      left: 0,
      marginLeft: 0
    }
  }
}

function logoTransitionStyles(
  cardHeight: number = DEFAULT_CARD_HEIGHT
): Partial<Record<TransitionStatus, React.CSSProperties>> {
  const styles = logoStyles(cardHeight)

  return {
    entering: {
      ...styles.shown,
      animation: `${ANIMATION_DURATION / 2}ms ${
        ANIMATION_DURATION / 4
      }ms moveFromTopLeft both`
    },
    entered: { ...styles.shown },
    exiting: {
      ...styles.shown,
      animation: `${ANIMATION_DURATION / 2}ms ${
        ANIMATION_DURATION / 4
      }ms moveToTopLeft both`
    },
    exited: { ...styles.hidden }
  }
}

const loginPaneStyles = Object.freeze({
  shown: {
    opacity: 1
  },
  hidden: {
    opacity: 0
  }
})

const loginPaneTransitionStyles = Object.freeze({
  entering: {
    ...loginPaneStyles.shown,
    animation: `${ANIMATION_DURATION / 4}ms ${
      (ANIMATION_DURATION * 3) / 4
    }ms fadeIn both`
  },
  entered: { ...loginPaneStyles.shown },
  exiting: {
    ...loginPaneStyles.shown,
    animation: `${ANIMATION_DURATION / 4}ms fadeOut both`
  },
  exited: { ...loginPaneStyles.hidden }
})

interface LoginChromeProps {
  loading: boolean
  isShown: boolean
  appearance: 'light' | 'dark'
  onEntered: () => void
  title?: string
}

interface LoginChromeState {
  exiting: boolean
  cardHeight?: number
}

class LoginChrome extends React.Component<
  ThemeConsumer<LoginChromeProps>,
  LoginChromeState
> {
  static defaultProps = {
    title: 'Log in to continue',
    onEntered: () => {},
    appearance: 'dark'
  }

  private readonly cardRef = React.createRef<HTMLDivElement>()

  // TODO: please fix following typescript error
  // @ts-expect-error
  constructor(props) {
    super(props)

    this.state = {
      exiting: false,
      // TODO: please fix following typescript error
      // @ts-expect-error
      entering: false
    }
  }

  setCardHeight(): void {
    this.setState({
      cardHeight: this.cardRef.current?.offsetHeight
    })
  }

  componentDidMount(): void {
    this.setCardHeight()
  }

  // TODO: please fix following typescript error
  // @ts-expect-error
  componentDidUpdate(prevProps): void {
    if (prevProps.isShown && !this.props.isShown) {
      this.setState({
        exiting: true,
        // TODO: please fix following typescript error
        // @ts-expect-error
        entering: false
      })
    } else if (!prevProps.isShown && this.props.isShown) {
      this.setState({
        exiting: false,
        // TODO: please fix following typescript error
        // @ts-expect-error
        entering: true
      })
    } else if (prevProps.children !== this.props.children) {
      this.setCardHeight()
    }
  }

  handleEntered = (): void => {
    this.setState({
      // TODO: please fix following typescript error
      // @ts-expect-error
      entering: false
    })
    this.props.onEntered()
  }

  renderContent(): React.Component {
    const { loading, children } = this.props
    const {
      exiting,
      // TODO: please fix following typescript error
      // @ts-expect-error
      entering
    } = this.state

    if (loading || exiting || entering) {
      // TODO: please fix following typescript error
      // @ts-expect-error
      return (
        <Pane
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexGrow={1}
        >
          <Spinner size={majorScale(7)} />
        </Pane>
      )
    }

    // TODO: please fix following typescript error
    // @ts-expect-error
    return children
  }

  render(): React.Component {
    const { theme, title, isShown, loading, appearance } = this.props

    const {
      exiting,
      // TODO: please fix following typescript error
      // @ts-expect-error
      entering,
      cardHeight
    } = this.state

    const backgroundProps =
      appearance === 'light'
        ? { backgroundColor: theme.scales.neutral.N3 }
        : { backgroundColor: '#1A1A1A' }

    // TODO: please fix following typescript error
    // @ts-expect-error
    return (
      <Portal>
        <Transition
          appear={entering}
          onEntered={this.handleEntered}
          unmountOnExit
          mountOnEnter
          timeout={ANIMATION_DURATION}
          in={isShown && !exiting}
        >
          {(state) => (
            <Pane
              {...backgroundProps}
              display='flex'
              alignItems='center'
              justifyContent='center'
              top={0}
              left={0}
              minHeight='100%'
              position='absolute'
              overflowY='scroll'
              zIndex={10}
              // TODO: please fix following typescript error
              // @ts-expect-error
              style={backgroundTransitionStyles[state]}
            >
              <Pane
                position='absolute'
                style={logoTransitionStyles(cardHeight)[state]}
                padding={majorScale(3)}
              >
                <a
                  // parameterize this?
                  href='https://xkit.co'
                >
                  <Logo width={88} appearance={appearance} />
                </a>
              </Pane>
              {/* TODO: once upgraded to evergreen 5+ move ref to Card itself */}
              <div
                ref={this.cardRef}
                style={{ marginTop: 100, marginBottom: 36 }}
              >
                <Card
                  // TODO: please fix following typescript error
                  // @ts-expect-error
                  backgroundColor={theme.palette.base}
                  minHeight={DEFAULT_CARD_HEIGHT}
                  width={majorScale(50)}
                  elevation={appearance === 'light' ? 2 : 3}
                  padding={majorScale(4)}
                  display='flex'
                  flexDirection='column'
                  // TODO: please fix following typescript error
                  // @ts-expect-error
                  style={loginPaneTransitionStyles[state]}
                >
                  <Heading
                    size={700}
                    textAlign='center'
                    marginTop={majorScale(1)}
                  >
                    {loading || exiting ? 'Logging in...' : title}
                  </Heading>
                  {this.renderContent()}
                </Card>
              </div>
            </Pane>
          )}
        </Transition>
      </Portal>
    )
  }
}

export default withTheme(LoginChrome)
