import React, { memo } from 'react'
import ScopesForm from '../scopes-form'
import { Connector, ConnectorResultValues } from '../../api/connector'
import { Pane, Paragraph, majorScale, useTheme } from 'evergreen-ui'

export interface PermissionsFormProps<T extends Connector> {
  loading: boolean
  connector?: T
  updateConnector: (
    slug: T['slug'],
    connector: Partial<T>
    // TODO: I'm using any because there is some type issue with return value, fix later
  ) => Promise<ConnectorResultValues<any>>
  crmOnlyConnectors?: boolean
}

export const PermissionsForm = memo(
  <T extends Connector>({
    loading,
    connector,
    updateConnector,
    crmOnlyConnectors
  }: PermissionsFormProps<T>) => {
    const theme = useTheme()

    if (!connector) {
      return null
    }

    return (
      <Pane>
        <Pane marginBottom={majorScale(2)}>
          {!connector?.template.sync && (
            <>
              <Paragraph>
                Select the scopes that your integration needs.{' '}
              </Paragraph>
              <Paragraph color={theme.scales.gray[700]}>
                <a
                  href={
                    crmOnlyConnectors
                      ? 'https://xkit.co/docs/oauth'
                      : 'https://docs.xkit.co/docs/connecting-with-apps-overview'
                  }
                >
                  View the documentation
                </a>{' '}
                for more information about what each of these scopes mean and
                when you would use it.
              </Paragraph>
            </>
          )}
          {connector?.template.sync && (
            <>
              <Paragraph>
                Select additional scopes that your integration needs. The scopes
                required by the sync are added automatically when configuring
                pipelines.
              </Paragraph>
              <Paragraph color={theme.scales.gray[700]}>
                <a
                  href={
                    crmOnlyConnectors
                      ? 'https://xkit.co/docs/oauth'
                      : 'https://docs.xkit.co/docs/connecting-with-apps-overview'
                  }
                >
                  View the documentation
                </a>{' '}
                for more information about what each of these scopes mean and
                when you would use it.
              </Paragraph>
            </>
          )}
        </Pane>
        <ScopesForm
          loading={loading}
          connector={connector}
          update={async (params) =>
            await updateConnector(connector.slug, params)
          }
        />
      </Pane>
    )
  }
)
PermissionsForm.displayName = 'PermissionsForm'
