import { useContext, useEffect, useState } from 'react'
import { Connector, listConnectors } from '../../api/connector'
import { CredentialsContext } from '../login-wrapper'
import errorMessage from '../../util/error-message'

interface UseConnectorsState {
  loading: boolean
  error: string | undefined
  connectors: Connector[]
}

export const useConnectors = (): UseConnectorsState => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [connectors, setConnectors] = useState<Connector[]>([])
  const { callWithCredentials } = useContext(CredentialsContext)

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      setLoading(true)
      try {
        const connectors = await callWithCredentials(listConnectors)
        setConnectors(connectors)
        setError(undefined)
      } catch (e) {
        setError(`Error while loading connectors: ${errorMessage(e)}`)
      } finally {
        setLoading(false)
      }
    }

    void fetch()
  }, [callWithCredentials])

  return {
    loading,
    error,
    connectors
  }
}
