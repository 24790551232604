function slugify(name: string): string {
  return (
    name
      .toLowerCase()
      // replace non-allowed characters with dashes
      .replace(/[^a-z0-9-]/g, '-')
      // trim leading and trailing dashes
      .replace(/^-+|-+$/g, '')
      // get rid of duplicate dashes
      .replace(/(-+)/g, '-')
  )
}

export default slugify
