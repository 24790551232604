import React, { memo, FC } from 'react'
import {
  Icon,
  IconProps,
  majorScale,
  SearchIcon,
  Text,
  TextProps
} from 'evergreen-ui'

interface EmptyStateProps extends TextProps {
  icon?: IconProps['icon']
  message?: string
}

const EmptyState: FC<EmptyStateProps> = memo(
  // TODO: there is some typing issue with evergreen, fix any later
  ({ message = 'No data found', icon = SearchIcon as any, ...textProps }) => {
    return (
      <Text
        size={600}
        color='muted'
        display='flex'
        alignItems='center'
        {...textProps}
      >
        <Icon icon={icon} size={20} marginRight={majorScale(2)} />
        {message}
      </Text>
    )
  }
)

EmptyState.displayName = 'EmptyState'

export default EmptyState
