import { Heading, majorScale, Table, Text } from 'evergreen-ui'
import React from 'react'
import { Sync } from '../../api/sync'
import ContentTable from '../content-table'

interface SyncStatesProps {
  loading: boolean
  sync?: Sync
}

export const SyncStates: React.FC<SyncStatesProps> = ({ loading, sync }) => {
  return (
    <>
      <Heading
        marginTop={majorScale(2)}
        marginBottom={majorScale(2)}
        size={400}
      >
        Data Jobs
        <Text size={300} color='muted' marginLeft={majorScale(2)}>
          Status of the individual data jobs run by this sync.
        </Text>
      </Heading>
      <ContentTable
        loading={loading}
        heading={
          <Table.Head>
            <Table.TextHeaderCell>Key</Table.TextHeaderCell>
            <Table.TextHeaderCell>Last Successful Run At</Table.TextHeaderCell>
          </Table.Head>
        }
      >
        <Table.Body>
          {(sync?.sync_states ?? []).map((syncState) => (
            <Table.Row key={syncState.key}>
              <Table.TextCell>{syncState.key}</Table.TextCell>
              <Table.TextCell>
                {syncState.last_success_at.toLocaleString()}
              </Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </ContentTable>
    </>
  )
}

SyncStates.displayName = 'SyncStates'
