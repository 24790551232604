import { Connector } from '../../api/connector'
import React, { FC, memo } from 'react'
import { Button, ButtonProps, majorScale } from 'evergreen-ui'
import { Platform } from '../../api/platform'
import hostWithPort from '../../util/url'

export interface CatalogPreviewButtonProps extends ButtonProps {
  platform?: Platform
  connector?: Connector
}
export const CatalogPreviewButton: FC<CatalogPreviewButtonProps> = memo(
  ({ platform, connector, ...buttonProps }) => {
    if (!platform || !connector || !connector.slug) {
      return null
    }

    return (
      <Button
        appearance='minimal'
        iconAfter='share'
        height={majorScale(3)}
        marginLeft={majorScale(1)}
        style={{ verticalAlign: 'unset' }}
        is='a'
        href={`https://${hostWithPort(platform.domain)}/connectors/${
          connector.slug
        }`}
        target='_blank'
        {...buttonProps}
      >
        Show Preview
      </Button>
    )
  }
)
CatalogPreviewButton.displayName = 'CatalogPreviewButton'
