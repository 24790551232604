import * as React from 'react'
import {
  Paragraph,
  Link,
  TextInputField,
  toaster,
  majorScale
} from 'evergreen-ui'
import { Link as RouterLink } from 'react-router-dom'
import LoginForm from './login-form'
import { requestPasswordReset } from '../api/user'
import errorMessage from '../util/error-message'

interface PasswordResetState {
  email: string
  loading: boolean
}

class ReqeustPasswordReset extends React.Component<{}, PasswordResetState> {
  // TODO: please fix following typescript error
  // @ts-expect-error
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      loading: false
    }
  }

  handleReset = async (): Promise<void> => {
    const { email } = this.state
    this.setState({ loading: true })
    try {
      await requestPasswordReset({ email })
      toaster.success(`Sent reset email for ${email} if it exists`)
    } catch (e) {
      toaster.danger(`Error: ${errorMessage(e)}`)
    } finally {
      this.setState({ loading: false })
    }
  }

  render(): React.ReactNode {
    const { loading, email } = this.state

    return (
      <LoginForm
        isLoading={loading}
        submitLabel='Reset Password'
        onSubmit={this.handleReset}
        footer={
          <Paragraph color='muted' marginTop={majorScale(2)}>
            Don't have an account?{' '}
            <Link is={RouterLink} to='/sign-up'>
              Create an account
            </Link>
          </Paragraph>
        }
      >
        {({ ...inputProps }) => (
          <TextInputField
            {...inputProps}
            type='email'
            label='Work Email'
            autoComplete='work email'
            value={email}
            disabled={loading}
            // TODO: please fix following typescript error
            // @ts-expect-error
            onChange={(e) => this.setState({ email: e.target.value })}
          />
        )}
      </LoginForm>
    )
  }
}

export default ReqeustPasswordReset
