import React, { FC, memo, useCallback } from 'react'
import { Heading, majorScale, Pane, PaneProps } from 'evergreen-ui'
import { Connector } from '../../api/connector'
import { ProviderCard, ProviderCardProps } from './dashboard-provider'
import { NewItemCard } from '../components/new-item-card'

interface ProvidersProps extends PaneProps {
  selected: Connector[]
  providers: Connector[]
  onHover?: (target?: Connector) => void
  refCallback?: ProviderCardProps['refCallback']
}

export const Providers: FC<ProvidersProps> = memo(
  ({
    appearance = 'default',
    selected,
    providers,
    onHover,
    refCallback,
    ...paneProps
  }) => {
    const isSelected = useCallback(
      (provider: Connector, selected: Connector[]): boolean => {
        return selected.find((item) => item.slug === provider.slug) != null
      },
      []
    )

    return (
      <Pane {...paneProps}>
        <Heading size={500}>Providers</Heading>
        <Pane marginTop={majorScale(2)}>
          {providers.map((provider) => (
            <ProviderCard
              key={provider.slug}
              provider={provider}
              selected={isSelected(provider, selected)}
              onHover={onHover}
              refCallback={refCallback}
            />
          ))}
          {providers.length === 0 ? (
            <NewItemCard
              appearance='primary'
              to='/providers/new'
              title='Add provider'
              body="Your customers' apps where their data lives today — like their CRM or Customer Support tool."
              minHeight={150}
            />
          ) : (
            <NewItemCard to='/providers/new' title='Add provider' />
          )}
        </Pane>
      </Pane>
    )
  }
)

Providers.displayName = 'Providers'
