import React, { FC, memo } from 'react'

interface ShapesIconProps {
  marginRight?: number
  marginLeft?: number
  style?: {
    [key: string]: string
  }
  size?: number
  color?: string
}

// TODO: When we bump evergreen-ui to ^6.0.0, we can remove this file and use ShapesIcon directly

const ShapesIcon: FC<ShapesIconProps> = memo(
  ({ marginRight, marginLeft, style, size, color }) => (
    <svg
      style={
        style
          ? {
              ...style,
              marginRight: marginRight ? `${marginRight}px` : undefined,
              marginLeft: marginLeft ? `${marginLeft}px` : undefined
            }
          : undefined
      }
      fill={color ?? 'currentColor'}
      height={size ? `${size}px` : '16px'}
      width={size ? `${size}px` : '16px'}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
    >
      <path
        fillRule='evenodd'
        d='M7.88 11.12a.958.958 0 011.277.33l3.719 6.207c.081.136.124.29.124.447 0 .495-.419.896-.936.896H4.936a.969.969 0 01-.436-.103.878.878 0 01-.392-1.21l3.409-6.208a.915.915 0 01.362-.36zM15 5a4 4 0 110 8 4 4 0 010-8zM8 1a1 1 0 011 1v6a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1h6z'
      ></path>
    </svg>
  )
)

ShapesIcon.displayName = 'ShapesIcon'

export default ShapesIcon
