const API_PATH = '/api/platform_admin'
// Theoretically we can support http in development, but with Cookie policies
// as they are, we are better off going all https
const SCHEME = 'https:'

type RequestMethod = 'GET' | 'POST' | 'DELETE' | 'PUT'

// The ChangeError format here will replace LegacyChangeError but it will be applied
// one API at a time.
// This format supports nesting (see MappingErrors in ./mapping.ts) which has to be done manually.
export type ChangeError<T> = {
  [key in keyof T]: Array<{ message: string }>
}

export interface LegacyChangeError<T> {
  field: keyof T
  error: string
}

export interface LegacyChangeResult<T> {
  // e.g. {"errors": [{"field": "slug", "error": "has already been taken"}]}
  errors?: Array<LegacyChangeError<T>>
}

export interface LegacyChangeResultValues<T> extends LegacyChangeResult<T> {
  values?: T
}

export interface UserPassCredentials {
  username: string
  password: string
}

export interface TokenCredentials {
  token: string
}

type Credentials = UserPassCredentials | TokenCredentials

function isToken(creds: Credentials): creds is TokenCredentials {
  // TODO: please fix following typescript error
  // @ts-expect-error
  return creds.token != null
}

function isUserPass(creds: Credentials): creds is UserPassCredentials {
  // TODO: please fix following typescript error
  // @ts-expect-error
  return creds.username != null && creds.password != null
}

interface RequestOptions {
  path: string
  credentials?: Credentials
  method?: RequestMethod
  body?: UnknownJSON
}

interface FetchOptions {
  credentials: 'include' | 'same-origin' | 'omit'
  headers: Partial<Record<'Accept' | 'Authorization' | 'Content-Type', string>>
  method?: RequestMethod
  body?: string
}

export interface UnknownJSON {
  [index: string]: unknown | UnknownJSON
}

const ERROR_CODES = Object.freeze({
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  429: 'Too Many Requests',
  500: 'Internal Server Error',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout'
})

export class APIError extends Error {
  name: string
  message: string
  statusCode: number
  statusText: string
  debugMessage?: string

  constructor(message: string, res: Response, debugMessage?: string) {
    super()
    // Sigh: https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, APIError.prototype)
    this.name = 'APIError'
    this.message =
      // TODO: please fix following typescript error
      // @ts-expect-error
      message || res.statusText || ERROR_CODES[res.status] || 'Unknown Error' // eslint-disable-line @typescript-eslint/strict-boolean-expressions
    this.statusCode = res.status
    this.statusText = res.statusText
    this.debugMessage = debugMessage
    if (process.env.NODE_ENV === 'development') {
      if (this.debugMessage != null) {
        this.message = `${this.message} (debug: ${this.debugMessage})`
      }
    }
  }
}

function getFetchOptions(options: RequestOptions): FetchOptions {
  const fetchOptions: FetchOptions = {
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json'
    }
  }

  if (options.credentials != null && isToken(options.credentials)) {
    fetchOptions.headers.Authorization = `Bearer ${options.credentials.token}`
  } else if (options.credentials != null && isUserPass(options.credentials)) {
    const encodedCreds = btoa(
      `${options.credentials.username}:${options.credentials.password}`
    )
    fetchOptions.headers.Authorization = `Basic ${encodedCreds}`
  }

  if (options.method != null) {
    fetchOptions.method = options.method
  }

  if (options.body != null) {
    fetchOptions.headers['Content-Type'] = 'application/json'
    fetchOptions.body = JSON.stringify(options.body)
  }

  return fetchOptions
}

async function parseData(res: Response): Promise<UnknownJSON> {
  let data

  try {
    data = await res.json()
    if (data == null) {
      throw new Error('No data in response')
    }
  } catch (e) {
    if (!res.ok) {
      throw new APIError(res.statusText, res, e.message)
    }
    throw new APIError(e.message, res)
  }
  if (data.error != null) {
    throw new APIError(data.error, res)
  }

  return data
}

// interpret string date as UTC instead of local time
export function parseDate(dateWire: string): Date {
  if (dateWire.endsWith('Z')) {
    return new Date(dateWire)
  }
  return new Date(`${dateWire}Z`)
}

export async function request<T extends UnknownJSON = UnknownJSON>(
  options: RequestOptions
): Promise<T> {
  const res = await fetch(
    `${SCHEME}//${window.location.host}${API_PATH}${options.path}`,
    getFetchOptions(options)
  )

  let data

  // No Content response header
  if (res.status !== 204) {
    data = await parseData(res)
  } else {
    data = {}
  }

  // Ok, or Bad request (maybe validation?)
  if (res.ok || res.status === 400) {
    return data as T
  }

  throw new APIError(res.statusText, res)
}
