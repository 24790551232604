import { Selector, Mapping, TransformationParameters } from '../../api/mapping'
import {
  createOrUpdateMapping,
  getMapping,
  getSelectors,
  Pipeline
} from '../../api/pipeline'
import { useCallback, useContext, useState } from 'react'
import { CredentialsContext } from '../login-wrapper'
import { PlatformConnection } from '../../api/connection'
import { hasOwnProperty } from '../../util/has-own-property'
import { Input } from '../../api/destination-input'

export interface UseMappingState {
  selectors?: Selector[]
  mapping?: Mapping
  mappingInputs: Input[]
  loading: boolean
  error: any
  fetchMapping: () => void
  createOrUpdate: (
    transformations: TransformationParameters[],
    inputs: Input[]
  ) => Promise<boolean>
}

export const useMapping = (
  pipeline: Pipeline,
  connection?: PlatformConnection
): UseMappingState => {
  const [mapping, setMapping] = useState<Mapping | undefined>(undefined)
  const [mappingInputs, setMappingInputs] = useState<Input[]>([])
  const [selectors, setSelectors] = useState<Selector[] | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const { credentials } = useContext(CredentialsContext)

  const fetchMapping = useCallback(async () => {
    if (credentials == null) {
      return
    }

    setLoading(true)
    try {
      const selectors = await getSelectors(
        credentials,
        pipeline.uuid,
        connection?.id
      )
      setSelectors(selectors)
      const result = await getMapping(
        credentials,
        pipeline.uuid,
        connection?.id
      )
      if (
        result !== undefined &&
        hasOwnProperty(result, 'mapping') &&
        hasOwnProperty(result, 'inputs')
      ) {
        setMapping(result.mapping)
        setMappingInputs(result.inputs)
      } else {
        // when the mapping does not exist for existing selectors, we reset it
        // this sets the proper state for dependents of this hook
        setMapping(undefined)
        setMappingInputs([])
      }
    } catch (e) {
      setError(
        `Error while loading mapping: ${String(e.message ?? 'unknown error')}`
      )
    } finally {
      setLoading(false)
    }
  }, [credentials, pipeline, connection])

  const createOrUpdate = async (
    transformations: TransformationParameters[],
    inputs: Input[]
  ): Promise<boolean> => {
    try {
      if (credentials == null) {
        throw new Error('Invalid credentials or pipeline')
      }

      setLoading(true)
      const result = await createOrUpdateMapping(
        credentials,
        pipeline.uuid,
        transformations,
        inputs,
        connection?.id
      )
      if ('errors' in result) {
        // TODO: handle errors properly
        setError('Error occurred while updating inputs.')
        return false
      }

      setMapping(result.mapping)
      setMappingInputs(result.inputs)

      return true
    } catch (e) {
      setError(e.message ?? 'Error occurred while updating inputs.')
      return false
    } finally {
      setLoading(false)
    }
  }

  return {
    mapping,
    mappingInputs,
    selectors,
    loading,
    error,
    fetchMapping,
    createOrUpdate
  }
}
