import {
  request,
  LegacyChangeResult,
  LegacyChangeResultValues,
  TokenCredentials
} from './request'
import * as analytics from '../util/analytics'

export interface Prototype {
  name: string
  slug: string
  admin_label?: string
}

export interface Authorizer {
  prototype: Prototype
  callback_url?: string
  deauthorize_url?: string
  events_url?: string
  client_id?: string
  client_secret?: string
  trello_display_name?: string
  private_key_id?: string
  private_key_pem?: string
  google_service_account_email?: string
  google_verification_filename?: string
  hubspot_app_id?: string
  hubspot_api_key?: string
  segment_destination_slug?: string
  bamboohr_application_key?: string
}

export interface AuthorizerResult extends LegacyChangeResult<Authorizer> {
  authorizer: Authorizer
}

export type AuthorizerResultValues = LegacyChangeResultValues<Authorizer>

export async function listAuthorizers(
  credentials: TokenCredentials
): Promise<Authorizer[]> {
  const { authorizers } = await request({
    path: '/authorizers'
  })

  return authorizers as Authorizer[]
}

export async function getAuthorizer(
  credentials: TokenCredentials,
  prototypeSlug: string
): Promise<Authorizer> {
  const { authorizer } = await request({
    path: `/authorizers/${prototypeSlug}`,
    credentials
  })

  return authorizer as Authorizer
}

export async function updateAuthorizer(
  credentials: TokenCredentials,
  authorizer: Authorizer
): Promise<AuthorizerResult> {
  const { errors, authorizer: updatedAuthorizer } = await request({
    path: `/authorizers/${authorizer.prototype.slug}`,
    method: 'PUT',
    credentials,
    body: {
      authorizer
    }
  })

  return {
    // TODO: please fix following typescript error
    // @ts-expect-error
    authorizer: updatedAuthorizer,
    // TODO: please fix following typescript error
    // @ts-expect-error
    errors
  }
}

export async function updateAuthorizerValues(
  credentials: TokenCredentials,
  authorizer: Authorizer
): Promise<AuthorizerResultValues> {
  const { errors, authorizer: updatedAuthorizer } = await updateAuthorizer(
    credentials,
    authorizer
  )

  return {
    values: updatedAuthorizer,
    errors
  }
}

export async function newAuthorizer(
  credentials: TokenCredentials,
  prototypeSlug: string
): Promise<Authorizer> {
  const { authorizer } = await request({
    path: `/authorizers/${prototypeSlug}/new`,
    credentials
  })

  return authorizer as Authorizer
}

export async function createAuthorizer(
  credentials: TokenCredentials,
  params: Authorizer
): Promise<AuthorizerResult> {
  const { errors, authorizer } = await request({
    path: `/authorizers/${params.prototype.slug}`,
    method: 'POST',
    credentials,
    body: {
      authorizer: params
    }
  })

  if (authorizer != null) {
    analytics.track('create-authorizer', {
      prototypeSlug: params.prototype.slug
    })
  }

  return {
    // TODO: please fix following typescript error
    // @ts-expect-error
    authorizer,
    // TODO: please fix following typescript error
    // @ts-expect-error
    errors
  }
}
