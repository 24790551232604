import {
  CheckboxProps,
  Heading,
  majorScale,
  Pane,
  SelectField,
  SelectFieldProps,
  Switch,
  Text,
  TextareaField,
  TextareaFieldProps,
  TextInputField,
  TextInputFieldProps,
  useTheme
} from 'evergreen-ui'
import React, { FC, memo } from 'react'
import { CRMAPIErrors } from '../../api/crm'

export interface Field {
  number?: boolean
  textarea?: boolean
  select?: React.ReactNode
  checkbox?: boolean
  checked?: boolean
  value: string
  label: string
  description?: string | React.ReactNode
  placeholder?: string
  setField: (value: any) => void
  errorKey: string
}

interface FieldsProps {
  fields: Field[]
  errors: CRMAPIErrors
  setErrors: React.Dispatch<React.SetStateAction<CRMAPIErrors>>
}

const Fields: FC<FieldsProps> = memo(({ fields, errors, setErrors }) => {
  const theme = useTheme()
  return (
    <>
      {fields.map((field) => {
        const {
          number,
          textarea,
          select,
          checkbox,
          checked,
          value,
          label,
          description,
          placeholder,
          setField,
          errorKey
        } = field

        let Component: React.ComponentType<
          | TextInputFieldProps
          | TextareaFieldProps
          | SelectFieldProps
          | CheckboxProps
        > = textarea ? TextareaField : TextInputField

        let children: React.ReactNode | undefined
        if (select) {
          Component = SelectField
          children = select
        }

        if (checkbox) {
          return (
            <Pane key={errorKey} marginBottom={majorScale(3)}>
              <Heading size={400} color={theme.colors.text.default}>
                {label}
              </Heading>
              <Pane
                display='flex'
                alignItems='center'
                marginTop={majorScale(1)}
              >
                <Switch
                  checked={checked}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setField(event.target.checked)
                    setErrors({
                      ...errors,
                      [errorKey]: []
                    })
                  }}
                />
                <Text
                  size={400}
                  color={theme.colors.text.muted}
                  marginLeft={majorScale(2)}
                >
                  {description}
                </Text>
              </Pane>
            </Pane>
          )
        }

        return (
          <Component
            key={errorKey}
            value={value}
            label={label}
            description={description}
            placeholder={select ? undefined : placeholder}
            isInvalid={Boolean(errors[errorKey]?.length)}
            validationMessage={
              errors[errorKey]?.length
                ? `${label} ${errors[errorKey][0].message}`
                : undefined
            }
            onChange={(
              event: React.ChangeEvent<
                HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
              >
            ) => {
              if (number) {
                if (/^\d+$/.test(event.target.value)) {
                  setField(parseInt(event.target.value, 10))
                  setErrors({
                    ...errors,
                    [errorKey]: []
                  })
                }
              } else {
                setField(event.target.value)
                setErrors({
                  ...errors,
                  [errorKey]: []
                })
              }
            }}
          >
            {children}
          </Component>
        )
      })}
    </>
  )
})

Fields.displayName = 'Fields'

export default Fields
