import {
  BackButton,
  Button,
  Card,
  Heading,
  IconButton,
  majorScale,
  minorScale,
  Pane,
  Paragraph,
  SelectMenu,
  toaster,
  TrashIcon,
  useTheme
} from 'evergreen-ui'
import React, { FC, Fragment, memo, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AppObject, updateAppObject } from '../../api/crm'
import { TokenCredentials } from '../../api/request'
import { Accordion } from '../components/accordion'
import { ContentCard } from '../components/content-card'
import { Divider } from '../components/divider'
import { NewItemCard } from '../components/new-item-card'
import { SpinnerSuspend } from '../components/spinner'
import { useTitle } from '../hooks/common'
import { AppObjectTabType } from './app-object'
import EditAppObjectAction from './edit-app-object-action'

interface AppObjectActionsProps {
  object: AppObject | undefined
  loading: boolean
  credentials: TokenCredentials | null
  refresh: () => Promise<void>
}

const AppObjectActions: FC<AppObjectActionsProps> = memo(
  ({ object, loading, credentials, refresh }) => {
    useTitle(`${object?.label_one ?? ''} App Object Actions`)
    const theme = useTheme()

    const [primaryActionID, setPrimaryActionID] = useState<number | null>(
      object?.primary_action_id ?? null
    )
    const [mainLinkActionID, setMainLinkActionID] = useState<number | null>(
      object?.main_link_action_id ?? null
    )
    const [emptyStateActionID, setEmptyStateActionID] = useState<number | null>(
      object?.empty_state_action_id ?? null
    )
    const [saving, setSaving] = useState<boolean>(false)

    useEffect(() => {
      if (object) {
        setPrimaryActionID(object?.primary_action_id ?? null)
        setMainLinkActionID(object?.main_link_action_id ?? null)
        setEmptyStateActionID(object?.empty_state_action_id ?? null)
      }
    }, [object])

    const primaryAction = object?.actions.find(
      (action) => action.id === primaryActionID
    )
    const mainLinkAction = object?.actions.find(
      (action) => action.id === mainLinkActionID
    )
    const emptyStateAction = object?.actions.find(
      (action) => action.id === emptyStateActionID
    )

    const saveSpecialActions: () => Promise<void> = async () => {
      if (object) {
        if (credentials == null) {
          toaster.danger('Invalid credentials')
          return
        }
        setSaving(true)
        const response = await updateAppObject(credentials, {
          ...object,
          primary_action_id: primaryActionID,
          main_link_action_id: mainLinkActionID,
          empty_state_action_id: emptyStateActionID
        })
        if (response.errors) {
          console.error('Could not save special actions', response.errors)
          toaster.danger('Could not save special actions')
          setSaving(false)
        } else {
          toaster.success('Special actions saved')
          setSaving(false)
          await refresh()
        }
      }
    }

    return (
      <>
        <SpinnerSuspend spinning={loading}>
          {object ? (
            <>
              {object.actions.length ? (
                <>
                  <ContentCard
                    elevation={1}
                    marginBottom={majorScale(2)}
                    width={570}
                  >
                    {object.actions.map((action, index) => (
                      <Fragment key={action.id}>
                        <Accordion title={action.label}>
                          <EditAppObjectAction
                            object={object}
                            action={action}
                            credentials={credentials}
                            refresh={refresh}
                          />
                        </Accordion>
                        {index === object.actions.length - 1 ? null : (
                          <Divider />
                        )}
                      </Fragment>
                    ))}
                  </ContentCard>
                  <ContentCard
                    elevation={1}
                    padding={majorScale(2)}
                    marginBottom={majorScale(2)}
                    width={570}
                  >
                    <Heading size={600}>Special Actions</Heading>
                    <Heading
                      size={400}
                      marginTop={majorScale(3)}
                      color={theme.colors.text.default}
                    >
                      Primary Action
                    </Heading>
                    <Paragraph
                      size={400}
                      marginBottom={minorScale(1)}
                      color={theme.colors.text.muted}
                    >
                      The selected action will be available as a separate button
                    </Paragraph>
                    <Paragraph
                      size={300}
                      marginBottom={minorScale(2)}
                      color={theme.colors.text.muted}
                    >
                      Note: You can only select actions that are scoped to a
                      collection
                    </Paragraph>
                    <Pane display='flex'>
                      <SelectMenu
                        title='Select action...'
                        options={object.actions
                          .filter((action) => action.scope === 'collection')
                          .map((action) => ({
                            label: action.label,
                            value: action.id
                          }))}
                        onSelect={async (item) => {
                          setPrimaryActionID(+item.value)
                        }}
                        closeOnSelect={true}
                      >
                        <Button>
                          {primaryAction
                            ? primaryAction.label
                            : 'Select action...'}
                        </Button>
                      </SelectMenu>
                      {primaryAction ? (
                        <IconButton
                          icon={TrashIcon}
                          intent='danger'
                          width={32}
                          marginLeft={majorScale(2)}
                          onClick={async () => {
                            setPrimaryActionID(null)
                          }}
                        />
                      ) : null}
                    </Pane>
                    <Heading
                      size={400}
                      marginTop={majorScale(3)}
                      color={theme.colors.text.default}
                    >
                      Main Link
                    </Heading>
                    <Paragraph
                      size={400}
                      marginBottom={minorScale(1)}
                      color={theme.colors.text.muted}
                    >
                      The title of an App Object will available as the selected
                      link
                    </Paragraph>
                    <Paragraph
                      size={300}
                      marginBottom={minorScale(2)}
                      color={theme.colors.text.muted}
                    >
                      Note: You can only select actions that are scoped to a
                      record and are of type 'link'
                    </Paragraph>
                    <Pane display='flex'>
                      <SelectMenu
                        title='Select action...'
                        options={object.actions
                          .filter(
                            (action) =>
                              action.scope === 'object' &&
                              action.type === 'link'
                          )
                          .map((action) => ({
                            label: action.label,
                            value: action.id
                          }))}
                        onSelect={async (item) => {
                          setMainLinkActionID(+item.value)
                        }}
                        closeOnSelect={true}
                      >
                        <Button>
                          {mainLinkAction
                            ? mainLinkAction.label
                            : 'Select action...'}
                        </Button>
                      </SelectMenu>
                      {mainLinkAction ? (
                        <IconButton
                          icon={TrashIcon}
                          intent='danger'
                          width={32}
                          marginLeft={majorScale(2)}
                          onClick={async () => {
                            setMainLinkActionID(null)
                          }}
                        />
                      ) : null}
                    </Pane>
                    <Heading
                      size={400}
                      marginTop={majorScale(3)}
                      color={theme.colors.text.default}
                    >
                      Empty State Action
                    </Heading>
                    <Paragraph
                      size={400}
                      marginBottom={minorScale(1)}
                      color={theme.colors.text.muted}
                    >
                      The selected action will be shown when there are no App
                      Objects to display
                    </Paragraph>
                    <Paragraph
                      size={300}
                      marginBottom={minorScale(2)}
                      color={theme.colors.text.muted}
                    >
                      Note: You can only select actions that are scoped to a
                      collection
                    </Paragraph>
                    <Pane display='flex'>
                      <SelectMenu
                        title='Select action...'
                        options={object.actions
                          .filter((action) => action.scope === 'collection')
                          .map((action) => ({
                            label: action.label,
                            value: action.id
                          }))}
                        onSelect={async (item) => {
                          setEmptyStateActionID(+item.value)
                        }}
                        closeOnSelect={true}
                      >
                        <Button>
                          {emptyStateAction
                            ? emptyStateAction.label
                            : 'Select action...'}
                        </Button>
                      </SelectMenu>
                      {emptyStateAction ? (
                        <IconButton
                          icon={TrashIcon}
                          intent='danger'
                          width={32}
                          marginLeft={majorScale(2)}
                          onClick={async () => {
                            setEmptyStateActionID(null)
                          }}
                        />
                      ) : null}
                    </Pane>
                    <Card marginTop={majorScale(4)}>
                      <Button
                        appearance='primary'
                        isLoading={saving}
                        onClick={async () => {
                          try {
                            await saveSpecialActions()
                          } catch (error) {
                            console.error(error)
                            toaster.danger('Could not save special actions')
                          }
                        }}
                      >
                        Save
                      </Button>
                    </Card>
                  </ContentCard>
                </>
              ) : null}
              <NewItemCard
                to={`/app-objects/${object.id}/${AppObjectTabType.Actions}/new`}
                width={570}
                height={60}
                marginTop={majorScale(2)}
                title='Add Action'
              />
            </>
          ) : null}
        </SpinnerSuspend>
        <BackButton is={Link} to={`/app-objects`} marginTop={majorScale(3)}>
          Back to App Objects
        </BackButton>
      </>
    )
  }
)

AppObjectActions.displayName = 'AppObjectActions'

export default AppObjectActions
