import { Button, Heading, majorScale, Pane, toaster } from 'evergreen-ui'
import React, { FC, memo, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CRMAPIErrors, CRMObject, saveCRMObjects } from '../../api/crm'
import { TokenCredentials } from '../../api/request'
import { ContentCard } from '../components/content-card'
import Fields from './fields'

interface EditCRMObjectProps {
  object?: CRMObject
  objects: CRMObject[]
  credentials: TokenCredentials | null
}

const EditCRMObject: FC<EditCRMObjectProps> = memo(
  ({ object, objects, credentials }) => {
    const [slug, setSlug] = useState<string>(object ? object.slug : '')
    const [label, setLabel] = useState<string>(object ? object.label : '')
    const [description, setDescription] = useState<string>(
      object ? object.description : ''
    )
    const [loading, setLoading] = useState<boolean>(false)
    const [errors, setErrors] = useState<CRMAPIErrors>({})

    const history = useHistory()
    const navigateToCRMObjectsList = useCallback(() => {
      history.push(`/crm-objects`)
    }, [history])

    const fields = [
      {
        value: slug,
        label: 'Slug',
        description: 'Unique identifier that appears in the Xkit API',
        placeholder: 'Slug',
        setField: setSlug,
        errorKey: 'slug'
      },
      {
        value: label,
        label: 'Name',
        description: 'Human friendly name that appears to users during mapping',
        placeholder: 'Name',
        setField: setLabel,
        errorKey: 'label'
      },
      {
        textarea: true,
        value: description,
        label: 'Description',
        description:
          'A description to help your users determine the right object in their CRM to map to it',
        placeholder: 'Description',
        setField: setDescription,
        errorKey: 'description'
      }
    ]

    const deleteObject: () => Promise<void> = async () => {
      if (!object) {
        return
      }
      if (credentials == null) {
        toaster.danger('Invalid credentials')
        return
      }
      const index = objects.findIndex(
        (individualObject) => individualObject.id === object.id
      )
      if (!(index > -1)) {
        return
      }
      objects.splice(index, 1)
      const response = await saveCRMObjects(credentials, objects)
      if (response.errors) {
        console.error('Could not remove CRM Object', response.errors)
        toaster.danger('Could not remove CRM Object')
      } else {
        toaster.success('CRM Object removed')
        navigateToCRMObjectsList()
      }
    }

    let saveObject: () => Promise<void> = async () => {
      if (credentials == null) {
        toaster.danger('Invalid credentials')
        return
      }
      const response = await saveCRMObjects(credentials, [
        ...objects,
        { slug, label, description }
      ])
      if (
        response.errors?.crm_objects &&
        response.errors.crm_objects.length > objects.length
      ) {
        setErrors(response.errors.crm_objects[objects.length])
        console.error('Could not create CRM Object', response.errors)
        toaster.danger('Could not create CRM Object')
      } else {
        toaster.success('CRM Object created')
        navigateToCRMObjectsList()
      }
    }
    if (object) {
      saveObject = async () => {
        if (credentials == null) {
          toaster.danger('Invalid credentials')
          return
        }
        const index = objects.findIndex(
          (individualObject) => individualObject.id === object.id
        )
        if (!(index > -1)) {
          return
        }
        const id = objects[index].id
        objects[index] = {
          id,
          slug,
          label,
          description
        }
        const response = await saveCRMObjects(credentials, objects)
        if (response.errors?.crm_objects) {
          setErrors(response.errors.crm_objects[index])
          console.error('Could not update CRM Object', response.errors)
          toaster.danger('Could not update CRM Object')
        } else {
          toaster.success('CRM Object updated')
        }
      }
    }

    return (
      <ContentCard
        elevation={1}
        marginBottom={majorScale(2)}
        width={570}
        padding={majorScale(2)}
      >
        {object ? (
          <Heading
            size={600}
            paddingBottom={10}
            textOverflow='ellipsis'
            overflow='hidden'
            whiteSpace='nowrap'
          >
            {object.label}
          </Heading>
        ) : null}
        {object ? (
          <Pane display='flex' justifyContent='end'>
            <Button
              appearance='minimal'
              intent='danger'
              iconBefore='trash'
              isLoading={loading}
              onClick={async () => {
                setLoading(true)
                try {
                  await deleteObject()
                } catch (error) {
                  console.error(error)
                  toaster.danger('Could not remove CRM Object')
                }
                setLoading(false)
              }}
            >
              Remove CRM Object
            </Button>
          </Pane>
        ) : null}
        <Fields fields={fields} errors={errors} setErrors={setErrors} />
        <Button
          appearance='primary'
          isLoading={loading}
          onClick={async () => {
            setLoading(true)
            try {
              await saveObject()
            } catch (error) {
              console.error(error)
              toaster.danger('Could not save CRM Object')
            }
            setLoading(false)
          }}
        >
          {object ? 'Save' : 'Add CRM Object'}
        </Button>
      </ContentCard>
    )
  }
)

EditCRMObject.displayName = 'EditCRMObject'

export default EditCRMObject
