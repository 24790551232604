import React, { memo, FunctionComponent, useState, useEffect } from 'react'
import {
  Button,
  Pane,
  Paragraph,
  ShareIcon,
  Switch,
  Table,
  Text,
  majorScale,
  useTheme
} from 'evergreen-ui'
import { FormProps } from './form-props'
import { AppBuildStatus } from './app-build-status'
import { useAppBuild } from '../../hooks/app-build'
import { useTitle, useErrorToaster } from '../../hooks/common'
import { SpinnerSuspend } from '../../components/spinner'
import DeleteDialog from '../../delete-dialog'

const HUBSPOT_OBJECT_TYPES = [
  { slug: 'contacts', label: 'Contacts' },
  { slug: 'companies', label: 'Companies' },
  { slug: 'deals', label: 'Deals' },
  { slug: 'tickets', label: 'Tickets' }
]

type Cards = Record<string, string[]>

export const HubSpotForm: FunctionComponent<FormProps> = memo(
  ({ provider, appObjects }) => {
    const {
      loading,
      enqueueing,
      inProgress,
      error,
      appBuild,
      enqueueBuild,
      refresh
    } = useAppBuild(provider.slug)
    useTitle('CRM Embed for HubSpot')
    useErrorToaster(error)
    const theme = useTheme()
    const [cards, setCards] = useState<Cards>({})

    useEffect(() => {
      setCards(appBuild == null ? {} : (appBuild.params.cards as Cards))
    }, [appBuild])

    function hasObjectType(appObjectSlug: string, objectType: string): boolean {
      const objectTypes = cards[appObjectSlug] ?? []
      return objectTypes.includes(objectType)
    }

    function setObjectType(
      appObjectSlug: string,
      objectType: string,
      selected: boolean
    ): void {
      let objectTypes = cards[appObjectSlug] ?? []
      // By always removing the slug we avoid duplicates.
      objectTypes = objectTypes.filter((slug) => slug !== objectType)
      if (selected) {
        objectTypes = objectTypes.concat([objectType])
      }
      const newCards = { ...cards, [appObjectSlug]: objectTypes }
      setCards(newCards)
    }

    function hasValidObjectTypes(): boolean {
      return appObjects.every((appObject) => {
        const objectTypes = cards[appObject.slug] ?? []
        return objectTypes.length !== 0
      })
    }

    async function handleClickBuild(): Promise<void> {
      await enqueueBuild({ cards: cards })
      await refresh()
    }

    return (
      <SpinnerSuspend spinning={appBuild == null}>
        <Paragraph color={theme.scales.gray[700]}>
          CRM Embed for HubSpot uses{' '}
          <a
            href='https://developers.hubspot.com/docs/api/crm/extensions/custom-cards'
            target='_blank'
            // TODO: please fix following typescript error
            // @ts-expect-error
            noreferrer='true'
            external='true'
            rel='noreferrer'
          >
            CRM custom cards
            <ShareIcon size={12} marginLeft={4} />
          </a>
          . Xkit will create a card for each App Object that you have defined.
        </Paragraph>

        <Text
          size={400}
          marginTop={majorScale(4)}
          display='block'
          fontWeight={500}
        >
          Associated HubSpot object types
        </Text>

        <Paragraph>
          Each card can appear on multiple HubSpot objects. At least one HubSpot
          object is required for each App Object.
        </Paragraph>

        <Table marginTop={majorScale(1)}>
          <Table.Head>
            {appObjects.map((appObject) => (
              <Table.TextHeaderCell key={appObject.slug}>
                {appObject.label_many}
              </Table.TextHeaderCell>
            ))}
          </Table.Head>
          <Table.Body>
            {HUBSPOT_OBJECT_TYPES.map((objectType) => (
              <Table.Row key={objectType.slug}>
                {appObjects.map((appObject) => (
                  <Table.Cell key={appObject.slug}>
                    <Switch
                      checked={hasObjectType(appObject.slug, objectType.slug)}
                      onChange={(e) =>
                        setObjectType(
                          appObject.slug,
                          objectType.slug,
                          e.target.checked
                        )
                      }
                    />
                    <Text marginLeft={majorScale(1)}>{objectType.label}</Text>
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <DeleteDialog
          deleteName='Confirm'
          deletingName='Enqueueing...'
          noun='destructive action'
          dialogText={
            <span>
              This will replace all existing CRM cards in your HubSpot app and
              will <strong>immediately</strong> take effect in your customers'
              HubSpot accounts.
            </span>
          }
          onDelete={handleClickBuild}
        >
          {(showDeleteDialog) => {
            return (
              <Button
                appearance='primary'
                marginTop={majorScale(4)}
                onClick={showDeleteDialog}
                disabled={loading || inProgress || !hasValidObjectTypes()}
                isLoading={enqueueing}
              >
                Build
              </Button>
            )
          }}
        </DeleteDialog>
        {appBuild != null && (
          <Pane marginTop={majorScale(3)}>
            <AppBuildStatus appBuild={appBuild} />
          </Pane>
        )}
      </SpinnerSuspend>
    )
  }
)

HubSpotForm.displayName = 'HubSpotForm'
