import React from 'react'
import {
  CardProps,
  FunctionIcon,
  Heading,
  Pane,
  majorScale
} from 'evergreen-ui'
import { ContentCard } from '../components/content-card'

type FunctionCardProps = {
  name: string
} & CardProps

function functionCard({
  name,
  ...cardProps
}: FunctionCardProps): React.ReactElement {
  return (
    <ContentCard
      padding={majorScale(2)}
      display='flex'
      flexDirection='column'
      width='278px'
      height='108px'
      cursor='pointer'
      {...cardProps}
    >
      <Pane display='flex'>
        <FunctionIcon size={40} color='black' />
      </Pane>
      <Heading
        size={600}
        marginTop={majorScale(1)}
        textOverflow='ellipsis'
        overflow='hidden'
      >
        {name}
      </Heading>
    </ContentCard>
  )
}

export const FunctionCard: React.FC<FunctionCardProps> = (props) => {
  return functionCard(props)
}

FunctionCard.displayName = 'FunctionCard'
