import { Connection } from '@xkit-co/xkit.js/lib/api/connection'
import {
  Button,
  Heading,
  IconButton,
  majorScale,
  Menu,
  Pane,
  PeopleIcon,
  RefreshIcon,
  Spinner,
  Table,
  toaster
} from 'evergreen-ui'
import React, { FC, memo, useCallback, useContext, useState } from 'react'
import { Link, Redirect, useHistory } from 'react-router-dom'
import {
  deleteConnection,
  getConnectionReadableTitle,
  PlatformConnection
} from '../../api/connection'
import { Connector } from '../../api/connector'
import {
  getPlatformContextReadableTitle,
  isTestContext
} from '../../api/platform-user'
import { TokenCredentials } from '../../api/request'
import errorMessage from '../../util/error-message'
import { MoreMenu } from '../components/more-menu'
import { TableRowEmpty } from '../components/table-row-empty'
import { TestContextTooltip } from '../components/test-context-tooltip'
import ContentTable from '../content-table'
import DeleteDialog from '../delete-dialog'
import { useErrorToaster } from '../hooks/common'
import { useConnectionsByConnector } from '../hooks/connections-by-connector.hook'
import { usePipelinesByConnector } from '../hooks/pipelines-by-connector.hook'
import { useSyncsByConnector } from '../hooks/syncs.hook'
import { useCredentials } from '../login-wrapper'
import { XkitContext } from '../xkit-wrapper'
import { ConnectionStatusBadge } from './connection-status-badge'

interface ConnectionsTableProps {
  loading: boolean
  connector?: Connector
}

interface TestConnectionButtonProps {
  appearance: 'minimal' | 'primary'
  connector: Connector
  onAddConnection: (connection: Connection) => void
}

const tableHead = (
  <Table.Head display='flex'>
    <Table.TextHeaderCell flexBasis={1} flexGrow={2}>
      Context
    </Table.TextHeaderCell>
    <Table.TextHeaderCell flexBasis={1} flexGrow={2}>
      Connection
    </Table.TextHeaderCell>
    <Table.TextHeaderCell flexBasis={1} flexGrow={1}>
      Status
    </Table.TextHeaderCell>
  </Table.Head>
)

const TestConnectionButton: FC<TestConnectionButtonProps> = memo(
  ({ appearance, connector, onAddConnection }) => {
    const { xkit } = useContext(XkitContext)
    const [loading, setLoading] = useState(false)

    const handleAdd = useCallback(async () => {
      if (!xkit) {
        toaster.danger('Xkit is not yet initialized')
        return
      }

      setLoading(true)

      try {
        const connection = await xkit.addConnection(connector.slug)
        toaster.success(`Created test connection for ${connector.name}`)
        onAddConnection(connection)
      } catch (e) {
        toaster.danger(`Error during test: ${errorMessage(e)}`)
      } finally {
        setLoading(false)
      }
    }, [xkit, connector.name, connector.slug, onAddConnection])

    return (
      <Table.Row
        display='flex'
        alignItems='center'
        justifyContent='center'
        height={appearance === 'primary' ? majorScale(10) : undefined}
      >
        <Button
          appearance={appearance}
          iconBefore={loading ? undefined : 'add'}
          onClick={handleAdd}
          isLoading={loading}
        >
          Add test connection
        </Button>
      </Table.Row>
    )
  }
)

TestConnectionButton.displayName = 'TestConnectionButton'

export const ConnectionsTable: FC<ConnectionsTableProps> = memo(
  ({ loading, connector }) => {
    const [removeConnectionLoading, setRemoveConnectionLoading] =
      useState(false)
    const credentials = useCredentials()
    const {
      connections,
      loading: connectionsLoading,
      error: connectionsError,
      refresh
    } = useConnectionsByConnector(connector)
    useErrorToaster(connectionsError)

    const {
      loading: pipelinesLoading,
      error: pipelinesError,
      pipelines
    } = usePipelinesByConnector(connector)
    useErrorToaster(pipelinesError)

    const {
      syncs,
      loading: syncsLoading,
      error: syncsError
    } = useSyncsByConnector(connector?.slug)
    useErrorToaster(syncsError)

    const history = useHistory()

    const refreshOrNavigateToConnection = useCallback(
      (connection: Connection) => {
        if (connector?.crm) {
          void refresh()
        } else {
          history.push(`connections/${connection.id}`)
        }
      },
      [connector?.crm, refresh, history]
    )

    const hasTestConnection = connections.some(({ context }) =>
      isTestContext(context)
    )
    const showTestButton =
      !hasTestConnection || connector?.supports_multiple_connections

    const removeConnection = useCallback(
      async (
        credentials: TokenCredentials | null,
        connection: PlatformConnection
      ) => {
        if (credentials == null) {
          toaster.danger('Invalid credentials')
          return
        }
        try {
          setRemoveConnectionLoading(true)
          await deleteConnection(credentials, connection)
          toaster.success(
            `Removed connection '${getPlatformContextReadableTitle(
              connection.context
            )}'`
          )
          await refresh()
        } catch (e) {
          toaster.danger(`Error when removing connection: ${errorMessage(e)}`)
        } finally {
          setRemoveConnectionLoading(false)
        }
      },
      [refresh]
    )

    // If there isn't a pipeline yet for a sync provider, first load the pipelines screen
    if (
      !loading &&
      connector &&
      connector.sync &&
      !pipelinesLoading &&
      !pipelines.length
    ) {
      return <Redirect to={`/providers/${connector.slug}/pipelines`} />
    }

    return (
      <>
        <Pane marginBottom={majorScale(2)} display='flex' alignItems='center'>
          <Heading size={600} marginRight={majorScale(1)}>
            Connections
          </Heading>
          <IconButton icon={RefreshIcon} onClick={refresh} title='Refresh' />
        </Pane>
        <ContentTable
          heading={tableHead}
          loading={loading || connectionsLoading}
        >
          <Table.Body>
            {connections.length === 0 && !showTestButton ? (
              <Table.Body>
                <TableRowEmpty message='No connections' icon={PeopleIcon} />
              </Table.Body>
            ) : null}
            {connections.map((connection) => (
              <Table.Row
                key={connection.id}
                isSelectable={!connector?.crm}
                is={connector?.crm ? undefined : Link}
                to={connector?.crm ? undefined : `connections/${connection.id}`}
                display='flex'
              >
                <Table.TextCell flexBasis={1} flexGrow={2}>
                  {getPlatformContextReadableTitle(connection.context)}
                  <TestContextTooltip context={connection.context} />
                </Table.TextCell>
                <Table.TextCell flexBasis={1} flexGrow={2}>
                  {getConnectionReadableTitle(connection)}
                </Table.TextCell>
                <Table.TextCell flexBasis={1} flexGrow={1}>
                  <Pane
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <ConnectionStatusBadge
                      connection={connection}
                      syncs={syncs.filter(
                        (sync) => sync.connectionId === connection.id
                      )}
                      loading={syncsLoading}
                    />
                    {removeConnectionLoading ? (
                      <Spinner margin={majorScale(1)} size={16} />
                    ) : (
                      <MoreMenu margin={majorScale(1)} flexShrink='0'>
                        <DeleteDialog
                          noun='connection'
                          onDelete={async () => {
                            await removeConnection(credentials, connection)
                          }}
                          dialogText={`
                          This will permanently delete the connection.
                          The authorization made by your user will be removed from Xkit, until the user decides to connect again.
                          Use with caution!
                        `}
                        >
                          {(showDeleteDialog) => {
                            return (
                              <Menu.Item
                                intent='danger'
                                icon='trash'
                                onSelect={showDeleteDialog}
                              >
                                Delete
                              </Menu.Item>
                            )
                          }}
                        </DeleteDialog>
                      </MoreMenu>
                    )}
                  </Pane>
                </Table.TextCell>
              </Table.Row>
            ))}
            {showTestButton && connector != null ? (
              <TestConnectionButton
                appearance={hasTestConnection ? 'minimal' : 'primary'}
                connector={connector}
                onAddConnection={refreshOrNavigateToConnection}
              />
            ) : null}
          </Table.Body>
        </ContentTable>
      </>
    )
  }
)

ConnectionsTable.displayName = 'ConnectionsTable'
