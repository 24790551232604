import React, { FC, memo } from 'react'
import { FilterFieldProps } from './filter-field'
import { HistoryCountFilterOverrideField } from './history-count-filter-override-field'

// UI for the supported Google Play Reviews filters
export const GooglePlayReviewsFilterField: FC<FilterFieldProps> = memo(
  ({ syncFilters, sourceFilters, filterErrors, onChange }) => {
    return (
      <HistoryCountFilterOverrideField
        syncFilters={syncFilters}
        sourceFilters={sourceFilters}
        filterErrors={filterErrors}
        onChange={onChange}
        label='Historical reviews limit'
        description='The number of historical reviews to sync'
      />
    )
  }
)

GooglePlayReviewsFilterField.displayName = 'GooglePlayReviewsFilterField'
