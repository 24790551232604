import {
  ErrorIcon,
  minorScale,
  Pane,
  PauseIcon,
  Position,
  TickCircleIcon,
  TimeIcon,
  Tooltip,
  useTheme,
  WarningSignIcon
} from 'evergreen-ui'
import React, { ComponentType, FC, memo } from 'react'
import { PlatformConnection } from '../../api/connection'
import { BasicSync } from '../../api/sync'
import { getSyncStatus, Status } from './connection-status-badge'

interface BaseIconProps {
  tooltip: string
  color: string
  icon: ComponentType<any>
}

const BaseIcon: FC<BaseIconProps> = memo(({ tooltip, color, icon: Icon }) => {
  const theme = useTheme()
  return (
    <Tooltip content={tooltip} position={Position.TOP}>
      <Pane
        display='flex'
        alignItems='center'
        justifyContent='center'
        height='40px'
        width='40px'
      >
        <Icon
          color={color}
          size={minorScale(5)}
          background={theme.palette.neutral.lightest}
        />
      </Pane>
    </Tooltip>
  )
})

BaseIcon.displayName = 'BaseIcon'

interface SyncStatusIconProps {
  connection: PlatformConnection
  sync: BasicSync
}

// Note: Most of the tooltips here are borrowed from `connection-status-badge.tsx`
export const SyncStatusIcon: FC<SyncStatusIconProps> = memo(
  ({ connection, sync }) => {
    const status = getSyncStatus(connection, [sync])

    switch (status) {
      case Status.active:
        return (
          <BaseIcon
            tooltip='Sync is up and running'
            color='success'
            icon={TickCircleIcon}
          />
        )
      case Status.connectionError:
        return (
          <BaseIcon
            tooltip='Your user will need to re-authorize this connection'
            color='warning'
            icon={WarningSignIcon}
          />
        )
      case Status.syncError:
        return <BaseIcon tooltip='Sync error' color='danger' icon={ErrorIcon} />
      case Status.syncPaused:
        return (
          <BaseIcon tooltip='Sync is paused' color='muted' icon={PauseIcon} />
        )
      case Status.syncNotStarted:
        return (
          <BaseIcon
            tooltip='Sync not started'
            color='info'
            // Would have preferred to use an Hourglass icon or a strikethrough
            // Play icon, but those weren't available in the component library.
            icon={TimeIcon}
          />
        )
      default:
        return null
    }
  }
)

SyncStatusIcon.displayName = 'SyncStatusIcon'
