import * as React from 'react'
import {
  Link,
  withRouter,
  RouteComponentProps,
  matchPath
} from 'react-router-dom'
import {
  IconProps,
  SidebarTab,
  Spinner,
  Tooltip,
  Position,
  majorScale
} from 'evergreen-ui'

interface NavMenuItemProps {
  linkTo?: string
  linkExternal?: string
  loading?: boolean
  onSelect?: () => void
  // See Evergreen's internal definition of `IconComponent`
  Icon: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<Omit<IconProps, 'icon'>> &
      React.RefAttributes<SVGElement>
  >
  exact?: boolean
  strict?: boolean
  sensitive?: boolean
  exclude?: string | string[]
  iconOnly?: boolean
}

interface SidebarTabProps {
  onSelect: () => void
  is?: React.Component | string
  to?: string
  href?: string
}

function noop(): void {}

class NavMenuItem extends React.Component<
  RouteComponentProps & NavMenuItemProps
> {
  matchesLinkTo(): boolean {
    const { linkTo, exact, strict, sensitive, location } = this.props

    if (linkTo == null) {
      return false
    }

    return (
      matchPath(location.pathname, {
        path: linkTo,
        exact,
        strict,
        sensitive
      }) != null
    )
  }

  matchesExclude(): boolean {
    const { exclude, exact, strict, sensitive, location } = this.props

    if (exclude == null) {
      return false
    }

    return (
      matchPath(location.pathname, {
        path: exclude,
        exact,
        strict,
        sensitive
      }) != null
    )
  }

  renderIcon(): React.ReactNode {
    const { loading, iconOnly, Icon } = this.props

    const margins = iconOnly
      ? { marginRight: majorScale(1), marginLeft: majorScale(1) }
      : { marginRight: majorScale(2) }

    if (loading) {
      return (
        <Spinner
          {...margins}
          size={16}
          display='inline-block'
          style={{ verticalAlign: 'text-bottom' }}
        />
      )
    }

    return <Icon {...margins} style={{ verticalAlign: 'text-bottom' }} />
  }

  tabProps(): SidebarTabProps {
    const { linkTo, linkExternal, onSelect } = this.props

    if (linkTo != null && linkTo !== '') {
      return {
        // TODO: please fix following typescript error
        // @ts-expect-error
        is: Link,
        to: linkTo
      }
    }

    if (linkExternal != null && linkExternal !== '') {
      return {
        is: 'a',
        href: linkExternal,
        // TODO: please fix following typescript error
        // @ts-expect-error
        target: '_blank'
      }
    }

    return { onSelect: onSelect ?? noop }
  }

  renderTab(text?: string): React.ReactElement {
    return (
      <SidebarTab
        {...this.tabProps()}
        height={majorScale(5)}
        paddingLeft={majorScale(3)}
        isSelected={this.matchesLinkTo() && !this.matchesExclude()}
        // TODO: please fix following typescript error
        // @ts-expect-error
        appearance='dark'
      >
        {this.renderIcon()}
        {text}
      </SidebarTab>
    )
  }

  render(): React.ReactElement {
    const { children, iconOnly } = this.props

    if (iconOnly) {
      return (
        <Tooltip
          position={Position.RIGHT}
          hideDelay={0}
          showDelay={0}
          content={children}
        >
          {this.renderTab()}
        </Tooltip>
      )
    }

    // TODO: please fix following typescript error
    // @ts-expect-error
    return this.renderTab(children)
  }
}

export default withRouter(NavMenuItem)
