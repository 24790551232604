import React, { FC, memo, useCallback, useState } from 'react'
import {
  Pane,
  Paragraph,
  majorScale,
  Button,
  UnorderedList,
  ListItem,
  toaster
} from 'evergreen-ui'
import ContentSection from '../content-section'
import TestConnectorButton from '../test-connector-button'
import { Connector } from '../../api/connector'

interface ConnectorSettingsTestProps {
  connector?: Connector
  onComplete: () => void
}

const ConnectorTest: FC<ConnectorSettingsTestProps> = memo(
  ({ connector, onComplete }) => {
    const [isTested, setTested] = useState(false)

    const onCompleteHandler = useCallback(() => {
      toaster.success(`Successfully tested ${connector?.name ?? ''}.`)
      setTested(true)
    }, [connector?.name])

    return (
      <ContentSection
        title={connector?.sync ? 'Source Test' : 'Connector Test'}
      >
        <Pane marginBottom={majorScale(3)}>
          <Paragraph>
            Test {connector?.name} with your own account to:
          </Paragraph>
          <UnorderedList marginTop={majorScale(1)} marginBottom={majorScale(2)}>
            <ListItem>See the end user's experience</ListItem>
            <ListItem>Verify that you have configured it correctly</ListItem>
          </UnorderedList>
          <TestConnectorButton
            icon={isTested ? 'tick' : 'clean'}
            appearance={isTested ? 'default' : 'primary'}
            intent={isTested ? 'success' : 'none'}
            height={40}
            connector={connector}
            showLoggedInContext
            onComplete={onCompleteHandler}
          >
            Test {connector?.name}
          </TestConnectorButton>
        </Pane>
        <Pane>
          <Button
            onClick={onComplete}
            appearance={isTested ? 'primary' : 'default'}
          >
            {isTested ? 'Finish' : 'Skip'}
          </Button>
        </Pane>
      </ContentSection>
    )
  }
)
ConnectorTest.displayName = 'ConnectorTest'

export default ConnectorTest
