import React, { FC, memo } from 'react'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'
import { Pipeline } from '../../api/pipeline'
import { ConnectionsTable } from './connections-table'

interface ConnectionsTabProps {
  loading: boolean
  pipeline: Pipeline
  pipelineFetch: () => void
}

const ConnectionsTab: FC<ConnectionsTabProps> = memo(
  ({ loading, pipeline, pipelineFetch }) => {
    const { path } = useRouteMatch()

    return (
      <Switch>
        <Route path={`${path}/`} exact>
          <ConnectionsTable loading={loading} pipeline={pipeline} />
        </Route>
        <Route
          path={`${path}/:connectionId`}
          render={({ match }) => (
            <Redirect
              to={`/providers/${pipeline.source.provider.slug}/connections/${match.params.connectionId}/syncs/${pipeline.uuid}`}
            />
          )}
        />
      </Switch>
    )
  }
)

ConnectionsTab.displayName = 'ConnectionsTab'

export { ConnectionsTab }
