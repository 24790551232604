import React from 'react'
import { Pane } from 'evergreen-ui'

interface WideArrowProps {
  height?: string
}

export const WideArrow: React.FC<WideArrowProps> = ({ height = '108px' }) => {
  return (
    <Pane>
      <svg
        width='100%'
        height={height}
        fill='currentColor'
        viewBox='0 0 200 101'
      >
        <line
          x1='0'
          x2='198'
          y1='51'
          y2='51'
          stroke='#696f8c'
          strokeWidth='3px'
        />
        <polygon points='200,51 193,46 193,56' fill='#696f8c' />
      </svg>
    </Pane>
  )
}

WideArrow.displayName = 'WideArrow'
