import React, { FC, memo } from 'react'
import { majorScale, Pane, PaneProps } from 'evergreen-ui'
import { HeadingSection } from './heading-section'

interface PopoverContentProps extends PaneProps {
  title?: string
}

export const PopoverContent: FC<PopoverContentProps> = memo(
  ({ title, children, ...paneProps }) => (
    <Pane
      minWidth={majorScale(50)}
      padding={majorScale(2)}
      maxHeight='100vh'
      overflowY='scroll'
      {...paneProps}
    >
      {title ? (
        <HeadingSection marginBottom={majorScale(2)}>{title}</HeadingSection>
      ) : undefined}
      {children}
    </Pane>
  )
)

PopoverContent.displayName = 'PopoverContent'
