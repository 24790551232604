import React from 'react'
import { Pipeline } from '../../../api/pipeline'
import { Input } from '../../../api/destination-input'
import { TransformationField } from '../transformation-field/transformation-field'
import { Selector, TransformationParameters } from '../../../api/mapping'
import {
  MappingForm,
  MappingFormItem,
  transformationOverrideState
} from './form-data'
import { TransformationObjectField } from './object-input'
import { majorScale } from 'evergreen-ui'

interface InputFormProps {
  pipeline: Pipeline
  input: Input
  form: MappingForm
  selectors: Selector[]
  allowOverrides: boolean
  mappingFormItem: MappingFormItem
  onChange: (
    input: Input,
    transformation: Partial<TransformationParameters>
  ) => void
  onNewInput: (parentInput: Input, newInput: Input) => void
  onRemoveInput: (parentInput: Input, newInput: Input) => void
}

export const InputForm: React.FC<InputFormProps> = ({
  pipeline,
  input,
  form,
  selectors,
  mappingFormItem,
  allowOverrides,
  onChange,
  onNewInput,
  onRemoveInput
}) => {
  const { childInputs } = mappingFormItem
  const [overridden, currentTransformation] =
    transformationOverrideState(mappingFormItem)

  if (input.simple_type.type === 'object') {
    return (
      <TransformationObjectField
        pipeline={pipeline}
        selectors={selectors}
        overridden={overridden}
        allowOverrides={allowOverrides}
        form={form}
        parentInput={input}
        nestedInputs={childInputs}
        parentInputTransformation={currentTransformation}
        onTransformationChange={onChange}
        onNewInput={onNewInput}
        onRemoveInput={onRemoveInput}
      />
    )
  } else {
    return (
      <TransformationField
        transformation={currentTransformation}
        overridden={overridden}
        allowOverrides={allowOverrides}
        provider={pipeline.source.provider}
        selectors={selectors}
        input={input}
        marginBottom={majorScale(1)}
        onChange={(transformation) => onChange(input, transformation)}
      />
    )
  }
}
