import React, { memo, FC, ReactNode } from 'react'
import { Switch, Redirect, Route, Link, useRouteMatch } from 'react-router-dom'
import { Pane, minorScale, majorScale, TabNavigation, Tab } from 'evergreen-ui'
import { NotFoundRoute } from './not-found-route'

export interface TabRoute {
  title: string
  slug: string
  loading: boolean
  content: ReactNode
}

export interface TabRoutesProps {
  tabRoutes: TabRoute[]
}

export const TabRoutes: FC<TabRoutesProps> = memo(({ tabRoutes }) => {
  const { path, url } = useRouteMatch()

  if (!tabRoutes.length) {
    return null
  }

  return (
    <Switch>
      <Route path={path} exact>
        <Redirect to={`${url}/${tabRoutes[0].slug}`} />
      </Route>
      <Route
        path={`${path}/:tab`}
        render={({ match }) => (
          <>
            <TabNavigation marginBottom={majorScale(2)}>
              {tabRoutes.map((tab) =>
                tab.loading ? (
                  <Tab key={tab.slug}>Loading...</Tab>
                ) : (
                  <Tab
                    key={tab.slug}
                    is={Link}
                    to={`${url}/${tab.slug}`}
                    isSelected={tab.slug === match.params.tab}
                  >
                    {tab.title}
                  </Tab>
                )
              )}
            </TabNavigation>
            <Pane
              marginRight={minorScale(1)}
              marginLeft={minorScale(1)}
              marginBottom={majorScale(2)}
              background='tint1'
              flex='1'
            >
              <Switch>
                {tabRoutes.map((tab) => (
                  <Route key={tab.slug} path={`${path}/${tab.slug}`}>
                    <Pane role='tabpanel'>{tab.content}</Pane>
                  </Route>
                ))}
                <NotFoundRoute to={url} />
              </Switch>
            </Pane>
          </>
        )}
      />
    </Switch>
  )
})

TabRoutes.displayName = 'TabRoutes'
