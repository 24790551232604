import * as React from 'react'
import { Tooltip, Position, Table, Strong } from 'evergreen-ui'
import ConnectorMark from '../components/connector-mark'
import { Connector } from '../../api/connector'

function connectorTooltip(connector: Connector): string {
  if (connector.template.admin_label) {
    return `${connector.name} (${connector.template.admin_label})`
  }

  return connector.name
}

interface ConnectorColumnHeaderProps {
  connector: Connector
  minWidth: number
}

const ConnectorColumnHeader: React.FC<ConnectorColumnHeaderProps> = ({
  connector,
  minWidth
}) => {
  return (
    <Tooltip content={connectorTooltip(connector)} position={Position.TOP}>
      <Table.HeaderCell
        flexBasis={minWidth}
        flexShrink={0}
        flexGrow={1}
        display='flex'
        justifyContent='center'
      >
        {connector.mark_url ? (
          <ConnectorMark size={16} markUrl={connector.mark_url} />
        ) : (
          <Strong display='block' width={16} textAlign='center'>
            {connector.name.slice(0, 1)}
          </Strong>
        )}
      </Table.HeaderCell>
    </Tooltip>
  )
}

export default ConnectorColumnHeader
