import * as React from 'react'
import {
  Heading,
  Text,
  Paragraph,
  Icon,
  Button,
  majorScale,
  Pane,
  Table,
  Link,
  // TODO: please fix following typescript error
  // @ts-expect-error
  withTheme,
  SegmentedControl,
  IconName,
  toaster
} from 'evergreen-ui'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import InfiniteLoader from 'react-window-infinite-loader'
import {
  RouteComponentProps,
  Link as RouterLink,
  withRouter
} from 'react-router-dom'
import {
  PlatformUser,
  PlatformGroup,
  listPlatformUsers,
  listPlatformGroups,
  PlatformContext,
  listPlatformContexts
} from '../../api/platform-user'
import { Connector, listConnectors } from '../../api/connector'
import {
  PlatformConnection,
  listPlatformConnections,
  ConnectionBy,
  ConnectionStatus,
  getConnectionStatus
} from '../../api/connection'
import { debounce } from '../../util/throttle'
import { withCredentials, CredentialConsumer } from '../login-wrapper'
import ContentTable from '../content-table'
import PageButtons from '../page-buttons'
import DropdownButton from '../dropdown-button'
import TableHeadPageable from '../table-head-pageable'
import ConnectorColumnHeader from './connector-column-header'
import {
  ConnectionsRowListItem,
  targetKey,
  PlatformTarget,
  PlatformTargets
} from './connections-row'
import TableSpinner from '../components/table-spinner'
import errorMessage from '../../util/error-message'

enum StatusFilter {
  all,
  enabled,
  errored
}

const CONNECTION_LABEL = Object.freeze({
  [ConnectionBy.user]: 'User',
  [ConnectionBy.group]: 'Group',
  [ConnectionBy.context]: 'Context'
})

const ICON_NAME = Object.freeze<Record<ConnectionBy, IconName>>({
  [ConnectionBy.user]: 'person',
  [ConnectionBy.group]: 'people',
  [ConnectionBy.context]: 'people'
})

const DOCUMENTATION_LINK = Object.freeze({
  [ConnectionBy.user]: 'https://docs.xkit.co/docs/xkit-user-tokens',
  [ConnectionBy.group]: 'https://docs.xkit.co/docs/user-groups',
  [ConnectionBy.context]: '#' // TODO: Needs to add context link to the documentation
})

const FILTER_LABEL = Object.freeze({
  [StatusFilter.all]: 'All',
  [StatusFilter.enabled]: 'Enabled',
  [StatusFilter.errored]: 'Errored'
})

interface ConnectionsState {
  maxConnections: number
  users: PlatformUser[]
  groups: PlatformGroup[]
  contexts: PlatformContext[]
  connectors: Connector[]
  connections: Record<string, PlatformConnection[]>
  idSearch: string
  loading: boolean
  selected: ConnectionBy
  statusFilter: StatusFilter
  startIndex: number
}

const idColumnMinWidth = majorScale(24)
const connectionColumnMinWidth = majorScale(5)

function appendAllURLSearchParams(
  params: URLSearchParams,
  toAppend: URLSearchParams
): URLSearchParams {
  for (const [key, value] of Array.from(toAppend)) {
    params.append(key, value)
  }

  return params
}

type ConnectionsProps = CredentialConsumer<RouteComponentProps>

class Connections extends React.Component<ConnectionsProps, ConnectionsState> {
  constructor(props: ConnectionsProps) {
    super(props)
    this.state = {
      maxConnections: 0,
      startIndex: 0,
      users: [],
      groups: [],
      contexts: [],
      connectors: [],
      connections: {},
      loading: false,
      idSearch: '',
      selected: this.selectedFromSearch(),
      statusFilter: this.statusFilterFromSearch()
    }
  }

  componentDidMount(): void {
    document.title = 'Connections | Xkit'
    void this.loadTargetsAndConnectors()
  }

  componentDidUpdate(
    prevProps: ConnectionsProps,
    prevState: ConnectionsState
  ): void {
    if (
      prevState.selected !== this.state.selected ||
      prevState.statusFilter !== this.state.statusFilter
    ) {
      if (prevState.selected !== this.state.selected) {
        void this.loadTargets()
      }
      if (this.props.location.search !== this.searchFromState()) {
        this.props.history.push({
          search: this.searchFromState()
        })
      }
    } else if (this.props.location.search !== prevProps.location.search) {
      const selected = this.selectedFromSearch()
      if (selected !== this.state.selected) {
        this.setState({ selected })
      }
      const statusFilter = this.statusFilterFromSearch()
      if (statusFilter !== this.state.statusFilter) {
        this.setState({ statusFilter })
      }
    }
  }

  searchFromState(): string {
    const params = new URLSearchParams()

    appendAllURLSearchParams(params, this.selectedToParams())
    appendAllURLSearchParams(params, this.statusFilterToParams())

    return params.toString()
  }

  selectedFromSearch(): ConnectionBy {
    const params = new URLSearchParams(this.props.location.search)

    if (params.get('by') === ConnectionBy[ConnectionBy.group]) {
      return ConnectionBy.group
    }

    if (params.get('by') === ConnectionBy[ConnectionBy.user]) {
      return ConnectionBy.user
    }

    return ConnectionBy.context
  }

  selectedToParams(): URLSearchParams {
    if (this.state.selected !== ConnectionBy.user) {
      return new URLSearchParams({ by: ConnectionBy[this.state.selected] })
    }

    return new URLSearchParams()
  }

  statusFilterFromSearch(): StatusFilter {
    const params = new URLSearchParams(this.props.location.search)

    // TODO: please fix following typescript error
    // @ts-expect-error
    return StatusFilter[params.get('status')] || StatusFilter.all
  }

  statusFilterToParams(): URLSearchParams {
    if (this.state.statusFilter !== StatusFilter.all) {
      return new URLSearchParams({
        status: StatusFilter[this.state.statusFilter]
      })
    }

    return new URLSearchParams()
  }

  handleChangeMaxConnections = (maxConnections: number): void => {
    const { startIndex, connectors } = this.state

    this.setState({
      maxConnections,
      startIndex: Math.max(
        0,
        Math.min(startIndex, connectors.length - maxConnections)
      )
    })
  }

  async loadTargetsAndConnectors(): Promise<void> {
    try {
      this.setState({ loading: true })
      const [, connectors] = await Promise.all([
        this.loadTargetsUnsafe(),
        this.props.callWithCredentials(listConnectors)
      ])
      this.setState({
        connectors
      })
    } catch (e) {
      toaster.danger(`Error while loading: ${errorMessage(e)}`)
    } finally {
      this.setState({ loading: false })
    }
  }

  async loadTargets(): Promise<void> {
    try {
      this.setState({ loading: true })
      await this.loadTargetsUnsafe()
    } catch (e) {
      toaster.danger(`Error while loading: ${errorMessage(e)}`)
    } finally {
      this.setState({ loading: false })
    }
  }

  async loadTargetsUnsafe(): Promise<void> {
    if (this.state.selected === ConnectionBy.group) {
      const groups = await this.props.callWithCredentials(listPlatformGroups)
      this.setState({ groups })
    } else if (this.state.selected === ConnectionBy.context) {
      const contexts = await this.props.callWithCredentials(
        listPlatformContexts
      )
      this.setState({ contexts })
    } else {
      const users = await this.props.callWithCredentials(listPlatformUsers)
      this.setState({ users })
    }
  }

  async getConnectionsUnsafe(
    target: PlatformTarget,
    connectionType: ConnectionBy
  ): Promise<PlatformConnection[]> {
    const targetConnections = await this.props.callWithCredentials(
      async (cred) =>
        await listPlatformConnections(cred, target.external_id, connectionType)
    )
    return targetConnections
  }

  async loadConnectionsUnsafe(
    target: PlatformTarget,
    connectionType: ConnectionBy
  ): Promise<void> {
    const targetConnections = await this.getConnectionsUnsafe(
      target,
      connectionType
    )
    const { connections } = this.state
    const newConnections = Object.assign({}, connections, {
      [targetKey(target, connectionType)]: targetConnections
    })
    this.setState({ connections: newConnections })
  }

  loadTargetConnectionsByIndex = async (
    startIndex: number,
    stopIndex: number
  ): Promise<void> => {
    const targets = this.filtered()
    // `stopIndex` is inclusive, Array.prototype.slice does not include `end` so we add to the index
    const targetsToLoad = targets.slice(startIndex, stopIndex + 1)

    try {
      await Promise.all(
        targetsToLoad.map(
          async (target) =>
            await this.loadConnectionsUnsafe(target, this.state.selected)
        )
      )
    } catch (e) {
      toaster.danger(`Error while loading: ${errorMessage(e)}`)
    }
  }

  getTargetConnections(
    target: PlatformTarget,
    connectionType: ConnectionBy
  ): PlatformConnection[] | undefined {
    return this.state.connections[targetKey(target, connectionType)]
  }

  isTargetConnectionsLoaded(
    target: PlatformTarget,
    connectionType: ConnectionBy
  ): boolean {
    return this.getTargetConnections(target, connectionType) != null
  }

  isTargetConnectionsLoadedByIndex = (index: number): boolean => {
    return this.isTargetConnectionsLoaded(
      this.filtered()[index],
      this.state.selected
    )
  }

  updateSearch = debounce((idSearch: string): void => {
    this.setState({ idSearch })
  }, 200)

  targets(): PlatformTargets {
    const selected = this.state.selected
    if (selected === ConnectionBy.group) {
      return this.state.groups
    }
    if (selected === ConnectionBy.context) {
      return this.state.contexts
    }
    return this.state.users
  }

  filtered(): PlatformTargets {
    return this.filteredByStatus(
      this.filteredBySearch(this.targets()),
      this.state.selected
    )
  }

  filteredBySearch(targets: PlatformTargets): PlatformTargets {
    const { idSearch } = this.state

    if (!idSearch.length) {
      return targets
    }

    return targets.filter((target) => {
      return (
        target.external_id.toLowerCase().includes(idSearch.toLowerCase()) ||
        target.external_name?.toLowerCase().includes(idSearch.toLowerCase())
      )
    })
  }

  filteredByStatus(
    targets: PlatformTargets,
    connectionType: ConnectionBy
  ): PlatformTargets {
    const { statusFilter } = this.state

    if (!statusFilter) {
      return targets
    }

    return targets.filter((target) => {
      const connections = this.getTargetConnections(target, connectionType)
      if (!connections) {
        return true
      }

      const filteredConnections = connections.filter((connection) => {
        const status = getConnectionStatus(connection)
        if (statusFilter === StatusFilter.enabled) {
          return status !== ConnectionStatus.disabled
        }
        if (statusFilter === StatusFilter.errored) {
          return status === ConnectionStatus.error
        }
        return false
      })

      return filteredConnections.length > 0
    })
  }

  visibleConnectors(): Connector[] {
    const { connectors, maxConnections, startIndex } = this.state

    return connectors.slice(startIndex, startIndex + maxConnections)
  }

  renderTargets(targets: PlatformTargets): React.ReactNode | React.ReactNode[] {
    const { selected, loading, idSearch, statusFilter, connections } =
      this.state

    if (loading) {
      return <TableSpinner />
    }

    if (targets.length === 0) {
      if (idSearch || statusFilter) {
        // no search results
        return (
          <Table.Cell
            height={240}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <Text size={600} color='muted' display='flex' alignItems='center'>
              <Icon icon='search' size={20} marginRight={majorScale(1)} />
              No {CONNECTION_LABEL[selected]}s Found
            </Text>
          </Table.Cell>
        )
      }

      return (
        <Table.Cell
          height={240}
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
        >
          <Text size={600} color='muted' display='flex' alignItems='center'>
            <Icon
              icon={ICON_NAME[selected]}
              size={20}
              marginRight={majorScale(1)}
            />
            No {CONNECTION_LABEL[selected]}s Provisioned
          </Text>
          <Paragraph color='muted' marginTop='default'>
            <Link href={DOCUMENTATION_LINK[selected]}>
              View the documentation
              <Icon icon='share' size={12} marginLeft={4} marginRight={8} />
            </Link>
            to provision your first {ConnectionBy[selected]}
          </Paragraph>
        </Table.Cell>
      )
    }

    return (
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            itemCount={targets.length}
            isItemLoaded={this.isTargetConnectionsLoadedByIndex}
            loadMoreItems={this.loadTargetConnectionsByIndex}
          >
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                height={height}
                width={width}
                itemSize={48}
                itemCount={targets.length}
                overscanCount={10}
                onItemsRendered={onItemsRendered}
                itemData={{
                  targets,
                  idColumnMinWidth,
                  connectionColumnMinWidth,
                  connectors: this.visibleConnectors(),
                  connections,
                  connectionType: this.state.selected
                }}
                itemKey={(index, { targets }) =>
                  targetKey(targets[index], this.state.selected)
                }
                ref={ref}
              >
                {ConnectionsRowListItem}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    )
  }

  renderConnectorsHead(): React.ReactNode {
    const { connectors, loading, maxConnections } = this.state

    if (connectors.length === 0 && !loading) {
      return (
        <Table.TextHeaderCell
          flexBasis={maxConnections * connectionColumnMinWidth}
          flexShrink={0}
          flexGrow={1}
          textAlign='center'
        >
          <Button
            appearance='minimal'
            is={RouterLink}
            to='/connectors/new'
            iconBefore='new-link'
          >
            Create a connector
          </Button>
        </Table.TextHeaderCell>
      )
    }

    return this.visibleConnectors().map((connector) => {
      return (
        <ConnectorColumnHeader
          key={connector.slug}
          connector={connector}
          minWidth={connectionColumnMinWidth}
        />
      )
    })
  }

  render(): React.ReactNode {
    const { connectors, maxConnections, startIndex, selected, statusFilter } =
      this.state
    const targets = this.filtered()

    return (
      <Pane minHeight='100vh' display='flex' flexDirection='column'>
        <Heading marginBottom={majorScale(2)} size={700} display='flex'>
          {CONNECTION_LABEL[selected]} Connections
          <DropdownButton
            title='Connections by'
            options={[
              {
                label: CONNECTION_LABEL[ConnectionBy.context],
                value: ConnectionBy.context
              },
              {
                label: CONNECTION_LABEL[ConnectionBy.user],
                value: ConnectionBy.user
              },
              {
                label: CONNECTION_LABEL[ConnectionBy.group],
                value: ConnectionBy.group
              }
            ]}
            // TODO: please fix following typescript error
            // @ts-expect-error
            selected={selected}
            // TODO: please fix following typescript error
            // @ts-expect-error
            onChange={(selected) => this.setState({ selected })}
          />
          <SegmentedControl
            marginLeft={majorScale(2)}
            width={248}
            height={32}
            options={[
              {
                label: FILTER_LABEL[StatusFilter.all],
                value: StatusFilter.all
              },
              {
                label: FILTER_LABEL[StatusFilter.enabled],
                value: StatusFilter.enabled
              },
              {
                label: FILTER_LABEL[StatusFilter.errored],
                value: StatusFilter.errored
              }
            ]}
            value={statusFilter}
            // TODO: please fix following typescript error
            // @ts-expect-error
            onChange={(statusFilter) => this.setState({ statusFilter })}
          />
          <Pane flexGrow={1} />
          <PageButtons
            maxPerPage={maxConnections}
            index={startIndex}
            count={connectors.length}
            onSetIndex={(newIndex: number) =>
              this.setState({ startIndex: newIndex })
            }
          />
        </Heading>
        <ContentTable>
          <TableHeadPageable
            fixedWidth={idColumnMinWidth}
            widthPerElement={connectionColumnMinWidth}
            onChange={this.handleChangeMaxConnections}
          >
            <Table.SearchHeaderCell
              placeholder={`Search by ${CONNECTION_LABEL[selected]} ID...`}
              onChange={(value) => this.updateSearch(value)}
              flexBasis={majorScale(25) + 24}
              flexShrink={1}
              minWidth={idColumnMinWidth}
            />
            {this.renderConnectorsHead()}
          </TableHeadPageable>
          <Table.Body flex={1}>{this.renderTargets(targets)}</Table.Body>
        </ContentTable>
      </Pane>
    )
  }
}

export default withCredentials(withRouter(withTheme(Connections)))
