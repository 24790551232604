import { XkitJs } from '@xkit-co/xkit.js'
import { Connection } from '@xkit-co/xkit.js/lib/api/connection'
import { Button, IconProps, toaster } from 'evergreen-ui'
import React, { useState } from 'react'
import { Template } from '../api/template'
import { track } from '../util/analytics'
import errorMessage from '../util/error-message'

interface ConnectDeveloperAccountButtonProps {
  devConnectorTemplate: Template
  connection: Connection | null
  devConnectionID: string | null
  xkit: XkitJs | null
  appearance?: 'default' | 'minimal' | 'primary'
  intent?: 'none' | 'success' | 'warning' | 'danger'
  icon?: IconProps['icon']
  height?: number
  marginLeft?: number
  marginRight?: number
  isLinked: boolean
  onComplete?: (added: boolean) => void
  children: React.ReactNode
}

function trackPayload(props: ConnectDeveloperAccountButtonProps): unknown {
  return {
    connector_template_slug: props.devConnectorTemplate.slug,
    connector_template_name: props.devConnectorTemplate.name
  }
}

const ConnectDeveloperAccountButton: React.FC<ConnectDeveloperAccountButtonProps> =
  (props) => {
    const {
      devConnectorTemplate,
      connection,
      devConnectionID,
      xkit,
      icon = 'clean',
      appearance = 'primary',
      intent = 'none',
      height,
      marginLeft,
      marginRight,
      isLinked,
      children = 'Connect'
    } = props

    const [loading, setLoading] = useState(false)

    const handleComplete = (added: boolean): void => {
      const { onComplete } = props

      if (onComplete) {
        onComplete(added)
      } else {
        toaster.success(
          `Successfully linked ${devConnectorTemplate.name ?? ''}.`
        )
      }

      track('developer-account-connector-success', trackPayload(props))
    }

    const addDeveloperAccountConnection = async (): Promise<void> => {
      try {
        track('developer-account-connector-started', trackPayload(props))
        if (!xkit || !devConnectionID) {
          throw new Error('Could not prepare connection instance')
        }
        setLoading(true)
        await xkit.addConnection(devConnectorTemplate.slug, devConnectionID)
        handleComplete(true)
      } catch (e) {
        const errorMessageToDisplay = errorMessage(e)
        toaster.danger(
          `Failed to connect${
            errorMessageToDisplay ? `: ${errorMessageToDisplay}` : ''
          }`
        )
        track('developer-account-connector-failed', trackPayload(props))
      } finally {
        setLoading(false)
      }
    }

    const removeDeveloperAccountConnection = async (
      connection: Connection
    ): Promise<void> => {
      try {
        if (!xkit || !devConnectionID) {
          throw new Error('Could not prepare connection instance')
        }
        setLoading(true)
        await xkit.removeConnection({ id: connection.id })
        handleComplete(false)
      } catch (e) {
        toaster.danger(`Error when disconnecting: ${errorMessage(e)}`)
      } finally {
        setLoading(false)
      }
    }

    if (!devConnectorTemplate || !xkit) {
      return (
        <Button
          disabled
          height={height}
          iconBefore={icon}
          marginLeft={marginLeft}
          marginRight={marginRight}
        >
          {children}
        </Button>
      )
    }

    if (isLinked && connection) {
      return (
        <>
          <Button
            appearance='minimal'
            intent='danger'
            height={height}
            iconBefore={loading ? null : 'trash'}
            isLoading={loading}
            onClick={async () =>
              await removeDeveloperAccountConnection(connection)
            }
            marginLeft={marginLeft}
            marginRight={marginRight}
          >
            Remove {devConnectorTemplate.name} Connection
          </Button>
        </>
      )
    }

    return (
      <>
        <Button
          appearance={appearance}
          intent={intent}
          height={height}
          iconBefore={loading ? null : icon}
          isLoading={loading}
          onClick={async () => await addDeveloperAccountConnection()}
          marginLeft={marginLeft}
          marginRight={marginRight}
        >
          {children}
        </Button>
      </>
    )
  }

export default ConnectDeveloperAccountButton
