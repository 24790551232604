import React, { FC, memo, useCallback } from 'react'
import { TextInput, TagInput } from 'evergreen-ui'
import { FilterFieldProps, getFilter, updateFilter } from './filter-field'
import { FilterOverrideField } from './filter-override'
import { HistoryCountFilterOverrideField } from './history-count-filter-override-field'

const WORKSPACE_ID_PROPERTY = 'workspace_id'
const FORM_IDS_PROPERTY = 'form_ids'

function getFormIdsFilterValue(value?: string | string[]): string[] {
  if (Array.isArray(value)) {
    return value
  }

  return []
}

export const TypeformResponsesFilterField: FC<FilterFieldProps> = memo(
  ({ syncFilters, sourceFilters, filterErrors, onChange }) => {
    const updateProperty = useCallback(
      (propertyName, operator, value) => {
        const updatedFilters = updateFilter(
          syncFilters ?? sourceFilters,
          propertyName,
          operator,
          value
        )
        onChange(updatedFilters)
      },
      [sourceFilters, syncFilters, onChange]
    )

    const workspaceIdFilter = getFilter(
      sourceFilters,
      syncFilters,
      filterErrors,
      WORKSPACE_ID_PROPERTY
    )

    const formIdsFilter = getFilter(
      sourceFilters,
      syncFilters,
      filterErrors,
      FORM_IDS_PROPERTY
    )

    return (
      <>
        <FilterOverrideField
          propertyName={WORKSPACE_ID_PROPERTY}
          sourceFilters={sourceFilters}
          syncFilters={syncFilters}
          onChange={onChange}
          label='Workspace ID'
          description='Sync forms only from given filter (if not given then it will sync from from all workspaces)'
          validationMessage={workspaceIdFilter.validationMessage}
        >
          <TextInput
            name={WORKSPACE_ID_PROPERTY}
            isInvalid={workspaceIdFilter.isInvalid}
            disabled={workspaceIdFilter.disabled}
            value={workspaceIdFilter.isEmpty ? '' : workspaceIdFilter.value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              updateProperty(WORKSPACE_ID_PROPERTY, 'eq', event.target.value)
            }
          />
        </FilterOverrideField>
        <FilterOverrideField
          propertyName={FORM_IDS_PROPERTY}
          sourceFilters={sourceFilters}
          syncFilters={syncFilters}
          onChange={onChange}
          defaultOperator='in'
          defaultValue={[]}
          label='Form ids'
          description='Which forms to sync'
          hint='Please insert form ids. Leave blank to sync all forms'
          validationMessage={formIdsFilter.validationMessage}
        >
          <TagInput
            width='100%'
            name={FORM_IDS_PROPERTY}
            values={getFormIdsFilterValue(formIdsFilter.value)}
            onChange={(formIds: string[]) => {
              updateProperty(FORM_IDS_PROPERTY, 'in', formIds)
            }}
            disabled={formIdsFilter.disabled}
          />
        </FilterOverrideField>
        <HistoryCountFilterOverrideField
          syncFilters={syncFilters}
          sourceFilters={sourceFilters}
          filterErrors={filterErrors}
          onChange={onChange}
          label='Historical responses limit'
          description='The number of historical form responses to sync (per form)'
        />
      </>
    )
  }
)

TypeformResponsesFilterField.displayName = 'TypeformResponsesFilterField'
