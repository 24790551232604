import React, { FC, memo } from 'react'
import { FilterFieldProps } from './filter-field'
import { HistoryCountFilterOverrideField } from './history-count-filter-override-field'

// UI for the supported Intercom Conversations filters
export const IntercomConversationsFilterField: FC<FilterFieldProps> = memo(
  ({ sourceFilters, syncFilters, filterErrors, onChange }) => {
    return (
      <>
        <HistoryCountFilterOverrideField
          syncFilters={syncFilters}
          sourceFilters={sourceFilters}
          filterErrors={filterErrors}
          onChange={onChange}
          label='Historical conversations limit'
          description='The number of historical conversations to sync'
        />
      </>
    )
  }
)

IntercomConversationsFilterField.displayName =
  'IntercomConversationsFilterField'
