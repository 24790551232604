import React, { useContext } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import Login from './login'
import SignUp from './sign-up'
import ResetPassword from './reset-password'
import RequestPasswordReset from './request-password-reset'
import LoginChrome from './login-chrome'
import { toaster } from 'evergreen-ui'
import { getDisplayName } from './react-util'
import { getToken, logout } from '../api/session'
import { TokenCredentials } from '../api/request'
import errorMessage from '../util/error-message'

interface CredentialProps {
  loggingOut: boolean
  credentials: TokenCredentials
  callWithCredentials: <ReturnType>(
    fn: (credentials: TokenCredentials) => Promise<ReturnType>
  ) => Promise<ReturnType>
}

export interface CredentialsContextProps extends CredentialProps {
  loading: boolean
}

export const CredentialsContext = React.createContext<CredentialsContextProps>({
  loading: true,
  loggingOut: false,
  // TODO: please fix following typescript error
  // @ts-expect-error
  credentials: undefined,
  // TODO: please fix following typescript error
  // @ts-expect-error
  callWithCredentials: async (fn) => await fn(undefined)
})

export type CredentialConsumer<BaseProps = {}> = BaseProps & CredentialProps

export function useCredentials(): TokenCredentials | null {
  const context = useContext(CredentialsContext)
  return context.credentials
}

export function withCredentials<Props extends {}>(
  WrappedComponent: React.ComponentType<Props>
): React.ComponentType<Omit<Props, keyof CredentialProps>> {
  class WithCredentials extends React.Component {
    render(): React.ReactNode {
      return (
        <CredentialsContext.Consumer>
          {({ credentials, loading, callWithCredentials, loggingOut }) => {
            if (loading || credentials == null) {
              return null
            }
            return (
              <WrappedComponent
                credentials={credentials}
                callWithCredentials={callWithCredentials}
                loggingOut={loggingOut}
                {...(this.props as Props)}
              />
            )
          }}
        </CredentialsContext.Consumer>
      )
    }
  }

  // TODO: please fix following typescript error
  // @ts-expect-error
  WithCredentials.displayName = `WithCredentials(${getDisplayName(
    // TODO: please fix following typescript error
    // @ts-expect-error
    WrappedComponent
  )})`

  // TODO: please fix following typescript error
  // @ts-expect-error
  return WithCredentials
}

type LoginWrapperState = CredentialsContextProps

interface LoginWrapperProps {
  children: (callback: () => Promise<void>) => React.ReactNode
}

export class LoginWrapper extends React.Component<
  LoginWrapperProps,
  LoginWrapperState
> {
  constructor(props: LoginWrapperProps) {
    super(props)
    this.state = {
      loading: true,
      loggingOut: false,
      // TODO: please fix following typescript error
      // @ts-expect-error
      credentials: undefined,
      callWithCredentials: this.callWithCredentials
    }
  }

  async componentDidMount(): Promise<void> {
    await this.loadToken()
  }

  async loadToken(): Promise<void> {
    this.setState({ loading: true })
    try {
      const credentials = await getToken()
      this.setState({ credentials })
    } catch (e) {
      if (e.statusCode === 401) {
        // session is invalid
        return
      }
      toaster.danger(`Error while establishing connection: ${errorMessage(e)}`)
    } finally {
      this.setState({ loading: false })
    }
  }

  handleLogout = async (): Promise<void> => {
    this.setState({ loggingOut: true })
    try {
      await logout()
    } catch (e) {
      toaster.danger(`Error while logging out: ${errorMessage(e)}`)
    } finally {
      this.finishLogout()
    }
  }

  finishLogout = (): void => {
    this.setState({
      loggingOut: false,
      // TODO: please fix following typescript error
      // @ts-expect-error
      credentials: undefined
    })
  }

  handleLogin = (credentials: TokenCredentials): void => {
    this.setState({
      credentials
    })
  }

  handleLoginEntered = (): void => {
    this.finishLogout()
  }

  callWithCredentials = async <T extends unknown>(
    fn: (credentials: TokenCredentials) => Promise<T>
  ): Promise<T> => {
    const { credentials } = this.state

    try {
      const res = await fn(credentials)
      return res
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          const newCredentials = await getToken()
          this.setState({ credentials: newCredentials })
        } catch (e) {
          console.error(
            `Encountered error while refreshing access: ${errorMessage(e)}`
          )
          // TODO: please fix following typescript error
          // @ts-expect-error
          this.setState({ credentials: undefined })
          throw e
        }

        // TODO: please fix following typescript error
        // @ts-expect-error
        const res = await fn(newCredentials)
        return res
      }
      throw e
    }
  }

  renderChildren(): React.ReactNode {
    const { children } = this.props
    const { loading, credentials } = this.state

    if (loading || credentials == null) {
      return null
    }

    return children(this.handleLogout)
  }

  redirectPath(): string {
    try {
      // TODO: please fix following typescript error
      // @ts-expect-error
      const params = new URL(window.location).searchParams
      if (params != null) {
        const to = params.get('to')
        // TODO: please fix following typescript error
        // @ts-expect-error
        return to.length > 0 ? to : '/'
      }
    } catch (e) {}
    return '/'
  }

  render(): React.ReactNode {
    const { loading, loggingOut, credentials } = this.state

    return (
      <CredentialsContext.Provider value={this.state}>
        <Switch>
          <Route path='/login' exact>
            {credentials != null && !loading ? (
              <Redirect to={this.redirectPath()} />
            ) : (
              ''
            )}
            <LoginChrome
              loading={loading}
              isShown
              onEntered={this.handleLoginEntered}
              appearance='dark'
            >
              <Login onLogin={this.handleLogin} />
            </LoginChrome>
          </Route>

          <Route path='/account/reset-password' exact>
            {credentials == null && !loading ? <Redirect to='/login' /> : ''}
            <LoginChrome
              loading={loading}
              isShown
              onEntered={this.handleLoginEntered}
              appearance='dark'
              title='Reset your password'
            >
              {/* TODO: please fix following typescript error */}
              {/* @ts-expect-error */}
              <ResetPassword
                callWithCredentials={this.callWithCredentials}
                onReset={this.handleLogin}
              />
            </LoginChrome>
          </Route>

          <Route path='/account/password-reset' exact>
            {credentials != null && !loading ? (
              <Redirect to={this.redirectPath()} />
            ) : (
              ''
            )}
            <LoginChrome
              loading={loading}
              isShown
              onEntered={this.handleLoginEntered}
              appearance='dark'
              title='Reset your password'
            >
              <RequestPasswordReset />
            </LoginChrome>
          </Route>

          {/* <Route path='/sign-up' exact>
            {credentials != null && !loading ? (
              <Redirect to={this.redirectPath()} />
            ) : (
              ''
            )}
            <LoginChrome
              loading={loading}
              isShown
              onEntered={this.handleLoginEntered}
              appearance='light'
              title='Create a free account'
            >
              <SignUp onSignUp={this.handleLogin} />
            </LoginChrome>
          </Route> */}

          <Route path='/'>
            {credentials == null && !loading ? (
              <Redirect
                to={{
                  pathname: '/login',
                  search: `?to=${window.location.pathname}`
                }}
              />
            ) : (
              ''
            )}
            <LoginChrome
              loading={loading || credentials == null}
              isShown={loading || loggingOut || credentials == null}
              onEntered={this.handleLoginEntered}
              appearance='dark'
            />
            {this.renderChildren()}
          </Route>
        </Switch>
      </CredentialsContext.Provider>
    )
  }
}
