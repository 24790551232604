import React, { ReactElement, memo } from 'react'
import ContentSection from '../content-section'
import { CatalogForm, CatalogFormProps } from './catalog-form'
import { Connector } from '../../api/connector'

interface CatalogTabProps<T extends Connector> extends CatalogFormProps<T> {
  title?: string
}

const CatalogTab = memo(
  <T extends Connector>({
    title,
    ...formProps
  }: CatalogTabProps<T>): ReactElement => {
    return (
      <ContentSection title={title ?? ''}>
        <CatalogForm {...formProps} />
      </ContentSection>
    )
  }
)
CatalogTab.displayName = 'EditCatalog'

export default CatalogTab
