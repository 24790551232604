import React, { memo, FunctionComponent } from 'react'
import {
  Badge,
  Code,
  Pane,
  Pre,
  Spinner,
  Text,
  majorScale,
  minorScale,
  useTheme
} from 'evergreen-ui'
import {
  AppBuild,
  AppBuildStatus as AppBuildStatusModel
} from '../../../api/crm-embed'

const StatusBadge: FunctionComponent<{ status: AppBuildStatusModel }> = ({
  status
}) => {
  switch (status) {
    case AppBuildStatusModel.notStarted:
      return <Badge color='neutral'>Not started</Badge>
    case AppBuildStatusModel.inProgress:
      return (
        <Pane display='flex'>
          <Badge color='blue'>In progress</Badge>
          <Spinner size={16} marginLeft={minorScale(1)} />
        </Pane>
      )
    case AppBuildStatusModel.success:
      return <Badge color='green'>Success</Badge>
    case AppBuildStatusModel.failed:
      return <Badge color='red'>Failed</Badge>
    default:
      return null
  }
}

export const AppBuildStatus: FunctionComponent<{ appBuild: AppBuild }> = memo(
  ({ appBuild }) => {
    const theme = useTheme()

    return (
      <Pane>
        <Text
          size={400}
          marginTop={majorScale(4)}
          display='block'
          fontWeight={500}
        >
          Last build status
        </Text>
        <Pane marginTop={minorScale(1)}>
          <StatusBadge status={appBuild.status} />
        </Pane>
        <Text
          size={400}
          marginTop={majorScale(2)}
          display='block'
          fontWeight={500}
        >
          Last build log
        </Text>
        <Pane marginTop={minorScale(1)}>
          <Pre>
            <Code
              size={300}
              appearance='minimal'
              padding={majorScale(2)}
              display='block'
              overflow='scroll'
              backgroundColor={theme.colors.background.tint2}
            >
              {appBuild.log ?? "The app hasn't been built yet."}
            </Code>
          </Pre>
        </Pane>
      </Pane>
    )
  }
)

AppBuildStatus.displayName = 'AppBuildStatus'
