import React, { useState, useEffect, useCallback } from 'react'
import { Pipeline } from '../../../api/pipeline'
import { Input } from '../../../api/destination-input'
import {
  Selector,
  TransformationParameters,
  isTransformationEmpty
} from '../../../api/mapping'
import { Button, IconButton, majorScale, Pane } from 'evergreen-ui'
import { TransformationField } from '../transformation-field/transformation-field'
import { MappingForm, transformationOverrideState } from './form-data'
import { NestedInputForm } from './nested-input-form'

interface TransformationObjectFieldProps {
  pipeline: Pipeline
  selectors: Selector[]
  overridden: boolean
  allowOverrides: boolean
  form: MappingForm
  parentInput: Input
  nestedInputs: Input[]
  parentInputTransformation?: TransformationParameters
  onTransformationChange: (
    parentOrNestedInput: Input,
    transformation: Partial<TransformationParameters>
  ) => void
  onNewInput: (parentInput: Input, newNestedInput: Input) => void
  onRemoveInput: (parentInput: Input, newNestedInput: Input) => void
}

export const TransformationObjectField: React.FC<TransformationObjectFieldProps> =
  ({
    pipeline,
    selectors,
    overridden,
    allowOverrides,
    form,
    parentInput,
    nestedInputs,
    parentInputTransformation,
    onTransformationChange,
    onNewInput,
    onRemoveInput
  }) => {
    const [newInputFormVisible, setNewInputFormVisible] = useState(false)
    const [canAddProperties, setCanAddProperties] = useState(false)
    const [canChooseData, setCanChooseData] = useState(false)
    const [parentVisible, setParentVisible] = useState(true)

    const formOrigin = allowOverrides ? 'sync' : 'pipeline'

    useEffect(() => {
      // logic for when the input differs slightly when overriding
      if (allowOverrides) {
        // overridden == true -> the user wants to change the input
        const parentDefinedInputs = nestedInputs.filter(
          (i) => i.origin === 'pipeline' || i.origin === 'destination'
        )

        if (parentDefinedInputs.length > 0) {
          setParentVisible(false)
          setCanAddProperties(true)
        } else {
          if (overridden) {
            setCanAddProperties(
              isTransformationEmpty(parentInputTransformation)
            )
            setCanChooseData(nestedInputs.length === 0)
          }
        }
      } else {
        setCanAddProperties(isTransformationEmpty(parentInputTransformation))
        setCanChooseData(nestedInputs.length === 0)
      }
    }, [
      overridden,
      allowOverrides,
      parentInputTransformation,
      nestedInputs,
      setCanAddProperties,
      setCanChooseData,
      setParentVisible
    ])

    const handleNewInput = useCallback(
      (newNestedInput: Partial<Input>) => {
        setNewInputFormVisible(false)
        onNewInput(
          parentInput,
          Object.assign({
            ...newNestedInput,
            // generate an "unique" id so when the transformation belongs to this input
            id: -Math.floor(Math.random() * 10000),
            origin: formOrigin
          })
        )
      },
      [formOrigin, parentInput, setNewInputFormVisible, onNewInput]
    )

    return (
      <Pane marginBottom={majorScale(1)}>
        {parentVisible && (
          <TransformationField
            transformation={parentInputTransformation}
            overridden={overridden}
            allowOverrides={allowOverrides}
            forceDisabled={!canChooseData}
            provider={pipeline.source.provider}
            selectors={selectors}
            input={parentInput}
            onChange={(transformation) => {
              onTransformationChange(parentInput, transformation)
            }}
          />
        )}
        {nestedInputs.map((nestedInput) => {
          const t = form.get(nestedInput.id)
          if (!t) return null

          const [childOverridden, currentChildTransformation] =
            transformationOverrideState(t)

          return (
            <Pane display='flex' key={nestedInput.id}>
              <TransformationField
                marginTop={majorScale(1)}
                transformation={currentChildTransformation}
                overridden={childOverridden || nestedInput.origin === 'sync'}
                allowOverrides={
                  nestedInput.origin === 'sync' ? false : allowOverrides
                }
                provider={pipeline.source.provider}
                selectors={selectors}
                input={nestedInput}
                label={`${parentInput.name}.${nestedInput.name}`}
                onChange={(changedTransformation) => {
                  onTransformationChange(nestedInput, changedTransformation)
                }}
              />
              {nestedInput.origin === formOrigin && (
                <IconButton
                  appearance='minimal'
                  icon='small-cross'
                  marginLeft={majorScale(1)}
                  marginTop={32}
                  onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                    e.preventDefault()
                    onRemoveInput(parentInput, nestedInput)
                  }}
                />
              )}
            </Pane>
          )
        })}
        {newInputFormVisible && (
          <NestedInputForm parentInput={parentInput} onSave={handleNewInput} />
        )}
        {!newInputFormVisible && (
          <Button
            disabled={!canAddProperties}
            iconBefore='add'
            appearance='minimal'
            marginTop={majorScale(1)}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault()
              setNewInputFormVisible(true)
            }}
          >
            Add new property
          </Button>
        )}
      </Pane>
    )
  }
