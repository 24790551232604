import { useEffect, useState, useCallback, useContext } from 'react'
import { CredentialsContext } from '../login-wrapper'
import { Sync, getSync, updateSyncState } from '../../api/sync'
import { PlatformConnection } from '../../api/connection'
import errorMessage from '../../util/error-message'

interface UseSyncState {
  loading: boolean
  updating: boolean
  pause: () => Promise<void>
  resume: () => Promise<void>
  resetSync: () => Promise<void>
  error?: string
  sync?: Sync
}

export const useSync = (
  pipelineUuid: string,
  externalConnectionId?: PlatformConnection['id']
): UseSyncState => {
  const [loading, setLoading] = useState(true)
  const [updating, setUpdating] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [sync, setSync] = useState<Sync | undefined>(undefined)
  const { callWithCredentials } = useContext(CredentialsContext)

  useEffect(() => {
    const loadSync: () => Promise<void> = async () => {
      if (externalConnectionId == null) {
        return
      }
      setLoading(true)
      try {
        const sync = await callWithCredentials(
          async (creds) =>
            await getSync(creds, pipelineUuid, externalConnectionId)
        )
        setSync(sync)
      } catch (error) {
        console.error(error)
        setError(`Error while loading sync: ${errorMessage(error)}`)
      } finally {
        setLoading(false)
      }
    }

    loadSync().catch(console.error)
  }, [callWithCredentials, pipelineUuid, externalConnectionId])

  const resetSync = useCallback(async () => {
    if (externalConnectionId == null) {
      return
    }
    setUpdating(true)
    try {
      const sync = await callWithCredentials(
        async (creds) =>
          await updateSyncState(
            creds,
            pipelineUuid,
            externalConnectionId,
            'new'
          )
      )
      setSync(sync)
    } catch (error) {
      console.error(error)
      setError(`Error while resetting sync: ${errorMessage(error)}`)
      throw error
    } finally {
      setUpdating(false)
    }
  }, [callWithCredentials, pipelineUuid, externalConnectionId])

  const pause = useCallback(async () => {
    if (externalConnectionId == null) {
      return
    }
    setUpdating(true)
    try {
      setError(undefined)
      const sync = await callWithCredentials(
        async (creds) =>
          await updateSyncState(
            creds,
            pipelineUuid,
            externalConnectionId,
            'paused'
          )
      )
      setSync(sync)
    } catch (error) {
      console.error(error)
      setError(`Error while pausing sync: ${errorMessage(error)}`)
    } finally {
      setUpdating(false)
    }
  }, [callWithCredentials, pipelineUuid, externalConnectionId])

  const resume = useCallback(async () => {
    if (externalConnectionId == null) {
      return
    }
    setUpdating(true)
    try {
      setError(undefined)
      const sync = await callWithCredentials(
        async (creds) =>
          await updateSyncState(
            creds,
            pipelineUuid,
            externalConnectionId,
            'running'
          )
      )
      setSync(sync)
    } catch (error) {
      console.error(error)
      setError(`Error while resuming sync: ${errorMessage(error)}`)
    } finally {
      setUpdating(false)
    }
  }, [callWithCredentials, pipelineUuid, externalConnectionId])

  return {
    loading,
    updating,
    error,
    sync,
    pause,
    resume,
    resetSync
  }
}
