import {
  request,
  UnknownJSON,
  TokenCredentials,
  LegacyChangeResult
} from './request'
import * as analytics from '../util/analytics'

export interface SubscriptionParams {
  event_type: string
  url: string
}

export interface Subscription extends SubscriptionParams {
  id: string | number
}

function parseSubscription(res: UnknownJSON): Subscription {
  return {
    // TODO: please fix following typescript error
    // @ts-expect-error
    id: res.id,
    // TODO: please fix following typescript error
    // @ts-expect-error
    event_type: res.event_type,
    // TODO: please fix following typescript error
    // @ts-expect-error
    url: res.url
  }
}

export async function listSubscriptions(
  credentials: TokenCredentials
): Promise<Subscription[]> {
  const { subscriptions } = await request({
    path: '/subscriptions',
    credentials
  })

  // TODO: please fix following typescript error
  // @ts-expect-error
  return subscriptions.map(parseSubscription)
}

export async function deleteSubscription(
  credentials: TokenCredentials,
  { id }: Subscription
): Promise<void> {
  await request({
    path: `/subscriptions/${id}`,
    method: 'DELETE',
    credentials
  })
}

export async function createSubscription(
  credentials: TokenCredentials,
  subscription: SubscriptionParams
): Promise<LegacyChangeResult<Subscription> | Subscription> {
  const { errors, subscription: createdSubscription } = await request({
    path: '/subscriptions',
    method: 'POST',
    credentials,
    body: {
      subscription
    }
  })

  if (createdSubscription != null) {
    analytics.track('create-subscription')
  }

  // TODO: please fix following typescript error
  // @ts-expect-error
  if (errors != null && errors.length > 0) {
    // TODO: please fix following typescript error
    // @ts-expect-error
    return { errors }
  }

  return {
    // TODO: please fix following typescript error
    // @ts-expect-error
    subscription: parseSubscription(createdSubscription)
  }
}
