import { useState, useCallback } from 'react'

export function useDebouncedState<T>(
  initialState: T,
  delay: number
): [T, (newState: T) => void] {
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined)
  const [state, setState] = useState(initialState)

  const setDebouncedState = useCallback(
    (newState: T) => {
      if (timer != null) {
        clearTimeout(timer)
      }

      setTimer(
        setTimeout(() => {
          setState(newState)
        }, delay)
      )
    },
    [timer, setTimer, setState, delay]
  )

  return [state, setDebouncedState]
}
