import * as React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { NotFoundRoute } from '../components/not-found-route'
import EditPipeline from './edit-pipeline'
import NewPipeline from './new-pipeline'

const Pipelines: React.FC = () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route
        path={`${match.path}/new/:slug`}
        render={({ match }) => {
          return <NewPipeline providerSlug={match.params.slug} />
        }}
      />
      <Route
        path={`${match.path}/:uuid`}
        render={({ match }) => {
          return <EditPipeline uuid={match.params.uuid} />
        }}
      />
      <Route path='*'>
        <NotFoundRoute to='/providers' />
      </Route>
    </Switch>
  )
}

Pipelines.displayName = 'Pipelines'

export default Pipelines
