import React, {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useEffect,
  useState
} from 'react'
import {
  Button,
  FunctionIcon,
  toaster,
  majorScale,
  Pane,
  Paragraph
} from 'evergreen-ui'
import {
  Mapping,
  Selector,
  Transformation,
  TransformationName,
  TransformationParameters
} from '../../api/mapping'
import EmptyState from '../components/empty-state'
import { SpinnerSuspend } from '../components/spinner'
import { SelectedRootObject } from '../../api/source-object'
import { Pipeline } from '../../api/pipeline'
import { Input } from '../../api/destination-input'
import { InputForm } from './mapping/input-form'
import {
  MappingForm,
  MappingFormItem,
  setInputTransformation,
  addInput,
  removeInput
} from './mapping/form-data'

export interface DestinationInputsFormProps {
  loading: boolean
  selectedRootObject?: SelectedRootObject
  pipeline: Pipeline
  inputs: Input[]
  mapping?: Mapping
  selectors?: Selector[]
  updateMapping: (
    transformations: TransformationParameters[],
    inputs: Input[]
  ) => Promise<boolean>
  allowOverrides?: boolean
}

export const DestinationInputsForm: FC<DestinationInputsFormProps> = memo(
  ({
    loading,
    selectedRootObject,
    pipeline,
    inputs,
    mapping,
    selectors,
    updateMapping,
    allowOverrides
  }) => {
    const [updating, setUpdating] = useState(false)
    const [form, setForm] = useState<MappingForm>(new Map())

    useEffect(() => {
      setForm(
        inputs.reduce((acc, input) => {
          const transformation = mapping?.transformations.find(
            (transformation) => transformation.destination_input.id === input.id
          )
          const defaultTransformation = mapping?.defaultTransformations.find(
            (transformation) => transformation.destination_input.id === input.id
          )
          const childInputs = inputs.filter(
            (child) => child.parent_id === input.id
          )

          acc.set(input.id, {
            input: input,
            childInputs: childInputs,
            transformation: toTransformationParameter(input, transformation),
            defaultTransformation: toTransformationParameter(
              input,
              defaultTransformation
            )
          })

          return acc
        }, new Map<number, MappingFormItem>())
      )
    }, [mapping, inputs])

    const handleTransformationChange = useCallback(
      (input: Input, transformation: TransformationParameters) => {
        setForm(setInputTransformation(form, input, transformation))
      },
      [form, setForm]
    )

    const handleNewInput = useCallback(
      (parentInput: Input, newInput: Input) => {
        setForm(addInput(form, parentInput, newInput))
      },
      [form, setForm]
    )

    const handleRemoveInput = useCallback(
      (parentInput: Input, newInput: Input) => {
        setForm(removeInput(form, parentInput, newInput))
      },
      [form, setForm]
    )

    const handleSubmit = useCallback(
      async (event: ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()
        event.stopPropagation()
        setUpdating(true)
        try {
          const inputs = Array.from(form.values()).map((f) => f.input)
          const transformations = Array.from(form.values())
            .map((f) => f.transformation)
            .filter(
              (value): value is TransformationParameters =>
                value != null &&
                ([TransformationName.static, TransformationName.empty].includes(
                  value.name
                ) ||
                  value.source_relation_id != null)
            )

          const didUpdate = await updateMapping(transformations, inputs)

          if (didUpdate) {
            toaster.success('Saved inputs!')
          }
        } finally {
          setUpdating(false)
        }
      },
      [form, updateMapping]
    )

    if (pipeline.destination == null) {
      return (
        <EmptyState
          message='No Destination Selected'
          icon={FunctionIcon}
          padding={majorScale(2)}
        />
      )
    }

    return (
      <Pane maxWidth={435}>
        <Paragraph marginBottom={majorScale(2)}>
          Select the right data to send to these inputs.
        </Paragraph>
        <SpinnerSuspend spinning={loading}>
          <form onSubmit={handleSubmit}>
            {Array.from(form.values())
              .filter((ot) => !ot.input.parent_id)
              .map((ot) => {
                return (
                  <InputForm
                    key={ot.input.id}
                    pipeline={pipeline}
                    input={ot.input}
                    form={form}
                    selectors={selectors ?? []}
                    allowOverrides={allowOverrides === true}
                    mappingFormItem={ot}
                    onChange={handleTransformationChange}
                    onNewInput={handleNewInput}
                    onRemoveInput={handleRemoveInput}
                  />
                )
              })}
            <Button
              appearance='primary'
              isLoading={updating}
              marginTop={majorScale(1)}
            >
              Save
            </Button>
          </form>
        </SpinnerSuspend>
      </Pane>
    )
  }
)
DestinationInputsForm.displayName = 'DestinationInputsForm'

function toTransformationParameter(
  input: Input,
  transformation?: Transformation
): TransformationParameters | undefined {
  if (transformation === undefined) return
  return { ...transformation, destination_input_id: input.id, deleted: false }
}
