import { JsonPointer } from 'json-ptr'
import { ChangeError, parseDate } from './request'
import { Input, SimpleType } from './destination-input'
import { applySearchFilter } from '../util/apply-search-filter'

export enum TransformationName {
  collect = 'collect',
  date = 'date',
  direct = 'direct',
  find = 'find',
  static = 'static',
  empty = 'empty'
}

export const TRANSFORMATION_NAME_LABEL: Record<TransformationName, string> = {
  [TransformationName.direct]: 'None',
  [TransformationName.static]: 'None',
  [TransformationName.date]: 'Date',
  [TransformationName.find]: 'Find',
  [TransformationName.collect]: 'Collect',
  [TransformationName.empty]: 'None'
}

export const TRANSFORMATION_NAME_HINTS: Record<TransformationName, string> = {
  [TransformationName.direct]:
    'Send the source data directly to the destination.',
  [TransformationName.static]:
    'Send the static data directly to the destination.',
  [TransformationName.date]:
    'Convert the source data to the ISO 8601 Date format.',
  [TransformationName.find]:
    'Find one element in a list, and optionally extract a property from the found element.',
  [TransformationName.collect]:
    'Create a list by collecting object properties from an existing list.',
  [TransformationName.empty]:
    'Send the source data directly to the destination.'
}

export enum TransformationType {
  native = 'native'
}

interface Condition {
  operator: 'eq'
  value: string
  pointer: string
}

export interface TransformationParameters {
  type: TransformationType
  name: TransformationName
  source_relation_id: number
  source_pointer: string
  destination_input_id: number
  static_value?: string | boolean
  extract_pointer?: string
  condition?: Condition
  deleted: boolean
}

export interface TransformationDirect extends TransformationParameters {
  name: TransformationName.direct
  static_value: undefined
  extract_pointer: undefined
  condition: undefined
}

export interface TransformationStatic extends TransformationParameters {
  name: TransformationName.static
  static_value: string | boolean
  extract_pointer: undefined
  condition: undefined
}

export interface TransformationCollect extends TransformationParameters {
  name: TransformationName.collect
  static_value: undefined
  condition: undefined
}

export interface TransformationFind extends TransformationParameters {
  name: TransformationName.find
  static_value: undefined
  condition: Condition
}

export interface TransformationDate extends TransformationParameters {
  name: TransformationName.date
  static_value: undefined
  extract_pointer: undefined
  condition: undefined
}

export interface TransformationEmpty extends TransformationParameters {
  name: TransformationName.empty
  static_value: undefined
  extract_pointer: undefined
  condition: undefined
}

export interface TransformationWire {
  type: TransformationType
  name: TransformationName
  source_relation_id: number
  source_pointer: string
  static_value?: string | boolean
  extract_pointer?: string
  condition?: Condition
  destination_input: Input
  inserted_at: string
  updated_at: string
}

// TODO: split into concrete transformations
export interface Transformation {
  type: TransformationType
  name: TransformationName
  source_relation_id: number
  source_pointer: string
  static_value?: string | boolean
  extract_pointer?: string
  condition?: Condition
  destination_input: Input
  inserted_at: Date
  updated_at: Date
}

export interface MappingWire {
  inserted_at: string
  updated_at: string
  transformations: TransformationWire[]
  default_transformations?: TransformationWire[]
}

export interface Mapping {
  inserted_at: Date
  updated_at: Date
  transformations: Transformation[]
  defaultTransformations: Transformation[]
}

// Needs to be a type to be compatible with UnknownJSON
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type MappingErrors = {
  errors: { transformations: Array<ChangeError<TransformationParameters>> }
}

export type MappingWireResult =
  | { mapping: MappingWire; inputs: Input[] }
  | MappingErrors
export type MappingResult =
  | { mapping?: Mapping; inputs: Input[] }
  | MappingErrors

interface SelectorInputType {
  input_type: SimpleType
  transformations: TransformationName[]
}

export interface Selector {
  source_relation_id: number
  label?: string
  type_label: string | string[]
  label_pattern?: string
  nullable: boolean
  input_types: SelectorInputType[]
  children?: Selector[]
}

function parseTransformation(wire: TransformationWire): Transformation {
  return {
    ...wire,
    inserted_at: parseDate(wire.inserted_at),
    updated_at: parseDate(wire.updated_at)
  }
}

export function parseMapping(wire: MappingWire): Mapping {
  return {
    ...wire,
    transformations: (wire.transformations ?? []).map(parseTransformation),
    defaultTransformations: (wire.default_transformations ?? []).map(
      parseTransformation
    ),
    updated_at: parseDate(wire.updated_at),
    inserted_at: parseDate(wire.inserted_at)
  }
}

export function isStaticTransformation(
  transformation: TransformationParameters
): transformation is TransformationStatic {
  return transformation.name === TransformationName.static
}

export function requiresArguments(
  transformationName: TransformationName
): boolean {
  return [
    TransformationName.find,
    TransformationName.collect,
    TransformationName.static
  ].includes(transformationName)
}

export function getPathToSelector(
  root: Selector,
  selector: Selector,
  path?: string[]
): string[] | null {
  if (!selector.label) {
    return null
  }

  if (!root.label) {
    return null
  }

  // Special handling for the actual root element
  const pathToRoot = path == null ? [] : [...path, root.label]

  if (root === selector) {
    return pathToRoot
  }

  const childPaths = (root.children ?? [])
    .map((child) => getPathToSelector(child, selector, pathToRoot))
    .filter((path) => path != null)
  if (childPaths.length) {
    return childPaths[0]
  }

  return null
}

function getSelectorFromPointerInRoot(
  root: Selector,
  pointer: string
): Selector {
  const paths = JsonPointer.decode(pointer)
  let selector = root

  for (const label of paths) {
    const newSelector = (selector.children ?? []).find(
      (child) =>
        child.label === label ||
        (child.label_pattern &&
          new RegExp(child.label_pattern).test(String(label)))
    )
    if (newSelector == null) {
      throw new Error(`Cannot find ${pointer} in selector root`)
    }
    selector = newSelector
  }

  return selector
}

export function getSelectorFromPointer(
  pointer: string,
  roots: Selector[],
  sourceRelationId: number
): Selector {
  const root = findSelector(roots, sourceRelationId)

  if (!root) {
    throw new Error('Invalid selector root')
  }

  return getSelectorFromPointerInRoot(root, pointer)
}

export function findSelector(
  roots: Selector[],
  sourceRelationId: number
): Selector | undefined {
  return roots.find((root) => {
    return sourceRelationId === root.source_relation_id
  })
}

export function selectorMatchesSearch(
  selector: Selector,
  search: string
): boolean {
  return applySearchFilter(selector.label, search)
}

export function childrenMatchSearch(
  selector: Selector,
  search: string
): boolean {
  return (selector.children ?? []).some(
    (selector) =>
      selectorMatchesSearch(selector, search) ||
      childrenMatchSearch(selector, search)
  )
}

// Count the number of nodes that will be expanded for a given search term
export function countMatchingDescendants(
  selector: Selector,
  search: string,
  forceCount?: boolean
): number {
  // We force counting this node and all of its descendants if one of its
  // ancestors matches the search term
  if (forceCount) {
    return (selector.children ?? []).reduce(
      (count, child) =>
        count + countMatchingDescendants(child, search, forceCount),
      1
    )
  }

  // If this node matches the search, we count it and all of its descendants
  if (
    selectorMatchesSearch(selector, search) ||
    (selector.label_pattern && new RegExp(selector.label_pattern).test(search))
  ) {
    return (selector.children ?? []).reduce(
      (count, child) => count + countMatchingDescendants(child, search, true),
      1
    )
  }

  const descendantsCount = (selector.children ?? []).reduce(
    (count, child) => count + countMatchingDescendants(child, search, false),
    0
  )

  // If this node has a descendant that matches the search term, we'll have to expand it
  // too.
  if (descendantsCount > 0) {
    return descendantsCount + 1
  }

  return 0
}

export function isTransformationEmpty(
  transformation?: Partial<TransformationParameters>
): boolean {
  if (!transformation) return true
  if (!transformation.source_pointer) return true
  return false
}
