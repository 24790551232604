import React, { useEffect, useState } from 'react'
import {
  Button,
  FormField,
  Pane,
  TextInputField,
  majorScale
} from 'evergreen-ui'
import { ContentCard } from '../../components/content-card'
import { BaseProviderForm } from './provider-form'
import { InputsField } from '../functions/inputs-field'
import valsAreEqual from '../../../util/vals-are-equal'
import { Controlled as CodeMirror } from 'react-codemirror2'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/xq-light.css'
require('codemirror/mode/javascript/javascript')

const GenericForm: BaseProviderForm = ({
  loading,
  saving,
  name,
  setName,
  nameError,
  inputs,
  setInputs,
  inputsError,
  config,
  setConfig,
  configError,
  onSubmit
}) => {
  const [rawConfig, setRawConfig] = useState('')
  const [rawConfigError, setRawConfigError] = useState<string | undefined>(
    undefined
  )

  useEffect(() => {
    setRawConfig(JSON.stringify(config, null, 2))
  }, [config])

  function handleConfigChange(value: string): void {
    try {
      setRawConfig(value)
      const newConfig = JSON.parse(value)
      setRawConfigError(undefined)
      if (!valsAreEqual(newConfig, config)) {
        setConfig(newConfig)
      }
    } catch (error) {
      if (error instanceof SyntaxError) {
        setRawConfigError(error.message)
      } else {
        console.error(error)
        setRawConfigError('has invalid format')
      }
    }
  }

  const error = rawConfigError ?? configError

  return (
    <ContentCard
      elevation={1}
      padding={majorScale(2)}
      marginBottom={majorScale(2)}
    >
      <Pane maxWidth={640}>
        <TextInputField
          label='Name'
          description='The name of your destination'
          placeholder={loading ? 'Loading...' : 'My Destination'}
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setName(e.target.value)
          }
          onKeyDown={(e: React.KeyboardEvent) =>
            e.keyCode === 13 ? onSubmit() : null
          }
          isInvalid={nameError != null}
          validationMessage={nameError != null ? `Name ${nameError}` : null}
          disabled={saving}
        />
        <InputsField
          disabled={saving || loading}
          inputs={inputs}
          setInputs={setInputs}
          inputsError={inputsError}
        />
        <FormField
          label='Configuration'
          description='Provider destination configuration in JSON'
          validationMessage={error != null ? `Config ${error}` : null}
          marginBottom={majorScale(4)}
        >
          <Pane border='default' marginTop={majorScale(1)}>
            <CodeMirror
              value={rawConfig}
              options={{
                mode: 'javascript',
                theme: 'xq-light',
                lineNumbers: true,
                readOnly: saving || loading
              }}
              onBeforeChange={(editor, data, value) =>
                handleConfigChange(value)
              }
            />
          </Pane>
        </FormField>
        <Button
          appearance='primary'
          isLoading={saving || loading}
          onClick={onSubmit}
        >
          {loading ? 'Loading...' : saving ? 'Saving...' : 'Save'}
        </Button>
      </Pane>
    </ContentCard>
  )
}

GenericForm.displayName = 'GenericForm'

export default GenericForm
