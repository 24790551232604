import { request, TokenCredentials } from './request'
import { Connector, ConnectorSlim } from './connector'
import { PlatformContext } from './platform-user'

interface AuthorizationSlim {
  id: number | string
  status: 'active' | 'error' | 'retrieving_tokens' | 'awaiting_callback'
  error_message?: string
  display_label?: string
}

export interface PlatformConnection {
  id: string
  enabled: boolean
  connector: ConnectorSlim
  authorization?: AuthorizationSlim
  context: PlatformContext
}

export enum ConnectionBy {
  user,
  group,
  context
}

export enum ConnectionStatus {
  disabled = 'disabled',
  active = 'active',
  pending = 'pending',
  error = 'error'
}

export const getConnectionReadableTitle = (
  connection?: PlatformConnection
): string => {
  if (connection == null) {
    return ''
  }

  return (
    connection.authorization?.display_label ?? `#${connection.id.slice(0, 4)}`
  )
}

export function getConnectionStatus(
  connection: PlatformConnection
): ConnectionStatus {
  if (!connection.enabled) {
    return ConnectionStatus.disabled
  }
  if (
    connection.authorization == null ||
    connection.authorization.status === 'error'
  ) {
    return ConnectionStatus.error
  }
  if (connection.authorization?.status === 'active') {
    return ConnectionStatus.active
  }

  return ConnectionStatus.pending
}

const LIST_PLATFORM_CONNECTIONS_HANDLERS: Record<
  ConnectionBy,
  (
    credentials: TokenCredentials,
    externalId: string
  ) => Promise<PlatformConnection[]>
> = {
  [ConnectionBy.user]: listUserConnections,
  [ConnectionBy.group]: listGroupConnections,
  [ConnectionBy.context]: listContextConnections
}
export async function listPlatformConnections(
  credentials: TokenCredentials,
  externalId: string,
  type: ConnectionBy
): Promise<PlatformConnection[]> {
  return await LIST_PLATFORM_CONNECTIONS_HANDLERS[type](credentials, externalId)
}

async function listUserConnections(
  credentials: TokenCredentials,
  externalUserId: string
): Promise<PlatformConnection[]> {
  const { connections } = await request({
    path: `/platform_users/${externalUserId}/connections`,
    credentials
  })

  return connections as PlatformConnection[]
}

async function listGroupConnections(
  credentials: TokenCredentials,
  externalGroupId: string
): Promise<PlatformConnection[]> {
  const { connections } = await request({
    path: `/platform_groups/${externalGroupId}/connections`,
    credentials
  })

  return connections as PlatformConnection[]
}

async function listContextConnections(
  credentials: TokenCredentials,
  externalContextId: string
): Promise<PlatformConnection[]> {
  const { connections } = await request({
    path: `/platform_contexts/${externalContextId}/connections`,
    credentials
  })

  return connections as PlatformConnection[]
}

export async function getConnection(
  credentials: TokenCredentials,
  externalConnectionId: string
): Promise<PlatformConnection> {
  const { connection } = await request({
    path: `/connections/${externalConnectionId}`,
    credentials
  })

  return connection as PlatformConnection
}

export async function listConnectionsByConnector(
  credentials: TokenCredentials,
  connectorSlug: Connector['slug']
): Promise<PlatformConnection[]> {
  const { connections } = await request<{ connections: PlatformConnection[] }>({
    path: `/connectors/${connectorSlug}/connections`,
    credentials
  })
  return connections
}

export async function deleteConnection(
  credentials: TokenCredentials,
  connection: PlatformConnection
): Promise<void> {
  await request({
    path: `/connections/${connection.id}`,
    method: 'DELETE',
    credentials
  })
}
