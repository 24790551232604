import React from 'react'
import { Pane } from 'evergreen-ui'

interface ArrowProps {
  height?: string
}

export const Arrow: React.FC<ArrowProps> = ({ height = '108px' }) => {
  return (
    <Pane>
      <svg
        width='100%'
        height={height}
        fill='currentColor'
        viewBox='0 0 100 101'
      >
        <line
          x1='0'
          x2='98'
          y1='51'
          y2='51'
          stroke='#696f8c'
          strokeWidth='3px'
        />
        <polygon points='100,51 93,46 93,56' fill='#696f8c' />
      </svg>
    </Pane>
  )
}

Arrow.displayName = 'Arrow'
