import { useContext, useState, useEffect } from 'react'
import { CredentialsContext } from '../login-wrapper'
import {
  FunctionDependencies,
  getDefaultDependencies
} from '../../api/destination'

export const useDefaultDependencies = (): FunctionDependencies => {
  const { callWithCredentials } = useContext(CredentialsContext)
  const [dependencies, setDependencies] = useState<FunctionDependencies>({})
  useEffect(() => {
    const fetchDeps = async (): Promise<void> => {
      const dependencies = await callWithCredentials(getDefaultDependencies)
      setDependencies(dependencies)
    }
    void fetchDeps()
  }, [callWithCredentials])

  return dependencies
}
