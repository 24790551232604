import * as React from 'react'
import { Pane, PaneProps, Tablist, Tab, majorScale } from 'evergreen-ui'
import slugify from '../util/slugify'

interface TabContentProps {
  title: string
  isShown: boolean
}

const TabContent: React.FC<TabContentProps> = ({
  title,
  children,
  isShown
}) => {
  const id = slugify(title)

  return (
    <Pane
      id={`panel-${id}`}
      role='tabpanel'
      aria-labelledby={id}
      aria-hidden={!isShown}
      display={isShown ? 'block' : 'none'}
    >
      {children}
    </Pane>
  )
}

interface TabData {
  title: string
  content: React.ReactElement
}

interface TabbedContentProps extends PaneProps {
  tabs: TabData[]
}

const TabbedContent: React.FC<TabbedContentProps> = ({
  tabs,
  ...paneProps
}) => {
  const [shownTab, setShownTab] = React.useState<string | undefined>(undefined)
  const firstTab = tabs[0]
  React.useEffect(() => {
    if (shownTab == null && firstTab != null) {
      setShownTab(slugify(firstTab.title))
    }
  }, [shownTab, firstTab])

  return (
    <Pane {...paneProps}>
      <Tablist marginBottom={majorScale(1)}>
        {tabs.map((tab) => (
          <Tab
            key={slugify(tab.title)}
            id={slugify(tab.title)}
            isSelected={shownTab === slugify(tab.title)}
            onSelect={() => setShownTab(slugify(tab.title))}
            aria-controls={`panel-${slugify(tab.title)}`}
          >
            {tab.title}
          </Tab>
        ))}
      </Tablist>
      <Pane>
        {tabs.map((tab) => (
          <TabContent
            key={slugify(tab.title)}
            title={tab.title}
            isShown={shownTab === slugify(tab.title)}
          >
            {tab.content}
          </TabContent>
        ))}
      </Pane>
    </Pane>
  )
}

export default TabbedContent
