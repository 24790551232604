import React, { FC, memo } from 'react'
import { FilterFieldProps } from './filter-field'
import { HistoryCountFilterOverrideField } from './history-count-filter-override-field'

// UI for the supported Delighted Survey Responses filters
export const DelightedSurveyResponsesFilterField: FC<FilterFieldProps> = memo(
  ({ syncFilters, sourceFilters, filterErrors, onChange }) => {
    return (
      <HistoryCountFilterOverrideField
        syncFilters={syncFilters}
        sourceFilters={sourceFilters}
        filterErrors={filterErrors}
        onChange={onChange}
        label='Historical survey responses limit'
        description='The number of historical survey responses to sync'
      />
    )
  }
)

DelightedSurveyResponsesFilterField.displayName =
  'DelightedSurveyResponsesFilterField'
