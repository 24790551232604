import { request, TokenCredentials } from './request'

export enum AppBuildStatus {
  notStarted = 'not_started',
  inProgress = 'in_progress',
  success = 'success',
  failed = 'failed'
}

export type AppBuildParams = Record<string, Object>

export interface AppBuild {
  status: AppBuildStatus
  log?: string
  params: AppBuildParams
}

export async function enqueueAppBuild(
  credentials: TokenCredentials,
  providerSlug: string,
  params: AppBuildParams
): Promise<void> {
  await request({
    path: `/crm_embed/${providerSlug}/app_build`,
    method: 'POST',
    body: { params: params },
    credentials
  })
}

export async function getAppBuild(
  credentials: TokenCredentials,
  providerSlug: string
): Promise<AppBuild> {
  const { app_build: appBuild } = await request({
    path: `/crm_embed/${providerSlug}/app_build`,
    method: 'GET',
    credentials
  })

  return appBuild as AppBuild
}
