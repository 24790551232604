import { defaultTheme } from 'evergreen-ui'
// This is a hack because Themer and memoizeClassName are not exposed, but
// building a theme otherwise is a nightmare
// TODO: please fix following typescript error
// @ts-expect-error
import { Themer } from 'evergreen-ui/commonjs/themer'
// TODO: please fix following typescript error
// @ts-expect-error
import memoizeClassName from 'evergreen-ui/commonjs/theme/src/default-theme/utils/memoizeClassName'
import React from 'react'

const scales = {
  ...defaultTheme.scales,
  // Create a light variant for transparent-neutral
  light: {
    L1A: 'rgba(249, 249, 251, 0.04)',
    L2A: 'rgba(249, 249, 251, 0.06)',
    L3A: 'rgba(249, 249, 251, 0.09)',
    L4A: 'rgba(249, 249, 251, 0.14)',
    L5A: 'rgba(249, 249, 251, 0.30)',
    L6A: 'rgba(249, 249, 251, 0.47)',
    L7A: 'rgba(249, 249, 251, 0.70)',
    L8A: 'rgba(249, 249, 251, 0.81)'
  },
  // Uses colors from the more recent version of Evergreen
  gray: {
    50: '#FAFBFF',
    75: '#F9FAFC',
    90: '#F4F6FA',
    100: '#F4F5F9',
    200: '#edeff5',
    300: '#E6E8F0',
    400: '#d8dae5',
    500: '#c1c4d6',
    600: '#8f95b2',
    700: '#696f8c',
    800: '#474d66',
    900: '#101840'
  }
}

const palette = {
  ...defaultTheme.palette,
  base: 'white'
}

const theme = {
  ...defaultTheme,
  scales,
  palette,
  getTabClassName: memoizeClassName(getTabAppearance)
}

function getTabAppearance(appearance = 'default'): React.CSSProperties {
  switch (appearance) {
    case 'default':
      return defaultTabApperance
    case 'dark':
      return darkTabAppearance
    default:
      throw new Error(`Invalid Tab Appearance: ${appearance}`)
  }
}

const defaultTabApperance = Themer.createTabAppearance({
  base: {},
  hover: {
    backgroundColor: theme.scales.neutral.N2A
  },
  focus: {
    boxShadow: '0 0 0 2px '.concat(theme.scales.blue.B5A)
  },
  active: {
    backgroundColor: theme.scales.blue.B3A,
    color: theme.scales.blue.B9
  },
  disabled: {
    opacity: 0.8,
    backgroundImage: 'none',
    backgroundColor: theme.scales.light.L2A,
    boxShadow: 'none',
    color: theme.scales.light.L7A,
    pointerEvents: 'none'
  },
  current: {}
}) as React.CSSProperties

const darkTabAppearance = Themer.createTabAppearance({
  base: {
    color: theme.scales.neutral.N5
  },
  hover: {
    backgroundColor: theme.scales.light.L2A
  },
  focus: {
    boxShadow: `0 0 0 2px ${theme.scales.light.L5A}`
  },
  active: {
    backgroundColor: theme.scales.light.L4A,
    color: theme.palette.neutral.lightest
  },
  disabled: {
    opacity: 0.8,
    backgroundImage: 'none',
    backgroundColor: theme.scales.light.L2A,
    boxShadow: 'none',
    color: theme.scales.light.L7A,
    pointerEvents: 'none'
  },
  current: {}
}) as React.CSSProperties

export default theme
