import React, { FC, memo } from 'react'
import ConnectorsList from '../connectors/connectors-list'
import { NewItemCard } from '../components/new-item-card'
import { majorScale, SegmentedControl } from 'evergreen-ui'
import { useRouteMatch } from 'react-router-dom'
import { useConnectors } from '../hooks/connectors.hook'
import { useErrorToaster, useTitle } from '../hooks/common'
import { useProviderMode, Mode } from '../hooks/provider-mode.hook'
import { HeadingTitle } from '../components/heading-title'

interface ProvidersProps {
  crmOnlyConnectors?: boolean
}

const Providers: FC<ProvidersProps> = memo(({ crmOnlyConnectors }) => {
  const providersNoun = crmOnlyConnectors ? 'CRMs' : 'Providers'
  const providerNoun = crmOnlyConnectors ? 'CRM' : 'Provider'
  const { connectors, loading, error } = useConnectors()
  useErrorToaster(error)
  useTitle(providersNoun)
  const match = useRouteMatch()
  const [mode, setMode] = useProviderMode('crm')

  const filteredConnectors = connectors.filter((connector) => {
    switch (mode) {
      case 'auth':
        return !connector.sync && !connector.crm
      case 'data':
        return connector.sync
      case 'crm':
      default:
        return connector.crm
    }
  })

  return (
    <>
      <HeadingTitle display='flex'>
        Added {providersNoun}
        {crmOnlyConnectors ? null : (
          <SegmentedControl
            marginLeft={majorScale(2)}
            width={372}
            height={32}
            options={[
              {
                label: 'CRM',
                value: 'crm'
              },
              {
                label: 'Data Sync',
                value: 'data'
              },
              {
                label: 'Authorization Only',
                value: 'auth'
              }
            ]}
            value={mode}
            onChange={(newMode: Mode) => setMode(newMode)}
          />
        )}
      </HeadingTitle>
      <ConnectorsList
        searchPlaceholder={`Search for ${providersNoun}...`}
        connectors={filteredConnectors}
        loading={loading}
        slugLinkPrefix={`${match.path}/`}
      >
        <NewItemCard
          to={`${match.path}/new?type=${mode}`}
          width={250}
          height={150}
          marginRight={majorScale(3)}
          marginBottom={majorScale(3)}
          title={`Add ${providerNoun}`}
        />
      </ConnectorsList>
    </>
  )
})

Providers.displayName = 'Providers'

export default Providers
