import React, { FC, useState, useEffect, memo, useCallback } from 'react'
import { TextInputField } from 'evergreen-ui'
import { FilterFieldProps } from './filter-field'
import { Filter, FilterErrors } from '../../../api/filter'

function getPropertyValidationMessage(
  filterError?: string,
  propertyError?: string
): string | undefined {
  if (filterError != null) {
    return `Filter ${filterError}`
  }

  if (propertyError != null) {
    return `Property name ${propertyError}`
  }
}

// UI for the supported HubSpot Contact filters
// TODO: support sync filter overrides
export const HubSpotContactsFilterField: FC<FilterFieldProps> = memo(
  ({ sourceFilters, syncFilters, filterErrors, onChange }) => {
    const [property, setProperty] = useState('')
    const [value, setValue] = useState('')

    // Assume that we only support a single filter for HubSpot for now
    const filter = sourceFilters[0]
    const filterErrorElement: Partial<FilterErrors[number]> =
      filterErrors?.[0] ?? {}
    const filterError =
      'message' in filterErrorElement ? filterErrorElement.message : undefined
    const propertyError =
      'property' in filterErrorElement
        ? filterErrorElement.property?.[0].message
        : undefined
    const valueError =
      'value' in filterErrorElement
        ? filterErrorElement.value?.[0].message
        : undefined

    useEffect(() => {
      // We need to make sure these are actual values,
      // otherwise we might wipe out the user's inputs when
      // they only have half a filter defined.
      if (filter?.property?.length) {
        setProperty(filter?.property)
      }
      if (typeof filter?.value === 'string' && filter?.value?.length) {
        setValue(filter?.value)
      }
    }, [filter?.property, filter?.value])

    const handleChange = useCallback(
      (update: Partial<Filter>) => {
        const oldFilter: Partial<Filter> = filter ?? {}
        const newFilter: Filter = {
          operator: 'eq',
          ...oldFilter,
          property,
          value,
          ...update
        }

        if (newFilter.property.length && newFilter.value.length) {
          onChange([newFilter])
        } else {
          onChange([])
        }
      },
      [filter, property, value, onChange]
    )

    return (
      <>
        <TextInputField
          label='Property Name'
          description='The name of the HubSpot property with which to filter contacts'
          placeholder='should_be_synced'
          hint='This must be the internal name of the property, which can be viewed by clicking the </> icon in HubSpot.'
          isInvalid={filterError != null || propertyError != null}
          validationMessage={getPropertyValidationMessage(
            filterError,
            propertyError
          )}
          value={property}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setProperty(e.target.value)
            handleChange({ property: e.target.value })
          }}
          // Only allow changing the filter at the source level
          disabled={syncFilters != null}
        />
        <TextInputField
          label='Property Value'
          description='Contacts whose property matches this value will be synced'
          placeholder='true'
          isInvalid={valueError != null}
          validationMessage={valueError ? `Property value ${valueError}` : null}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value)
            handleChange({ value: e.target.value })
          }}
          // Only allow changing the filter at the source level
          disabled={syncFilters != null}
        />
      </>
    )
  }
)

HubSpotContactsFilterField.displayName = 'HubSpotContactsFilterField'
