import React, { FC, useCallback, memo } from 'react'
import {
  Switch,
  FormField,
  FormFieldProps,
  Pane,
  majorScale
} from 'evergreen-ui'
import { Filter } from '../../../api/filter'
import { getFilter, updateFilter } from './filter-field'

interface FilterOverrideSwitchProps {
  propertyName: string
  sourceFilters: Filter[]
  syncFilters?: Filter[]
  onChange: (updated: Filter[]) => void
  defaultOperator?: Filter['operator']
  defaultValue?: Filter['value']
}

export const FilterOverrideSwitch: FC<FilterOverrideSwitchProps> = memo(
  ({
    propertyName,
    sourceFilters,
    syncFilters,
    onChange,
    defaultOperator = 'eq',
    defaultValue = ''
  }) => {
    const filter = getFilter(
      sourceFilters,
      syncFilters,
      undefined,
      propertyName
    )

    const toggleFilterOverride = useCallback(
      (removeOverride: boolean) => {
        // if we want to `useCallback`, we need to define this
        // function even it won't be used (rules-of-hooks).
        // Since this switch is only used when we're updating sync filters
        // we throw an error in this impossible scenario.
        if (syncFilters == null) {
          throw new Error('Can only use filter overrides for individual syncs.')
        }
        const updatedFilters = updateFilter(
          syncFilters,
          propertyName,
          filter.operator ?? defaultOperator,
          filter.value ?? defaultValue,
          removeOverride
        )
        onChange(updatedFilters)
      },
      [
        syncFilters,
        propertyName,
        filter.operator,
        defaultOperator,
        filter.value,
        defaultValue,
        onChange
      ]
    )

    // This toggle is only relevant when in the context of a sync,
    // otherwise there is nothing to override
    if (syncFilters == null) {
      return null
    }

    return (
      <Switch
        name={`override_${propertyName}`}
        checked={filter.isOverride}
        marginTop={majorScale(1)}
        marginLeft={majorScale(1)}
        onChange={(e) => toggleFilterOverride(!e.target.checked)}
      />
    )
  }
)

FilterOverrideSwitch.displayName = 'FilterOverrideSwitch'

type FilterOverrideFieldProps = FormFieldProps & FilterOverrideSwitchProps

export const FilterOverrideField: FC<FilterOverrideFieldProps> = memo(
  ({
    propertyName,
    sourceFilters,
    syncFilters,
    onChange,
    children,
    marginBottom = majorScale(3),
    ...formFieldProps
  }) => {
    return (
      <FormField marginBottom={marginBottom} {...formFieldProps}>
        <Pane display='flex'>
          {children}
          <FilterOverrideSwitch
            propertyName={propertyName}
            sourceFilters={sourceFilters}
            syncFilters={syncFilters}
            onChange={onChange}
          />
        </Pane>
      </FormField>
    )
  }
)

FilterOverrideField.displayName = 'FilterOverrideField'
