import React, { FC } from 'react'
import {
  Popover,
  IconButton,
  Position,
  Menu,
  IconButtonProps
} from 'evergreen-ui'

export const MoreMenu: FC<IconButtonProps> = ({ children, ...props }) => {
  return (
    <Popover content={<Menu>{children}</Menu>} position={Position.BOTTOM_RIGHT}>
      <IconButton icon='more' height={24} appearance='minimal' {...props} />
    </Popover>
  )
}

MoreMenu.displayName = 'MoreMenu'
