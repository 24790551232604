import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { page } from '../util/analytics'

function usePageViews(): void {
  const location = useLocation()
  React.useEffect(() => page(), [location])
}

const PageViewTracker: React.FC = () => {
  usePageViews()
  return null
}

export default PageViewTracker
