import React from 'react'
import { CardProps, Heading, Pane, majorScale } from 'evergreen-ui'
import ConnectorMark from './connector-mark'
import ConnectorLabel from './connector-label'
import { ContentCard } from './content-card'
import { Connector } from '../../api/connector'

type ProviderCardProps = {
  provider: Connector
} & CardProps

export const providerCard = ({
  provider,
  ...cardProps
}: ProviderCardProps): React.ReactElement => {
  return (
    <ContentCard
      padding={majorScale(2)}
      display='flex'
      flexDirection='column'
      width='278px'
      height='108px'
      cursor='pointer'
      {...cardProps}
    >
      <Pane display='flex'>
        <Pane flexGrow={1}>
          <ConnectorMark markUrl={provider.mark_url} size={majorScale(5)} />
        </Pane>
        <ConnectorLabel connector={provider} />
      </Pane>
      <Heading
        size={600}
        marginTop={majorScale(1)}
        textOverflow='ellipsis'
        overflow='hidden'
      >
        {provider.name}
      </Heading>
    </ContentCard>
  )
}

export const ProviderCard: React.FC<ProviderCardProps> = (props) => {
  return providerCard(props)
}

ProviderCard.displayName = 'ProviderCard'
