import * as React from 'react'
import { Menu, Popover, IconButton } from 'evergreen-ui'

interface Option {
  label: string
  value: string | number
}

interface DropdownButtonProps {
  title: string
  selected?: string
  options: Option[]
  onChange: (selected: string) => void
}

const DropdownButton: React.FC<DropdownButtonProps> = ({
  title,
  options,
  selected,
  onChange
}) => {
  return (
    <Popover
      content={({ close }) => {
        return (
          <Menu>
            <Menu.OptionsGroup
              title={title}
              options={options}
              selected={selected}
              onChange={(selected) => {
                // TODO: please fix following typescript error
                // @ts-expect-error
                onChange(selected)
                close()
              }}
            />
          </Menu>
        )
      }}
    >
      <IconButton icon='caret-down' appearance='minimal' />
    </Popover>
  )
}

export default DropdownButton
