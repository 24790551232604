import React, { FC, memo } from 'react'
import { Heading, HeadingProps } from 'evergreen-ui'

export type HeadingSectionProps = HeadingProps

export const HeadingSection: FC<HeadingSectionProps> = memo(
  ({ children, ...headingProps }) => {
    return (
      <Heading size={600} {...headingProps}>
        {children}
      </Heading>
    )
  }
)
HeadingSection.displayName = 'HeadingSection'
