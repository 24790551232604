import React, { FC, memo, useEffect } from 'react'
import {
  Text,
  Pane,
  majorScale,
  Heading,
  PauseIcon,
  PlayIcon,
  ArrowRightIcon
} from 'evergreen-ui'
import {
  getConnectionStatus,
  ConnectionStatus,
  PlatformConnection
} from '../../api/connection'
import { Pipeline } from '../../api/pipeline'
import { Sync } from '../../api/sync'
import { useSync } from '../hooks/sync.hook'
import { usePipeline } from '../hooks/pipeline.hook'
import { useRootObject } from '../hooks/root-object.hook'
import { useSyncInvocations } from '../hooks/invocations.hook'
import { useTitle, useErrorToaster } from '../hooks/common'
import { XText } from '../components/x-text'
import { ActionButton } from '../components/action-button'
import { ContentCard } from '../components/content-card'
import { SpinnerSuspend } from '../components/spinner'
import { InvocationsTable } from '../invocations/invocations-table'
import { DestinationInputsForm } from '../pipelines/destination-inputs-form'
import DeleteDialog from '../delete-dialog'
import { ConnectionStatusBadge } from './connection-status-badge'
import { SyncFilters } from './sync-filters'
import { SyncStates } from './sync-states'
import { useMapping } from '../hooks/mapping.hook'

interface SyncDetailProps {
  pipelineUUID: Pipeline['uuid']
  connection?: PlatformConnection
}

interface SyncControlsProps {
  sync?: Sync
  loading: boolean
  onPause: () => void
  onResume: () => void
}

const SyncStateControl: FC<SyncControlsProps> = memo(
  ({ sync, loading, onPause, onResume }) => {
    if (sync == null) {
      return null
    }
    switch (sync.state) {
      case 'running':
        return (
          <ActionButton
            isLoading={loading}
            onClick={onPause}
            iconBefore={PauseIcon}
          >
            Pause
          </ActionButton>
        )
      case 'paused':
        return (
          <ActionButton
            isLoading={loading}
            onClick={onResume}
            iconBefore={PlayIcon}
          >
            Resume
          </ActionButton>
        )
      case 'not_started':
        return (
          <ActionButton
            loading={loading}
            onClick={onResume}
            iconBefore={PlayIcon}
          >
            Start
          </ActionButton>
        )

      default:
        return null
    }
  }
)
SyncStateControl.displayName = 'SyncStateControl'

interface MappingInputsFormProps {
  pipeline: Pipeline
  connection?: PlatformConnection
}

const MappingInputsForm: React.FC<MappingInputsFormProps> = ({
  pipeline,
  connection
}) => {
  const {
    mapping,
    mappingInputs,
    selectors,
    loading,
    fetchMapping,
    createOrUpdate,
    error
  } = useMapping(pipeline, connection)
  useErrorToaster(error)
  useEffect(() => {
    void fetchMapping()
  }, [fetchMapping])

  return (
    <DestinationInputsForm
      loading={loading}
      pipeline={pipeline}
      inputs={mappingInputs}
      mapping={mapping}
      selectors={selectors}
      updateMapping={createOrUpdate}
      allowOverrides={true}
    />
  )
}

const SyncDetail: React.FC<SyncDetailProps> = memo(
  ({ pipelineUUID, connection }) => {
    const {
      pipeline,
      loading: pipelineLoading,
      error: pipelineError
    } = usePipeline(pipelineUUID)
    const { selectedRootObject } = useRootObject(pipeline)
    useErrorToaster(pipelineError)

    const {
      loading: syncLoading,
      updating: syncUpdating,
      error: syncError,
      sync,
      resetSync,
      pause,
      resume
    } = useSync(pipelineUUID, connection?.id)
    useErrorToaster(syncError)

    const {
      loading: invocationsLoading,
      error: invocationsError,
      hasMore: hasMoreInvocations,
      loadNextInvocations,
      invocations
    } = useSyncInvocations(pipelineUUID, connection?.id)
    useErrorToaster(invocationsError)

    const connectionContextName =
      connection?.context.external_name ?? connection?.context.external_id ?? ''

    useTitle(
      connection != null
        ? `${connection.connector.name} connection for ${connectionContextName}`
        : undefined
    )

    const connectionStatus: ConnectionStatus | null =
      connection != null ? getConnectionStatus(connection) : null

    return (
      <Pane marginBottom={majorScale(3)}>
        <Pane display='flex'>
          <Pane display='flex' alignItems='end'>
            <Heading size={500}>
              <XText>{selectedRootObject?.label_many}</XText>
              <ArrowRightIcon
                marginLeft={majorScale(2)}
                marginRight={majorScale(2)}
                size={14}
              />
              <XText>{pipeline?.destination.name}</XText>
            </Heading>
            <Pane
              display='flex'
              flexDirection='column'
              justifyContent='center'
              marginLeft={majorScale(3)}
            >
              <ConnectionStatusBadge
                connection={connection}
                sync={sync}
                loading={syncLoading}
              />
            </Pane>
          </Pane>
          <Pane flexGrow={1} />
          <Pane marginRight={majorScale(2)}>
            <SyncStateControl
              loading={syncLoading || syncUpdating}
              sync={sync}
              onPause={pause}
              onResume={resume}
            />
          </Pane>
          <DeleteDialog
            deleteName='Reset'
            deletingName='Resetting'
            noun='Sync'
            onDelete={resetSync}
            dialogText={`
              This will restart the sync process for this user from the very beginning. It may take considerable time to complete and will cause many duplicate requests to destinations.
              Use with caution.
            `}
          >
            {(showDeleteDialog) => {
              return (
                <ActionButton
                  isLoading={syncLoading || syncUpdating}
                  disabled={connectionStatus !== ConnectionStatus.active}
                  intent='danger'
                  iconBefore='refresh'
                  onClick={showDeleteDialog}
                >
                  Reset
                </ActionButton>
              )
            }}
          </DeleteDialog>
        </Pane>
        <SyncFilters
          connection={connection}
          pipeline={pipeline}
          selectedRootObject={selectedRootObject}
          sync={sync}
          loading={syncLoading || pipelineLoading}
        />
        <Heading
          marginTop={majorScale(2)}
          marginBottom={majorScale(2)}
          size={400}
        >
          Mapping Overrides
          <Text size={300} color='muted' marginLeft={majorScale(2)}>
            Defines a custom mapping for this connection.
          </Text>
        </Heading>
        <ContentCard
          padding={majorScale(2)}
          elevation={1}
          marginBottom={majorScale(3)}
        >
          <SpinnerSuspend spinning={pipelineLoading}>
            {pipeline == null || connection == null ? null : (
              <MappingInputsForm pipeline={pipeline} connection={connection} />
            )}
          </SpinnerSuspend>
        </ContentCard>
        <SyncStates sync={sync} loading={syncLoading} />
        <Heading
          marginTop={majorScale(2)}
          marginBottom={majorScale(2)}
          size={400}
        >
          Latest destination logs
        </Heading>
        <InvocationsTable
          hasMore={hasMoreInvocations}
          showEntity={true}
          loading={invocationsLoading}
          invocations={invocations}
          nextPage={loadNextInvocations}
        />
      </Pane>
    )
  }
)
SyncDetail.displayName = 'SyncDetail'

export default SyncDetail
