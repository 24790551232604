import { TokenCredentials, ChangeError, request } from './request'
import { Source, BasicSource } from './source'
import { RootObjectParams, RootObjectResult } from './source-object'
import {
  Destination,
  DestinationWire,
  parseDestinationWire,
  BasicDestination,
  BasicDestinationWire,
  parseBasicDestinationWire
} from './destination'
import {
  MappingResult,
  MappingWireResult,
  Selector,
  TransformationParameters,
  parseMapping
} from './mapping'
import { Input } from './destination-input'
import { PlatformConnection } from './connection'
import { hasOwnProperty } from '../util/has-own-property'
import { Connector } from './connector'

export interface PipelineCreateParams {
  provider_slug: string
  destination_uuid: string
}

export interface PipelineUpdateParams {
  autostart_syncs: boolean
}

export interface PipelineWire {
  uuid: string
  source: Source
  destination: DestinationWire
  autostart_syncs: boolean
}

export interface Pipeline {
  uuid: string
  source: Source
  destination: Destination
  autostart_syncs: boolean
}

export interface BasicPipelineWire {
  uuid: string
  source: BasicSource
  destination: BasicDestinationWire
}

export interface BasicPipeline {
  uuid: string
  source: BasicSource
  destination: BasicDestination
}

export type PipelineWireResult<T> =
  | { pipeline: PipelineWire }
  | { errors: ChangeError<T> }

export type PipelineResult<T> =
  | { pipeline: Pipeline }
  | { errors: ChangeError<T> }

export function parsePipelineWire(pipelineWire: PipelineWire): Pipeline {
  return {
    ...pipelineWire,
    destination: parseDestinationWire(pipelineWire.destination)
  }
}

export function parseBasicPipelineWire(
  pipelineWire: BasicPipelineWire
): BasicPipeline {
  return pipelineWire
    ? {
        ...pipelineWire,
        destination: parseBasicDestinationWire(pipelineWire.destination)
      }
    : pipelineWire
}

export async function createPipeline(
  credentials: TokenCredentials,
  params: PipelineCreateParams
): Promise<PipelineResult<PipelineCreateParams>> {
  const result = await request<PipelineWireResult<PipelineCreateParams>>({
    path: '/pipelines',
    method: 'POST',
    credentials,
    body: {
      pipeline: params
    }
  })

  if (hasOwnProperty(result, 'pipeline')) {
    return { pipeline: parsePipelineWire(result.pipeline) }
  } else {
    return result
  }
}

export async function listPipelines(
  credentials: TokenCredentials
): Promise<Pipeline[]> {
  const { pipelines } = await request<{ pipelines: PipelineWire[] }>({
    path: '/pipelines',
    credentials
  })

  return pipelines.map(parsePipelineWire)
}

export async function getPipeline(
  credentials: TokenCredentials,
  uuid: string
): Promise<Pipeline> {
  const { pipeline } = await request<{ pipeline: PipelineWire }>({
    path: `/pipelines/${uuid}`,
    credentials
  })

  return parsePipelineWire(pipeline)
}

export async function updatePipeline(
  credentials: TokenCredentials,
  uuid: string,
  params: PipelineUpdateParams
): Promise<PipelineResult<PipelineUpdateParams>> {
  const result = await request<PipelineWireResult<PipelineUpdateParams>>({
    path: `/pipelines/${uuid}`,
    method: 'PUT',
    credentials,
    body: {
      pipeline: params
    }
  })

  if (hasOwnProperty(result, 'pipeline')) {
    return { pipeline: parsePipelineWire(result.pipeline) }
  } else {
    return result
  }
}

export async function createOrUpdateRootObject(
  credentials: TokenCredentials,
  pipelineUuid: string,
  params: RootObjectParams
): Promise<RootObjectResult> {
  return await request<RootObjectResult>({
    path: `/pipelines/${pipelineUuid}/source/object`,
    method: 'POST',
    credentials,
    body: {
      root_object: params
    }
  })
}

function buildMappingPath(
  pipelineUuid: string,
  connectionExternalId?: string
): string {
  return connectionExternalId
    ? `/pipelines/${pipelineUuid}/connections/${connectionExternalId}/mapping`
    : `/pipelines/${pipelineUuid}/mapping`
}

export async function createOrUpdateMapping(
  credentials: TokenCredentials,
  pipelineUuid: string,
  transformations: TransformationParameters[],
  inputs: Input[],
  connectionExternalId?: string
): Promise<MappingResult> {
  const result = await request<MappingWireResult>({
    path: buildMappingPath(pipelineUuid, connectionExternalId),
    method: 'POST',
    credentials,
    body: {
      inputs,
      mapping: {
        transformations
      }
    }
  })
  if (hasOwnProperty(result, 'mapping')) {
    return { mapping: parseMapping(result.mapping), inputs: result.inputs }
  } else {
    return result
  }
}

export async function getMapping(
  credentials: TokenCredentials,
  pipelineUuid: string,
  connectionExternalId?: string
): Promise<MappingResult | undefined> {
  try {
    const result = await request<MappingWireResult>({
      path: buildMappingPath(pipelineUuid, connectionExternalId),
      credentials
    })

    if (hasOwnProperty(result, 'mapping') && hasOwnProperty(result, 'inputs')) {
      const mapping = result.mapping ? parseMapping(result.mapping) : undefined

      return { mapping: mapping, inputs: result.inputs }
    } else {
      return
    }
  } catch (err) {
    // Gracefully handle an undefined mapping by just returning undefined
    if (err.statusCode === 404) {
      return
    }
    throw err
  }
}

export async function getSelectors(
  credentials: TokenCredentials,
  pipelineUuid: string,
  connectionExternalId?: string
): Promise<Selector[]> {
  const path = connectionExternalId
    ? `/pipelines/${pipelineUuid}/connections/${connectionExternalId}/selectors`
    : `/pipelines/${pipelineUuid}/mapping/source_data`

  const { selectors } = await request<{ selectors: Selector[] }>({
    path,
    credentials
  })

  return selectors
}

export async function listConnectionsByPipeline(
  credentials: TokenCredentials,
  pipelineUuid: string
): Promise<PlatformConnection[]> {
  const { connections } = await request<{ connections: PlatformConnection[] }>({
    path: `/pipelines/${pipelineUuid}/connections`,
    credentials
  })
  return connections
}

export function getProviderFromPipeline(pipeline: Pipeline): Connector {
  // TODO: We should really be checking the destination role.
  //       Consider introducing PlatformDestination and ProviderDestination
  return pipeline.destination.provider ?? pipeline.source.provider
}
