import React, { FC, memo, useCallback } from 'react'
import {
  Card,
  CardProps,
  Heading,
  majorScale,
  Pane,
  Pill,
  Tooltip
} from 'evergreen-ui'
import { Link } from 'react-router-dom'
import ConnectorMark from '../components/connector-mark'
import { useConnectionsByConnector } from '../hooks/connections-by-connector.hook'
import { Connector } from '../../api/connector'

export type ProviderCardProps = CardProps & {
  provider: Connector
  selected: boolean
  onHover?: (target: Connector | undefined) => void
  refCallback?: (provider: Connector, element: HTMLElement) => void
}

// TODO: unify with the provider card in the pipelines UI.
export const ProviderCard: FC<ProviderCardProps> = memo(
  ({ provider, selected, onHover, refCallback }) => {
    const { connections } = useConnectionsByConnector(provider)

    const onEnterHandler = useCallback(() => {
      onHover?.(provider)
    }, [onHover, provider])

    const onLeaveHandler = useCallback(() => {
      onHover?.(undefined)
    }, [onHover])

    const internalRefCallback = useCallback(
      (element: HTMLElement) => {
        refCallback?.(provider, element)
      },
      [refCallback, provider]
    )

    return (
      // TODO: please fix following typescript error
      // @ts-expect-error
      <div ref={internalRefCallback} data-uuid={provider.slug}>
        <Card
          is={Link}
          to={`/providers/${provider.slug}`}
          elevation={1}
          hoverElevation={3}
          display='flex'
          padding={majorScale(2)}
          textDecoration='none'
          opacity={selected ? 1 : 0.5}
          background='white'
          marginBottom={majorScale(2)}
          onMouseEnter={onEnterHandler}
          onMouseLeave={onLeaveHandler}
        >
          <Pane width='100%' display='flex' alignItems='center'>
            <ConnectorMark
              markUrl={provider.mark_url}
              size={20}
              paddingRight={majorScale(1)}
            />
            <Heading
              size={600}
              title={provider.name}
              textOverflow='ellipsis'
              overflow='hidden'
              paddingRight={majorScale(1)}
              flexGrow={1}
            >
              {provider.name}
            </Heading>
            <Tooltip content='Connections'>
              <Pill>{connections.length}</Pill>
            </Tooltip>
          </Pane>
        </Card>
      </div>
    )
  }
)

ProviderCard.displayName = 'ProviderCard'
