import React, { memo, FunctionComponent } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Link, Paragraph } from 'evergreen-ui'
import ContentSection from '../content-section'
import { SpinnerSuspend } from '../components/spinner'
import { Connector } from '../../api/connector'
import { useAppObjects } from '../hooks/app-objects'
import { useErrorToaster } from '../hooks/common'
import { FormProps } from './crm-embed/form-props'
import { HubSpotForm } from './crm-embed/hubspot-form'
import { SalesforceForm } from './crm-embed/salesforce-form'
import { NotSupported } from './crm-embed/not-supported'

const FORMS: Record<string, FunctionComponent<FormProps>> = {
  'hubspot-crm': HubSpotForm,
  'salesforce-sales-cloud-crm': SalesforceForm
}

export interface CrmEmbedTabProps {
  loading: boolean
  connector?: Connector
}

export const CrmEmbedTab: FunctionComponent<CrmEmbedTabProps> = memo(
  ({ loading: connectorLoading, connector }) => {
    const { error, appObjects } = useAppObjects()
    useErrorToaster(error)

    const Form =
      connector != null ? FORMS[connector.template.slug] ?? NotSupported : null

    return (
      <ContentSection>
        <SpinnerSuspend spinning={connectorLoading || appObjects == null}>
          {appObjects != null &&
            appObjects.length !== 0 &&
            Form != null &&
            connector != null && (
              <Form provider={connector} appObjects={appObjects} />
            )}
          {appObjects != null && appObjects.length === 0 && (
            <Paragraph>
              No App Objects defined.{' '}
              <Link is={RouterLink} to='/app-objects/new'>
                Add an App Object
              </Link>
              .
            </Paragraph>
          )}
        </SpinnerSuspend>
      </ContentSection>
    )
  }
)

CrmEmbedTab.displayName = 'CrmEmbedTab'
