import * as React from 'react'
import CopyableTextInput from './copyable-text-input'
import { Paragraph, Dialog, Button, toaster } from 'evergreen-ui'
import { createAPIKey, NewAPIKey } from '../api/key'
import { withCredentials, CredentialConsumer } from './login-wrapper'
import { withNoRecording } from './with-no-recording'
import errorMessage from '../util/error-message'

interface KeyDisplayProps {
  label: string
  value: string
  className?: string
}

const KeyDisplay: React.FunctionComponent<KeyDisplayProps> = ({
  label,
  value,
  className
}) => {
  return (
    <CopyableTextInput
      label={label}
      value={value}
      className={className}
      fontFamily='mono'
    />
  )
}

const SecretKeyDisplay = withNoRecording(KeyDisplay)

interface GenerateAPIKeyProps {
  appearance?: 'default' | 'primary' | 'minimal'
  onCreate: (key: NewAPIKey) => void
  hideDialog?: boolean
}

interface GenerateAPIKeyState {
  generating: boolean
  newKey?: NewAPIKey
}

class GenerateAPIKey extends React.Component<
  CredentialConsumer<GenerateAPIKeyProps>,
  GenerateAPIKeyState
> {
  constructor(props: GenerateAPIKeyProps) {
    // TODO: please fix following typescript error
    // @ts-expect-error
    super(props)
    this.state = {
      generating: false
    }
  }

  handleGenerateKey = async (): Promise<void> => {
    this.setState({ generating: true })
    try {
      const key = await this.props.callWithCredentials(createAPIKey)
      this.setState({
        newKey: key
      })
      this.props.onCreate(key)
    } catch (e) {
      console.error(e)
      toaster.danger(`Error while generating API Key: ${errorMessage(e)}`)
    } finally {
      this.setState({ generating: false })
    }
  }

  renderNewKeyDialog(): React.ReactNode {
    const { hideDialog } = this.props
    const { newKey } = this.state

    return (
      <Dialog
        isShown={!hideDialog && newKey != null}
        onCloseComplete={() => this.setState({ newKey: undefined })}
        hasCancel={false}
        confirmLabel='Done'
        title='Generated API Key'
      >
        <KeyDisplay
          label='Publishable Key'
          value={newKey ? newKey.publishable_key : ''}
        />
        <SecretKeyDisplay
          label='Secret Key'
          value={newKey ? newKey.secret_key : ''}
        />
        <Paragraph>
          Copy the values displayed above for your generated API Key. The Secret
          Key will not be available again, so be sure to copy it to a safe and
          secure location.
        </Paragraph>
      </Dialog>
    )
  }

  render(): React.ReactNode {
    const { generating } = this.state
    const { appearance } = this.props

    return (
      <>
        {this.renderNewKeyDialog()}
        <Button
          // TODO: please fix following typescript error
          // @ts-expect-error
          iconBefore={generating ? '' : 'add'}
          appearance={appearance}
          isLoading={generating}
          onClick={this.handleGenerateKey}
        >
          Generate API Key
        </Button>
      </>
    )
  }
}

export default withCredentials(GenerateAPIKey)
