import {
  request,
  LegacyChangeResult,
  LegacyChangeResultValues,
  TokenCredentials
} from './request'
import { Scope } from './scope'
import { Template } from './template'
import * as analytics from '../util/analytics'

export interface ConnectorSlim {
  name: string
  slug: string
  authorizer_prototype_slug: string
  template_slug: string
  short_description: string
  mark_url: string
  about?: string
  description?: string
  supports_multiple_connections: boolean
  sync: boolean
  crm: boolean
}

export interface Connector extends ConnectorSlim {
  scopes: Scope[]
  template: Template
}

export type ConnectorParams = Partial<{
  name: string
  slug: string
  authorizer_prototype_slug: string
  short_description: string
  mark_url: string
  about?: string
  description?: string
  supports_multiple_connections: boolean
  scopes: Scope[]
}>

export interface ConnectorResult extends LegacyChangeResult<ConnectorParams> {
  connector?: Connector
}

export type ConnectorResultValues<T extends Connector = Connector> =
  LegacyChangeResultValues<T>

export async function listConnectors(
  credentials: TokenCredentials
): Promise<Connector[]> {
  const { connectors } = await request({
    path: '/connectors',
    credentials
  })

  return connectors as Connector[]
}

export async function getConnector(
  credentials: TokenCredentials,
  connectorSlug: string
): Promise<Connector> {
  const { connector } = await request({
    path: `/connectors/${connectorSlug}`,
    credentials
  })

  return connector as Connector
}

export async function updateConnector(
  credentials: TokenCredentials,
  connectorSlug: string,
  connector: ConnectorParams
): Promise<ConnectorResult> {
  const { errors, connector: updatedConnector } = await request({
    path: `/connectors/${connectorSlug}`,
    method: 'PUT',
    credentials,
    body: {
      connector
    }
  })

  return {
    // TODO: please fix following typescript error
    // @ts-expect-error
    connector: updatedConnector,
    // TODO: please fix following typescript error
    // @ts-expect-error
    errors
  }
}

export async function updateConnectorValues(
  credentials: TokenCredentials,
  connectorSlug: string,
  connector: ConnectorParams
): Promise<ConnectorResultValues> {
  const { errors, connector: updatedConnector } = await updateConnector(
    credentials,
    connectorSlug,
    connector
  )

  return {
    values: updatedConnector,
    errors
  }
}

export function newConnector(template: Template): Connector {
  return {
    name: template.name,
    slug: template.slug,
    authorizer_prototype_slug: template.authorizer_prototype_slug,
    short_description: template.short_description,
    mark_url: template.mark_url,
    about: template.about,
    description: '',
    supports_multiple_connections: template.supports_multiple_connections,
    scopes: [],
    template,
    template_slug: template.slug,
    sync: template.sync,
    crm: template.crm
  }
}

export async function createConnector(
  credentials: TokenCredentials,
  connector: ConnectorParams
): Promise<ConnectorResult> {
  const { errors, connector: createdConnector } = await request({
    path: '/connectors',
    method: 'POST',
    credentials,
    body: {
      connector
    }
  })

  if (createdConnector != null) {
    analytics.track('create-connector', {
      // TODO: please fix following typescript error
      // @ts-expect-error
      templateSlug: createdConnector.template.slug,
      // TODO: please fix following typescript error
      // @ts-expect-error
      slug: createdConnector.slug
    })
  }

  return {
    // TODO: please fix following typescript error
    // @ts-expect-error
    connector: createdConnector,
    // TODO: please fix following typescript error
    // @ts-expect-error
    errors
  }
}

export async function deleteConnector(
  credentials: TokenCredentials,
  connectorSlug: string
): Promise<void> {
  await request({
    path: `/connectors/${connectorSlug}`,
    method: 'DELETE',
    credentials
  })
}
