import { BackButton, majorScale } from 'evergreen-ui'
import React, { FC, Fragment, memo } from 'react'
import { Link } from 'react-router-dom'
import { AppObject } from '../../api/crm'
import { TokenCredentials } from '../../api/request'
import { Accordion } from '../components/accordion'
import { ContentCard } from '../components/content-card'
import { Divider } from '../components/divider'
import { NewItemCard } from '../components/new-item-card'
import { SpinnerSuspend } from '../components/spinner'
import { useTitle } from '../hooks/common'
import { AppObjectTabType } from './app-object'
import EditAppObjectProperty from './edit-app-object-property'

interface AppObjectPropertiesProps {
  object: AppObject | undefined
  loading: boolean
  credentials: TokenCredentials | null
  refresh: () => Promise<void>
}

const AppObjectProperties: FC<AppObjectPropertiesProps> = memo(
  ({ object, loading, credentials, refresh }) => {
    useTitle(`${object?.label_one ?? ''} App Object Properties`)

    return (
      <>
        <SpinnerSuspend spinning={loading}>
          {object ? (
            <>
              {object.properties.length ? (
                <ContentCard
                  elevation={1}
                  marginBottom={majorScale(2)}
                  width={570}
                >
                  {object.properties.map((property, index) => (
                    <Fragment key={property.id}>
                      <Accordion title={property.label}>
                        <EditAppObjectProperty
                          object={object}
                          property={property}
                          credentials={credentials}
                          refresh={refresh}
                        />
                      </Accordion>
                      {index === object.properties.length - 1 ? null : (
                        <Divider />
                      )}
                    </Fragment>
                  ))}
                </ContentCard>
              ) : null}
              <NewItemCard
                to={`/app-objects/${object.id}/${AppObjectTabType.Properties}/new`}
                width={570}
                height={60}
                marginTop={majorScale(2)}
                title='Add Property'
              />
            </>
          ) : null}
        </SpinnerSuspend>
        <BackButton is={Link} to={`/app-objects`} marginTop={majorScale(3)}>
          Back to App Objects
        </BackButton>
      </>
    )
  }
)

AppObjectProperties.displayName = 'AppObjectProperties'

export default AppObjectProperties
