import React, { useEffect, useState, memo, FC } from 'react'
import { toaster } from 'evergreen-ui'
import { hasOwnProperty } from '../../../util/has-own-property'
import { InputParameters } from '../../../api/destination-input'
import { UseDestinationState } from '../../hooks/destination.hook'
import ProviderForm from './provider-form'

type EditProviderProps = UseDestinationState

export const EditProvider: FC<EditProviderProps> = memo(
  ({ destination, loading, saving, validationErrors, updateDestinationFx }) => {
    const [name, setName] = useState<string>('')
    const [inputs, setInputs] = useState<InputParameters[]>([])
    const [config, setConfig] = useState<Record<string, string>>({})

    useEffect(() => {
      setName(destination?.name ?? '')
      setInputs(destination?.inputs ?? [])
      setConfig(destination?.config ?? {})
    }, [destination?.name, destination?.inputs, destination?.config])

    const erroredInput = validationErrors?.inputs?.find(
      (inputsError) => Object.keys(inputsError).length
    )

    const inputsError =
      erroredInput?.name?.[0]?.message ??
      erroredInput?.simple_type?.type?.[0]?.message ??
      erroredInput?.simple_type?.format?.[0]?.message

    const configError = validationErrors?.config?.[0]?.message

    async function handleSubmit(): Promise<void> {
      try {
        const result = await updateDestinationFx({
          ...destination,
          name,
          inputs,
          config
        })

        if (hasOwnProperty(result, 'errors')) {
          toaster.danger('Failed to save destination')
        } else {
          toaster.success('Saved destination!')
        }
      } catch (e) {}
    }

    if (destination?.provider == null) return <></>

    return (
      <ProviderForm
        providerTemplateSlug={destination.provider.template.slug}
        loading={loading}
        saving={saving}
        onSubmit={handleSubmit}
        name={name}
        nameError={validationErrors?.name?.[0]?.message}
        setName={setName}
        inputs={inputs}
        inputsError={inputsError}
        setInputs={setInputs}
        config={config}
        setConfig={setConfig}
        configError={configError}
      />
    )
  }
)

EditProvider.displayName = 'EditProvider'
