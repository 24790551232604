import * as React from 'react'
import {
  Paragraph,
  Heading,
  Link,
  Code,
  Table,
  Menu,
  majorScale,
  toaster
} from 'evergreen-ui'
import ContentSection from '../content-section'
import DeleteDialog from '../delete-dialog'
import { deleteAPIKey, listAPIKeys, APIKey } from '../../api/key'
import GenerateAPIKey from '../generate-api-key'
import { withCredentials, CredentialConsumer } from '../login-wrapper'
import TableSpinner from '../components/table-spinner'
import { MoreMenu } from '../components/more-menu'
import errorMessage from '../../util/error-message'

interface APIKeyRowProps {
  apiKey: APIKey
}

interface ActiveAPIKeyRowProps extends APIKeyRowProps {
  onUpdate: (apiKey: APIKey) => void
}

interface ActiveAPIKeyRowState {
  showingRevoke: boolean
  revoking: boolean
}

class ActiveAPIKeyRowWithoutCreds extends React.Component<
  CredentialConsumer<ActiveAPIKeyRowProps>,
  ActiveAPIKeyRowState
> {
  constructor(props: ActiveAPIKeyRowProps) {
    // TODO: please fix following typescript error
    // @ts-expect-error
    super(props)
    this.state = {
      showingRevoke: false,
      revoking: false
    }
  }

  handleRevoke = async (): Promise<void> => {
    const { apiKey, callWithCredentials } = this.props

    this.setState({
      revoking: true
    })
    try {
      await callWithCredentials(
        async (credentials) => await deleteAPIKey(credentials, apiKey)
      )
      this.props.onUpdate({ ...apiKey, revoked: true })
      toaster.success('API Key revoked')
    } catch (e) {
      toaster.danger(`Error while revoking API Key: ${errorMessage(e)}`)
      this.setState({ revoking: false })
    }
  }

  render(): React.ReactNode {
    const { publishable_key: publishableKey, inserted_at: insertedAt } =
      this.props.apiKey

    return (
      <Table.Row>
        <Table.TextCell>
          <Code size={300}>{publishableKey}</Code>
        </Table.TextCell>
        <Table.TextCell>{insertedAt.toLocaleString()}</Table.TextCell>
        <Table.Cell width={48} flex='none'>
          <MoreMenu>
            <Menu.Group>
              <DeleteDialog
                noun='API Key'
                deleteName='Revoke'
                deletingName='Revoking'
                onDelete={this.handleRevoke}
                dialogText={
                  <>
                    This will permanently revoke the API key with a Publishable
                    Key of <Code size={300}>{publishableKey}</Code>. Any
                    attempts to access the Xkit API using this key after it is
                    revoked will result in an authentication error.
                  </>
                }
              >
                {(showDeleteDialog) => {
                  return (
                    <Menu.Item
                      intent='danger'
                      icon='ban-circle'
                      onSelect={showDeleteDialog}
                    >
                      Revoke...
                    </Menu.Item>
                  )
                }}
              </DeleteDialog>
            </Menu.Group>
          </MoreMenu>
        </Table.Cell>
      </Table.Row>
    )
  }
}

const ActiveAPIKeyRow = withCredentials(ActiveAPIKeyRowWithoutCreds)

class RevokedAPIKeyRow extends React.Component<APIKeyRowProps> {
  render(): React.ReactNode {
    const { publishable_key: publishableKey, updated_at: updatedAt } =
      this.props.apiKey

    return (
      <Table.Row>
        <Table.TextCell>
          <Code size={300}>{publishableKey}</Code>
        </Table.TextCell>
        <Table.TextCell>{updatedAt.toLocaleString()}</Table.TextCell>
        <Table.Cell width={48} flex='none' />
      </Table.Row>
    )
  }
}

type APIKeySettingsProps = {
  crmOnlyConnectors: boolean
} & CredentialConsumer

interface APIKeySettingsState {
  loading: boolean
  keys: APIKey[]
}

class APIKeySettings extends React.Component<
  APIKeySettingsProps,
  APIKeySettingsState
> {
  constructor(props: APIKeySettingsProps) {
    super(props)

    this.state = {
      loading: false,
      keys: []
    }
  }

  componentDidMount(): void {
    void this.loadAPIKeys()
  }

  async loadAPIKeys(): Promise<void> {
    this.setState({ loading: true })
    try {
      const keys = await this.props.callWithCredentials(listAPIKeys)
      this.setState({ keys })
    } catch (e) {
      console.error(e)
      toaster.danger(`Error while loading API keys: ${errorMessage(e)}`)
    } finally {
      this.setState({ loading: false })
    }
  }

  updateKey(apiKey: APIKey): void {
    const { keys } = this.state
    const index = keys.findIndex(({ id }) => id === apiKey.id)
    if (index === -1) {
      console.debug(
        `Could not find API Key to replace during onUpdate: ${apiKey.id}`
      )
      return
    }
    const updatedKeys = keys.slice()
    updatedKeys.splice(index, 1, apiKey)

    this.setState({ keys: updatedKeys })
  }

  addKey(apiKey: APIKey): void {
    const { keys } = this.state
    const updatedKeys = keys.slice()
    // TODO: please fix following typescript error
    // @ts-expect-error
    updatedKeys.push({ ...apiKey, secret_key: undefined })
    this.setState({ keys: updatedKeys })
  }

  revokedKeys(): APIKey[] {
    const { keys } = this.state
    return keys.filter((key) => key.revoked)
  }

  activeKeys(): APIKey[] {
    const { keys } = this.state
    return keys.filter((key) => !key.revoked)
  }

  renderActiveTableBody(): React.ReactNode {
    const { loading } = this.state

    if (loading) {
      return <TableSpinner />
    }

    const keys = this.activeKeys()

    return (
      <>
        {keys.map((key) => {
          return (
            <ActiveAPIKeyRow
              key={key.id}
              apiKey={key}
              onUpdate={(updatedKey) => this.updateKey(updatedKey)}
            />
          )
        })}
        <Table.Row display='flex' alignItems='center' justifyContent='center'>
          <GenerateAPIKey
            onCreate={(key) => this.addKey(key)}
            appearance='minimal'
          />
        </Table.Row>
      </>
    )
  }

  renderRevokedKeys(): React.ReactNode {
    const revokedKeys = this.revokedKeys()

    if (revokedKeys.length === 0) {
      return
    }

    return (
      <>
        <Heading marginTop='default'>Revoked API Keys</Heading>
        <Paragraph marginTop='default'>
          The keys listed below have been revoked and cannot be used to access
          the Xkit API. They are listed here for recordkeeping purposes. If you
          need a new key, generate one above.
        </Paragraph>
        <Table marginTop={majorScale(2)} maxWidth='680px'>
          <Table.Head>
            <Table.TextHeaderCell>Publishable Key</Table.TextHeaderCell>
            <Table.TextHeaderCell>Revocation Date</Table.TextHeaderCell>
            <Table.HeaderCell width={48} flex='none' />
          </Table.Head>
          <Table.Body>
            {revokedKeys.map((key) => (
              <RevokedAPIKeyRow apiKey={key} key={key.id} />
            ))}
          </Table.Body>
        </Table>
      </>
    )
  }

  render(): React.ReactElement {
    const { crmOnlyConnectors } = this.props
    return (
      <ContentSection
        title='API Keys'
        description={
          <>
            API Keys allow you to interact with the Xkit API.{' '}
            <Link
              size={300}
              href={
                crmOnlyConnectors
                  ? 'https://xkit.co/docs/crm-api#authenticating-with-the-api'
                  : 'https://docs.xkit.co/docs/authentication-guide'
              }
            >
              View documentation
            </Link>
          </>
        }
      >
        <Heading>Active API Keys</Heading>
        <Paragraph marginTop='default'>
          The keys listed below are currently active and can be used to access
          the Xkit API. To use these keys, you need both the Publishable Key
          listed, and the Secret Key which was shown to you when the key was
          generated. It is impossible for us to retrieve a Secret Key after it
          has been generated, instead you should generate a new key and revoke
          the old one.{' '}
          <Link
            href={
              crmOnlyConnectors
                ? 'https://xkit.co/docs/crm-api#authenticating-with-the-api'
                : 'https://docs.xkit.co/docs/authentication-guide'
            }
          >
            View the documentation
          </Link>{' '}
          for more information about API keys.
        </Paragraph>
        <Table marginTop={majorScale(2)} maxWidth='680px'>
          <Table.Head>
            <Table.TextHeaderCell>Publishable Key</Table.TextHeaderCell>
            <Table.TextHeaderCell>Creation Date</Table.TextHeaderCell>
            <Table.HeaderCell width={48} flex='none' />
          </Table.Head>
          <Table.Body>{this.renderActiveTableBody()}</Table.Body>
        </Table>
        {this.renderRevokedKeys()}
      </ContentSection>
    )
  }
}

export default withCredentials(APIKeySettings)
