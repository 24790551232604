import React, { useState } from 'react'
import {
  Pane,
  BackButton,
  FunctionIcon,
  majorScale,
  toaster
} from 'evergreen-ui'
import { Redirect, Link } from 'react-router-dom'
import { hasOwnProperty } from '../../../util/has-own-property'
import { useTitle } from '../../hooks/common'
import { useDefaultDependencies } from '../../hooks/dependencies.hook'
import {
  Destination,
  DestinationRole,
  DestinationType,
  DestinationResult,
  createDestination
} from '../../../api/destination'
import { InputParameters } from '../../../api/destination-input'
import { CredentialConsumer, withCredentials } from '../../login-wrapper'
import FunctionForm from './function-form'
import { HeadingTitle } from '../../components/heading-title'

const EXAMPLE_BODY = `exports.handler = async function ({ variables, inputs, context, connection, source }) {
  console.log('variables: ' + JSON.stringify(variables, null, 2))
  console.log('inputs: ' + JSON.stringify(inputs, null, 2))
  console.log('context: ' + JSON.stringify(context, null, 2))
  console.log('connection: ' + JSON.stringify(connection, null, 2))
  console.log('source: ' + JSON.stringify(source, null, 2))
}`

const NewFunction: React.FC<CredentialConsumer> = ({ callWithCredentials }) => {
  useTitle('New Function Destination')
  const [name, setName] = useState<string>('')
  const [nameError, setNameError] = useState<string | undefined>(undefined)
  const [variables, setVariables] = useState<Array<[string, string]>>([])
  const [inputs, setInputs] = useState<InputParameters[]>([])
  const [variablesError, setVariablesError] = useState<string | undefined>(
    undefined
  )
  const [inputsError, setInputsError] = useState<string | undefined>(undefined)
  const [upsertBody, setUpsertBody] = useState<string>(EXAMPLE_BODY)
  const [upsertBodyError, setUpsertBodyError] = useState<string | undefined>(
    undefined
  )
  const [deleteBody, setDeleteBody] = useState<string>(EXAMPLE_BODY)
  const [deleteBodyError, setDeleteBodyError] = useState<string | undefined>(
    undefined
  )
  const [destination, setDestination] = useState<Destination | undefined>(
    undefined
  )
  const [saving, setSaving] = useState<boolean>(false)
  const dependencies = useDefaultDependencies()

  // TODO: please fix following typescript error
  // @ts-expect-error
  const createDestinationFx: () => Promise<DestinationResult> = async () => {
    setSaving(true)
    try {
      const res = await callWithCredentials(
        async (creds) =>
          await createDestination(creds, {
            role: DestinationRole.platform,
            type: DestinationType.function,
            name,
            inputs,
            variables: Object.fromEntries(variables),
            upsert_function: { body: upsertBody },
            delete_function: { body: deleteBody }
          })
      )

      // TODO: consolidate validation error logic with edit-destination
      if (hasOwnProperty(res, 'errors')) {
        const errors = res.errors
        let foundError = false
        // Reset all errors
        setNameError(undefined)
        setVariablesError(undefined)
        setInputsError(undefined)
        setUpsertBodyError(undefined)
        setDeleteBodyError(undefined)
        if (errors.name?.length) {
          setNameError(errors.name[0].message)
          foundError = true
        }
        if (errors.variables?.length) {
          setVariablesError(errors.variables[0].message)
          foundError = true
        }
        if (errors.inputs?.length) {
          const inputsError = errors.inputs.find(
            (inputsError) => Object.keys(inputsError).length
          )
          if (inputsError != null) {
            setInputsError(
              inputsError.name?.[0].message ??
                inputsError.simple_type?.type?.[0].message ??
                inputsError.simple_type?.format?.[0].message
            )
            foundError = true
          }
        }
        if (errors.upsert_function?.body?.length) {
          setUpsertBodyError(errors.upsert_function.body[0].message)
          foundError = true
        }
        if (errors.delete_function?.body?.length) {
          setDeleteBodyError(errors.delete_function.body[0].message)
          foundError = true
        }

        if (!foundError) {
          throw new Error('Unknown error while saving destination')
        }
      } else if (
        hasOwnProperty(res, 'destination') &&
        res.destination != null
      ) {
        setDestination(res.destination)
        return { destination: res.destination }
      } else {
        throw new Error('Unknown error while saving destination')
      }
    } catch (e) {
      toaster.danger(e.message)
    } finally {
      setSaving(false)
    }
  }

  if (destination != null) {
    toaster.success('Created Destination!')
    return <Redirect to={`/destinations/${destination.uuid}`} />
  }

  return (
    <Pane>
      <HeadingTitle display='flex' alignItems='center'>
        <FunctionIcon
          size={24}
          marginRight={majorScale(2)}
          style={{ verticalAlign: 'bottom' }}
        />
        New Function Destination
      </HeadingTitle>
      <FunctionForm
        saving={saving}
        name={name}
        nameError={nameError}
        setName={setName}
        variables={variables}
        variablesError={variablesError}
        setVariables={setVariables}
        inputs={inputs}
        setInputs={setInputs}
        inputsError={inputsError}
        upsertBody={upsertBody}
        upsertBodyError={upsertBodyError}
        setUpsertBody={setUpsertBody}
        upsertDependencies={dependencies}
        deleteBody={deleteBody}
        deleteBodyError={deleteBodyError}
        setDeleteBody={setDeleteBody}
        deleteDependencies={dependencies}
        onSubmit={async () => await createDestinationFx()}
      />
      <BackButton is={Link} to='/destinations'>
        Back to Destinations
      </BackButton>
    </Pane>
  )
}

export default withCredentials(NewFunction)
