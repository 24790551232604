import React, { useState } from 'react'
import {
  Button,
  Heading,
  majorScale,
  Paragraph,
  Position,
  Table,
  Text,
  Tooltip
} from 'evergreen-ui'
import { getInvocationReadableUUID, Invocation } from '../../api/invocation'
import ContentTable from '../content-table'
import CustomSideSheet from '../side-sheet'
import CodeBlock from '../code-block'
import { InvocationStatusIcon } from './invocation-status-icon'
import { TableRowEmpty } from '../components/table-row-empty'

interface InvocationDetailsSheetProps {
  isShown: boolean
  showEntity: boolean
  onClose: () => void
  invocation: Invocation
}

const InvocationDetailsSheet: React.FC<InvocationDetailsSheetProps> = ({
  isShown,
  showEntity,
  onClose,
  invocation
}) => {
  return (
    <CustomSideSheet
      // this was fixed on v5.1+: https://github.com/segmentio/evergreen/pull/1048
      // @ts-expect-error
      position={Position.BOTTOM}
      preventBodyScrolling
      isShown={isShown}
      title={`Invocation #${invocation.uuid}`}
      onClose={onClose}
    >
      <Heading size={400} marginBottom={majorScale(1)}>
        Status
      </Heading>
      <Paragraph marginBottom={majorScale(1)}>{invocation.status}</Paragraph>
      {showEntity && (
        <>
          <Heading size={400} marginBottom={majorScale(1)}>
            Entity ID
          </Heading>
          <Paragraph marginBottom={majorScale(1)}>
            {invocation.entity_external_id}
          </Paragraph>
        </>
      )}
      <Heading size={400} marginBottom={majorScale(1)}>
        Operation
      </Heading>
      <Paragraph marginBottom={majorScale(1)}>{invocation.operation}</Paragraph>
      <Heading size={400} marginBottom={majorScale(1)}>
        Completed At
      </Heading>
      <Paragraph marginBottom={majorScale(1)}>
        {invocation.returned_at?.toLocaleString()}
      </Paragraph>
      <Heading size={400}>Inputs</Heading>
      <CodeBlock
        code={JSON.stringify(invocation.request_body.inputs ?? '', null, 2)}
        language='json'
      />
      <Heading size={400} marginTop={majorScale(2)}>
        Variables
      </Heading>
      <CodeBlock
        code={JSON.stringify(invocation.request_body.variables ?? '', null, 2)}
        language='json'
      />
      <Heading size={400} marginTop={majorScale(2)}>
        Context
      </Heading>
      <CodeBlock
        code={JSON.stringify(invocation.request_body.context ?? '', null, 2)}
        language='json'
      />
      <Heading size={400} marginTop={majorScale(2)}>
        Response
      </Heading>
      <CodeBlock code={invocation.response_body ?? ''} language='markdown' />
      <Heading size={400} marginTop={majorScale(2)}>
        Log Result
      </Heading>
      <CodeBlock code={invocation.log_result ?? ''} language='markdown' />
    </CustomSideSheet>
  )
}

interface DestinationLogRowProps {
  showEntity: boolean
  invocation: Invocation
}

const DestinationLogRow: React.FC<DestinationLogRowProps> = ({
  showEntity,
  invocation
}) => {
  const [showDetails, setShowDetails] = useState<boolean>(false)

  return (
    <>
      <Table.Row
        isSelectable
        onClick={() => setShowDetails(true)}
        key={invocation.uuid}
      >
        <Table.Cell width={42} flex='none'>
          <InvocationStatusIcon status={invocation.status} />
        </Table.Cell>
        <Table.TextCell>
          <Tooltip content={invocation.uuid} position={Position.RIGHT}>
            <Text size={300}>{getInvocationReadableUUID(invocation)}</Text>
          </Tooltip>
        </Table.TextCell>
        <Table.TextCell>{invocation.operation}</Table.TextCell>
        {showEntity && (
          <Table.TextCell>{invocation.entity_external_id}</Table.TextCell>
        )}
        <Table.TextCell>
          {invocation.returned_at?.toLocaleString()}
        </Table.TextCell>
        <Table.Cell width={130} flex='none'>
          <Button appearance='minimal' onClick={() => setShowDetails(true)}>
            View details
          </Button>
        </Table.Cell>
      </Table.Row>
      <InvocationDetailsSheet
        invocation={invocation}
        showEntity={showEntity}
        isShown={showDetails}
        onClose={() => {
          setShowDetails(false)
        }}
      />
    </>
  )
}

interface InvocationTableProps {
  showEntity: boolean
  loading: boolean
  hasMore: boolean
  invocations: Invocation[]
  nextPage: (invocations: Invocation[]) => void
}

export const InvocationsTable: React.FC<InvocationTableProps> = ({
  showEntity,
  loading,
  hasMore,
  invocations,
  nextPage
}) => {
  const firstLoading = loading && invocations.length === 0

  const invocationsList = invocations.map((invocation) => (
    <DestinationLogRow
      key={invocation.uuid}
      invocation={invocation}
      showEntity={showEntity}
    />
  ))

  const loadMoreButton = hasMore ? (
    <Table.Row>
      <Table.Cell justifyContent='center'>
        <Button
          appearance='minimal'
          onClick={() => nextPage(invocations)}
          isLoading={loading}
        >
          Load more destination logs
        </Button>
      </Table.Cell>
    </Table.Row>
  ) : (
    <></>
  )

  const emptyState = (
    <TableRowEmpty
      icon='stopwatch'
      message='This destination has yet to be invoked'
    />
  )

  return (
    <ContentTable
      loading={firstLoading}
      heading={
        <Table.Head>
          <Table.HeaderCell width={42} flex='none' />
          <Table.TextHeaderCell>Id</Table.TextHeaderCell>
          <Table.TextHeaderCell>Operation</Table.TextHeaderCell>
          {showEntity && <Table.TextHeaderCell>Entity ID</Table.TextHeaderCell>}
          <Table.TextHeaderCell>Completed Date</Table.TextHeaderCell>
          <Table.HeaderCell width={130} flex='none' />
        </Table.Head>
      }
    >
      <Table.Body>
        {invocations.length > 0 ? invocationsList : emptyState}
        {loadMoreButton}
      </Table.Body>
    </ContentTable>
  )
}
