import { ChangeError } from './request'

export interface SimpleType {
  type: 'string' | 'number' | 'integer' | 'boolean' | 'object' | 'array'
  format?: 'datetime' | 'string' | 'number' | 'integer' | 'boolean' | 'object'
}

export interface InputParameters {
  // When adding a new input (during creation or update) the id is blank
  // when updating an existing input, it exists
  id?: number
  name: string
  simple_type: SimpleType
  required: boolean
}

export interface InputError
  extends ChangeError<Omit<InputParameters, 'simple_type'>> {
  simple_type?: ChangeError<SimpleType>
}

export interface Input {
  id: number
  parent_id?: number
  name: string
  origin: string
  simple_type: SimpleType
  required: boolean
}

export function isTypeEqual(a: SimpleType, b: SimpleType): boolean {
  if (a.type !== b.type) {
    return false
  }

  // Handle the tricky case of `isTypeEqual({type: 'string'}, {type: 'string', format: null})`
  if (a.format == null && b.format == null) {
    return true
  }

  return a.format === b.format
}
