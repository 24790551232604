import {
  DuplicateIcon,
  FormField,
  IconButton,
  majorScale,
  Pane,
  TextInput,
  TextInputProps,
  toaster
} from 'evergreen-ui'
import * as React from 'react'
import slugify from '../util/slugify'

interface CopyableTextInputProps extends Omit<TextInputProps, 'value'> {
  label: string
  value: string
  description?: string
}

const CopyableTextInput: React.FunctionComponent<CopyableTextInputProps> = ({
  label,
  value,
  description,
  validationMessage,
  hint,
  ...props
}) => {
  const randId = Math.floor(Math.random() * 10000)
  const id = `copyable-text-input-${slugify(label)}-${randId}`

  return (
    <FormField
      label={label}
      description={description}
      labelFor={id}
      marginBottom={majorScale(2)}
      validationMessage={validationMessage}
      hint={hint}
    >
      <Pane display='flex'>
        <TextInput
          width='100%'
          id={id}
          readOnly
          value={value}
          // TODO: please fix following typescript error
          // @ts-expect-error
          onFocus={(e) => e.target.select()}
          {...props}
        />
        <IconButton
          marginLeft={majorScale(2)}
          icon={DuplicateIcon}
          onClick={(event: {
            stopPropagation: () => void
            preventDefault: () => void
          }) => {
            event.preventDefault()
            event.stopPropagation()
            navigator.clipboard.writeText(value).then(
              function () {
                toaster.success('Copied to clipboard')
              },
              function (reason) {
                console.log('Could not copy to clipboard ', reason)
              }
            )
          }}
        />
      </Pane>
    </FormField>
  )
}

export default CopyableTextInput
