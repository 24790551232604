import React from 'react'
import { FormField, majorScale } from 'evergreen-ui'
import { InputsTable } from '../../components/inputs-table'
import { TableCellTextInput } from '../../components/table-cell-text-input'

interface VariablesFieldProps {
  disabled: boolean
  variables: Array<[string, string]>
  setVariables: (variables: Array<[string, string]>) => void
  variablesError?: string
}

export const VariablesField: React.FC<VariablesFieldProps> = ({
  disabled,
  variables,
  setVariables,
  variablesError
}) => (
  <FormField
    label='Variables'
    description='Static configuration available in your functions'
    validationMessage={
      variablesError != null ? `Variables ${variablesError}` : null
    }
    marginBottom={majorScale(2)}
  >
    <InputsTable<{ name: string; value: string }>
      disabled={disabled}
      inputLabel='Variable'
      inputs={variables.map(([name, value]) => {
        return {
          value: { name, value },
          isInvalid: variablesError != null
        }
      })}
      newValue={() => {
        return { name: '', value: '' }
      }}
      setInputs={(inputs) =>
        setVariables(inputs.map(({ value: { name, value } }) => [name, value]))
      }
      headers={['Name', 'Value']}
      render={({ disabled, isInvalid, value }, onChange) => {
        return (
          <>
            <TableCellTextInput
              disabled={disabled}
              isInvalid={isInvalid}
              value={value.name}
              onChange={(newName) => onChange({ ...value, name: newName })}
            />
            <TableCellTextInput
              disabled={disabled}
              isInvalid={isInvalid}
              value={value.value}
              onChange={(newValue) => onChange({ ...value, value: newValue })}
            />
          </>
        )
      }}
    />
  </FormField>
)
