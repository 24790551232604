import { request, UnknownJSON, TokenCredentials } from './request'
import * as analytics from '../util/analytics'

export interface APIKey {
  id: string | number
  publishable_key: string
  inserted_at: Date
  updated_at: Date
  revoked: boolean
}

export interface NewAPIKey extends APIKey {
  secret_key: string
}

function parseAPIKey(res: UnknownJSON): APIKey | NewAPIKey {
  return {
    // TODO: please fix following typescript error
    // @ts-expect-error
    id: res.id,
    // TODO: please fix following typescript error
    // @ts-expect-error
    publishable_key: res.publishable_key,
    // TODO: please fix following typescript error
    // @ts-expect-error
    secret_key: res.secret_key,
    // TODO: please fix following typescript error
    // @ts-expect-error
    revoked: res.revoked,
    // TODO: please fix following typescript error
    // @ts-expect-error
    inserted_at: new Date(res.inserted_at),
    // TODO: please fix following typescript error
    // @ts-expect-error
    updated_at: new Date(res.updated_at)
  }
}

export async function listAPIKeys(
  credentials: TokenCredentials
): Promise<APIKey[]> {
  const { api_keys: apiKeys } = await request({
    path: '/keys',
    credentials
  })

  // TODO: please fix following typescript error
  // @ts-expect-error
  return apiKeys.map(parseAPIKey)
}

export async function deleteAPIKey(
  credentials: TokenCredentials,
  { id }: APIKey
): Promise<void> {
  await request({
    path: `/keys/${id}`,
    method: 'DELETE',
    credentials
  })
}

export async function createAPIKey(
  credentials: TokenCredentials
): Promise<NewAPIKey> {
  const { api_key: apiKey } = await request({
    path: '/keys',
    method: 'POST',
    credentials
  })

  if (apiKey != null) {
    analytics.track('create-api-key')
  }

  // TODO: please fix following typescript error
  // @ts-expect-error
  return parseAPIKey(apiKey)
}
