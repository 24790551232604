import { Socket } from 'phoenix'
import { TokenCredentials } from './request'

const SOCKET_ENDPOINT = '/platform_admin/socket'

// TODO: reuse sockets
export function createSocket(credentials: TokenCredentials): Socket {
  const socket = new Socket(SOCKET_ENDPOINT, {
    params: { token: credentials.token }
  })
  socket.connect()
  socket.onError((e) => console.debug(`Socket error: ${String(e)}`))
  socket.onClose(() => console.debug('Socket closed'))

  return socket
}
