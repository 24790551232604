import {
  Card,
  FunctionIcon,
  Heading,
  majorScale,
  Menu,
  Pane,
  Spinner,
  Text,
  toaster
} from 'evergreen-ui'
import React, { FC, memo, useCallback, useContext, useState } from 'react'
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from 'react-router-dom'
import {
  getConnectionReadableTitle,
  PlatformConnection
} from '../../api/connection'
import {
  getPlatformContextReadableTitle,
  isTestContext
} from '../../api/platform-user'
import { BasicSync } from '../../api/sync'
import errorMessage from '../../util/error-message'
import ConnectorMark from '../components/connector-mark'
import { ContentCard } from '../components/content-card'
import EmptyState from '../components/empty-state'
import { MoreMenu } from '../components/more-menu'
import { SpinnerSuspend } from '../components/spinner'
import { TestContextTooltip } from '../components/test-context-tooltip'
import { useErrorToaster, useTitle } from '../hooks/common'
import { useConnection } from '../hooks/connection'
import { useSyncsByConnection } from '../hooks/syncs.hook'
import { CredentialsContext } from '../login-wrapper'
import { WideArrow } from '../pipelines/wide-arrow'
import { XkitContext } from '../xkit-wrapper'
import { ConnectionStatusBadge } from './connection-status-badge'
import SyncDetail from './sync'
import { SyncStatusIcon } from './sync-status-icon'

interface SyncListingProps {
  url: string
  sync: BasicSync
  connection: PlatformConnection
}

const SyncListing: FC<SyncListingProps> = memo(({ url, sync, connection }) => {
  const [hover, setHover] = useState(false)
  return (
    <Card
      display='flex'
      alignItems='center'
      is={Link}
      to={`${url}/syncs/${sync.pipeline.uuid}`}
      textDecoration='none'
      marginTop={majorScale(2)}
      marginBottom={majorScale(4)}
      maxWidth='601px'
      position='relative'
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
    >
      <ContentCard
        paddingLeft={majorScale(2)}
        paddingRight={majorScale(2)}
        display='flex'
        alignItems='center'
        width='250px'
        height='56px'
        elevation={hover ? 2 : 1}
        transition='box-shadow 150ms'
      >
        <ConnectorMark
          markUrl={sync.pipeline.source.provider.mark_url}
          size={majorScale(3)}
        />
        <Heading
          size={600}
          paddingLeft={10}
          textOverflow='ellipsis'
          overflow='hidden'
          whiteSpace='nowrap'
        >
          {
            sync.pipeline.source.source_objects.find(
              (obj) => obj.is_selected_root
            )?.label_many
          }
        </Heading>
      </ContentCard>
      <Pane position='absolute' left='282px' top='6px'>
        <SyncStatusIcon connection={connection} sync={sync} />
      </Pane>
      <WideArrow height={'51px'} />
      <ContentCard
        paddingLeft={majorScale(2)}
        paddingRight={majorScale(2)}
        display='flex'
        alignItems='center'
        width='250px'
        height='56px'
        elevation={hover ? 2 : 1}
        transition='box-shadow 150ms'
      >
        <FunctionIcon size={24} color='black' />
        <Heading
          size={600}
          paddingLeft={10}
          textOverflow='ellipsis'
          overflow='hidden'
          whiteSpace='nowrap'
        >
          {sync.pipeline.destination.name}
        </Heading>
      </ContentCard>
    </Card>
  )
})

SyncListing.displayName = 'SyncListing'
interface ConnectionProps {
  id: string
}

const Connection: React.FC<ConnectionProps> = memo(({ id }) => {
  const { path, url } = useRouteMatch()
  const { callWithCredentials } = useContext(CredentialsContext)
  const {
    loading: connectionLoading,
    error: connectionError,
    connection
  } = useConnection(callWithCredentials, id)
  const {
    loading: syncsLoading,
    error: syncsError,
    syncs
  } = useSyncsByConnection(id)
  useErrorToaster(connectionError)
  useErrorToaster(syncsError)
  const history = useHistory()
  const { xkit } = useContext(XkitContext)
  const [removingTest, setRemovingTest] = useState(false)

  const removeConnection = useCallback(async () => {
    if (!xkit) {
      toaster.danger('Xkit is not initialized')
      return
    }

    if (connectionLoading || !connection) {
      toaster.danger(`Can't remove a connection that is not yet loaded`)
      return
    }

    if (!isTestContext(connection.context)) {
      toaster.danger('Only test connections can be removed')
      return
    }

    try {
      setRemovingTest(true)
      await xkit.removeConnection({ id: connection.id })
      toaster.success(
        `Removed test connection for ${connection.connector.name}`
      )
      history.push(`/providers/${connection.connector.slug}/connections`)
    } catch (e) {
      toaster.danger(`Error when removing test connection: ${errorMessage(e)}`)
    } finally {
      setRemovingTest(false)
    }
  }, [xkit, history, connection, connectionLoading])

  const connectionContextName =
    connection?.context.external_name ?? connection?.context.external_id ?? ''

  useTitle(
    connection != null
      ? `${connection.connector.name} connection for ${connectionContextName}`
      : undefined
  )

  return (
    <Pane marginBottom={majorScale(3)}>
      <Pane display='flex' marginBottom={majorScale(2)}>
        <Pane>
          <Pane display='flex'>
            <Heading size={600}>
              {getPlatformContextReadableTitle(connection?.context)}
              <TestContextTooltip context={connection?.context} />
            </Heading>
            <Pane
              display='flex'
              flexDirection='column'
              justifyContent='center'
              marginLeft={majorScale(3)}
            >
              <ConnectionStatusBadge
                connection={connection}
                syncs={syncs}
                loading={syncsLoading || connectionLoading}
              />
            </Pane>
          </Pane>
          <Pane display='flex'>
            <Text color='muted'>
              Connection {getConnectionReadableTitle(connection)}
            </Text>
            {connection != null && isTestContext(connection?.context) ? (
              removingTest ? (
                <Spinner marginLeft={majorScale(1)} size={16} />
              ) : (
                <MoreMenu marginLeft={majorScale(1)}>
                  <Menu.Group>
                    <Menu.Item
                      intent='danger'
                      icon='trash'
                      onSelect={removeConnection}
                    >
                      Remove connection
                    </Menu.Item>
                  </Menu.Group>
                </MoreMenu>
              )
            ) : null}
          </Pane>
        </Pane>
      </Pane>
      <Switch>
        <Route path={`${path}/`} exact>
          {/* If we have only one sync, we can skip this table */}
          {syncs.length === 1 ? (
            <Redirect to={`${url}/syncs/${syncs[0].pipeline.uuid}`} />
          ) : null}
          {connection?.connector.sync !== false ? (
            <>
              <Heading
                marginBottom={majorScale(2)}
                size={500}
                display='flex'
                alignItems='end'
              >
                Pipelines
                <Pane flexGrow={1} />
                <Pane marginRight={majorScale(2)}>
                  {/* TODO: add controls for managing all syncs together */}
                </Pane>
              </Heading>
              <SpinnerSuspend spinning={connectionLoading || syncsLoading}>
                {connection?.enabled && syncs.length > 0 ? (
                  syncs.map((sync) => (
                    <SyncListing
                      url={url}
                      sync={sync}
                      connection={connection}
                      key={sync.pipeline.uuid}
                    />
                  ))
                ) : (
                  <EmptyState
                    message={
                      connection && !connection.enabled
                        ? 'This connection is disabled'
                        : 'This connection has no sync pipelines'
                    }
                    padding={majorScale(2)}
                  />
                )}
              </SpinnerSuspend>
            </>
          ) : null}
        </Route>
        <Route
          path={`${path}/syncs/:pipelineUUID`}
          render={({ match }) => (
            <SyncDetail
              pipelineUUID={match.params.pipelineUUID}
              connection={connection}
            />
          )}
        />
      </Switch>
    </Pane>
  )
})
Connection.displayName = 'Connection'

export default Connection
