import * as React from 'react'
import { Pane, Button, majorScale } from 'evergreen-ui'

interface LoginFormProps {
  isLoading: boolean
  submitLabel: string
  // TODO: please fix following typescript error
  // @ts-expect-error
  onSubmit: (event: React.KeyboardEvent<FormControl>) => void
  // TODO: please fix following typescript error
  // @ts-expect-error
  footer?: React.Node
}

const LoginForm: React.FC<LoginFormProps> = ({
  isLoading,
  submitLabel,
  onSubmit,
  footer,
  children
}) => {
  // TODO: please fix following typescript error
  // @ts-expect-error
  const handleSubmit = (e): void => {
    e.preventDefault()
    onSubmit(e)
  }

  return (
    <Pane marginTop={majorScale(4)}>
      <form>
        <Pane
          display='flex'
          flexDirection='column'
          minHeight={majorScale(22)}
          justifyContent='center'
        >
          {/* TODO: please fix following typescript error */}
          {/* @ts-expect-error */}
          {children({
            inputHeight: 40,
            // TODO: please fix following typescript error
            // @ts-expect-error
            onKeyDown: (e) => (e.keyCode === 13 ? handleSubmit(e) : null)
          })}
        </Pane>
        <Button
          marginTop={majorScale(2)}
          onClick={handleSubmit}
          isLoading={isLoading}
          appearance='primary'
          height={40}
          width='100%'
          justifyContent='center'
        >
          {submitLabel}
        </Button>
        <Pane textAlign='center' paddingTop={majorScale(2)}>
          {footer}
        </Pane>
      </form>
    </Pane>
  )
}

export default LoginForm
