import React, { useEffect } from 'react'
import { majorScale } from 'evergreen-ui'
import { ObjectForm } from './object-form'
import { ConfigForm } from './config-form'
import { FilterForm, hasFilters } from './filter-form'
import { DestinationInputsForm } from './destination-inputs-form'
import { ContentCard } from '../components/content-card'
import { Accordion } from '../components/accordion'
import { Divider } from '../components/divider'
import { useRootObject } from '../hooks/root-object.hook'
import { Pipeline } from '../../api/pipeline'
import { useMapping } from '../hooks/mapping.hook'
import { useErrorToaster } from '../hooks/common'

interface MappingTabProps {
  loading: boolean
  pipeline: Pipeline
  pipelineFetch: () => void
}

// TODO: consider inlining the form components and have a single "Save" button.
export const MappingTab: React.FC<MappingTabProps> = ({
  loading,
  pipeline,
  pipelineFetch
}) => {
  const { errors, sourceRootObjects, selectedRootObject, updateFx } =
    useRootObject(pipeline)

  const {
    mapping,
    mappingInputs,
    selectors,
    loading: mappingLoading,
    fetchMapping,
    createOrUpdate,
    error
  } = useMapping(pipeline)
  useErrorToaster(error)

  useEffect(() => {
    if (selectedRootObject) {
      void fetchMapping()
    }
  }, [selectedRootObject, fetchMapping])

  const templateSlug = pipeline.source.provider.template.slug
  const showFilters = hasFilters(templateSlug)

  return (
    <ContentCard elevation={1} marginBottom={majorScale(2)}>
      <Accordion title='Configuration'>
        <ConfigForm pipeline={pipeline} loading={loading} />
      </Accordion>
      <Divider />
      <Accordion title='Data'>
        <ObjectForm
          selectedRootObject={selectedRootObject}
          sourceRootObjects={sourceRootObjects}
          updateRootObject={updateFx}
          rootObjectErrors={errors}
          onChange={pipelineFetch}
          loading={loading}
        />
      </Accordion>
      {showFilters && <Divider />}
      {showFilters && (
        <Accordion title='Filters' disabled={selectedRootObject == null}>
          <FilterForm
            sourceTemplateSlug={templateSlug}
            selectedRootObject={selectedRootObject}
            updateRootObject={updateFx}
            rootObjectErrors={errors}
            loading={loading}
          />
        </Accordion>
      )}
      <Divider />
      <Accordion title='Input mapping' disabled={selectedRootObject == null}>
        <DestinationInputsForm
          loading={mappingLoading}
          selectedRootObject={selectedRootObject}
          pipeline={pipeline}
          inputs={mappingInputs}
          mapping={mapping}
          selectors={selectors}
          updateMapping={createOrUpdate}
        />
      </Accordion>
    </ContentCard>
  )
}

MappingTab.displayName = 'MappingTab'
