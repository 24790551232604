import React, { FC, memo } from 'react'
import { Pane } from 'evergreen-ui'
import { Input } from '../../../api/destination-input'
import { Selector } from '../../../api/mapping'
import { EditableNode } from './editable-node'
import { SelectorNodeButton } from './selector-node-button'
import { BUTTON_SIZE } from './constants'

interface BareNodeProps {
  input: Input
  root: Selector
  selector: Selector
  onSelect: (selector: Selector) => void
  onNameChange: (selector: Selector, newLabel: string | undefined) => void
}

const BareNode: FC<BareNodeProps> = memo(
  ({ input, root, selector, onSelect, onNameChange }) => {
    if (selector.label_pattern != null) {
      return (
        <EditableNode
          input={input}
          root={root}
          selector={selector}
          onNameChange={onNameChange}
          onSelect={onSelect}
        />
      )
    }

    return (
      <SelectorNodeButton
        input={input}
        root={root}
        selector={selector}
        onSelect={onSelect}
      />
    )
  }
)

BareNode.displayName = 'BareNode'

export const NodeEntry: FC<BareNodeProps> = memo(
  ({ children, ...bareNodeProps }) => (
    <Pane display='flex' paddingLeft={children ? 0 : BUTTON_SIZE}>
      {children}
      <BareNode {...bareNodeProps} />
    </Pane>
  )
)

NodeEntry.displayName = 'NodeEntry'
