import React, {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useEffect,
  useState
} from 'react'
import { Button, FormField, majorScale, Popover, Select } from 'evergreen-ui'
import {
  TRANSFORMATION_NAME_HINTS,
  TRANSFORMATION_NAME_LABEL,
  TransformationName,
  TransformationParameters,
  TransformationFind,
  TransformationCollect
} from '../../../api/mapping'
import { PopoverContent } from '../../components/popover-content'
import { CollectArguments } from './collect-arguments'
import { FindArguments } from './find-arguments'

interface ContentProps {
  disabled?: boolean
  value?: TransformationParameters
  names: TransformationName[]
  onChange: (name: TransformationParameters) => void
  close: () => unknown
}

const RESET_ARGS: Partial<TransformationParameters> = {
  name: undefined,
  condition: undefined,
  extract_pointer: undefined,
  static_value: undefined
}

const DEFAULT_ARGS: Record<
  TransformationName,
  Partial<TransformationParameters>
> = {
  [TransformationName.find]: {
    name: TransformationName.find,
    condition: {
      value: '',
      operator: 'eq',
      pointer: ''
    },
    extract_pointer: ''
  },
  [TransformationName.collect]: {
    name: TransformationName.collect,
    extract_pointer: ''
  },
  [TransformationName.direct]: {
    name: TransformationName.direct
  },
  [TransformationName.date]: {
    name: TransformationName.date
  },
  [TransformationName.static]: {
    name: TransformationName.static,
    static_value: ''
  },
  [TransformationName.empty]: {
    name: TransformationName.static
  }
}

const Content: FC<ContentProps> = memo(({ value, names, onChange, close }) => {
  const [localTransformation, setLocalTransformation] = useState<
    Partial<TransformationParameters>
  >({})
  const [error, setError] = useState<string | undefined>(undefined)

  useEffect(() => {
    setLocalTransformation({ ...value })
  }, [value, setLocalTransformation])

  const handleSelect = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setError(undefined)
      if (e.target.value === '') {
        setLocalTransformation({
          ...localTransformation,
          ...RESET_ARGS
        })
      } else {
        const name = e.target.value as TransformationName
        setLocalTransformation({
          ...localTransformation,
          ...RESET_ARGS,
          ...DEFAULT_ARGS[name]
        })
      }
    },
    [setLocalTransformation, localTransformation]
  )

  const handleSubmit = useCallback(
    (e: ChangeEvent<HTMLFormElement>) => {
      e.preventDefault()
      e.stopPropagation()
      if (localTransformation.name == null) {
        setError('Select a valid transformation')
        return
      }
      setError(undefined)
      onChange(localTransformation as TransformationParameters)
      close()
    },
    [onChange, localTransformation, close]
  )

  return (
    <PopoverContent title='Transform data'>
      <form onSubmit={handleSubmit}>
        <FormField
          label='Transformation'
          hint={
            localTransformation.name != null
              ? TRANSFORMATION_NAME_HINTS[localTransformation.name]
              : ''
          }
          validationMessage={error}
          marginBottom={majorScale(2)}
        >
          <Select
            onChange={handleSelect}
            value={localTransformation.name ?? ''}
          >
            <option value=''>Select a transformation...</option>
            {names.map((name) => (
              <option key={name} value={name}>
                {TRANSFORMATION_NAME_LABEL[name]}
              </option>
            ))}
          </Select>
        </FormField>
        {localTransformation.name === TransformationName.collect && (
          <CollectArguments
            value={localTransformation as TransformationCollect}
            marginBottom={majorScale(2)}
            onChange={setLocalTransformation}
          />
        )}
        {localTransformation.name === TransformationName.find && (
          <FindArguments
            value={localTransformation as TransformationFind}
            marginBottom={majorScale(2)}
            onChange={setLocalTransformation}
          />
        )}
        <Button appearance='primary' type='submit'>
          Save
        </Button>
      </form>
    </PopoverContent>
  )
})
Content.displayName = 'Content'

export type TransformationFieldNameProps = Omit<ContentProps, 'close'>

export const TransformationNameField: FC<TransformationFieldNameProps> = memo(
  ({ value, disabled, ...props }) => {
    const isStatic = value?.name === TransformationName.static
    const isDisabled = isStatic || disabled

    // "force" rendering a disabled input, so we don't need to handle this case everywhere
    if (isDisabled) {
      return (
        <Select disabled marginLeft={majorScale(1)}>
          <option>Select...</option>
        </Select>
      )
    }

    return (
      <Popover
        content={({ close }) => (
          <Content value={value} close={close} {...props} />
        )}
      >
        <Select
          marginLeft={majorScale(1)}
          minWidth={107}
          /**
           * It should be onMouseDown because we need to cancel default handler,
           * so we can show custom picker in a popover.
           */
          onMouseDown={(e: ChangeEvent) => {
            e.preventDefault()
          }}
        >
          {value?.name ? (
            <option>{TRANSFORMATION_NAME_LABEL[value.name]}</option>
          ) : (
            <option>Select...</option>
          )}
        </Select>
      </Popover>
    )
  }
)
TransformationNameField.displayName = 'TransformationNameField'
