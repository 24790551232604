import React, { useEffect, useState, useContext } from 'react'
import {
  Button,
  majorScale,
  Paragraph,
  Code,
  InfoSignIcon,
  toaster,
  IconProps
} from 'evergreen-ui'
import { Connection } from '@xkit-co/xkit.js/lib/api/connection'
import { Connector } from '../api/connector'
import { track } from '../util/analytics'
import errorMessage from '../util/error-message'
import { XkitContext } from './xkit-wrapper'

interface TestConnectorButtonProps {
  connector?: Connector
  appearance?: 'default' | 'minimal' | 'primary'
  intent?: 'none' | 'success' | 'warning' | 'danger'
  icon?: IconProps['icon']
  height?: number
  marginLeft?: number
  marginRight?: number
  showLoggedInContext?: boolean
  allowRemoveConnection?: boolean
  onComplete?: () => void
  children: React.ReactNode
}

function trackPayload(props: TestConnectorButtonProps): unknown {
  return {
    connector_slug: props.connector?.slug,
    connector_name: props.connector?.name,
    connector_template_slug: props.connector?.template.slug,
    connector_template_name: props.connector?.template.name
  }
}

const TestConnectorButton: React.FC<TestConnectorButtonProps> = (props) => {
  const {
    connector,
    icon = 'clean',
    appearance = 'primary',
    intent = 'none',
    height,
    marginLeft,
    marginRight,
    showLoggedInContext,
    allowRemoveConnection,
    children = 'Test'
  } = props

  let loggedInContext = null
  const [loading, setLoading] = useState(true)
  const [connection, setConnection] = useState<Connection | null>(null)
  const { xkit, context } = useContext(XkitContext)

  useEffect(() => {
    if (!connector || !xkit) {
      return
    }

    setLoading(true)

    const loadConnections = async (): Promise<void> => {
      try {
        const connection = await xkit.getConnection({ slug: connector.slug })
        setConnection(connection)
      } catch (e) {
        setConnection(null)
      } finally {
        setLoading(false)
      }
    }

    void loadConnections()
  }, [connector, xkit])

  const handleComplete = (): void => {
    const { onComplete, connector } = props

    if (onComplete) {
      onComplete()
    } else {
      toaster.success(`Successfully tested ${connector?.name ?? ''} connector!`)
    }

    track('test-connector-success', trackPayload(props))
  }

  const addTestConnection = async (): Promise<void> => {
    try {
      track('test-connector-started', trackPayload(props))
      setLoading(true)
      // TODO: please fix following typescript error
      // @ts-expect-error
      const connection = await xkit.addConnection(connector.slug)
      setConnection(connection)
      handleComplete()
    } catch (e) {
      toaster.danger(`Error during test: ${errorMessage(e)}`)
      track('test-connector-failed', trackPayload(props))
    } finally {
      setLoading(false)
    }
  }

  const removeTestConnection = async (
    connection: Connection
  ): Promise<void> => {
    try {
      setLoading(true)
      await xkit?.removeConnection({ id: connection.id })
      setConnection(null)
      toaster.success(
        `Successfully removed ${connector?.name ?? ''} test connection!`
      )
    } catch (e) {
      toaster.danger(`Error when removing test connection: ${errorMessage(e)}`)
    } finally {
      setLoading(false)
    }
  }

  const connected = connection?.enabled

  if (!connector || !xkit) {
    return (
      <Button
        disabled
        height={height}
        iconBefore={icon}
        marginLeft={marginLeft}
        marginRight={marginRight}
      >
        {children}
      </Button>
    )
  }

  if (showLoggedInContext) {
    loggedInContext = (
      <Paragraph size={300} color='muted' marginTop={majorScale(1)}>
        <InfoSignIcon
          size={12}
          marginRight={majorScale(1)}
          marginTop={(18 - 12) / 2}
        />
        We created a test context for you with the ID{' '}
        <Code size={300} appearance='minimal'>
          {context?.external_id}
        </Code>
        . The connection will be made for that context.
      </Paragraph>
    )
  }

  // connector was tested and the connection is removable
  if (connected && allowRemoveConnection) {
    return (
      <>
        <Button
          appearance='minimal'
          intent='danger'
          height={height}
          iconBefore={loading ? null : 'trash'}
          isLoading={loading}
          onClick={async () => await removeTestConnection(connection)}
          marginLeft={marginLeft}
          marginRight={marginRight}
        >
          Remove {connector?.name} Test Connection
        </Button>
        {loggedInContext}
      </>
    )
  }

  // connector was tested but the connection is not removable
  if (connected && !allowRemoveConnection) {
    return (
      <>
        <Button
          disabled
          appearance='default'
          intent={intent}
          height={height}
          iconBefore={loading ? null : 'tick'}
          isLoading={loading}
          marginLeft={marginLeft}
          marginRight={marginRight}
        >
          Test {connector?.name} Connector
        </Button>
        {loggedInContext}
      </>
    )
  }

  return (
    <>
      <Button
        appearance={appearance}
        intent={intent}
        height={height}
        iconBefore={loading ? null : icon}
        isLoading={loading}
        onClick={async () => await addTestConnection()}
        marginLeft={marginLeft}
        marginRight={marginRight}
      >
        {children}
      </Button>
      {loggedInContext}
    </>
  )
}

export default TestConnectorButton
