import { Input } from '../../../api/destination-input'
import {
  TransformationParameters,
  TransformationName,
  TransformationType
} from '../../../api/mapping'

export interface MappingFormItem {
  input: Input
  childInputs: Input[]
  transformation?: TransformationParameters
  defaultTransformation?: TransformationParameters
}

export type MappingForm = Map<number, MappingFormItem>

function fetchMappingFormItem(
  form: MappingForm,
  input: Input
): MappingFormItem {
  const item = form.get(input.id)
  if (!item) throw new Error(`MappingFormItem for input ${input.id} not found`)

  return item
}

export function setInputTransformation(
  form: MappingForm,
  input: Input,
  newTransformation: TransformationParameters
): MappingForm {
  const newMappingForm = new Map(form)
  const existingInput = fetchMappingFormItem(newMappingForm, input)

  const emptyTransformation = !('name' in newTransformation)
  const deleted = !!newTransformation.deleted
  const hasDefaultTransformation = !!existingInput.defaultTransformation

  if (emptyTransformation && hasDefaultTransformation && !deleted) {
    newTransformation.type ||= TransformationType.native
    newTransformation.name = TransformationName.empty
    newTransformation.destination_input_id ||= input.id
  }

  newMappingForm.set(input.id, {
    ...existingInput,
    transformation: newTransformation
  })

  return newMappingForm
}

export function addInput(
  form: MappingForm,
  parentInput: Input,
  newInput: Input
): MappingForm {
  const newMappingForm = new Map(form)
  const existingParentInput = fetchMappingFormItem(newMappingForm, parentInput)

  newMappingForm.set(newInput.id, { input: newInput, childInputs: [] })

  newMappingForm.set(parentInput.id, {
    ...existingParentInput,
    childInputs: [...existingParentInput.childInputs, newInput]
  })

  return newMappingForm
}

export function removeInput(
  form: MappingForm,
  parentInput: Input,
  newInput: Input
): MappingForm {
  const newMappingForm = new Map(form)
  newMappingForm.delete(newInput.id)

  const existingParentInput = fetchMappingFormItem(newMappingForm, parentInput)
  const updatedChildInputs = existingParentInput.childInputs.filter(
    (c) => c.id !== newInput.id
  )
  newMappingForm.set(parentInput.id, {
    ...existingParentInput,
    childInputs: updatedChildInputs
  })

  return newMappingForm
}

export function transformationOverrideState({
  transformation,
  defaultTransformation
}: MappingFormItem): [boolean, TransformationParameters?] {
  const overridden = transformation !== undefined && !transformation.deleted
  const currentTransformation = overridden
    ? transformation
    : defaultTransformation

  return [overridden, currentTransformation]
}
