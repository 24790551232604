import React, { FC, memo } from 'react'
import { Card, CardProps, useTheme } from 'evergreen-ui'

export type ContentCardProps = CardProps

export const ContentCard: FC<ContentCardProps> = memo(
  ({ children, ...cardProps }) => {
    const theme = useTheme()

    return (
      <Card background={theme.palette.base} {...cardProps}>
        {children}
      </Card>
    )
  }
)
ContentCard.displayName = 'ContentCard'
