import { ControlIcon, majorScale, Menu, Pane, toaster } from 'evergreen-ui'
import React, { FC, memo, useCallback, useMemo } from 'react'
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom'
import { removeAppObject } from '../../api/crm'
import { HeadingTitle } from '../components/heading-title'
import { MoreMenu } from '../components/more-menu'
import { TabRoute, TabRoutes } from '../components/tab-routes'
import { XText } from '../components/x-text'
import { ConnectorHeadingSkeleton } from '../connector/connector-heading-skeleton'
import DeleteDialog from '../delete-dialog'
import { useAppObject } from '../hooks/app-object'
import { useErrorToaster, useTitle } from '../hooks/common'
import { useCredentials } from '../login-wrapper'
import ActionsAppObjectTab from './actions-app-object-tab'
import ObjectAppObjectTab from './object-app-object-tab'
import PropertiesAppObjectTab from './properties-app-object-tab'
import RelationsAppObjectTab from './relations-app-object-tab'

export const enum AppObjectTabType {
  Object = 'object',
  Properties = 'properties',
  Actions = 'actions',
  Relations = 'relations'
}

const AppObject: FC = memo(() => {
  const routeMatch = useRouteMatch<{ id: string }>()
  const { id } = routeMatch.params

  const { appObject, loading, error, refresh } = useAppObject(id)
  useErrorToaster(error)
  useTitle(`Edit App Object`)

  const credentials = useCredentials()
  const history = useHistory()
  const navigateToAppObjectsList = useCallback(() => {
    history.push(`/app-objects`)
  }, [history])

  const tabs = [
    useMemo<TabRoute>(
      () => ({
        slug: AppObjectTabType.Object,
        title: 'Object',
        loading: false,
        content: (
          <ObjectAppObjectTab
            object={appObject}
            loading={loading}
            credentials={credentials}
          />
        )
      }),
      [appObject, loading, credentials]
    ),
    useMemo<TabRoute>(
      () => ({
        slug: AppObjectTabType.Properties,
        title: 'Properties',
        loading: false,
        content: (
          <PropertiesAppObjectTab
            object={appObject}
            loading={loading}
            credentials={credentials}
            refresh={refresh}
          />
        )
      }),
      [appObject, loading, credentials, refresh]
    ),
    useMemo<TabRoute>(
      () => ({
        slug: AppObjectTabType.Actions,
        title: 'Actions',
        loading: false,
        content: (
          <ActionsAppObjectTab
            object={appObject}
            loading={loading}
            credentials={credentials}
            refresh={refresh}
          />
        )
      }),
      [appObject, loading, credentials, refresh]
    ),
    useMemo<TabRoute>(
      () => ({
        slug: AppObjectTabType.Relations,
        title: 'Relations',
        loading: false,
        content: (
          <RelationsAppObjectTab
            object={appObject}
            loading={loading}
            credentials={credentials}
            refresh={refresh}
          />
        )
      }),
      [appObject, loading, credentials, refresh]
    )
  ]

  // Failed to load
  if (!loading && !appObject) {
    return <Redirect to='/app-objects' />
  }

  return (
    <>
      <Pane marginRight={4} marginLeft={4} display='flex' alignItems='center'>
        <HeadingTitle display='flex' alignItems='center'>
          {loading ? (
            <ConnectorHeadingSkeleton />
          ) : (
            <>
              {appObject ? (
                <>
                  <Pane display='flex' alignItems='center'>
                    <ControlIcon size={24} marginRight={majorScale(2)} />
                    <XText color='inherit'>{appObject.label_one}</XText>
                  </Pane>
                  <MoreMenu marginLeft={majorScale(1)}>
                    <DeleteDialog
                      noun={`${appObject.label_one} App Object`}
                      onDelete={async () => {
                        if (credentials == null) {
                          toaster.danger('Invalid credentials')
                          return
                        }
                        try {
                          const response = await removeAppObject(
                            credentials,
                            appObject
                          )
                          if (response.errors) {
                            console.error(
                              'Could not remove App Object',
                              response.errors
                            )
                            toaster.danger('Could not remove App Object')
                          } else {
                            toaster.success('Successfully removed App Object')
                            navigateToAppObjectsList()
                          }
                        } catch (error) {
                          console.error(error)
                          toaster.danger('Could not remove App Object')
                        }
                      }}
                      dialogText={`
                        This will permanently delete the ${appObject.label_one} App Object.
                        All related properties and actions will be removed from Xkit entirely and irrevocably.
                        Use with caution!
                      `}
                    >
                      {(showDeleteDialog) => {
                        return (
                          <Menu.Item
                            intent='danger'
                            icon='trash'
                            onSelect={showDeleteDialog}
                          >
                            Delete
                          </Menu.Item>
                        )
                      }}
                    </DeleteDialog>
                  </MoreMenu>
                </>
              ) : null}
            </>
          )}
        </HeadingTitle>
      </Pane>
      <TabRoutes tabRoutes={tabs} />
    </>
  )
})

AppObject.displayName = 'AppObject'

export default AppObject
