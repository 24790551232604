import { TokenCredentials, ChangeError, parseDate, request } from './request'
import { hasOwnProperty } from '../util/has-own-property'
import { Input, InputParameters, InputError } from './destination-input'
import { Connector, ConnectorSlim } from './connector'
import { createSocket } from './socket'
import mitt, { Emitter } from 'mitt'

interface FunctionParameters {
  body: string
}

export type FunctionDependencies = Record<string, string>

export enum DestinationRole {
  platform = 'platform',
  provider = 'provider'
}

export enum DestinationType {
  function = 'function',
  native = 'native'
}

export interface DestinationParameters {
  role?: DestinationRole
  type?: DestinationType
  name: string
  inputs?: InputParameters[]
  variables?: Record<string, string>
  upsert_function?: FunctionParameters
  delete_function?: FunctionParameters
  provider_slug?: string
  config?: Record<string, string>
}

interface FunctionWire extends FunctionParameters {
  id: number
  uuid: string
  handler: string
  handler_filename: string
  runtime: string
  dependencies: FunctionDependencies
}

export interface DestinationWire {
  id: number
  uuid: string
  name: string
  role: DestinationRole
  type: DestinationType
  config?: Record<string, string>
  variables: Record<string, string>
  inputs?: Input[]
  upsert_function: FunctionWire
  delete_function: FunctionWire
  provider?: Connector
  updated_at: string
}

export interface Destination {
  id: number
  uuid: string
  name: string
  role: DestinationRole
  type: DestinationType
  config: Record<string, string>
  variables: Record<string, string>
  inputs: Input[]
  upsert_function: FunctionWire
  delete_function: FunctionWire
  provider?: Connector
  updated_at: Date
}

export interface BasicDestinationWire {
  uuid: string
  name: string
  role: DestinationRole
  type: DestinationType
  provider?: ConnectorSlim
  updated_at: string
}

export interface BasicDestination {
  uuid: string
  name: string
  role: DestinationRole
  type: DestinationType
  provider?: ConnectorSlim
  updated_at: Date
}

// Needs to be a type to be compatible with UnknownJSON
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ErrorResult = {
  errors: ChangeError<
    Omit<
      DestinationParameters,
      'inputs' | 'upsert_function' | 'delete_function'
    >
  > & {
    inputs?: InputError[]
    upsert_function?: ChangeError<FunctionParameters>
    delete_function?: ChangeError<FunctionParameters>
  }
}

type DestinationWireResult = { destination: DestinationWire } | ErrorResult
export type DestinationResult = { destination: Destination } | ErrorResult

export function parseDestinationWire(
  destinationWire: DestinationWire
): Destination {
  return {
    ...destinationWire,
    config: destinationWire.config ?? {},
    inputs: destinationWire.inputs ?? [],
    updated_at: parseDate(destinationWire.updated_at)
  }
}

export function parseBasicDestinationWire(
  destinationWire: BasicDestinationWire
): BasicDestination {
  return {
    ...destinationWire,
    updated_at: parseDate(destinationWire.updated_at)
  }
}

export async function listDestinations(
  credentials: TokenCredentials
): Promise<Destination[]> {
  const { destinations } = await request<{ destinations: DestinationWire[] }>({
    path: '/destinations',
    credentials
  })

  return destinations.map(parseDestinationWire)
}

export async function getDestination(
  credentials: TokenCredentials,
  uuid: string
): Promise<Destination> {
  const { destination } = await request<{ destination: DestinationWire }>({
    path: `/destinations/${uuid}`,
    credentials
  })

  return parseDestinationWire(destination)
}

export async function createDestination(
  credentials: TokenCredentials,
  params: DestinationParameters
): Promise<DestinationResult> {
  const result = await request<DestinationWireResult>({
    path: '/destinations',
    method: 'POST',
    credentials,
    body: {
      destination: params
    }
  })
  if (hasOwnProperty(result, 'destination')) {
    return { destination: parseDestinationWire(result.destination) }
  } else {
    return result
  }
}

export async function updateDestination(
  credentials: TokenCredentials,
  params: DestinationParameters & { uuid: string }
): Promise<DestinationResult> {
  const result = await request<DestinationWireResult>({
    path: `/destinations/${params.uuid}`,
    method: 'PUT',
    credentials,
    body: {
      destination: params
    }
  })

  if (hasOwnProperty(result, 'destination')) {
    return { destination: parseDestinationWire(result.destination) }
  } else {
    return result
  }
}

export async function getDefaultDependencies(
  credentials: TokenCredentials
): Promise<FunctionDependencies> {
  const { dependencies } = await request<{
    dependencies: FunctionDependencies
  }>({
    path: '/destinations/functions/default_dependencies',
    credentials
  })

  return dependencies
}

type DestinationSubscriber = (destination: Destination) => void
type DestinationEmitter = Emitter<{
  update: Destination
  error: string
}>
type DestinationSubscription = [(fn: DestinationSubscriber) => void, () => void]

export function subscribeToDestinations(
  credentials: TokenCredentials
): DestinationSubscription {
  const socket = createSocket(credentials)
  const channel = socket.channel('destinations')
  const emitter: DestinationEmitter = mitt()
  channel.on('update', ({ destination }: { destination: DestinationWire }) => {
    emitter.emit('update', parseDestinationWire(destination))
  })

  channel
    .join()
    .receive('ok', () => {
      // nothing to do
    })
    .receive('error', ({ reason }) => {
      // How to handle?
      console.error(`Failed to join channel: ${String(reason)}`)
      emitter.emit('error', reason)
    })
    .receive('timeout', () => {
      console.error('Timeout while joining channel')
      emitter.emit('error', 'Timeout while joining channel')
    })

  const subscribe = (fn: DestinationSubscriber): void => {
    emitter.on('update', fn)
  }

  const unsubscribe = (): void => {
    emitter.off('update')
    channel.leave()
  }

  return [subscribe, unsubscribe]
}
