import { parseDate, request, TokenCredentials } from './request'
import { PlatformConnection } from './connection'
import {
  BasicPipeline,
  BasicPipelineWire,
  parseBasicPipelineWire
} from './pipeline'
import { Filter, FilterErrors } from './filter'

type SyncRunStatus = 'running' | 'paused' | 'broken' | 'not_started'
type SyncStateParams = 'new' | 'running' | 'paused'

interface SyncStateWire {
  key: string
  updated_at: string
  last_success_at: string
}

interface SyncState {
  key: string
  updated_at: Date
  last_success_at: Date
}

interface SyncWire {
  state: SyncRunStatus
  enabled_at?: string
  paused_at?: string
  resumed_at?: string
  reset_at?: string
  filters?: Filter[]
  sync_states: SyncStateWire[]
}

export interface ApiObject {
  slug: string
  label: string
  is_root: boolean
}

interface BasicSyncWire {
  pipeline: BasicPipelineWire
  connection_id: string
  state: SyncRunStatus
  enabled_at?: string
  paused_at?: string
  resumed_at?: string
  reset_at?: string
}

export interface BasicSync {
  pipeline: BasicPipeline
  connectionId: string
  state: SyncRunStatus
  enabledAt?: Date
  pausedAt?: Date
  resumedAt?: Date
  resetAt?: Date
}

export interface Sync {
  state: SyncRunStatus
  enabledAt?: Date
  pausedAt?: Date
  resumedAt?: Date
  resetAt?: Date
  filters: Filter[]
  sync_states: SyncState[]
}

function parseUTCDate(str?: string): Date | undefined {
  if (str == null) {
    return undefined
  }

  if (str[str.length - 1] === 'Z') {
    return new Date(str)
  }

  return new Date(`${str}Z`)
}

function parseSyncRunStatus(state: string): SyncRunStatus {
  if (
    state === 'running' ||
    state === 'paused' ||
    state === 'broken' ||
    state === 'not_started'
  ) {
    return state
  }

  throw new Error(`Invalid sync state: ${state}`)
}

function parseSyncStates(syncStates: SyncStateWire[]): SyncState[] {
  return syncStates.map((wire) => {
    return {
      ...wire,
      updated_at: parseDate(wire.updated_at),
      last_success_at: parseDate(wire.last_success_at)
    }
  })
}

function parseSync(wire: SyncWire): Sync {
  return {
    state: parseSyncRunStatus(wire.state),
    enabledAt: parseUTCDate(wire.enabled_at),
    pausedAt: parseUTCDate(wire.paused_at),
    resumedAt: parseUTCDate(wire.resumed_at),
    resetAt: parseUTCDate(wire.reset_at),
    filters: wire.filters ?? [],
    sync_states: parseSyncStates(wire.sync_states)
  }
}

function parseBasicSync(wire: BasicSyncWire): BasicSync {
  return {
    pipeline: parseBasicPipelineWire(wire.pipeline),
    connectionId: wire.connection_id,
    state: parseSyncRunStatus(wire.state),
    enabledAt: parseUTCDate(wire.enabled_at),
    pausedAt: parseUTCDate(wire.paused_at),
    resumedAt: parseUTCDate(wire.resumed_at),
    resetAt: parseUTCDate(wire.reset_at)
  }
}

export async function listSyncsByConnection(
  credentials: TokenCredentials,
  connectionId: string
): Promise<BasicSync[]> {
  const { syncs } = await request<{ syncs: BasicSyncWire[] }>({
    path: `/connections/${connectionId}/syncs`,
    credentials
  })

  return syncs.map(parseBasicSync)
}

export async function listSyncsByConnector(
  credentials: TokenCredentials,
  connectorSlug: string
): Promise<BasicSync[]> {
  const { syncs } = await request<{ syncs: BasicSyncWire[] }>({
    path: `/connectors/${connectorSlug}/syncs`,
    credentials
  })

  return syncs.map(parseBasicSync)
}

export async function listSyncsByPipeline(
  credentials: TokenCredentials,
  pipelineUuid: string
): Promise<BasicSync[]> {
  const { syncs } = await request<{ syncs: BasicSyncWire[] }>({
    path: `/pipelines/${pipelineUuid}/syncs`,
    credentials
  })

  return syncs.map(parseBasicSync)
}

export async function getSync(
  credentials: TokenCredentials,
  pipelineUuid: string,
  connectionId: string
): Promise<Sync> {
  const { sync } = await request({
    path: `/pipelines/${pipelineUuid}/connections/${connectionId}/sync`,
    credentials
  })

  return parseSync(sync as SyncWire)
}

export async function updateSyncState(
  credentials: TokenCredentials,
  pipelineUuid: string,
  connectionId: PlatformConnection['id'],
  newState: SyncStateParams
): Promise<Sync> {
  const { sync } = await request<{ sync: SyncWire }>({
    path: `/pipelines/${pipelineUuid}/connections/${connectionId}/sync`,
    method: 'PUT',
    body: {
      state: newState
    },
    credentials
  })

  return parseSync(sync)
}

type FiltersResponse = { filters: Filter[] } | { errors: FilterErrors }

export async function updateSyncFilters(
  credentials: TokenCredentials,
  pipelineUuid: string,
  connectionId: PlatformConnection['id'],
  newFilters: Filter[]
): Promise<FiltersResponse> {
  const res = await request<FiltersResponse>({
    path: `/pipelines/${pipelineUuid}/connections/${connectionId}/sync/filters`,
    method: 'PUT',
    body: {
      filters: newFilters
    },
    credentials
  })

  return res
}
