import React, { FC, memo, useCallback } from 'react'
import { Select, TagInput } from 'evergreen-ui'
import { FilterFieldProps, getFilter, updateFilter } from './filter-field'
import { FilterOverrideField } from './filter-override'
import { HistoryCountFilterOverrideField } from './history-count-filter-override-field'

const INBOUND_PROPERTY = 'is_inbound'
const INBOX_NAME_PROPERTY = 'inbox_name'

function getInboxFilterValue(value?: string | string[]): string[] {
  if (Array.isArray(value)) {
    return value
  }

  // Backwards compatible for inbox filters that were set previously as strings
  if (typeof value === 'string') {
    return [value]
  }

  return []
}

// UI for the supported Front Message filters
export const FrontMessagesFilterField: FC<FilterFieldProps> = memo(
  ({ sourceFilters, syncFilters, filterErrors, onChange }) => {
    const updateProperty = useCallback(
      (propertyName, operator, value) => {
        const updatedFilters = updateFilter(
          syncFilters ?? sourceFilters,
          propertyName,
          operator,
          value
        )
        onChange(updatedFilters)
      },
      [sourceFilters, syncFilters, onChange]
    )

    const inboundFilter = getFilter(
      sourceFilters,
      syncFilters,
      filterErrors,
      INBOUND_PROPERTY
    )
    const inboxFilter = getFilter(
      sourceFilters,
      syncFilters,
      filterErrors,
      INBOX_NAME_PROPERTY
    )

    return (
      <>
        <FilterOverrideField
          propertyName={INBOUND_PROPERTY}
          sourceFilters={sourceFilters}
          syncFilters={syncFilters}
          onChange={onChange}
          label='Inbound or Outbound'
          description='Which type of Front messages to sync'
          hint='Inbound messages are from customers, outbound messages are from team members.'
          validationMessage={inboundFilter.validationMessage}
        >
          <Select
            name={INBOUND_PROPERTY}
            isInvalid={inboundFilter.isInvalid}
            value={inboundFilter.isEmpty ? '' : inboundFilter.value}
            disabled={inboundFilter.disabled}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              updateProperty(INBOUND_PROPERTY, 'eq', event.target.value)
            }
          >
            <option value=''>Both Inbound and Outbound</option>
            <option value='true'>Inbound Only</option>
            <option value='false'>Outbound Only</option>
          </Select>
        </FilterOverrideField>
        <FilterOverrideField
          propertyName={INBOX_NAME_PROPERTY}
          sourceFilters={sourceFilters}
          syncFilters={syncFilters}
          onChange={onChange}
          defaultOperator='in'
          defaultValue={[]}
          label='Inboxes'
          description='Which Front inboxes to sync'
          hint='The names must match exactly. Leave blank to sync messages from all Front inboxes.'
          validationMessage={inboxFilter.validationMessage}
        >
          <TagInput
            width='100%'
            name={INBOX_NAME_PROPERTY}
            values={
              inboxFilter.isEmpty ? [] : getInboxFilterValue(inboxFilter.value)
            }
            onChange={(inboxNames: string[]) => {
              updateProperty(INBOX_NAME_PROPERTY, 'in', inboxNames)
            }}
            disabled={inboxFilter.disabled}
          />
        </FilterOverrideField>
        <HistoryCountFilterOverrideField
          syncFilters={syncFilters}
          sourceFilters={sourceFilters}
          filterErrors={filterErrors}
          onChange={onChange}
          label='Historical messages limit'
          description='The number of historical messages to sync'
        />
      </>
    )
  }
)

FrontMessagesFilterField.displayName = 'FrontMessagesFilterField'
