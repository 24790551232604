import * as React from 'react'
import { Text, Pane } from 'evergreen-ui'
import CodeBlock from './code-block'
import GenerateAPIKey from './generate-api-key'
import { NewAPIKey } from '../api/key'

interface CurlBlockProps {
  docsLink?: string
  maxWidth?: number
  path: string
}

const CurlBlock: React.FC<CurlBlockProps> = ({ docsLink, path, maxWidth }) => {
  const [key, setKey] = React.useState<NewAPIKey | null>(null)
  const user = key?.publishable_key ?? 'AAAAAAAAAAAAAAAA'
  const pass = key?.secret_key ?? 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'

  // TODO: more explicit API host?
  const url = `${window.location.protocol}//${window.location.host}${path}`

  const code = `curl --user ${user}:${pass} \\
  ${url}`

  return (
    <>
      <Pane position='relative' maxWidth={maxWidth}>
        <CodeBlock code={code} language='bash' />
        <Pane
          position='absolute'
          top={0}
          left={0}
          height='100%'
          width='100%'
          style={{ backdropFilter: 'blur(3px)' }}
          display={key ? 'none' : 'flex'}
          alignItems='center'
          justifyContent='center'
        >
          <GenerateAPIKey
            appearance='primary'
            onCreate={(key) => setKey(key)}
            hideDialog
          />
        </Pane>
      </Pane>
      {docsLink ? (
        <Text size={300}>
          <a href={docsLink} target='_blank' rel='noreferrer'>
            View the documentation
          </a>{' '}
          for this API call
        </Text>
      ) : (
        ''
      )}
    </>
  )
}

export default CurlBlock
