import { Button, Pane, toaster } from 'evergreen-ui'
import React, { FC, memo, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  AppObject,
  AppObjectAction,
  CRMAPIErrors,
  updateAppObject
} from '../../api/crm'
import { TokenCredentials } from '../../api/request'
import { WindowWithStructuredClone } from '../../util/helper-types'
import { AppObjectTabType } from './app-object'
import {
  DEFAULT_CONFIRMATION_MESSAGE,
  DEFAULT_CONFIRMATION_NO_LABEL,
  DEFAULT_CONFIRMATION_YES_LABEL,
  DEFAULT_MODAL_HEIGHT,
  DEFAULT_MODAL_WIDTH
} from './constants'
import Fields, { Field } from './fields'

declare const window: WindowWithStructuredClone

interface EditAppObjectActionProps {
  object: AppObject
  action?: AppObjectAction
  credentials: TokenCredentials | null
  refresh: () => Promise<void>
}

const EditAppObjectAction: FC<EditAppObjectActionProps> = memo(
  ({ object, action, credentials, refresh }) => {
    const [slug, setSlug] = useState<string>(action ? action.slug : '')
    const [label, setLabel] = useState<string>(action ? action.label : '')
    const [description, setDescription] = useState<string>(
      action ? action.description : ''
    )
    const [type, setType] = useState<string>(action ? action.type : 'link')
    const [scope, setScope] = useState<string>(action ? action.scope : 'object')
    const [confirmationRequired, setConfirmationRequired] = useState<boolean>(
      action?.confirmation_required ?? false
    )
    const [confirmationMessage, setConfirmationMessage] = useState<string>(
      action?.confirmation_message ?? DEFAULT_CONFIRMATION_MESSAGE
    )
    const [confirmationYesLabel, setConfirmationYesLabel] = useState<string>(
      action?.confirmation_yes_label ?? DEFAULT_CONFIRMATION_YES_LABEL
    )
    const [confirmationNoLabel, setConfirmationNoLabel] = useState<string>(
      action?.confirmation_no_label ?? DEFAULT_CONFIRMATION_NO_LABEL
    )
    const [modalUseCustomSize, setModalUseCustomSize] = useState<boolean>(
      action?.modal_use_custom_size ?? false
    )
    const [modalHeight, setModalHeight] = useState<number>(
      action?.modal_height ?? DEFAULT_MODAL_HEIGHT
    )
    const [modalWidth, setModalWidth] = useState<number>(
      action?.modal_width ?? DEFAULT_MODAL_WIDTH
    )
    const [loading, setLoading] = useState<boolean>(false)
    const [errors, setErrors] = useState<CRMAPIErrors>({})

    const history = useHistory()
    const navigateToActions = useCallback(() => {
      history.push(`/app-objects/${object.id}/${AppObjectTabType.Actions}`)
    }, [history, object.id])

    const basicFields: Field[] = [
      {
        value: slug,
        label: 'Slug',
        description: 'Unique identifier for this action',
        placeholder: 'Slug',
        setField: setSlug,
        errorKey: 'slug'
      },
      {
        value: label,
        label: 'Name',
        description: 'Human friendly name that appears to users',
        placeholder: 'Name',
        setField: setLabel,
        errorKey: 'label'
      },
      {
        textarea: true,
        value: description,
        label: 'Description',
        description: 'A description of this action for your users',
        placeholder: 'Description',
        setField: setDescription,
        errorKey: 'description'
      },
      {
        value: type,
        label: 'Type',
        description: 'The type of action that is to be taken by the user',
        placeholder: '',
        setField: (value) => {
          setConfirmationRequired(false)
          setConfirmationMessage(DEFAULT_CONFIRMATION_MESSAGE)
          setConfirmationYesLabel(DEFAULT_CONFIRMATION_YES_LABEL)
          setConfirmationNoLabel(DEFAULT_CONFIRMATION_NO_LABEL)
          setModalUseCustomSize(false)
          setModalHeight(DEFAULT_MODAL_HEIGHT)
          setModalWidth(DEFAULT_MODAL_WIDTH)
          setType(value)
        },
        errorKey: 'type',
        select: (
          <>
            <option value='link'>Open a link</option>
            <option value='command'>Issue a command</option>
            <option value='modal'>Show a modal</option>
          </>
        )
      },
      {
        value: scope,
        label: 'Scope',
        description: 'The context to which the action is applied to',
        placeholder: '',
        setField: setScope,
        errorKey: 'scope',
        select: (
          <>
            <option value='object'>Individual record</option>
            <option value='collection'>Entire collection</option>
          </>
        )
      }
    ]

    let otherFields: Field[] = []

    if (type === 'command') {
      otherFields = otherFields.concat([
        {
          value: '',
          checkbox: true,
          checked: confirmationRequired,
          label: 'Show Confirmation Dialog',
          description:
            'Show a confirmation dialog when the user wants to perform this action',
          setField: (value) => {
            setConfirmationMessage(DEFAULT_CONFIRMATION_MESSAGE)
            setConfirmationYesLabel(DEFAULT_CONFIRMATION_YES_LABEL)
            setConfirmationNoLabel(DEFAULT_CONFIRMATION_NO_LABEL)
            setConfirmationRequired(value)
          },
          errorKey: 'confirmation_required'
        }
      ])
    }

    if (confirmationRequired) {
      otherFields = otherFields.concat([
        {
          textarea: true,
          value: confirmationMessage,
          label: 'Confirmation Message',
          description: 'The text to display in the confirmation dialog',
          placeholder: 'Confirmation Message',
          setField: setConfirmationMessage,
          errorKey: 'confirmation_message'
        },
        {
          value: confirmationYesLabel,
          label: 'Confirm Button Label',
          description:
            'The label for the confirm button in the confirmation dialog',
          placeholder: 'Confirm Button Label',
          setField: setConfirmationYesLabel,
          errorKey: 'confirmation_yes_label'
        },
        {
          value: confirmationNoLabel,
          label: 'Cancel Button Label',
          description:
            'The label for the cancel button in the confirmation dialog',
          placeholder: 'Cancel Button Label',
          setField: setConfirmationNoLabel,
          errorKey: 'confirmation_no_label'
        }
      ])
    }

    if (type === 'modal') {
      otherFields = otherFields.concat([
        {
          value: '',
          checkbox: true,
          checked: modalUseCustomSize,
          label: 'Use Custom Modal Dimensions',
          description:
            'Provide a width and height for the modal instead of having it appear fullscreen',
          setField: (value) => {
            setModalHeight(DEFAULT_MODAL_HEIGHT)
            setModalWidth(DEFAULT_MODAL_WIDTH)
            setModalUseCustomSize(value)
          },
          errorKey: 'modal_use_custom_size'
        }
      ])
    }

    if (modalUseCustomSize) {
      otherFields = otherFields.concat([
        {
          number: true,
          value: `${modalWidth}`,
          label: 'Preferred Modal Width',
          description:
            'Width of the modal (in pixels) displayed inside the CRM.',
          placeholder: `${DEFAULT_MODAL_WIDTH}`,
          setField: setModalWidth,
          errorKey: 'modal_width'
        },
        {
          number: true,
          value: `${modalHeight}`,
          label: 'Preferred Modal Height',
          description:
            'Height of the modal (in pixels) displayed inside the CRM.',
          placeholder: `${DEFAULT_MODAL_HEIGHT}`,
          setField: setModalHeight,
          errorKey: 'modal_height'
        }
      ])
    }

    const fields = basicFields.concat(otherFields)

    const deleteAction: () => Promise<void> = async () => {
      if (!action) {
        return
      }
      if (credentials == null) {
        toaster.danger('Invalid credentials')
        return
      }
      const index = object.actions.findIndex(
        (individualAction) => individualAction.id === action.id
      )
      if (!(index > -1)) {
        return
      }
      object.actions.splice(index, 1)

      if (action.id === object.primary_action_id) {
        object.primary_action_id = null
      }
      if (action.id === object.main_link_action_id) {
        object.main_link_action_id = null
      }
      if (action.id === object.empty_state_action_id) {
        object.empty_state_action_id = null
      }

      const response = await updateAppObject(credentials, object)
      if (response.errors) {
        console.error('Could not remove action', response.errors)
        toaster.danger('Could not remove action')
      } else {
        toaster.success('Action removed')
        await refresh()
      }
    }

    let saveAction: () => Promise<void> = async () => {
      if (credentials == null) {
        toaster.danger('Invalid credentials')
        return
      }
      setLoading(true)
      const response = await updateAppObject(credentials, {
        ...object,
        actions: [
          ...object.actions,
          {
            slug,
            label,
            description,
            type,
            scope,
            confirmation_required: confirmationRequired,
            confirmation_message: confirmationMessage,
            confirmation_yes_label: confirmationYesLabel,
            confirmation_no_label: confirmationNoLabel,
            modal_use_custom_size: modalUseCustomSize,
            modal_height: modalHeight,
            modal_width: modalWidth
          }
        ]
      })
      if (
        response.errors?.actions &&
        response.errors.actions.length > object.actions.length
      ) {
        setErrors(response.errors.actions[object.actions.length])
        console.error('Could not create action', response.errors)
        toaster.danger('Could not create action')
        setLoading(false)
      } else {
        toaster.success('Action created')
        setLoading(false)
        navigateToActions()
        await refresh()
      }
    }
    if (action) {
      saveAction = async () => {
        if (credentials == null) {
          toaster.danger('Invalid credentials')
          return
        }
        const index = object.actions.findIndex(
          (individualAction) => individualAction.id === action.id
        )
        if (!(index > -1)) {
          return
        }
        const id = object.actions[index].id
        const clonedObject = window.structuredClone<AppObject>(object)
        clonedObject.actions[index] = {
          id,
          slug,
          label,
          description,
          type,
          scope,
          confirmation_required: confirmationRequired,
          confirmation_message: confirmationMessage,
          confirmation_yes_label: confirmationYesLabel,
          confirmation_no_label: confirmationNoLabel,
          modal_use_custom_size: modalUseCustomSize,
          modal_height: modalHeight,
          modal_width: modalWidth
        }
        setLoading(true)

        if (
          action.id === clonedObject.primary_action_id &&
          scope !== 'collection'
        ) {
          clonedObject.primary_action_id = null
        }
        if (
          action.id === clonedObject.main_link_action_id &&
          (scope !== 'object' || type !== 'link')
        ) {
          clonedObject.main_link_action_id = null
        }
        if (
          action.id === clonedObject.empty_state_action_id &&
          scope !== 'collection'
        ) {
          clonedObject.empty_state_action_id = null
        }

        const response = await updateAppObject(credentials, clonedObject)
        if (response.errors?.actions) {
          setErrors(response.errors.actions[index])
          console.error('Could not update action', response.errors)
          toaster.danger('Could not update action')
          setLoading(false)
        } else {
          toaster.success('Action updated')
          setLoading(false)
          navigateToActions()
          await refresh()
        }
      }
    }

    return (
      <>
        {action ? (
          <Pane display='flex' justifyContent='end'>
            <Button
              appearance='minimal'
              intent='danger'
              iconBefore='trash'
              isLoading={loading}
              onClick={async () => {
                setLoading(true)
                try {
                  await deleteAction()
                } catch (error) {
                  console.error(error)
                  toaster.danger('Could not remove action')
                }
                setLoading(false)
              }}
            >
              Remove action
            </Button>
          </Pane>
        ) : null}
        <Fields fields={fields} errors={errors} setErrors={setErrors} />
        <Button
          appearance='primary'
          isLoading={loading}
          onClick={async () => {
            try {
              await saveAction()
            } catch (error) {
              console.error(error)
              toaster.danger('Could not save action')
            }
          }}
        >
          {object ? 'Save' : 'Add Action'}
        </Button>
      </>
    )
  }
)

EditAppObjectAction.displayName = 'EditAppObjectAction'

export default EditAppObjectAction
