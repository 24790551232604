import * as React from 'react'
import {
  Button,
  Pane,
  Paragraph,
  Code,
  Link as EvergreenLink,
  // TODO: please fix following typescript error
  // @ts-expect-error
  withTheme,
  toaster,
  majorScale
} from 'evergreen-ui'
import {
  Link,
  withRouter,
  Redirect,
  RouteComponentProps
} from 'react-router-dom'
import { getTestPlatformContext } from '../../api/platform-user'
import { Connector, listConnectors } from '../../api/connector'
import { Platform, getPlatform } from '../../api/platform'
import { PlatformSetupStatus } from '../../api/platform-status'
import { withCredentials, CredentialConsumer } from '../login-wrapper'
import CurlBlock from '../curl-block'
import CodeBlock from '../code-block'
import SetupSection from './setup-section'
import SetupItem from './setup-item'
import SelectConnector from '../select-connector'
import TestConnectorButton from '../test-connector-button'
import PlatformOrigins from '../settings/platform-origins'
import TabbedContent from '../tabbed-content'
import errorMessage from '../../util/error-message'

// This is not a real setup step, just a legacy behavior flag
type SetupSteps = keyof Omit<
  PlatformSetupStatus,
  'use_credential_connectors' | 'use_sync_connectors'
>

const SetupOrder: SetupSteps[] = [
  'added_connector',
  'enabled_test_connection',
  'retrieved_admin_credentials',
  'provisioned_non_test_user',
  'has_external_attempted_origins',
  'has_allowed_origins'
]

function firstIncompleteStep(status: PlatformSetupStatus): SetupSteps | null {
  for (let i = 0; i < SetupOrder.length; i++) {
    if (!status[SetupOrder[i]]) {
      return SetupOrder[i]
    }
  }

  return null
}

const SetupSlugs: Record<SetupSteps, string> = Object.freeze({
  added_connector: 'add-connector',
  enabled_test_connection: 'test-connection',
  retrieved_admin_credentials: 'retrieve-admin-credentials',
  provisioned_non_test_user: 'authenticate-users',
  has_external_attempted_origins: 'install-xkit-js',
  has_allowed_origins: 'enable-cors-access'
})

interface BaseSetupProps {
  setupStatus?: PlatformSetupStatus
}

type SetupProps = CredentialConsumer<RouteComponentProps & BaseSetupProps>

interface SetupState {
  loading: boolean
  connectors: Connector[]
  testUserId?: string
  platform?: Platform
}

class Setup extends React.Component<SetupProps, SetupState> {
  constructor(props: SetupProps) {
    super(props)

    this.state = {
      loading: true,
      connectors: []
    }
  }

  componentDidMount(): void {
    document.title = 'Set up | Xkit'
    void this.loadAll()
  }

  async loadAll(): Promise<void> {
    this.setState({ loading: true })
    await Promise.all([
      this.loadConnectors(),
      this.loadTestUser(),
      this.loadPlatform()
    ])
    this.setState({ loading: false })
  }

  async loadPlatform(): Promise<void> {
    try {
      const platform = await this.props.callWithCredentials(getPlatform)
      this.setState({ platform })
    } catch (e) {
      toaster.danger(`Error while loading platform: ${errorMessage(e)}`)
    }
  }

  async loadConnectors(): Promise<void> {
    try {
      const connectors = await this.props.callWithCredentials(listConnectors)
      this.setState({ connectors })
    } catch (e) {
      toaster.danger(`Error while loading connectors: ${errorMessage(e)}`)
    }
  }

  async loadTestUser(): Promise<void> {
    try {
      const { context } = await this.props.callWithCredentials(
        getTestPlatformContext
      )
      this.setState({ testUserId: context.external_id })
    } catch (e) {
      toaster.danger(`Error while loading test user: ${errorMessage(e)}`)
    }
  }

  render(): React.ReactElement {
    const { setupStatus, match } = this.props
    const { testUserId, connectors, platform, loading } = this.state

    const isLoading = loading || setupStatus == null

    if (!isLoading && match && match.isExact) {
      const nextStep = firstIncompleteStep(setupStatus)

      if (nextStep != null) {
        return <Redirect to={`${match.url}/${SetupSlugs[nextStep]}`} />
      }
    }

    return (
      <Pane>
        <SetupSection
          title='Try Xkit'
          description='See what Xkit can do for you and your users before installing it in your app'
        >
          <SetupItem
            title='Add a connector'
            isComplete={setupStatus?.added_connector}
            parentUrl={match.url}
            parentPath={match.path}
            slug={SetupSlugs.added_connector}
            loading={isLoading}
          >
            <>
              <Paragraph marginTop='default'>
                The first step in setting up Xkit is to add a connector for a
                service you're building an integration with.
              </Paragraph>
              <Paragraph marginTop='default'>
                Our in-app documentation will walk you through the set up
                process.
              </Paragraph>
              <Button
                marginTop={majorScale(2)}
                iconBefore={setupStatus?.added_connector ? 'tick' : 'add'}
                appearance={
                  setupStatus?.added_connector ? 'default' : 'primary'
                }
                intent={setupStatus?.added_connector ? 'success' : 'none'}
                height={40}
                is={Link}
                to='/connectors/new'
              >
                Add Connector
              </Button>
            </>
          </SetupItem>
          <SetupItem
            title='Test a connector'
            isComplete={setupStatus?.enabled_test_connection}
            parentUrl={match.url}
            parentPath={match.path}
            slug={SetupSlugs.enabled_test_connection}
            loading={isLoading}
          >
            <>
              <Paragraph marginTop='default'>
                Now that you have a connector set up, you can test it to see the
                end user experience.
              </Paragraph>
              <Paragraph marginTop='default'>
                Once you have Xkit installed in your app, this is the experience
                that your users will see.
              </Paragraph>
              <Paragraph marginTop='default' marginBottom={majorScale(2)}>
                By testing a connector, you'll be creating a connection to Xkit,
                allowing you to retrieve credentials to call the APIs for that
                service.
              </Paragraph>
              <SelectConnector connectors={connectors}>
                {(selectedConnector) => (
                  <Pane marginTop={majorScale(2)}>
                    <TestConnectorButton
                      height={40}
                      connector={selectedConnector}
                      showLoggedInContext
                    >
                      Test {selectedConnector?.name} Connector
                    </TestConnectorButton>
                  </Pane>
                )}
              </SelectConnector>
            </>
          </SetupItem>
          <SetupItem
            title='Retrieve your credentials'
            isComplete={setupStatus?.retrieved_admin_credentials}
            parentUrl={match.url}
            parentPath={match.path}
            slug={SetupSlugs.retrieved_admin_credentials}
            loading={isLoading}
          >
            <>
              <Paragraph marginTop='default' marginBottom={majorScale(2)}>
                You can retrieve the credentials for your test user by using the
                code below in your shell.
              </Paragraph>

              <SelectConnector connectors={connectors}>
                {(selectedConnector) => (
                  <CurlBlock
                    // TODO: please fix following eslint error
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    path={`/api/platform/users/${testUserId}/connections/${selectedConnector?.slug}`}
                    docsLink='https://docs.xkit.co/reference#get-user-connection'
                    maxWidth={800}
                  />
                )}
              </SelectConnector>
            </>
          </SetupItem>
        </SetupSection>
        <SetupSection
          title='Install Xkit'
          description='Add Xkit to your app so you can start building your integrations'
        >
          <SetupItem
            title='Install xkit.js'
            isComplete={setupStatus?.has_external_attempted_origins}
            parentUrl={match.url}
            parentPath={match.path}
            slug={SetupSlugs.has_external_attempted_origins}
            loading={isLoading}
          >
            <Paragraph marginTop='default'>
              Add{' '}
              <EvergreenLink
                href='https://docs.xkit.co/reference#xkitjs'
                target='_blank'
              >
                xkit.js
              </EvergreenLink>{' '}
              to every page in your app where you plan to connect integrations
              and reload your app. Once we detect that the script has been
              added, we'll update this section.
            </Paragraph>
            <TabbedContent
              marginTop={majorScale(3)}
              tabs={[
                {
                  title: 'Script Tag',
                  content: (
                    <>
                      <Paragraph marginTop='default'>
                        Place the script tag below in the{' '}
                        <Code>&lt;head&gt;</Code> section of your HTML.
                      </Paragraph>
                      <CodeBlock
                        code={`<script src="https://${
                          platform?.domain ?? 'example.com'
                        }/xkit.js"></script>`}
                        // TODO: please fix following typescript error
                        // @ts-expect-error
                        language='html'
                      />
                    </>
                  )
                },
                {
                  title: 'npm Package',
                  content: (
                    <>
                      <Paragraph marginTop='default'>
                        Install xkit.js into the <Code>package.json</Code> of
                        your project.
                      </Paragraph>
                      <CodeBlock
                        code='npm install --save @xkit-co/xkit.js'
                        // TODO: please fix following typescript error
                        // @ts-expect-error
                        language='shell'
                      />
                      <Paragraph marginTop='default'>
                        Import it and instantiate it with the domain of your
                        Xkit instance.
                      </Paragraph>
                      <CodeBlock
                        code={`import initXkit from '@xkit-co/xkit.js'

const xkit = initXkit('${platform?.domain ?? 'example.com'}')`}
                        language='javascript'
                      />
                    </>
                  )
                }
              ]}
            />
          </SetupItem>
          <SetupItem
            title='Secure browser-based access'
            isComplete={setupStatus?.has_allowed_origins}
            parentUrl={match.url}
            parentPath={match.path}
            slug={SetupSlugs.has_allowed_origins}
            loading={isLoading}
          >
            <Paragraph marginTop='default'>
              In order to protect your users from malicious actors, Xkit makes
              use of{' '}
              <EvergreenLink
                href='https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS'
                target='_blank'
              >
                CORS
              </EvergreenLink>{' '}
              to prevent usage of the Xkit APIs from websites that you have not
              explicitly approved.
            </Paragraph>
            <Paragraph marginTop='default'>
              You should see the origin you just used to access Xkit listed
              below. Change its status to "allowed" to enable usage of xkit.js
              on that origin.
            </Paragraph>
            <PlatformOrigins />
          </SetupItem>
          <SetupItem
            title='Authenticate your users with Xkit'
            isComplete={setupStatus?.provisioned_non_test_user}
            parentUrl={match.url}
            parentPath={match.path}
            slug={SetupSlugs.provisioned_non_test_user}
            loading={isLoading}
          >
            <Paragraph marginTop='default' marginBottom={majorScale(2)}>
              In order to allow your users to create connections to the services
              you're building integrations with, Xkit needs to establish a
              secure session with them. With a session established between your
              user and Xkit, we know the user's ID and know they are authorized
              to create connections.
            </Paragraph>
            <Button
              height={40}
              iconBefore='document-open'
              appearance='primary'
              is='a'
              href='https://docs.xkit.co/docs/xkit-user-tokens'
              target='_blank'
            >
              Authenticate your users
            </Button>
          </SetupItem>
        </SetupSection>
      </Pane>
    )
  }
}

export default withCredentials(withTheme(withRouter(Setup)))
