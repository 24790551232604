import createXkit, { XkitJs } from '@xkit-co/xkit.js'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Platform } from '../../api/platform'
import { getConnectPlatformToken } from '../../api/platform-user'
import hostWithPort from '../../util/url'
import { CredentialsContext } from '../login-wrapper'

interface UseXkitForConnectContextProps {
  platform?: Platform
}

export interface UseXkitForConnectContextEffect {
  xkitForConnect: XkitJs | null
  logout: () => Promise<void>
}

export const useXkitForConnectContext = ({
  platform
}: UseXkitForConnectContextProps): UseXkitForConnectContextEffect => {
  const [xkitForConnect, setXkitForConnect] = useState<XkitJs | null>(null)
  const [xkitForConnectLoggedIn, setXkitForConnectLoggedIn] = useState(false)
  const { callWithCredentials } = useContext(CredentialsContext)

  useEffect(() => {
    if (!platform) {
      return
    }

    const loginXkitForConnect = async (): Promise<void> => {
      const { token, domain } = await callWithCredentials(
        getConnectPlatformToken
      )
      const xkitForConnect = createXkit(hostWithPort(domain))
      setXkitForConnect(xkitForConnect)
      await xkitForConnect.login(token)
      setXkitForConnectLoggedIn(true)
    }

    void loginXkitForConnect()
  }, [platform, callWithCredentials])

  const logout = useCallback(async () => {
    if (xkitForConnect) {
      await xkitForConnect.logout()
    }
    setXkitForConnectLoggedIn(false)
  }, [xkitForConnect])

  return {
    xkitForConnect: xkitForConnectLoggedIn ? xkitForConnect : null,
    logout
  }
}
