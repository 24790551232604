import * as React from 'react'
import { majorScale, Button } from 'evergreen-ui'

interface PageButtonsProps {
  maxPerPage: number
  index: number
  count: number
  onSetIndex: (newIndex: number) => void
}

const PageButtons: React.FC<PageButtonsProps> = ({
  maxPerPage,
  index,
  count,
  onSetIndex
}) => {
  if (count <= maxPerPage) {
    return null
  }

  const prevIndex = Math.max(0, index - maxPerPage)
  const prevPageCount = Math.min(index, maxPerPage)
  const nextIndex = Math.min(index + maxPerPage, count - maxPerPage)
  const nextPageCount = Math.max(
    Math.min(count - (index + maxPerPage), maxPerPage),
    0
  )

  return (
    <>
      <Button
        iconBefore='chevron-left'
        marginRight={majorScale(2)}
        disabled={index === 0}
        onClick={() => onSetIndex(prevIndex)}
      >
        Last {prevPageCount || ''}
      </Button>
      <Button
        iconAfter='chevron-right'
        disabled={index + maxPerPage >= count}
        onClick={() => onSetIndex(nextIndex)}
      >
        Next {nextPageCount || ''}
      </Button>
    </>
  )
}

export default PageButtons
