import * as React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import DestinationsList from './destinations-list'
import NewDestination from './new-destination'
import NewFunction from './functions/new-function'
import NewProvider from './providers/new-provider'
import ViewDestination from './view-destination'

const Destinations: React.FC = () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={match.path} exact>
        <DestinationsList />
      </Route>
      <Route path={`${match.path}/new`} exact>
        <NewDestination />
      </Route>
      <Route path={`${match.path}/new/function`} exact>
        <NewFunction />
      </Route>
      <Route
        path={`${match.path}/new/:slug`}
        render={({ match }) => {
          return <NewProvider slug={match.params.slug} />
        }}
      />
      <Route
        path={`${match.path}/:uuid`}
        render={({ match }) => {
          return <ViewDestination uuid={match.params.uuid} />
        }}
      />
    </Switch>
  )
}

export default Destinations
