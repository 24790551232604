import { Pane, PaneProps, TextInputField } from 'evergreen-ui'
import { TransformationCollect } from '../../../api/mapping'
import React, { ChangeEvent, FC, memo, useCallback } from 'react'

export interface CollectArgumentsProps extends PaneProps {
  value: TransformationCollect
  onChange: (value: TransformationCollect) => unknown
}

export const CollectArguments: FC<CollectArgumentsProps> = memo(
  ({ value, onChange, ...paneProps }) => {
    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onChange({
          ...value,
          extract_pointer: e.target.value
        })
      },
      [onChange, value]
    )

    return (
      <Pane {...paneProps}>
        <TextInputField
          label='Pointer'
          description='JSON Pointer to the field on the objects in the array to extract'
          width='100%'
          placeholder='/name'
          // TODO: better way to specify JSON pointer
          value={value.extract_pointer ?? ''}
          onChange={handleChange}
        />
      </Pane>
    )
  }
)
CollectArguments.displayName = 'CollectArguments'
