import { TokenCredentials, parseDate, request } from './request'
import { createSocket } from './socket'
import mitt, { Emitter } from 'mitt'

export enum InvocationStatus {
  success = 'success',
  error = 'error',
  running = 'running',
  timeout = 'timeout'
}

interface InvocationError {
  message: string
  type: string
  trace?: string[]
}

interface InvocationWire {
  uuid: string
  operation: string
  log_result?: string
  error?: InvocationError
  entity_external_id?: string
  status: InvocationStatus
  request_body: Record<string, any>
  response_body?: string
  response_status?: number
  inserted_at: string
  returned_at?: string
}

export interface Invocation {
  uuid: string
  operation: string
  log_result?: string
  error?: InvocationError
  entity_external_id?: string
  status: InvocationStatus
  request_body: Record<string, any>
  response_body?: string
  response_status?: number
  inserted_at: Date
  returned_at?: Date
}

interface InvocationResponse {
  invocations: Invocation[]
  nextToken: string
  hasMore: boolean
}

export function getInvocationReadableUUID(invocation: Invocation): string {
  return `#${invocation.uuid.slice(0, 4)}`
}

function parseInvocation(wire: InvocationWire): Invocation {
  return {
    ...wire,
    inserted_at: parseDate(wire.inserted_at),
    returned_at: wire.returned_at ? parseDate(wire.returned_at) : undefined
  }
}

async function listInvocations(
  credentials: TokenCredentials,
  pathPrefix: string,
  nextTokenParam?: string
): Promise<InvocationResponse> {
  const {
    invocations,
    next_token: nextToken,
    has_more: hasMore
  } = await request<{
    invocations: InvocationWire[]
    next_token: string
    has_more: boolean
  }>({
    path: `${pathPrefix}/invocations?next_token=${nextTokenParam ?? ''}`,
    credentials
  })

  return {
    invocations: invocations.map(parseInvocation),
    nextToken: nextToken,
    hasMore: hasMore
  }
}

export async function listDestinationInvocations(
  credentials: TokenCredentials,
  destinationUUID: string,
  nextTokenParam?: string
): Promise<InvocationResponse> {
  return await listInvocations(
    credentials,
    `/destinations/${destinationUUID}`,
    nextTokenParam
  )
}

export async function listSyncInvocations(
  credentials: TokenCredentials,
  pipelineUUID: string,
  externalConnectionId: string,
  nextTokenParam?: string
): Promise<InvocationResponse> {
  return await listInvocations(
    credentials,
    `/pipelines/${pipelineUUID}/connections/${externalConnectionId}`,
    nextTokenParam
  )
}

type InvocationSubscriber = (invocation: Invocation) => void
type InvocationEmitter = Emitter<{
  update: Invocation
  error: string
}>
type InvocationSubscription = [(fn: InvocationSubscriber) => void, () => void]

function subscribeToInvocations(
  credentials: TokenCredentials,
  channelName: string
): InvocationSubscription {
  const socket = createSocket(credentials)
  const channel = socket.channel(`invocations:${channelName}`)
  const emitter: InvocationEmitter = mitt()
  channel.on('update', ({ invocation }: { invocation: InvocationWire }) => {
    emitter.emit('update', parseInvocation(invocation))
  })

  channel
    .join()
    .receive('ok', () => {
      // nothing to do
    })
    .receive('error', ({ reason }) => {
      // How to handle?
      console.error(`Failed to join channel: ${String(reason)}`)
      emitter.emit('error', reason)
    })
    .receive('timeout', () => {
      console.error('Timeout while joining channel')
      emitter.emit('error', 'Timeout while joining channel')
    })

  const subscribe = (fn: InvocationSubscriber): void => {
    emitter.on('update', fn)
  }

  const unsubscribe = (): void => {
    emitter.off('update')
    channel.leave()
  }

  return [subscribe, unsubscribe]
}

export function subscribeToDestinationInvocations(
  credentials: TokenCredentials,
  destinationUUID: string
): InvocationSubscription {
  return subscribeToInvocations(credentials, `destination-${destinationUUID}`)
}

export function subscribeToSyncInvocations(
  credentials: TokenCredentials,
  pipelineUUID: string,
  externalConnectionId: string
): InvocationSubscription {
  return subscribeToInvocations(
    credentials,
    `pipeline-${pipelineUUID}|connection-${externalConnectionId}`
  )
}
