import React, { FC, memo } from 'react'
import { Pane, PaneProps } from 'evergreen-ui'

interface ConnectorMarkProps extends PaneProps {
  markUrl: string
  size?: string | number
  opacity?: number
}

const ConnectorMark: FC<ConnectorMarkProps> = memo(
  ({ markUrl, size = '1em', opacity = 1, ...paneProps }) => {
    return (
      <Pane display='flex' alignItems='center' height={size} {...paneProps}>
        <img
          src={markUrl}
          height='100%'
          style={{
            borderRadius: '5px',
            opacity
          }}
        />
      </Pane>
    )
  }
)
ConnectorMark.displayName = 'ConnectorMark'

export default ConnectorMark
