// TODO: please fix following eslint error
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React from 'react'
import { Button, Pane, TextInputField, majorScale } from 'evergreen-ui'
import { FunctionDependencies } from '../../../api/destination'
import { InputParameters } from '../../../api/destination-input'
import { InputsField } from './inputs-field'
import { VariablesField } from './variables-field'
import { BodyEditor } from './body-editor'
import { ContentCard } from '../../components/content-card'

interface FunctionFormProps {
  saving?: boolean
  loading?: boolean
  onSubmit: () => void
  name: string
  setName: (name: string) => void
  nameError?: string
  upsertBody: string
  setUpsertBody: (body: string) => void
  upsertBodyError?: string
  upsertDependencies: FunctionDependencies
  deleteBody: string
  setDeleteBody: (body: string) => void
  deleteBodyError?: string
  variables: Array<[string, string]>
  setVariables: (variables: Array<[string, string]>) => void
  variablesError?: string
  inputs: InputParameters[]
  setInputs: (inputs: InputParameters[]) => void
  inputsError?: string
  deleteDependencies: FunctionDependencies
}

const FunctionForm: React.FC<FunctionFormProps> = ({
  saving,
  loading,
  onSubmit,
  name,
  setName,
  nameError,
  variables,
  setVariables,
  variablesError,
  inputs,
  setInputs,
  inputsError,
  upsertBody,
  setUpsertBody,
  upsertBodyError,
  upsertDependencies,
  deleteBody,
  setDeleteBody,
  deleteBodyError,
  deleteDependencies
}) => {
  return (
    <>
      <ContentCard
        elevation={1}
        padding={majorScale(2)}
        marginBottom={majorScale(2)}
      >
        <Pane maxWidth={640}>
          <TextInputField
            label='Name'
            description='The name of your function destination'
            placeholder={loading ? 'Loading...' : 'My Function Destination'}
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setName(e.target.value)
            }
            onKeyDown={(e: React.KeyboardEvent) =>
              e.keyCode === 13 ? onSubmit() : null
            }
            isInvalid={nameError != null}
            validationMessage={nameError != null ? `Name ${nameError}` : null}
            disabled={saving}
          />
          <VariablesField
            // TODO: please fix following typescript error
            // @ts-expect-error
            disabled={saving || loading}
            variables={variables}
            setVariables={setVariables}
            variablesError={variablesError}
          />
          <InputsField
            // TODO: please fix following typescript error
            // @ts-expect-error
            disabled={saving || loading}
            inputs={inputs}
            setInputs={setInputs}
            inputsError={inputsError}
          />
          <BodyEditor
            label='Upsert Function'
            description='Code to be executed when an object is added or updated.'
            // TODO: please fix following typescript error
            // @ts-expect-error
            disabled={saving || loading}
            body={upsertBody}
            setBody={setUpsertBody}
            bodyError={upsertBodyError}
            dependencies={upsertDependencies}
          />
          <BodyEditor
            label='Delete Function'
            description='Code to be executed when an object is deleted.'
            // TODO: please fix following typescript error
            // @ts-expect-error
            disabled={saving || loading}
            body={deleteBody}
            setBody={setDeleteBody}
            bodyError={deleteBodyError}
            dependencies={deleteDependencies}
          />
          <Button
            appearance='primary'
            isLoading={saving || loading}
            onClick={() => onSubmit()}
          >
            {loading ? 'Loading...' : saving ? 'Saving...' : 'Save'}
          </Button>
        </Pane>
      </ContentCard>
    </>
  )
}

export default FunctionForm
