import React, { FC, memo } from 'react'
import { Heading, HeadingProps } from 'evergreen-ui'

export type HeadingSubsectionProps = HeadingProps

export const HeadingSubsection: FC<HeadingSubsectionProps> = memo(
  ({ children, ...headingProps }) => {
    return (
      <Heading size={500} {...headingProps}>
        {children}
      </Heading>
    )
  }
)
HeadingSubsection.displayName = 'HeadingSubsection'
