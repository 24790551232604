import { Card, FunctionIcon, Heading, majorScale, Pane } from 'evergreen-ui'
import React, { FC, memo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Connector } from '../../api/connector'
import { Pipeline } from '../../api/pipeline'
import ConnectorMark from '../components/connector-mark'
import { ContentCard } from '../components/content-card'
import { NewItemCard } from '../components/new-item-card'
import { SpinnerSuspend } from '../components/spinner'
import { useErrorToaster, useTitle } from '../hooks/common'
import { usePipelinesByConnector } from '../hooks/pipelines-by-connector.hook'
import { Arrow } from '../pipelines/arrow'

interface PipelineListingProps {
  pipeline: Pipeline
}

const PipelineListing: FC<PipelineListingProps> = memo(({ pipeline }) => {
  const [hover, setHover] = useState(false)
  return (
    <Card
      display='flex'
      is={Link}
      to={`/pipelines/${pipeline.uuid}`}
      textDecoration='none'
      marginTop={majorScale(2)}
      marginBottom={majorScale(4)}
      maxWidth='551px'
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
    >
      <ContentCard
        paddingLeft={majorScale(2)}
        paddingRight={majorScale(2)}
        display='flex'
        alignItems='center'
        width='250px'
        height='56px'
        elevation={hover ? 2 : 1}
        transition='box-shadow 150ms'
      >
        <ConnectorMark
          markUrl={pipeline.source.provider.mark_url}
          size={majorScale(3)}
        />
        <Heading
          size={600}
          paddingLeft={10}
          textOverflow='ellipsis'
          overflow='hidden'
          whiteSpace='nowrap'
        >
          {
            pipeline.source.source_objects.find((obj) => obj.is_selected_root)
              ?.label_many
          }
        </Heading>
      </ContentCard>
      <Arrow height={'51px'} />
      <ContentCard
        paddingLeft={majorScale(2)}
        paddingRight={majorScale(2)}
        display='flex'
        alignItems='center'
        width='250px'
        height='56px'
        elevation={hover ? 2 : 1}
        transition='box-shadow 150ms'
      >
        <FunctionIcon size={24} color='black' />
        <Heading
          size={600}
          paddingLeft={10}
          textOverflow='ellipsis'
          overflow='hidden'
          whiteSpace='nowrap'
        >
          {pipeline.destination.name}
        </Heading>
      </ContentCard>
    </Card>
  )
})

PipelineListing.displayName = 'PipelineListing'

interface PipelinesListProps {
  loading: boolean
  connector?: Connector
}

export const PipelinesList: FC<PipelinesListProps> = memo(
  ({ loading, connector }) => {
    const {
      loading: pipelinesLoading,
      error,
      pipelines
    } = usePipelinesByConnector(connector)
    useTitle(`${connector?.name ?? ''} Pipelines`)
    useErrorToaster(error)
    return (
      <>
        <Pane marginBottom={majorScale(2)} display='flex' alignItems='center'>
          <Heading size={600} marginRight={majorScale(1)}>
            Pipelines
          </Heading>
        </Pane>
        <SpinnerSuspend spinning={loading || pipelinesLoading || error != null}>
          {pipelines.map((pipeline) => (
            <PipelineListing pipeline={pipeline} key={pipeline.uuid} />
          ))}
          {connector && (
            <NewItemCard
              to={`/pipelines/new/${connector.slug}`}
              width={551}
              height={56}
              title='Add Pipeline'
            />
          )}
        </SpinnerSuspend>
      </>
    )
  }
)

PipelinesList.displayName = 'PipelinesList'
