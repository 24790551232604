import * as React from 'react'
import { Paragraph, Dialog } from 'evergreen-ui'

interface DeleteDialogProps {
  deleteName?: string
  deletingName?: string
  noun?: string
  dialogText?: React.ReactNode
  onDelete: () => Promise<void>
  children: (fn: () => void) => React.ReactNode
}

interface DeleteDialogState {
  showingDialog: boolean
  deleting: boolean
}

class DeleteDialog extends React.Component<
  DeleteDialogProps,
  DeleteDialogState
> {
  static defaultProps = {
    deleteName: 'Delete',
    deletingName: 'Deleting',
    dialogText: 'Are you sure?'
  }

  constructor(props: DeleteDialogProps) {
    super(props)
    this.state = {
      showingDialog: false,
      deleting: false
    }
  }

  handleDelete = async (): Promise<void> => {
    this.setState({ deleting: true })
    try {
      await this.props.onDelete()
    } finally {
      this.setState({ deleting: false, showingDialog: false })
    }
  }

  render(): React.ReactNode {
    const { deleteName, deletingName, noun, dialogText } = this.props
    const { showingDialog, deleting } = this.state

    return (
      <>
        {this.props.children(() => this.setState({ showingDialog: true }))}
        <Dialog
          isShown={showingDialog}
          // TODO: please fix following eslint error
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          title={`${deleteName}${noun ? ` ${noun}` : ''}`}
          intent='danger'
          onCloseComplete={() =>
            this.setState({ showingDialog: false, deleting: false })
          }
          confirmLabel={deleting ? deletingName : deleteName}
          isConfirmLoading={deleting}
          onConfirm={this.handleDelete}
        >
          <Paragraph>{dialogText}</Paragraph>
        </Dialog>
      </>
    )
  }
}

export default DeleteDialog
