import 'regenerator-runtime'
import React, { FC, memo } from 'react'
import { useTitle, useErrorToaster } from './hooks/common'
import { FogbenderSimpleWidget } from 'fogbender-react'
import { useSupportToken } from './hooks/support.hook'
import { SpinnerSuspend } from './components/spinner'

const Support: FC = memo(() => {
  const { supportToken, loading, error } = useSupportToken()
  useErrorToaster(error)
  useTitle('Support')

  if (supportToken == null) {
    return (
      <>
        <SpinnerSuspend spinning={true}></SpinnerSuspend>
      </>
    )
  }

  return (
    <>
      <SpinnerSuspend spinning={loading || error != null}>
        <FogbenderSimpleWidget
          clientUrl='https://client.fogbender.com'
          token={supportToken}
        />
      </SpinnerSuspend>
    </>
  )
})

Support.displayName = 'Support'
export default Support
