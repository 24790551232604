import { BackButton, majorScale, Pane, Text, toaster } from 'evergreen-ui'
import * as React from 'react'
import {
  Link,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from 'react-router-dom'
import {
  Authorizer,
  createAuthorizer,
  getAuthorizer,
  newAuthorizer,
  updateAuthorizer
} from '../../api/authorizer'
import {
  Connector,
  ConnectorResultValues,
  createConnector,
  newConnector,
  updateConnector
} from '../../api/connector'
import { getPlatform, Platform } from '../../api/platform'
import { Template } from '../../api/template'
import errorMessage from '../../util/error-message'
import AuthorizerForm, { getAuthorizerFields } from '../authorizer-form'
import ConnectorLabel from '../components/connector-label'
import { HeadingTitle } from '../components/heading-title'
import ContentSection from '../content-section'
import { CredentialConsumer, withCredentials } from '../login-wrapper'
import CatalogTab from './catalog-tab'
import { ConnectorHeading } from './connector-heading'
import { ConnectorHeadingSkeleton } from './connector-heading-skeleton'
import ConnectorTest from './connector-test'
import DeveloperAccountConnectTab from './developer-account-connect'
import { PermissionsTab } from './permissions-tab'

const enum NewConnectorSteps {
  authorizer = 'authorizer',
  catalog = 'catalog',
  scopes = 'scopes',
  test = 'test',
  developer = 'developer',
  done = 'done'
}

const STEP_PATHS = Object.freeze({
  [NewConnectorSteps.authorizer]: '/authorizer',
  [NewConnectorSteps.catalog]: '/catalog',
  [NewConnectorSteps.scopes]: '/permissions',
  [NewConnectorSteps.developer]: '/developer-account',
  [NewConnectorSteps.test]: '/test'
})

interface NewConnectorState {
  connector?: Connector
  isCreated: boolean
  shouldCreateAuthorizer: boolean
  authorizer?: Authorizer
  loading: boolean
  step: NewConnectorSteps
  steps: NewConnectorSteps[]
  platform?: Platform
}

function initialSteps(template?: Template): NewConnectorSteps[] {
  const initialSteps = [
    NewConnectorSteps.authorizer,
    NewConnectorSteps.catalog,
    NewConnectorSteps.scopes,
    NewConnectorSteps.test,
    NewConnectorSteps.developer,
    NewConnectorSteps.done
  ]
  return updateSteps(initialSteps, undefined, template)
}

function updateSteps(
  steps: NewConnectorSteps[],
  authorizer?: Authorizer,
  template?: Template
): NewConnectorSteps[] {
  // Skip the scopes step if there are none to select
  if (template != null && template.scopes.length === 0) {
    steps = removeStep(steps, NewConnectorSteps.scopes)
  }

  // Skip the authorizer if it has no fields to fill out
  if (authorizer != null && getAuthorizerFields(authorizer).length === 0) {
    steps = removeStep(steps, NewConnectorSteps.authorizer)
  }

  // Skip the connection test if this is a sync or crm connector
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  if (template?.sync || template?.crm) {
    steps = removeStep(steps, NewConnectorSteps.test)
  }

  // Skip linking developer account if there is no developer connector template
  if (!template?.dev_template_slug) {
    steps = removeStep(steps, NewConnectorSteps.developer)
  }

  return steps
}

function ensureStep(
  steps: NewConnectorSteps[],
  step: NewConnectorSteps
): NewConnectorSteps[] {
  const index = steps.findIndex((s) => s === step)
  if (index !== -1) {
    return steps
  }

  const insertBefore = steps.findIndex((s) => s > step)
  const insertIndex = insertBefore === -1 ? steps.length : insertBefore - 1
  const newSteps = steps.slice()
  newSteps.splice(insertIndex, 0, step)

  return newSteps
}

function removeStep(
  steps: NewConnectorSteps[],
  step: NewConnectorSteps
): NewConnectorSteps[] {
  const index = steps.findIndex((s) => s === step)
  if (index === -1) {
    return steps
  }

  const newSteps = steps.slice()
  newSteps.splice(index, 1)

  return newSteps
}

function findStepIndex(
  steps: NewConnectorSteps[],
  step: NewConnectorSteps
): number {
  const index = steps.findIndex((s) => s === step)
  if (index === -1) {
    throw new Error(
      `Step ${step} is not included in list of steps: ${steps.toString()}`
    )
  }
  return index
}

function nextStep(
  steps: NewConnectorSteps[],
  step: NewConnectorSteps
): NewConnectorSteps {
  const stepIndex = findStepIndex(steps, step)

  return steps[stepIndex + 1]
}

function prevStep(
  steps: NewConnectorSteps[],
  step: NewConnectorSteps
): NewConnectorSteps {
  return steps[findStepIndex(steps, step) - 1]
}

interface NewConnectorBaseProps {
  template?: Template
  loading: boolean
  crmOnlyConnectors?: boolean
}

type NewConnectorProps = CredentialConsumer<
  NewConnectorBaseProps & RouteComponentProps
>

class ConnectorNew extends React.Component<
  NewConnectorProps,
  NewConnectorState
> {
  constructor(props: NewConnectorProps) {
    super(props)

    const steps = initialSteps(this.props.template)

    this.state = {
      connector: this.props.template
        ? newConnector(this.props.template)
        : undefined,
      loading: true,
      isCreated: false,
      shouldCreateAuthorizer: true,
      step: steps[0],
      steps: steps
    }
  }

  componentDidMount(): void {
    void this.loadAuthorizer()
    void this.loadPlatform()
    if (this.state.connector) {
      document.title = `New ${this.state.connector.name} Connector | Xkit`
    }
  }

  componentDidUpdate(prevProps: NewConnectorProps): void {
    const prevSlug = prevProps.template
      ? prevProps.template.authorizer_prototype_slug
      : undefined
    const slug = this.props.template
      ? this.props.template.authorizer_prototype_slug
      : undefined
    if (prevSlug !== slug) {
      this.setState({
        loading: true,
        authorizer: undefined
      })
      void this.loadAuthorizer()
    }

    if (prevProps.loading && !this.props.loading && this.props.template) {
      const steps = updateSteps(
        this.state.steps,
        this.state.authorizer,
        this.props.template
      )
      const connector = newConnector(this.props.template)
      this.setState({
        connector,
        steps,
        step: steps[0]
      })
      document.title = `New ${connector.name} Connector | Xkit`
    }
  }

  // TODO: refactor all the places we load the platform with a single context
  async loadPlatform(): Promise<void> {
    try {
      const platform = await this.props.callWithCredentials(getPlatform)
      this.setState({ platform })
    } catch (e) {
      toaster.danger(`Error while loading platform: ${errorMessage(e)}`)
    }
  }

  async loadAuthorizer(): Promise<void> {
    if (!this.props.template) {
      return
    }

    const {
      template: { authorizer_prototype_slug: slug },
      callWithCredentials
    } = this.props

    this.setState({ loading: true })

    try {
      const authorizer = await callWithCredentials(
        async (credentials) => await getAuthorizer(credentials, slug)
      )
      // Authorizer exists, we can skip its creation
      const steps = removeStep(this.state.steps, NewConnectorSteps.authorizer)
      this.setState({
        authorizer,
        steps,
        step: steps[0],
        shouldCreateAuthorizer: false
      })
    } catch (e) {
      let error = e
      if (error.statusCode === 404) {
        try {
          const authorizer = await callWithCredentials(
            async (credentials) => await newAuthorizer(credentials, slug)
          )
          const steps = updateSteps(
            ensureStep(this.state.steps, NewConnectorSteps.authorizer),
            authorizer,
            this.props.template
          )
          this.setState({
            authorizer,
            shouldCreateAuthorizer: true,
            steps,
            step: steps[0]
          })
          return
        } catch (err) {
          error = err
        }
      }
      toaster.danger(
        `Error while preparing new connector: ${errorMessage(error)}`
      )
    } finally {
      this.setState({ loading: false })
    }
  }

  updateAuthorizer = async (
    params: Authorizer
    // TODO: please fix following typescript error
    // @ts-expect-error
  ): Promise<AuthorizerResultValues> => {
    const { shouldCreateAuthorizer, step, steps } = this.state

    const updateFn = shouldCreateAuthorizer
      ? createAuthorizer
      : updateAuthorizer

    const { authorizer, errors } = await this.props.callWithCredentials(
      async (credentials) => await updateFn(credentials, params)
    )

    if (!errors && authorizer) {
      this.setState({
        authorizer,
        shouldCreateAuthorizer: false,
        step:
          step === NewConnectorSteps.authorizer ? nextStep(steps, step) : step
      })
    }

    return {
      values: authorizer,
      errors
    }
  }

  updateConnector = async (
    slug: string,
    params: Connector
  ): Promise<ConnectorResultValues> => {
    const { shouldCreateAuthorizer, authorizer, isCreated, step, steps } =
      this.state

    // If the authorizer doesn't exist yet and it's one we can just create, lets do it
    if (
      shouldCreateAuthorizer &&
      getAuthorizerFields(authorizer).length === 0
    ) {
      // TODO: please fix following typescript error
      // @ts-expect-error
      const { errors } = await this.updateAuthorizer(authorizer)
      if (errors?.length) {
        throw new Error('Unexpected error while creating authorizer')
      }
    }

    const updateFn = isCreated
      ? // TODO: please fix following typescript error
        // @ts-expect-error
        async (params) =>
          await this.props.callWithCredentials(
            async (credentials) =>
              await updateConnector(credentials, slug, params)
          )
      : // TODO: please fix following typescript error
        // @ts-expect-error
        async (params) =>
          await this.props.callWithCredentials(
            async (credentials) => await createConnector(credentials, params)
          )

    const { connector, errors } = await updateFn(params)

    if (!errors && connector) {
      const next = nextStep(steps, step)
      if (next === NewConnectorSteps.done) {
        toaster.success(`Added ${this.connectorName()}`)
      }
      this.setState({
        isCreated: true,
        connector,
        step: next
      })
    }

    return {
      // TODO: please fix following typescript error
      // @ts-expect-error
      connector,
      errors
    }
  }

  connectorName(): string {
    const { template } = this.props
    const { connector } = this.state
    return connector ? connector.name : template ? template.name : ''
  }

  renderBack(): React.ReactNode {
    const { template, crmOnlyConnectors } = this.props
    const { step, steps } = this.state
    const providersNoun = crmOnlyConnectors ? 'CRMs' : 'Providers'

    let templateMode = 'auth'
    if (template?.sync) {
      templateMode = 'data'
    }
    if (template?.crm) {
      templateMode = 'crm'
    }

    if (step <= steps[0]) {
      return (
        <BackButton is={Link} to={`/providers?type=${templateMode}`}>
          Back to {providersNoun}
        </BackButton>
      )
    }

    return (
      <BackButton
        onClick={() => this.setState({ step: prevStep(steps, step) })}
      />
    )
  }

  renderCounter(): React.ReactNode {
    const { loading: templateLoading } = this.props
    const { step, steps, loading: authorizerLoading } = this.state

    if (templateLoading || authorizerLoading || steps.length <= 1) {
      return
    }

    const stepNumber = findStepIndex(steps, step) + 1
    // Remove the `done` step
    const totalSteps = steps.length - 1

    return (
      <Text color='muted' marginLeft={majorScale(2)} marginTop='auto'>
        Step {stepNumber} of {totalSteps}
      </Text>
    )
  }

  render(): React.ReactNode {
    const {
      template,
      loading: templateLoading,
      match,
      crmOnlyConnectors
    } = this.props
    const {
      platform,
      connector,
      authorizer,
      loading: authorizerLoading,
      shouldCreateAuthorizer,
      steps,
      step
    } = this.state

    let templateMode = 'auth'
    if (template?.sync) {
      templateMode = 'data'
    }
    if (template?.crm) {
      templateMode = 'crm'
    }

    if (!template && !templateLoading) {
      toaster.danger('Could not load template to proceed with creation')
      return <Redirect to='/providers' />
    }

    if (!authorizer && !authorizerLoading && !shouldCreateAuthorizer) {
      return <Redirect to={`/providers?type=${templateMode}`} />
    }

    if (step === NewConnectorSteps.done) {
      return <Redirect to={`/providers/${connector?.slug ?? ''}`} />
    }

    return (
      <Pane>
        <HeadingTitle display='flex' alignItems='center'>
          {templateLoading || authorizerLoading ? (
            <ConnectorHeadingSkeleton />
          ) : (
            <>
              <ConnectorHeading
                connector={template}
                marginRight={majorScale(2)}
              />
              <ConnectorLabel connector={template} marginTop='auto' />
              {this.renderCounter()}
            </>
          )}
        </HeadingTitle>
        <Switch>
          <Route
            path={`${match.path}${STEP_PATHS[NewConnectorSteps.authorizer]}`}
          >
            {step !== NewConnectorSteps.authorizer ? (
              <Redirect to={match.url} />
            ) : (
              ''
            )}
            <ContentSection
              title={
                crmOnlyConnectors ? 'Credentials' : 'Service Provider Settings'
              }
            >
              <Pane maxWidth={400}>
                <AuthorizerForm
                  isCRMConnector={connector?.crm}
                  platform={platform}
                  loading={authorizerLoading}
                  authorizer={authorizer}
                  updateAuthorizer={this.updateAuthorizer}
                />
              </Pane>
            </ContentSection>
          </Route>
          <Route path={`${match.path}${STEP_PATHS[NewConnectorSteps.catalog]}`}>
            {step !== NewConnectorSteps.catalog ? (
              <Redirect to={match.url} />
            ) : (
              ''
            )}
            <CatalogTab
              title={crmOnlyConnectors ? 'Setup' : 'Catalog'}
              connector={connector}
              loading={templateLoading}
              update={this.updateConnector}
              // TODO: please fix following typescript error
              // @ts-expect-error
              platform={platform}
            />
          </Route>
          <Route path={`${match.path}${STEP_PATHS[NewConnectorSteps.scopes]}`}>
            {step !== NewConnectorSteps.scopes ? (
              <Redirect to={match.url} />
            ) : (
              ''
            )}
            <PermissionsTab
              showTitle
              connector={connector}
              loading={templateLoading}
              updateConnector={this.updateConnector}
              crmOnlyConnectors={crmOnlyConnectors}
            />
          </Route>
          <Route path={`${match.path}${STEP_PATHS[NewConnectorSteps.test]}`}>
            {step !== NewConnectorSteps.test ? <Redirect to={match.url} /> : ''}
            <ConnectorTest
              connector={connector}
              onComplete={() => this.setState({ step: nextStep(steps, step) })}
            />
          </Route>
          <Route
            path={`${match.path}${STEP_PATHS[NewConnectorSteps.developer]}`}
          >
            {step !== NewConnectorSteps.developer ? (
              <Redirect to={match.url} />
            ) : (
              ''
            )}
            <DeveloperAccountConnectTab
              loading={false}
              platformSlug={platform?.slug}
              connector={connector}
              onComplete={() => this.setState({ step: nextStep(steps, step) })}
            />
          </Route>
          <Route>
            {STEP_PATHS[step] ? (
              <Redirect to={`${match.url}${STEP_PATHS[step]}`} />
            ) : null}
          </Route>
        </Switch>
        {this.renderBack()}
      </Pane>
    )
  }
}

export default withCredentials(withRouter(ConnectorNew))
