import createXkit, { XkitJs } from '@xkit-co/xkit.js'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Platform } from '../../api/platform'
import {
  getTestPlatformContext,
  PlatformContext
} from '../../api/platform-user'
import hostWithPort from '../../util/url'
import { CredentialsContext } from '../login-wrapper'

interface UseTestUserEffectProps {
  platform?: Platform
}

export interface UseTestUserEffect {
  xkit: XkitJs | null
  context: PlatformContext | null
  logout: () => Promise<void>
}

export const useTestContext = ({
  platform
}: UseTestUserEffectProps): UseTestUserEffect => {
  const [xkitTestContext, setXkitTestContext] =
    useState<PlatformContext | null>(null)
  const [xkit, setXkit] = useState<XkitJs | null>(null)
  const [xkitLoggedIn, setXkitLoggedIn] = useState(false)
  const { callWithCredentials } = useContext(CredentialsContext)

  const getTestContext = useCallback(async (): Promise<string> => {
    const { context, accessToken } = await callWithCredentials(
      getTestPlatformContext
    )
    setXkitTestContext(context)

    return accessToken
  }, [callWithCredentials])

  useEffect(() => {
    if (!platform) {
      return
    }

    setXkit(createXkit(hostWithPort(platform.domain)))
  }, [platform])

  useEffect(() => {
    if (!xkit) {
      return
    }

    const loginXkit = async (): Promise<void> => {
      await xkit.login(async () => {
        return await getTestContext()
      })
      setXkitLoggedIn(true)
    }

    void loginXkit()
  }, [xkit, getTestContext])

  const logout = useCallback(async () => {
    if (xkit) {
      await xkit.logout()
    }
    setXkitLoggedIn(false)
    setXkitTestContext(null)
  }, [xkit])

  return {
    context: xkitTestContext,
    xkit: xkitLoggedIn ? xkit : null,
    logout
  }
}
