import { useCallback, useContext, useState, useEffect } from 'react'
import { Pipeline, getPipeline } from '../../api/pipeline'
import { CredentialsContext } from '../login-wrapper'

export interface UsePipelineState {
  loading: boolean
  error?: string
  pipeline?: Pipeline
  fetch: () => void
}

export const usePipeline = (uuid: string): UsePipelineState => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const [pipeline, setPipeline] = useState<Pipeline | undefined>(undefined)
  const { callWithCredentials } = useContext(CredentialsContext)

  const fetch = useCallback(async () => {
    try {
      setLoading(true)
      const pipeline = await callWithCredentials(
        async (creds) => await getPipeline(creds, uuid)
      )
      setPipeline(pipeline)
    } catch (error) {
      setError(`Error while loading pipeline: ${String(error.message)}`)
    } finally {
      setLoading(false)
    }
  }, [uuid, callWithCredentials])

  useEffect(() => {
    void fetch()
  }, [fetch])

  return {
    loading,
    error,
    pipeline,
    fetch
  }
}
