import React, { memo } from 'react'
import { Spinner } from 'evergreen-ui'
import { InvocationStatus } from '../../api/invocation'
import { StatusIcon } from '../components/status-icon'

const INVOCATION_STATUS_LABEL: Record<InvocationStatus, string> = {
  [InvocationStatus.success]: 'Success',
  [InvocationStatus.error]: 'Error',
  [InvocationStatus.running]: 'Pending',
  [InvocationStatus.timeout]: 'Timeout'
}

export const InvocationStatusIcon: React.FunctionComponent<{
  status: InvocationStatus
}> = memo(({ status }) => {
  const tooltip = INVOCATION_STATUS_LABEL[status]

  if (status === InvocationStatus.success) {
    return <StatusIcon intent='success' tooltip={tooltip} />
  }

  if (status === InvocationStatus.running) {
    return <Spinner size={16} />
  }

  return <StatusIcon intent='warning' tooltip={tooltip} />
})

InvocationStatusIcon.displayName = 'InvocationStatusIcon'
